import React from "react";
import MediaService from "../../services/MediaService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTachometerAlt } from "@fortawesome/pro-light-svg-icons";
import TimeService from "../../services/TimeService";
import { JobTCOffsets } from "@sumit-platforms/types";

export const Times = ({
  tcOffsets,
  timerEl,
  timerTcEl,
  mediaLength,
}: {
  tcOffsets?: JobTCOffsets;
  timerEl?: React.RefObject<HTMLDivElement>;
  timerTcEl?: React.RefObject<HTMLDivElement>;
  mediaLength?: number;
}) => {
  return (
    <div className="mediaTimeRange">
      {MediaService.status === "buffering" ? (
        <div className="curr-time unselectable">Buffering...</div>
      ) : (
        <>
          {tcOffsets && tcOffsets[0] && tcOffsets[0][1] > 0 && (
            <div className="tc-offset-time unselectable" ref={timerTcEl} />
          )}
          <div className="curr-time unselectable" ref={timerEl} />
          <div className="">/</div>
          <div className="curr-time unselectable">
            {mediaLength ? TimeService.getTimeStringFromSecs(mediaLength) : ""}
          </div>
        </>
      )}
    </div>
  );
};

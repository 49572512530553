import {
  Client,
  DocxTemplate,
  Job,
  JobStatus,
  JobTypeMeta,
  User,
  UserStatus,
  JobCommunityMembers,
  ValidationJobTypes,
  JobTranslationType,
  DocxFormatType,
  JobBonus,
  Tag,
  EnrichModelStatus,
  EnrichRequestStatus,
  IndexSearch,
} from "@sumit-platforms/types";
import { format } from "date-fns";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  CommunityMembersTableCell,
  ContextOption,
  HeadCell,
  IconAction,
  StatusCell,
  TableCell,
  TableContext,
  TableIconActions,
  PaymentsOverviewRow,
  JobTypeCell,
  IndexSearchStatusSummary,
  failedColor,
  primaryColor,
  grayColor3,
  TableCellToolTip,
  successColor2,
  failedColor2,
} from "../../index";
import {
  getPriceStringWithCurrency,
  getShortArrayString,
  secondsToTimeString,
} from "../utils/formatters";
import React, { CSSProperties, useCallback } from "react";
import {
  formatPhoneByCountry,
  getMustStatusVisual,
  getTcString,
  getUserRolesString,
  isJobEditorAccessible,
  isJobEditorAccessibleForClient,
} from "../../utils";
import { userImage } from "@sumit-platforms/common-assets/images";
import { Box, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import {
  DAY_MONTH_YEAR,
  DATE_FORMAT_1,
  DATE_FORMAT_2,
} from "../constants/globals";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAdd, faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";

//TODO: manage all column names
export type AvailableHeadCellsKeys =
  | "id"
  | "income"
  | "contextMenu"
  | "members"
  | "type"
  | "mustType"
  | "delivery"
  | "deliveryWithTranscribeDelivery"
  | "transcribeDelivery"
  | "duration"
  | "status"
  | "mustJobStatus"
  | "communityMemberStatus"
  | "client"
  | "clientLink"
  | "contactEmail"
  | "billingContact"
  | "yearlyTarget"
  | "name"
  | "mustJobName"
  | "templateId"
  | "templateName"
  | "templateType"
  | "userId"
  | "userName"
  | "userEmail"
  | "userPhoneNumber"
  | "editAddUser"
  | "removeUserFromClient"
  | "validationId"
  | "validationName"
  | "validationJobType"
  | "validationCreatedAt"
  | "removeAddValidation"
  | "month"
  | "jobsAmount"
  | "totalPayments"
  | "jobTask"
  | "actions"
  | "payment"
  | "paymentType"
  | "invoiceNumber"
  | "invoiceStatus"
  | "project"
  | "createdAt"
  | "projectName"
  | "projectVolume"
  | "projectLastActivity";

interface JobRow {
  id: number;
  name: string;
  client: Client;
  type: JobTypeMeta;
  status: JobStatus;
  duration: number;
  income: number;
  uploaded: Date;
  delivery: Date;
  contextMenu: any;
  job: Job;
  members?: JobCommunityMembers;
  sttProgress?: number;
  bonus?: Partial<JobBonus>;
  createdAt?: Date;
}

interface UserRow {
  id: number;
  name: string;
  contact: any;
  status: UserStatus;
  paymentAndCapacity: any;
  context: any;
  user: User;
  actions: any;
}

interface TemplateRow {
  id: number;
  name: string;
  template: DocxTemplate;
  type: DocxFormatType;
  actions: any;
}

interface ValidationRow {
  id: number;
  name: string;
  jobType: ValidationJobTypes;
  isDefault: boolean;
  createdAt: Date;
  actions: any;
}

interface ProjectRow {
  id: number;
  name: string;
  jobsAmount?: number;
  totalJobsDuration?: number;
  lastActivity?: Date;
}

interface UseHeadCellsProps {
  headCellsKeys: AvailableHeadCellsKeys[];
  // table context call back will get a row and return context configuration
  barColActions?: Partial<Record<AvailableHeadCellsKeys, any[]>>;
  tableContextCallBack?: (row: any) => ContextOption[];
  statusCellDropDownCallBack?: (row: any) => ContextOption[];
  cellAction?: Partial<Record<AvailableHeadCellsKeys, (row?: any) => void>>;
  cellActions?: Partial<Record<AvailableHeadCellsKeys, IconAction[]>>;
  cellLink?: Partial<Record<AvailableHeadCellsKeys, (row?: any) => string>>;
  labelAction?: Partial<Record<AvailableHeadCellsKeys, (row?: any) => void>>;
  labelIcon?: Partial<Record<AvailableHeadCellsKeys, IconProp | null>>;
  labelTooltip?: Partial<Record<AvailableHeadCellsKeys, string>>;
  styles?: Partial<Record<AvailableHeadCellsKeys, CSSProperties>>;
  userStatus?: UserStatus;
  selectedRows?: any[];
}

export const getIndexSearchStatusSummaryTooltip = (
  indexSearch: IndexSearch[]
): TableCellToolTip | undefined => {
  let toolTip: TableCellToolTip | undefined;
  if (!_.isEmpty(indexSearch)) {
    const hasDeficiencies =
      indexSearch[0].status === EnrichRequestStatus.FAILED ||
      (!_.isEmpty(indexSearch[0].models) &&
        indexSearch[0].models?.some((m: EnrichModelStatus) => !m.success));

    const color = hasDeficiencies
      ? failedColor
      : indexSearch[0].status === EnrichRequestStatus.DONE
      ? successColor2
      : grayColor3;

    toolTip = {
      content: (
        <IndexSearchStatusSummary
          status={indexSearch[0].status}
          color={color === failedColor ? failedColor2 : color}
          hasDeficiencies={hasDeficiencies}
        />
      ),
      icon: <FontAwesomeIcon icon={faMagnifyingGlass} />,
      color,
    };
  }
  return toolTip;
};

export const useHeadCells = ({
  headCellsKeys,
  barColActions,
  tableContextCallBack,
  statusCellDropDownCallBack,
  cellAction,
  cellActions,
  cellLink,
  labelAction,
  labelIcon,
  labelTooltip,
  styles,
  selectedRows,
}: UseHeadCellsProps) => {
  const { t } = useTranslation();
  const getStatusCellDropDownOptions = useCallback((row: any) => {
    if (statusCellDropDownCallBack) {
      return statusCellDropDownCallBack(row);
    }
    return [];
  }, []);

  const getCompletedOnToolTip = useCallback((r: JobRow) => {
    return r?.job?.status === JobStatus.done && r?.job?.doneAt
      ? {
          icon: <FontAwesomeIcon icon={faInfoCircle} />,
          content: (
            <>{`${t("completed_on")} ${format(
              new Date(r.job?.doneAt),
              DAY_MONTH_YEAR
            )}`}</>
          ),
          color: "inherit",
        }
      : undefined;
  }, []);

  const getLabelIcon = (key: AvailableHeadCellsKeys) => {
    return labelIcon && labelIcon[key] ? (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          fontSize: "24px",
          cursor: "pointer",
          padding: "0 10px",
        }}
      >
        <FontAwesomeIcon icon={labelIcon[key] as IconProp} />
      </Box>
    ) : undefined;
  };

  const jobsTableHeadCellsBank: Partial<
    Record<AvailableHeadCellsKeys, HeadCell<any>>
  > = {
    id: {
      id: "id",
      style: { width: "2%" },
      label: "",
      formatter: (r) => {
        return r.id ? <TableCell secondaryText={`#${r.id}`} /> : null;
      },
    },
    name: {
      id: "name",
      label: t("name"),
      style: { width: "30%" },
      barColActions:
        barColActions && barColActions["name"] ? barColActions["name"] : [],
      formatter: (r: any) => {
        const getSecondaryText = (row: any) => {
          return row.job?.createdAt
            ? format(new Date(row.job?.createdAt || null), DAY_MONTH_YEAR)
            : row.user?.roles?.length
            ? row.user?.roles
                .map((role: any) => t(getUserRolesString(role.idRole)))
                .join(", ")
            : row?.paymentDate
            ? format(new Date(row.paymentDate || null), DAY_MONTH_YEAR)
            : "";
        };
        return (
          <TableCell
            primaryText={r.name}
            boldTitle={r.isBonus}
            primaryLink={
              isJobEditorAccessible(
                r.status as JobStatus,
                r.job?.jobTranslation || null
              )
                ? cellLink && cellLink.name
                  ? cellLink.name(r)
                  : null
                : null
            }
            secondaryText={getSecondaryText(r)}
            icon={"job" in r ? undefined : userImage}
            toolTip={getIndexSearchStatusSummaryTooltip(r.indexSearch)}
          />
        );
      },
    },
    mustJobName: {
      id: "name",
      label: t("name"),
      style: { width: "30%" },
      barColActions:
        barColActions && barColActions["name"] ? barColActions["name"] : [],
      formatter: (r: any) => {
        return (
          <TableCell
            primaryText={r.name}
            primaryLink={
              isJobEditorAccessibleForClient(r.status as JobStatus)
                ? cellLink && cellLink.name
                  ? cellLink.name(r)
                  : null
                : null
            }
            secondaryText={
              r.createdAt ? format(r.createdAt, DATE_FORMAT_1) : ""
            }
            icon={"job" in r ? undefined : userImage}
            toolTip={getIndexSearchStatusSummaryTooltip(r.indexSearch)}
          />
        );
      },
    },
    client: {
      id: "client",
      style: { width: "15%" },
      label: t("client"),
      formatter: (r) => {
        <TableCell
          primaryText={r?.client?.name || "-"}
          linkFlavor="hidden"
          secondaryText={r.job?.project?.name || "-"}
        />;
      },
    },
    clientLink: {
      id: "client",
      style: { width: "15%" },
      label: t("client"),
      formatter: (r) => (
        <TableCell
          primaryText={r?.client?.name || "-"}
          primaryLink={`/clients/${r.client.idClient}`}
          linkFlavor="hidden"
          secondaryText={r.job?.project?.name || "-"}
        />
      ),
    },
    members: {
      id: "members",
      label: t("members"),
      style: { width: "20%" },
      formatter: (r: JobRow) => {
        return (
          <CommunityMembersTableCell
            members={r.members}
            prices={{ qc: r.job?.qcPrice, transcribe: r.job?.transcribePrice }}
            bonus={r.job.jobBonus}
          />
        );
      },
    },
    type: {
      id: "type",
      label: t("type"),
      style: { width: "8%" },
      formatter: (r) => {
        if (!r.job) return "-";

        return (
          <JobTypeCell
            inputLanguage={r.job.inputLanguage}
            outputLanguage={r.job.outputLanguage}
            translation={
              r.job.translation || r.job.jobSplit?.sourceJob?.translation
            }
            autoTranscriptDelivery={r.job.autoTranscriptDelivery}
            type={r.job.type}
            autoTranscriptIndication={"text"}
          />
        );
      },
    },
    mustType: {
      id: "type",
      label: t("type"),
      style: { width: "8%" },
      formatter: (r) => {
        if (!r.job) return "-";

        return (
          <JobTypeCell
            inputLanguage={r.job.inputLanguage}
            outputLanguage={r.job.outputLanguage}
            translation={
              r.job.translation || r.job.jobSplit?.sourceJob?.translation
            }
            autoTranscriptDelivery={r.job.autoTranscriptDelivery}
            type={r.job.type}
            autoTranscriptIndication={"icon"}
          />
        );
      },
    },
    paymentType: {
      id: "paymentType",
      label: t("type"),
      formatter: (r) => {
        const outputLanguage = r.job?.outputLanguage
          ? r.job.outputLanguage[0]
          : "";
        return (
          <TableCell
            primaryText={r.paymentType}
            secondaryText={t(outputLanguage)}
          />
        );
      },
    },
    status: {
      id: "status",
      label: t("status"),
      style: { width: "15%" },
      formatter: (r: JobRow) => {
        return (
          <StatusCell
            status={r.status}
            progress={r.sttProgress}
            type={"job"}
            dropDownOptions={getStatusCellDropDownOptions(r)}
            toolTip={getCompletedOnToolTip(r)}
          />
        );
      },
    },
    mustJobStatus: {
      id: "status",
      label: t("status"),
      style: { width: "15%" },
      sortable: false,
      formatter: (r: JobRow) => {
        return (
          <StatusCell
            status={r.status}
            type={"client"}
            toolTip={getCompletedOnToolTip(r)}
          />
        );
      },
    },
    communityMemberStatus: {
      id: "communityMemberStatus",
      label: t("status"),
      style: { width: "12%" },
      formatter: (r: UserRow) => {
        return (
          <StatusCell
            status={r.status}
            type={"user"}
            dropDownOptions={getStatusCellDropDownOptions(r)}
          />
        );
      },
    },
    duration: {
      id: "duration",
      label: t("duration"),
      style: { width: "8%" },
      formatter: (r: JobRow) => (
        <Tooltip
          title={
            !r?.job?.tcOffsets
              ? `${t("no_timecode_set")}`
              : `${t("timecode")}: ${getTcString(0, r?.job?.tcOffsets, false)}`
          }
          placement="bottom-start"
        >
          <div>{secondsToTimeString(r.duration)}</div>
        </Tooltip>
      ),
      barColData: () => {
        return (
          selectedRows &&
          selectedRows?.length > 0 &&
          _.has(selectedRows[0], "job.duration") &&
          secondsToTimeString(_.sumBy(selectedRows, "job.duration"))
        );
      },
    },
    delivery: {
      id: "delivery",
      label: t("deadline"),
      formatter: (r: JobRow) => (
        <TableCell primaryText={format(r.delivery, DATE_FORMAT_1)} />
      ),
    },
    deliveryWithTranscribeDelivery: {
      id: "deliveryWithTranscribeDelivery",
      label: t("deadline"),
      style: { width: "12%" },
      formatter: (r: any) => {
        if (!r.delivery && !r.job?.transcribeDelivery && r.isBonus) {
          return "-";
        }
        const delivery = r.delivery
          ? format(r.delivery, DATE_FORMAT_1)
          : t("not_selected");
        const transcribeDelivery = r.job?.transcribeDelivery
          ? format(new Date(r.job.transcribeDelivery), DATE_FORMAT_1)
          : t("not_selected");
        return (
          <TableCell
            primaryText={"C - " + delivery}
            secondaryText={"T - " + transcribeDelivery}
          />
        );
      },
    },
    transcribeDelivery: {
      id: "transcribeDelivery",
      label: t("deadline"),
      formatter: (r: JobRow) => {
        try {
          const transcribeDelivery = r.job.transcribeDelivery
            ? format(new Date(r.job.transcribeDelivery), DATE_FORMAT_1)
            : " - ";
          return <TableCell primaryText={transcribeDelivery} />;
        } catch (e) {
          return "";
        }
      },
    },
    income: {
      id: "income",
      label: t("income"),
      formatter: (r) => <TableCell primaryText={r["income"]?.toString()} />,
    },
    payment: {
      id: "payment",
      label: t("payment"),
      formatter: (r) => <TableCell primaryText={r.payment} />,
      barColData: () => {
        const moreThanOne = selectedRows && selectedRows.length > 1;
        const hasPayments = _.every(selectedRows, (row) => !!row.payment);
        const totalPayments = _.sumBy(selectedRows, "job.payment");
        const formattedTotalPayments = getPriceStringWithCurrency({
          price: totalPayments,
          currency: "ILS",
        });
        if (moreThanOne && hasPayments && formattedTotalPayments) {
          return formattedTotalPayments;
        } else return null;
      },
    },
    templateId: {
      id: "id",
      label: "",
      style: {
        width: "1%",
        maxWidth: 80,
      },
      hideColumn: true,
    },
    templateName: {
      id: "name",
      label: t("name"),
      formatter: (r: TemplateRow) => {
        return <TableCell primaryText={r.name} />;
      },
    },
    templateType: {
      id: "type",
      label: t("type"),
      formatter: (r: TemplateRow) => {
        return <TableCell primaryText={t(r.type)} />;
      },
    },
    contactEmail: {
      id: "contactEmail",
      label: t("contact"),
      formatter: (r: UserRow) => {
        return (
          <TableCell primaryText={r.user.email} secondaryText={r.user.phone} />
        );
      },
    },
    userId: {
      id: "id",
      label: "",
      style: {
        width: "1%",
        maxWidth: 80,
      },
      hideColumn: true,
    },
    userName: {
      id: "name",
      label: t("name"),
      formatter: (r: UserRow) => {
        return (
          <TableCell
            primaryText={r.name}
            secondaryText={
              !_.isEmpty(r.user?.roles)
                ? t(r.user?.roles[0].idRole?.toLocaleLowerCase())
                : t("no_role")
            }
            icon={userImage}
          />
        );
      },
    },
    userEmail: {
      id: "email",
      label: t("email"),
      formatter: (r: UserRow) => {
        return (
          <TableCell
            primaryText={
              r.user.email.charAt(0).toUpperCase() + r.user.email.slice(1)
            }
          />
        );
      },
    },
    userPhoneNumber: {
      id: "phoneNumber",
      label: t("phone_number"),
      formatter: (r: UserRow) => {
        return <TableCell primaryText={formatPhoneByCountry(r.user.phone)} />;
      },
    },
    validationId: {
      id: "id",
      label: "",
      style: {
        width: "1%",
        maxWidth: 80,
      },
      hideColumn: true,
    },
    validationName: {
      id: "name",
      label: t("name"),
      formatter: (r: ValidationRow) => {
        return <TableCell primaryText={r.name} />;
      },
    },
    validationJobType: {
      id: "jobType",
      label: t("type"),
    },
    validationCreatedAt: {
      id: "createdAt",
      label: t("created_date"),
      formatter: (r: ValidationRow) => {
        return format(r.createdAt, DAY_MONTH_YEAR);
      },
    },
    month: {
      id: "month",
      label: t("month"),
      formatter: (r: PaymentsOverviewRow) => {
        return (
          <TableCell
            primaryText={r.monthString}
            primaryLink={cellLink?.month ? cellLink.month(r) : null}
          />
        );
      },
    },
    jobTask: {
      id: "jobTask",
      label: t("task"),
      style: { width: "13%" },
      formatter: (r) => {
        return <TableCell primaryText={r.jobTask as string} />;
      },
    },
    jobsAmount: {
      id: "jobsAmount",
      label: t("jobs"),
      formatter: (r: PaymentsOverviewRow) => {
        return (
          <TableCell
            primaryText={`${
              _.isNil(r.jobsAmount) ? "-" : `${r.jobsAmount} Jobs`
            }`}
          />
        );
      },
    },
    totalPayments: {
      id: "totalPayments",
      label: t("payments"),
      formatter: (r: PaymentsOverviewRow) => {
        return <TableCell primaryText={r.totalPaymentsString} />;
      },
    },
    billingContact: {
      id: "billingContact",
      label: t("billing_contact"),
      formatter: (r: any) => {
        return (
          <TableCell
            primaryText={r.client.contactEmail || "-"}
            secondaryText={r.client.contactPhone || "-"}
          />
        );
      },
    },
    yearlyTarget: {
      id: "yearlyTarget",
      label: t("yearly_target"),
      formatter: (r: any) => {
        return (
          <TableCell
            primaryText={
              getPriceStringWithCurrency({
                currency: "ILS",
                price: r.client.yearlyTarget,
              }) || "-"
            }
          />
        );
      },
    },
    invoiceNumber: {
      id: "invoiceNumber",
      label: t("invoice_number"),
      formatter: (r: any) => {
        return <TableCell primaryText={r.invoiceNumber || "-"} />;
      },
      style: {
        width: "30%",
        maxWidth: 500,
      },
    },
    invoiceStatus: {
      id: "invoiceStatus",
      label: t("status"),
      formatter: (r: any) => {
        return (
          <StatusCell
            status={r.status}
            type={"invoice"}
            dropDownOptions={getStatusCellDropDownOptions(r)}
          />
        );
      },
      style: {
        width: "20%",
        maxWidth: 250,
      },
    },
    actions: {
      id: "actions",
      label: getLabelIcon("actions") || "",
      onLabelClick: () => {
        if (labelAction && labelAction["actions"]) labelAction["actions"]();
      },
      formatter: (row: any) => {
        if (cellActions && cellActions["actions"]) {
          return (
            <TableIconActions row={row} onRowActions={cellActions["actions"]} />
          );
        }
        return null;
      },
    },
    project: {
      id: "projectName",
      label: t("project"),
      style: { width: 200, maxWidth: 200 },
      formatter: (r: JobRow) => (
        <TableCell primaryText={r.job?.project?.name} />
      ),
    },
    projectName: {
      id: "name",
      label: t("name"),
      style: { width: 200, maxWidth: 200 },
      formatter: (r: ProjectRow) => <TableCell primaryText={r.name} />,
    },
    projectVolume: {
      id: "projectVolume",
      label: t("volume"),
      style: { width: 200, maxWidth: 200 },
      formatter: (r: ProjectRow) => (
        <TableCell
          primaryText={`${r.jobsAmount} ${t("jobs")}`}
          secondaryText={`${(r.totalJobsDuration! / 60).toFixed(2)} ${t(
            "mins"
          )}`}
        />
      ),
    },
    projectLastActivity: {
      id: "lastActivity",
      label: t("last_activity"),
      style: { width: 200, maxWidth: 200 },
      formatter: (r: ProjectRow) => (
        <TableCell
          primaryText={
            r.lastActivity ? format(r.lastActivity!, DAY_MONTH_YEAR) : "-"
          }
        />
      ),
    },
    createdAt: {
      id: "createdAt",
      label: t("upload_time"),
      style: { width: 200, maxWidth: 200 },
      formatter: (r: JobRow) => {
        return r.createdAt ? format(r.createdAt, DATE_FORMAT_1) : "";
      },
    },
  };

  if (tableContextCallBack) {
    jobsTableHeadCellsBank.contextMenu = {
      id: "contextMenu",
      label: getLabelIcon("contextMenu") || "",
      onLabelClick: () => {
        if (labelAction && labelAction["contextMenu"]) {
          labelAction["contextMenu"]();
        }
      },
      barColActions:
        barColActions && barColActions["contextMenu"]
          ? barColActions["contextMenu"]
          : [],
      formatter: (row: JobRow | UserRow) => (
        <TableContext
          context={tableContextCallBack(row).map((context) => {
            return {
              name: t(context.name),
              action: context.action,
              color: context.color,
              disabled: context.disabled,
            };
          })}
          row={row}
        />
      ),
    };
  }
  const addCss = (key: AvailableHeadCellsKeys, headCell: HeadCell<any>) => {
    if (styles) {
      headCell.style = {
        ...(headCell.style || {}),
        ...(styles[key as AvailableHeadCellsKeys] || {}),
      };
    }
  };
  const addHeadCellTooltip = (
    key: AvailableHeadCellsKeys,
    headCell: HeadCell<any>
  ) => {
    if (labelTooltip && labelTooltip[key as AvailableHeadCellsKeys]) {
      const headCellTooltipText = labelTooltip[key];
      const originalLabel = headCell.label;
      headCell.label = (
        <Tooltip placement={"top"} title={headCellTooltipText}>
          {originalLabel}
        </Tooltip>
      );
    }
  };
  const addCellLink = (
    key: AvailableHeadCellsKeys,
    headCell: HeadCell<any>
  ) => {
    if (cellLink && cellLink[key as AvailableHeadCellsKeys]) {
      const headCellLink = cellLink[key];
      const originalFormatter = headCell.formatter as any;
      if (!headCellLink) return;
      headCell.formatter = (rowData: any) => {
        const originalComponent = originalFormatter(rowData);
        if (originalComponent.props.primaryLink === undefined) {
          return React.cloneElement(originalComponent, {
            primaryLink: headCellLink(rowData),
          });
        }
        return originalComponent;
      };
    }
  };

  const addCellAction = (
    key: AvailableHeadCellsKeys,
    headCell: HeadCell<any>
  ) => {
    if (cellAction && cellAction[key as AvailableHeadCellsKeys]) {
      const headCellAction = cellAction[key];
      const originalFormatter = headCell.formatter as any;
      if (!headCellAction) return;
      headCell.formatter = (rowData: any) => {
        const originalComponent = originalFormatter(rowData);
        const action = _.isFunction(cellAction[key]) ? cellAction[key] : _.noop;
        return (
          <Box className={"pointer"} onClick={() => action!(rowData)}>
            {originalComponent}
          </Box>
        );
      };
    }
  };

  const addLabelIcons = (
    key: AvailableHeadCellsKeys,
    headCell: HeadCell<any>
  ) => {
    if (labelIcon && labelIcon[key as AvailableHeadCellsKeys]) {
      const cellLabelIcon = labelIcon[key];
      const originalLabel = headCell.label as any;
      if (!cellLabelIcon) return originalLabel;
      headCell.label = cellLabelIcon ? (
        <Box sx={{ margin: "auto" }}>
          <FontAwesomeIcon icon={cellLabelIcon} size={"lg"} />
        </Box>
      ) : (
        originalLabel
      );
    }
  };

  const getHeadCells = (headCellsKeys: AvailableHeadCellsKeys[]) => {
    if (!headCellsKeys) return [];
    Object.entries(jobsTableHeadCellsBank).forEach(([key, headCell]: any) => {
      addCss(key, headCell);
      addHeadCellTooltip(key, headCell);
      addCellLink(key, headCell);
      addCellAction(key, headCell);
      // addLabelIcons(key, headCell);//TODO: THIS SHOULD BE REMOVED
      //TODO: addOnLabelClick
    });
    return Object.values(_.pick(jobsTableHeadCellsBank, headCellsKeys));
  };

  return {
    headCells: getHeadCells(headCellsKeys),
  };
};

import { produce } from "immer";
import _, { uniqueId } from "lodash";
import React, { ChangeEvent, MouseEvent } from "react";
import { Box, Checkbox, SelectChangeEvent, ThemeProvider } from "@mui/material";
import { imageMimeTypes } from "@sumit-platforms/types";
import Input from "../Input/Input";
import { TextArray } from "../TextArray/TextArray";
import { Switch } from "../Switch/Switch";
import TimerPicker from "../../components/TimerPicker/TimerPicker";
import { getTimeNumberFromString, secondsToTC } from "../../utils/formatters";
import {
  ButtonGroupOption,
  ToggleButtonGroup,
} from "../ToggleButtonGroup/ToggleButtonGroup";
import { MultiSelect, Option } from "../MultiSelect/MultiSelect";
import { SelectSimple } from "../SelectSimple/SelectSimple";
import { ColorPicker } from "../ColorPicker/ColorPicker";
import { ImageUpload } from "../ImageUpload/ImageUpload";
import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { Button } from "../Button/Button";
import { SliderInput } from "../SliderInput/SliderInput";
import classNames from "classnames";
import { bazarTheme } from "../../bazar-theme";

import "./SettingsPage.scss";

interface SettingInput {
  key: string;
  label?: string;
  isColumn?: boolean;
  style?: React.CSSProperties;
  isDisabled?: () => boolean;
  hide?: boolean;
}

interface SettingInputText extends SettingInput {
  type: "text" | "number";
  regex?: RegExp;
  min?: number;
  max?: number;
}

interface SettingInputTextArray extends SettingInput {
  type: "textArray";
}

interface SettingInputToggle extends SettingInput {
  type: "toggle";
  buttons: ButtonGroupOption[];
  exclusive?: boolean;
}
interface SettingInputSelect extends SettingInput {
  type: "select";
  options: Option[];
}

interface SettingInputMultiSelect extends SettingInput {
  type: "multi-select";
  options: Option[];
  isMulti?: boolean;
}

interface SettingInputCheckbox extends SettingInput {
  type: "checkbox";
}

interface SettingInputSwitch extends SettingInput {
  type: "switch";
}

interface SettingInputColorPicker extends SettingInput {
  type: "colorPicker";
  icon?: IconDefinition;
}

interface SettingInputImageUpload extends SettingInput {
  type: "imageUpload";
  fileKey: string;
}

interface SettingsInputTimepicker extends SettingInput {
  type: "timerPicker";
}

interface SettingsInputButton extends SettingInput {
  type: "button";
  variant: "text" | "contained" | "outlined";
  buttonLabel: string;
  color:
    | "info"
    | "warning"
    | "inherit"
    | "secondary"
    | "primary"
    | "error"
    | "success";
  onClick?: () => void;
}

interface SettingsInputSlider extends SettingInput {
  type: "slider";
}

export interface Section {
  title: string;
  paragraph: string;
  isColumn: boolean;
  label?: string;
  titleHasActivate?: boolean;
  disableKey?: string;
  isBottom?: boolean;
  inputs: (
    | SettingInputText
    | SettingInputTextArray
    | SettingInputToggle
    | SettingInputSelect
    | SettingInputCheckbox
    | SettingInputSwitch
    | SettingInputColorPicker
    | SettingInputImageUpload
    | SettingsInputTimepicker
    | SettingsInputButton
    | SettingsInputSlider
    | SettingInputMultiSelect
  )[];
}

export interface PageSettings<TSettings> {
  settings: TSettings;
  sections: Partial<Section>[];
  path?: string;
  onSettingsChange?: (settings: any) => void;
  pageTab?: string;
  tabLabel?: string;
  imageUploadHandler?: (file: File) => Promise<string>;
  setIsValid?: (valid: boolean) => void;
}

export const SettingsPage = <TSettings,>({
  sections,
  settings,
  onSettingsChange,
  imageUploadHandler,
  setIsValid,
}: PageSettings<TSettings>) => {
  const getSettingValue = (key: string) => {
    return _.get(settings, key);
  };

  const handleOnInputChange = (key: string, value: any, type?: string) => {
    if (!onSettingsChange) return;
    if (type === "number") {
      value = Number(value);
    }
    let _settings;
    if (_.has(settings, key)) {
      _settings = produce(settings, (draft) => {
        _.set<TSettings>(draft as object, key, value);
      }) as TSettings;
    }
    onSettingsChange(_settings);
  };

  const isDisabled = ({ i, s }: { i?: SettingInput; s?: Partial<Section> }) => {
    return (
      (i?.isDisabled && i?.isDisabled()) ||
      (s?.disableKey ? !getSettingValue(s.disableKey!) : false)
    );
  };

  return (
    <ThemeProvider theme={bazarTheme}>
      <Box className="SettingsPage" width={"100%"}>
        {sections?.map((s, index) => (
          <Box
            pb={s.label && s.titleHasActivate ? 0 : 2}
            key={index}
            display={s.titleHasActivate ? "flex" : ""}
            justifyContent={s.titleHasActivate ? "space-between" : ""}
            className={classNames({
              disabledSection: isDisabled({ s }),
              sectionBottom: s.isBottom,
            })}
          >
            <Box
              display={s.titleHasActivate ? "inline-flex" : ""}
              flexDirection={"column"}
              alignItems={s.label ? "center" : ""}
              justifyContent={s.label ? "center" : ""}
            >
              {s.title && (
                <Box py={2} className={"sectionTitle"}>
                  <h4>{s.title}</h4>
                </Box>
              )}
              {s.paragraph && (
                <Box pb={1} className={"sectionParagraph"}>
                  <p>{s.paragraph}</p>
                </Box>
              )}
              {s.label && (
                <Box
                  py={0}
                  className={"sectionLabel"}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <label>{s.label}</label>
                </Box>
              )}
            </Box>
            <Box
              display={s.titleHasActivate ? "inline-flex" : "flex"}
              flexDirection={s.isColumn ? "column" : "row"}
              alignItems={s.isColumn ? "flex-start" : "center"}
            >
              {s.inputs
                ?.filter((i) => !i.hide)
                .map((i, index) => {
                  return (
                    <Box
                      key={index}
                      display={"flex"}
                      flexDirection={i.isColumn ? "column" : "row"}
                      alignItems={i.isColumn ? "flex-start" : "center"}
                    >
                      {i.label && (
                        <Box
                          className={"inputLabel"}
                          width={i.style?.width || "8rem"}
                          pb={i.isColumn ? 1 : 0}
                        >
                          {i.label}
                        </Box>
                      )}
                      <Box
                        className={"settingsPageInputWrapper"}
                        sx={{
                          paddingInlineEnd:
                            s.inputs && s.inputs.length > 1 && !s.isColumn
                              ? 1
                              : 0,
                          ...i.style,
                        }}
                      >
                        {i.type === "checkbox" && (
                          <Checkbox
                            disabled={isDisabled({ i, s })}
                            value={getSettingValue(i.key)}
                            onChange={(e) =>
                              handleOnInputChange(i.key, e.target.checked)
                            }
                          />
                        )}
                        {(i.type === "text" || i.type === "number") && (
                          <Box maxWidth={i.type === "number" ? "4rem" : "auto"}>
                            <Input
                              disabled={isDisabled({ i, s })}
                              type={i.type}
                              value={getSettingValue(i.key)}
                              onChange={(e) => {
                                handleOnInputChange(
                                  i.key,
                                  e.target?.value || "",
                                  i.type
                                );
                                if (i.regex && setIsValid) {
                                  setIsValid(
                                    i.regex.test(e.target?.value || "")
                                  );
                                }
                              }}
                              regex={i.regex}
                              min={i.min}
                              max={i.max}
                            />
                          </Box>
                        )}
                        {i.type === "toggle" && (
                          <ToggleButtonGroup
                            disabled={isDisabled({ i, s })}
                            spacing={5}
                            exclusive={i.exclusive}
                            buttons={i.buttons}
                            value={getSettingValue(i.key)}
                            onChange={(
                              _e: MouseEvent<HTMLElement>,
                              newValue: string | string[] | null
                            ) => {
                              if (i.exclusive && !newValue) return;
                              handleOnInputChange(i.key, newValue);
                            }}
                          />
                        )}
                        {i.type === "switch" && (
                          <Switch
                            disabled={isDisabled({ i, s })}
                            checked={getSettingValue(i.key) || false}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleOnInputChange(i.key, e.target.checked)
                            }
                          />
                        )}
                        {i.type === "select" && (
                          <SelectSimple
                            disabled={isDisabled({ i, s })}
                            options={i.options}
                            value={getSettingValue(i.key)}
                            onChange={(value) => {
                              handleOnInputChange(i.key, value);
                            }}
                          />
                        )}
                        {i.type === "multi-select" && (
                          <Box
                            className={"multiSelectWrapper"}
                            width={i.style?.width ? i.style?.width : "8rem"}
                          >
                            <MultiSelect
                              disabled={isDisabled({ i, s })}
                              options={i.options}
                              selected={getSettingValue(i.key)}
                              setSelected={(selected) => {
                                handleOnInputChange(i.key, selected);
                              }}
                              isMulti={i.isMulti}
                            />
                          </Box>
                        )}
                        {i.type === "colorPicker" && (
                          <ColorPicker
                            icon={i.icon}
                            disabled={isDisabled({ i, s })}
                            value={getSettingValue(i.key)}
                            onChange={(color: string) =>
                              handleOnInputChange(i.key, color)
                            }
                          />
                        )}
                        {i.type === "imageUpload" && (
                          <ImageUpload
                            disabled={isDisabled({ i, s })}
                            id={uniqueId()}
                            value={getSettingValue(i.key)}
                            accept={imageMimeTypes.join(",")}
                            onChange={async (file?: File) => {
                              let url = "";
                              if (file && imageUploadHandler) {
                                url = await imageUploadHandler(file);
                                handleOnInputChange(i.key, url);
                              } else {
                                handleOnInputChange(i.key, url);
                              }
                              return url;
                            }}
                          />
                        )}
                        {i.type === "textArray" && (
                          <TextArray
                            onChange={(value) => {
                              handleOnInputChange(i.key, value);
                            }}
                            values={getSettingValue(i.key)}
                          />
                        )}

                        {i.type === "timerPicker" && (
                          <TimerPicker
                            step={1}
                            handleBlur={_.noop}
                            value={getSettingValue(i.key)}
                            handleChange={(value) =>
                              handleOnInputChange(i.key, value)
                            }
                            addTime={() => {
                              const newValue =
                                getTimeNumberFromString(
                                  getSettingValue(i.key)
                                ) + 1;
                              handleOnInputChange(i.key, secondsToTC(newValue));
                            }}
                            deductTime={() => {
                              const newValue =
                                getTimeNumberFromString(
                                  getSettingValue(i.key)
                                ) - 1;
                              handleOnInputChange(i.key, secondsToTC(newValue));
                            }}
                            showMillis={false}
                            disabled={isDisabled({ i, s })}
                          />
                        )}
                        {i.type === "button" && (
                          <Button
                            color={i.color}
                            variant={i.variant}
                            onClick={i.onClick}
                            disabled={isDisabled({ i, s })}
                          >
                            {i.buttonLabel as string}
                          </Button>
                        )}
                        {i.type === "slider" && (
                          <SliderInput
                            value={getSettingValue(i.key)}
                            onChange={(
                              _event: Event,
                              value: number | number[],
                              _activeThumb: number
                            ) => {
                              handleOnInputChange(i.key, value);
                            }}
                            disabled={isDisabled({ i, s })}
                          />
                        )}
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        ))}
      </Box>
    </ThemeProvider>
  );
};

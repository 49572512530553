import _ from "lodash";
import React, { useMemo } from "react";
import { Job, Project } from "@sumit-platforms/types";
import { MultiSelect } from "./../../core/MultiSelect/MultiSelect";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useProjects } from "../../hooks/useProjects";

interface JobProjectEditProps {
  config: any;
  selectedJobs: Job[];
  selectedProject: number[];
  handleProjectChange: (idProject: number) => any;
  createNewProject: ({
    idClient,
    projectName,
  }: {
    idClient: number;
    projectName: string;
  }) => Promise<Project | undefined>;
}

export const JobProjectEdit = ({
  config,
  selectedJobs,
  selectedProject,
  createNewProject,
  handleProjectChange,
}: JobProjectEditProps) => {
  const { t } = useTranslation();

  const idClient = useMemo(() => {
    return selectedJobs[0].client?.idClient || selectedJobs[0].idClient;
  }, [selectedJobs]);
  const { isLoading, projects } = useProjects({ idClient, config });

  const projectOptions = useMemo<{ label: string; value: number }[]>(() => {
    const options = projects.map((p) => ({
      value: p.idProject,
      label: p.name,
    }));
    return options;
  }, [projects]);

  const handleCreateNewProject = async (projectName: string) => {
    if (!idClient) return;
    try {
      const newProject = await createNewProject({ idClient, projectName });
      if (newProject)
        projectOptions.push({
          value: newProject.idProject,
          label: newProject.name,
        });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box display={"flex"} justifyContent={"space-between"} gap={2}>
      <MultiSelect
        loading={isLoading}
        label={t("project")}
        className={"projectSelectInput"}
        options={projectOptions}
        onChange={(e) => {
          if (!e.target?.value) return;
          handleProjectChange(e.target?.value as number);
        }}
        selected={selectedProject}
        placeholder={t("select_project")}
        isMulti={false}
        addNewOption={{
          onApprove: handleCreateNewProject,
        }}
      />
    </Box>
  );
};

import React, { useEffect, useMemo, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GeneralTable, SearchAndFilters } from "../../../index";
import { useFilters, useHeadCells } from "../../../hooks";
import { useSearchParams } from "react-router-dom";
import {
  CommunityPaymentResponseData,
  CommunityPaymentResponseMetaData,
} from "@sumit-platforms/types";
import { monthNames } from "../../utils/date";
import { getPriceStringWithCurrency } from "../../utils/formatters";
import _ from "lodash";

interface WalletOverviewProps {
  communityPayments: CommunityPaymentResponseData;
  metaData: CommunityPaymentResponseMetaData;
  isLoading: boolean;
}

export interface PaymentsOverviewRow {
  key?: string;
  month: number | string;
  monthString: string;
  year: number | string;
  jobsAmount: number;
  totalPaymentsString: string;
  sortableMonth: number;
  onClick?: () => void;
  totalPayments: number;
}

export const WalletOverview = ({
  metaData,
  communityPayments,
  isLoading,
}: WalletOverviewProps) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { filtersScheme } = useFilters({
    filterKeys: ["payments"],
    metaData: {
      paymentsRange: {
        from: metaData.paymentAmountRangeByMonths?.from || 0,
        to: metaData.paymentAmountRangeByMonths?.to || 10000,
      },
    },
  });
  const filtersQuery = useRef({});
  const [rows, setRows] = useState<PaymentsOverviewRow[]>([]);
  const initialRows = useRef<PaymentsOverviewRow[]>([]);
  const { headCells } = useHeadCells({
    headCellsKeys: ["month", "jobsAmount", "totalPayments"],
  });

  useEffect(() => {
    const walletOverviewRows = communityPayments
      ? createWalletOverviewRow(communityPayments)
      : [];
    setRows(walletOverviewRows);
    initialRows.current = walletOverviewRows;
  }, [communityPayments]);

  useEffect(() => {
    const { paymentsStart, paymentsEnd } = Object.fromEntries(
      searchParams.entries()
    );
    if (_.isNumber(+paymentsStart) || _.isNumber(+paymentsEnd)) {
      const newRows = initialRows.current.filter((row) => {
        return (
          row.totalPayments >=
            (Number(paymentsStart) ||
              Number(metaData?.paymentAmountRangeByMonths?.from)) &&
          row.totalPayments <=
            (Number(paymentsEnd) ||
              Number(metaData?.paymentAmountRangeByMonths?.to))
        );
      });
      setRows(newRows);
    }
  }, [searchParams]);
  const onSearch = (e: any) => {
    const newRows = initialRows.current.filter((r) =>
      r.monthString.toLowerCase()?.includes(e.target.value)
    );
    setRows(newRows);
  };

  const defaultQuery = useMemo(
    () => ({
      paymentsStart: metaData.paymentAmountRangeByMonths?.from,
      paymentsEnd: metaData.paymentAmountRangeByMonths?.to,
    }),
    [metaData]
  );

  const onFilterChange = (query: any) => {
    filtersQuery.current = query;
  };

  const onRowClick = (row: PaymentsOverviewRow) => {
    const existingParams = Object.fromEntries(searchParams.entries());
    setSearchParams({
      ...existingParams,
      month: row.month.toString(),
      year: row.year.toString(),
    });
  };

  const createWalletOverviewRow = (
    responseData: CommunityPaymentResponseData
  ) => {
    const rows: PaymentsOverviewRow[] = [];
    for (const paymentItem of responseData) {
      const row: PaymentsOverviewRow = {
        key: `${paymentItem.year}/${paymentItem.month}`,
        jobsAmount: paymentItem.jobIds.length,
        month: paymentItem.month,
        monthString: `${t(monthNames[paymentItem.month])} ${paymentItem.year}`,
        onClick: () => {
          onRowClick(row);
        },
        totalPaymentsString: getPriceStringWithCurrency({
          price: paymentItem.totalPayments,
          currency: "ILS",
        }),
        totalPayments: paymentItem.totalPayments,
        sortableMonth: paymentItem.year * 100 + paymentItem.month,
        year: paymentItem.year,
      };
      rows.push(row);
    }
    return rows;
  };
  return (
    <Grid
      className={"WalletOverview Page"}
      container
      display={"flex"}
      justifyContent={"center"}
    >
      <Grid item xs={11} mb={3}>
        <h1 className="pageTitle">{t("wallet")}</h1>
        <h3 className="pageSubTitle">
          {`${t("showing")} ${rows.length} ${t("from")} ${
            initialRows.current.length
          } ${t("payments").toLowerCase()}`}
        </h3>
      </Grid>
      <Grid item xs={11} mb={3}>
        <SearchAndFilters
          direction={t("dir")}
          onSearch={onSearch}
          isLoading={false}
          searchPlaceholder={`${t("search")} ${t("month").toLowerCase()}`}
          filterBtnTitle={t("filters")}
          title={t("filter_by")}
          defaultQuery={defaultQuery}
          onFilterChange={onFilterChange}
          filters={filtersScheme}
        />
      </Grid>
      <Grid item xs={11} mb={3}>
        <GeneralTable
          headCells={headCells}
          rows={rows}
          allowSelect={false}
          loading={isLoading}
        />
      </Grid>
    </Grid>
  );
};

import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Invoice } from "@sumit-platforms/types";
import InvoiceService from "../services/invoiceService";

export const useInvoices = ({
  idClient,
  config,
}: {
  idClient?: number;
  config?: any;
}): {
  invoices: Invoice[];
  isLoading: boolean;
} => {
  const invoicesService = useMemo(() => InvoiceService({ config }), []);
  const [isLoading, setIsLoading] = useState(false);

  const [invoices, setInvoices] = useState<Invoice[]>([]);

  const getInvoices = useCallback(async (idClient: number) => {
    try {
      setIsLoading(true);
      const _invoices = await invoicesService.getInvoices(idClient);
      setInvoices(_invoices);
      setIsLoading(false);
    } catch (e: any) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (idClient) {
      getInvoices(idClient);
    }
  }, [idClient]);

  return {
    invoices,
    isLoading,
  };
};

import React, { MouseEvent } from "react";
import {
  ButtonGroupOption,
  Input,
  ToggleButtonGroup,
} from "@sumit-platforms/ui-bazar";
import { Grid } from "@mui/material";

export type SegmentInput = {
  type: any;
  value?: any;
  onChange: (e: Partial<React.ChangeEvent<HTMLInputElement>>) => void;
  suffix?: string;
  label?: string;
  disabled?: boolean;
  min?: number;
  max?: number;
};

interface Props {
  segment: {
    value: string;
    onChange: (e: MouseEvent<HTMLElement>, newValue: any) => any;
    buttons: ButtonGroupOption[];
    disabled: boolean;
    label?: string;
  };
  inputs: SegmentInput[];
}

const SegmentInputsGroup = ({ segment, inputs }: Props) => {
  return (
    <>
      <ToggleButtonGroup
        disabled={segment.disabled}
        buttons={segment.buttons as ButtonGroupOption[]}
        value={segment.value}
        label={segment.label && segment.label}
        onChange={segment.onChange}
      />
      {inputs?.map((input) => {
        return (
          <Grid item pt={1} key={input.label}>
            <Input
              type={input.type}
              value={input.value}
              onChange={input.onChange}
              suffix={input.suffix}
              label={input.label}
              disabled={input.disabled}
              min={input.min}
              max={input.max}
            />
          </Grid>
        );
      })}
    </>
  );
};

export default SegmentInputsGroup;

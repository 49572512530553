import React from "react";
import { Box } from "@mui/material";

interface ActionElement {
  children: React.ReactNode;
  hide: boolean;
}

export const ActionElement = ({ children, hide }: ActionElement) => {
  return hide ? null : (
    <Box className={"EditorActionsWrapper staticScroller"}>{children}</Box>
  );
};

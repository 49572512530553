import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { JobSpeaker } from "@sumit-platforms/types";
import {
  CreatableAutocomplete,
  CreatableAutocompleteOption,
} from "@sumit-platforms/ui-bazar";
import { useRecoilState, useRecoilValue } from "recoil";
import { speakersState, directionState } from "../../store/states";
import _ from "lodash";

import "./SpeakerNameEditor.scss";

const SpeakerNameEditor: FC<{
  speakerName?: string | null;
  onChange: (speaker: JobSpeaker) => void;
  placeholder: string;
  isDisabled?: boolean;
  focusOnInit?: boolean;
  sx?: React.CSSProperties;
  onBeforeSpeakersFocus?: () => void;
  focusElement?: () => void;
  onUnmount?: () => void;
}> = ({
  speakerName,
  sx,
  onBeforeSpeakersFocus,
  onChange,
  placeholder,
  isDisabled,
  focusOnInit,
  onUnmount,
  focusElement,
}) => {
  const direction = useRecoilValue(directionState);
  const { t } = useTranslation();
  const [speakers, setSpeakers] = useRecoilState(speakersState);
  const onNewValueAdded = (newValue: CreatableAutocompleteOption) => {
    const newSpeakers = _.uniqBy(
      [{ name: newValue.inputValue.trim() }, ...speakers],
      "name"
    );
    setSpeakers(newSpeakers);
  };

  const formatSpeakersToOptions = (
    speakers: JobSpeaker[]
  ): CreatableAutocompleteOption[] => {
    const speakersOptions = speakers.map((speaker) => {
      return {
        inputValue: speaker.name,
        title: speaker.name,
      };
    });
    return speakersOptions;
  };

  const setValue = (speaker: CreatableAutocompleteOption) => {
    onChange({ name: speaker.inputValue.trim() });
  };

  if (isDisabled) return <span>{speakerName}</span>;

  return (
    <CreatableAutocomplete
      value={
        speakerName
          ? {
              inputValue: speakerName || t("unidentified_speaker"),
              title: speakerName || t("unidentified_speaker"),
            }
          : null
      }
      paintStrings
      setValue={setValue}
      options={formatSpeakersToOptions(speakers)}
      onNewValueAdded={onNewValueAdded}
      placeholder={placeholder}
      sx={sx}
      direction={direction}
      focusOnInit={focusOnInit}
      onBeforeFocus={onBeforeSpeakersFocus}
      onTab={focusElement}
      onUnmount={onUnmount}
    />
  );
};

export default SpeakerNameEditor;

import React from "react";
import classNames from "classnames";
import { LeafProps } from "../../types";

const Leaf = (props: LeafProps) => {
  const { attributes, children, leaf } = props;
  return (
    <span
      {...attributes}
      className={classNames({
        highlightGreen: leaf.highlightGreen,
        highlightLightblue: leaf.highlightLightblue,
      })}
    >
      {children}
    </span>
  );
};

export default Leaf;

import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Project } from "@sumit-platforms/types";
import ProjectService from "../services/projectService";

export const useProjects = ({
  idClient,
  config,
}: {
  idClient?: number;
  config?: any;
}): {
  projects: Project[];
  isLoading: boolean;
} => {
  const projectService = useMemo(() => ProjectService({ config }), []);
  const [isLoading, setIsLoading] = useState(false);

  const [projects, setProjects] = useState<Project[]>([]);

  const getProjects = useCallback(async (idClient?: number) => {
    try {
      setIsLoading(true);
      let _projects: Project[] = [];
      if (idClient) {
        _projects = await projectService.getClientProjects(idClient);
      } else {
        _projects = await projectService.getProjects();
      }
      setProjects(_projects);
      setIsLoading(false);
    } catch (e: any) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getProjects(idClient);
  }, [idClient]);

  return {
    projects,
    isLoading,
  };
};

import React, { FC, useState, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import stc from "string-to-color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";

import AutocompleteInput from "../AutocompleteInput/AutocompleteInput";
import { handleKeyboardShortcut } from "../../utils/keyboardShortcuts";
import { JobSpeaker } from "@sumit-platforms/types";

import "./SpeakerNameEditor.scss";

const SpeakerNameEditor: FC<{
  speakerName?: string | null;
  speakers: JobSpeaker[];
  handleSetNewSpeakerName: (speaker: JobSpeaker) => boolean;
  cancelEdit?: () => void;
  placeholder: string;
  autocomplete?: boolean;
  isEditing: boolean;
  isDisabled?: boolean;
}> = ({
  speakerName,
  speakers,
  handleSetNewSpeakerName,
  cancelEdit,
  autocomplete = true,
  isEditing,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const [newSpeakerName, setNewSpeakerName] = useState("");
  const [error, setError] = useState(false);
  const [editMode, setEditMode] = useState(isEditing);

  useEffect(() => {
    if (isEditing === editMode) return;
    setEditMode(isEditing);
  }, [isEditing]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (handleKeyboardShortcut(e, ["Enter", "NumpadEnter", "Tab"])) {
      handleApprove();
    }

    if (handleKeyboardShortcut(e, ["Escape"])) {
      handleCancel();
    }
  };

  const handleCancel = () => {
    cancelEdit && cancelEdit();
    setEditMode(false);
  };

  const handleApprove = () => {
    if (!newSpeakerName || _.isEmpty(newSpeakerName)) {
      if (speakerName === t("unidentified_speaker")) {
        handleCancel();
        return;
      }
      setEditMode(false);
      return;
    }
    const newSpeakerResult = handleSetNewSpeakerName({
      name: newSpeakerName.trim(),
    });
    if (newSpeakerResult === false) {
      setError(true);
      return;
    }
    setError(false);
    setEditMode(false);
  };

  if (isDisabled) return <span>{speakerName}</span>;
  return (
    <div
      className={classNames("SpeakerNameEditor", {
        editMode,
      })}
    >
      {editMode ? (
        <div
          className={classNames("newSpeakerContainer", {
            error,
          })}
        >
          {autocomplete ? (
            <AutocompleteInput
              options={speakers.map((s) => s.name).filter((s) => !_.isNil(s))}
              handleOnChange={setNewSpeakerName}
              handleOnKeyDown={handleKeyDown}
              handleOnBlur={cancelEdit}
              autoFocus={true}
            />
          ) : (
            <input
              className="freeTextInput"
              type="text"
              autoFocus={true}
              placeholder={speakerName || "דובר לזיהוי"}
              onChange={(e) => setNewSpeakerName(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          )}
          <div className="iconContainer">
            <FontAwesomeIcon
              className="icon approve"
              icon={faCheck}
              onClick={handleApprove}
            />
            <FontAwesomeIcon
              className="icon close"
              icon={faTimes}
              onClick={handleCancel}
            />
          </div>
        </div>
      ) : (
        <span
          className={classNames("speakerName", {
            unidentifiedSpeaker:
              !speakerName ||
              (speakerName &&
                speakerName.startsWith(t("unidentified_speaker"))),
          })}
          onClick={() => setEditMode(true)}
          style={{ color: stc(speakerName) }}
        >
          {speakerName || t("unidentified_speaker")}
        </span>
      )}
    </div>
  );
};

export default SpeakerNameEditor;

import React, { useEffect, useState, useRef, FC } from "react";
import { handleKeyboardShortcut } from "../../utils/keyboardShortcuts";

import "./AutocompleteInput.scss";

const AutocompleteInput: FC<{
  options: string[];
  handleOnChange: (value: string) => void;
  handleOnKeyDown?: (event: React.KeyboardEvent) => void;
  handleOnBlur?: () => void;
  placeholder?: string;
  autoFocus?: boolean;
}> = ({
  options,
  handleOnChange: handleOnChangeProp,
  handleOnKeyDown,
  handleOnBlur,
  placeholder,
  autoFocus,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [text, setText] = useState("");
  const [candidate, setCandidate] = useState(options[0]);
  const [curSuggestionIndex, setCurSuggestionIndex] = useState(0);

  useEffect(() => {
    handleOnChangeProp(candidate);
    if (autoFocus) {
      inputRef.current?.select();
    }
  }, []);

  useEffect(() => {
    if (!handleOnBlur) return;
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        handleOnBlur();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleOnBlur]);

  const getSuggestions = (input: string) => {
    const filteredOptions = options.filter((option) =>
      option.startsWith(input)
    );

    let suggestionIndex = 0;
    if (filteredOptions.length == curSuggestionIndex) {
      setCurSuggestionIndex(filteredOptions.length - 1);
      suggestionIndex = filteredOptions.length - 1;
    }
    return filteredOptions[suggestionIndex] || "";
  };

  const showNewSuggestion = (input: string) => {
    const suggestion = getSuggestions(input);
    setCandidate(suggestion);
    setText(input);
    handleOnChangeProp(suggestion || input);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = e.target.value;
    showNewSuggestion(newVal);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const { value } = e.target as HTMLTextAreaElement;

    if (handleKeyboardShortcut(e, ["ArrowDown"])) {
      setCurSuggestionIndex(curSuggestionIndex + 1);
      showNewSuggestion(value);
    }

    if (handleKeyboardShortcut(e, ["ArrowUp"])) {
      if (curSuggestionIndex === 0) return;
      setCurSuggestionIndex(curSuggestionIndex - 1);
      showNewSuggestion(value);
    }

    if (handleOnKeyDown) handleOnKeyDown(e); // Extend keydown from outside
  };

  return (
    <div className="AutocompleteInput" ref={wrapperRef}>
      <input
        className="autocompletedInput"
        value={candidate}
        readOnly={true}
        type="text"
      />
      <input
        ref={inputRef}
        className="freeTextInput"
        type="text"
        placeholder={placeholder}
        value={text}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

export default AutocompleteInput;

// General
export const mainYellow = "#ffb400";
export const secondaryBlue = "#3c5069";

// Gray
export const disabledButtonGray = "#a4a4a4";

// Job Status Colors delivery
export const processingColor = "#e6e7ec";
export const pendingColor = "#ffcf1e";
export const openColor = "#f7cecb";
export const atWorkColor = "#f1efca";
export const reviewingColor = "#cae4f4";
export const aligningColor = "#f3caf4";
export const splittingColor = "#cfcaf4";
export const kitRecordingColor = "#faaa4a";
export const pendingReviewColor = "#c6f4f4";
export const doneColor = "#74f2b0";
export const archiveColor = "#89c8a7";
export const noStatusColor = "#e6e7ec";

// Job status colors b2b
export const b2bProcessingColor = "#cdd0d8";
export const underReviewColor = "#dcd98f";
export const qualityCheckColor = "#7dfab6";

// Transcriber color
export const activeColor = "#7dfab6";
export const trainingColor = "#95c9e9";
export const newMemberColor = "#e4e094";
export const disabledColor = "#ee9b97";
export const rejectedColor = "#cdd0d8";

import config from "./../../config";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Breadcrumbs,
  Grid,
  Link,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { useAlert, useModal } from "@sumit-platforms/ui-bazar/store";
import {
  Button,
  PageSettings,
  SelectLanguageModal,
  SettingsPager,
} from "@sumit-platforms/ui-bazar";
import {
  cloudinaryService,
  ProjectService,
} from "@sumit-platforms/ui-bazar/services";
import useClients from "../../hooks/useClients";
import docxTemplateService from "../../services/docxTemplateService";
import validationPresetsService from "../../services/validationPresetsService";
import {
  Platform,
  sttPriorityOptions,
  ValidationLang,
  ValidationLanguagePage,
} from "@sumit-platforms/types";
import { metadataStore } from "@sumit-platforms/ui-bazar/store";
import {
  ProjectSettingsPagesMetadata,
  useMetadata,
} from "@sumit-platforms/ui-bazar/hooks";

import "./SettingsEditor.scss";

const projectService = ProjectService({ config });

export const SettingsEditor = ({
  useSettings,
  settingsType,
}: {
  useSettings: any;
  settingsType: "template" | "validation" | "project";
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setAlert } = useAlert();
  const { setModalContent, clearModalContent } = useModal();
  useMetadata({ config });
  const { sttModels } = metadataStore();
  const { idClient, id } = useParams();
  const { client } = useClients({ idClient: Number(idClient), config });
  const [inputSettings, setInputSettings] = useState<any>();
  const outputSettings = useRef<any>();
  const [metadata, setMetadata] = useState<any>({});
  const { pages, setPages, resetPages } = useSettings({
    settings: inputSettings,
    metadata,
    platform: Platform.OPERA,
  });
  const [listButtons, setListButtons] = useState<React.ReactNode[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [actionsBySettingsType, setActionsBySettingsType] = useState<{
    saveSettings: ((id: number) => void) | null;
    fetchSettings: ((id: number) => void) | null;
  }>({
    saveSettings: null,
    fetchSettings: null,
  });

  const handleSaveSettings = async () => {
    try {
      setLoading(true);
      if (actionsBySettingsType.saveSettings)
        await actionsBySettingsType?.saveSettings(Number(id));
      setLoading(false);
    } catch (err) {
      setAlert({
        message: t("failed"),
        severity: "error",
      });
      setLoading(false);
    }
  };

  const handleAddNewLanguage = (language: string) => {
    const newSettings = outputSettings.current;
    newSettings[language] = new ValidationLanguagePage();
    setInputSettings(newSettings);
    clearModalContent();
  };

  const openSelectLanguageModal = () => {
    setModalContent(
      <SelectLanguageModal
        onCancel={clearModalContent}
        langOptions={Object.values(ValidationLang).filter(
          (l) => !Object.keys(outputSettings.current).includes(l)
        )}
        onConfirm={handleAddNewLanguage}
      />
    );
  };

  const setTemplateDepsBySettingsType = () => {
    setActionsBySettingsType({
      saveSettings: async (id: number) => {
        await docxTemplateService.saveDocxTemplate(
          outputSettings.current,
          Number(id)
        );

        navigate(`/clients/${idClient}?tab=templates`);
      },
      fetchSettings: (id: number) => {
        if (client && client.templates?.length > 0) {
          const template = _.find(
            client.templates,
            (t) => t.idDocxTemplate === Number(id)
          );
          if (template) {
            setInputSettings(template.settings);
          }
        }
      },
    });
  };

  const setValidationDepsBySettingsType = () => {
    setListButtons([
      <ListItem
        disablePadding
        className="bottom"
        key={"add_laguage_key"}
        onClick={openSelectLanguageModal}
      >
        <ListItemButton className="settingsPagerPageListButton">
          <ListItemIcon>
            <Box display={"flex"} gap={1}>
              <FontAwesomeIcon icon={faPlus} />
              <Box>{t("add_language")}</Box>
            </Box>
          </ListItemIcon>
        </ListItemButton>
      </ListItem>,
    ]);
    setActionsBySettingsType({
      saveSettings: async (id: number) => {
        await validationPresetsService.saveValidationPreset({
          idValidationPreset: Number(id),
          settings: outputSettings.current,
        });
        navigate(`/clients/${idClient}?tab=validations`);
      },
      fetchSettings: (id: number) => {
        if (client && client.validations?.length > 0) {
          const validation = _.find(
            client.validations,
            (t) => t.idValidationPreset === Number(id)
          );
          if (validation) {
            setInputSettings(validation.settings);
          }
        }
      },
    });
  };

  const setProjectDepsBySettingsType = useCallback(() => {
    setActionsBySettingsType({
      saveSettings: async (idProject: number) => {
        await projectService.updateProject(
          { settings: outputSettings.current },
          idProject
        );
        navigate(`/clients/${idClient}?tab=projects`);
      },
      fetchSettings: async (idProject: number) => {
        const _metadata: ProjectSettingsPagesMetadata = {
          sttPriority: sttPriorityOptions,
          validationOptions: [],
        };
        if (client && client.validations?.length > 0) {
          const validations = client.validations;
          if (!_.isEmpty(validations)) {
            _metadata.validationOptions = validations.map((v) => ({
              label: v.settings?.general_lang?.presetName,
              value: v.idValidationPreset,
            }));
          }
        }

        _metadata.sttModelOptions = sttModels?.map((m) => ({
          label: t(m.name),
          value: m.model,
        }));

        setMetadata(_metadata);
        if (idProject) {
          const projectPage = await projectService.getProjectPage(idProject);
          setInputSettings(projectPage.settings);
        }
      },
    });
  }, [sttModels, client]);

  useEffect(() => {
    let combinedSettings: any = {};
    pages.map((p: PageSettings<any>) => {
      combinedSettings = { ...combinedSettings, ...p.settings };
    });
    outputSettings.current = combinedSettings;
  }, [pages]);

  useEffect(() => {
    if (actionsBySettingsType.fetchSettings) {
      actionsBySettingsType.fetchSettings(Number(id));
    }
  }, [actionsBySettingsType.fetchSettings, id]);

  useEffect(() => {
    if (!(client || idClient || settingsType)) return;
    switch (settingsType) {
      case "template":
        setTemplateDepsBySettingsType();
        break;
      case "validation":
        setValidationDepsBySettingsType();
        break;
      case "project":
        setProjectDepsBySettingsType();
        break;
    }
  }, [settingsType, client, idClient, sttModels]);

  return (
    <Grid
      className="SettingsEditor"
      container
      display={"flex"}
      justifyContent={"center"}
    >
      <Grid item xs={11} my={2}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            color="inherit"
            href="/clients"
            onClick={(e) => {
              e.preventDefault();
              navigate("/clients");
            }}
          >
            <Typography color="text.primary">{t("clients")}</Typography>
          </Link>
          <Link
            underline="hover"
            color="inherit"
            href={`/clients/${idClient}`}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/clients/${idClient}`);
            }}
          >
            <Typography color="text.primary">{client?.name}</Typography>
          </Link>
          <Typography color="text.primary">{id}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid container item xs={11}>
        <Grid item md={12} lg={6} className="settingsPagerWrapper">
          <SettingsPager
            pages={pages}
            setPages={setPages}
            imageUploadHandler={cloudinaryService(config).upload}
            listButtons={listButtons}
          />
        </Grid>
        <Grid item md={12} lg={6}>
          <Grid item xs={12}>
            <Box display={"inline-block"} px={2}>
              <Button variant="outlined" onClick={resetPages}>
                {t("reset")}
              </Button>
            </Box>
            <Box display={"inline-block"}>
              <Button onClick={handleSaveSettings} loading={loading}>
                {id === "new" ? t("add_new") : t("save")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

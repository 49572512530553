import { CustomEditor } from "../types";

export const withPlainTextPaste = (editor: CustomEditor) => {
  const { insertData, insertText } = editor;

  editor.insertData = (data) => {
    const text = data.getData("text/plain");

    if (text) {
      insertText(text);
    } else {
      //CHeck what is data ?? and when it triggers
      insertData(data);
    }
  };

  return editor;
};

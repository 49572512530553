import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Box } from "@mui/material";
import { Button } from "../../core/Button/Button";
import { Input } from "../../core/Input/Input";

import "./UpdateProjectModal.scss";

export interface UpdateProjectModalProps {
  cancel: () => void;
  confirm: (name: string) => Promise<void>;
  name?: string;
  modalTitle: string;
}

export const UpdateProjectModal = ({
  cancel,
  confirm,
  name,
  modalTitle,
}: UpdateProjectModalProps) => {
  const { t } = useTranslation();
  const [projectName, setProjectName] = useState(name || "");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSave = async () => {
    try {
      setIsLoading(true);
      if (projectName) {
        await confirm(projectName);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <Box className="UpdateProjectModal" px={3} py={2}>
      <Box pt={2}>
        <Box width={"100%"}>
          <Box pb={2} display={"flex"} justifyContent={"center"}>
            <h4>{modalTitle}</h4>
          </Box>
          <Box>
            <p>{modalTitle}:</p>
          </Box>
        </Box>
      </Box>
      <Box pt={1} pb={4}>
        <Box>
          <Input
            value={projectName}
            type="text"
            onChange={(e) => setProjectName(e.target?.value || "")}
            placeholder={modalTitle}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent={"flex-end"} gap={1}>
        <Button onClick={cancel} variant="outlined">
          {t("cancel")}
        </Button>
        <Button
          disabled={!projectName}
          loading={isLoading}
          onClick={handleSave}
          color="primary"
        >
          {t("confirm")}
        </Button>
      </Box>
    </Box>
  );
};

import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Languages, languages, Upload } from "@sumit-platforms/types";

import { MultiSelect, Option } from "../../core/MultiSelect/MultiSelect";

import "./LanguageSelectPopover.scss";

export interface LanguageSelectPopoverProps {
  selected: number[];
  update: (ids: number[], partOfUpload: Partial<Upload>) => Promise<void>;
  language?: { input: string[]; output: string[] };
}

export const LanguageSelectPopover = ({
  selected,
  update,
  language,
}: LanguageSelectPopoverProps) => {
  const { t } = useTranslation();
  const [options] = useState<Option[]>(languages);

  const [source, setSource] = useState<string | null>();
  const [target, setTarget] = useState<string[] | null>();

  const setSelectedSource = async (selectedSource: Languages[]) => {
    await update(selected, { inputLanguage: selectedSource });
    setSource(selectedSource[0]);
  };

  const setSelectedTarget = async (selectedTarget: Languages[]) => {
    await update(selected, { outputLanguage: selectedTarget });
    setTarget(selectedTarget);
  };

  return (
    <div className="LanguageSelectPopover">
      <div className="action">
        <div className="titleSrc">{`${t("select_source_language")}:`}</div>
        <MultiSelect
          selected={
            language && language.input[0]
              ? [language.input[0]]
              : source
              ? [source]
              : []
          }
          setSelected={setSelectedSource}
          placeholder="Not selected"
          isMulti={false}
          options={options}
        />
        {((language && language.input[0]) || source) && (
          <>
            <div className="titleTarget">{`${t(
              "select_target_language"
            )}:`}</div>
            <MultiSelect
              selected={
                language && !_.isEmpty(language.output)
                  ? language.output
                  : target || []
              }
              setSelected={setSelectedTarget}
              placeholder="Not selected"
              isMulti={true}
              options={options}
            />
          </>
        )}
      </div>
    </div>
  );
};

import ListItemButton from "@mui/material/ListItemButton";
import React, { useCallback, useMemo } from "react";
import MediaService from "../../services/MediaService";
import { getTimecodeFromSeconds } from "@sumit-platforms/ui-bazar/utils";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import classNames from "classnames";
import { JobValidationsRange } from "./JobValidationsExplorer";
import { ValidationList } from "../RangeValidation/ValidationList";
import EditorService from "../../services/EditorService";
import { useSlateStatic, useSlate } from "@sumit-platforms/slate-react";
import { CustomEditor, CustomElement } from "../../types";
import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons";
import { currentTimeState } from "../../store/states";
import { useRecoilValue } from "recoil";

interface JobValidationsItemProps {
  range: JobValidationsRange;
  index: number;
}

const JobValidationsExplorerItem = ({
  range,
  index,
}: JobValidationsItemProps) => {
  const editor = useSlateStatic() as CustomEditor;
  const time = useRecoilValue(currentTimeState);

  const bold = time ? time >= range.st && time <= range.et : false;

  const handleRangeClick = useCallback(
    (range: JobValidationsRange) => {
      EditorService.navigateToSubtitle({
        editor,
        st: range.st,
      });
    },
    [editor]
  );

  const placeholderCount = useMemo(() => {
    return EditorService.getPlaceholderRangesCount(editor, index);
  }, [editor, index]);

  return (
    <ListItemButton
      id={`range-item-${index}`}
      onClick={() => handleRangeClick(range)}
      sx={{
        padding: "4px 0",
        display: "inline-block",
        width: "100%",
        height: "100%",
      }}
      key={`${range.st}${range.et}${JSON.stringify(range.error)}`}
      disableRipple
      dir={"ltr"}
    >
      <Box className={classNames("JobValidation", { bold })}>
        <Box className={"wrapper"}>
          <Box>
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className={"errorValidationsItemIcon"}
              size={"2xs"}
            />
          </Box>
          <Box>
            <Box className={classNames("rangeIndexTimes")}>
              {getTimecodeFromSeconds(range.st, {
                fps: MediaService.frameRate,
                tcOffsets: MediaService.tcOffsets,
              })}
              <FontAwesomeIcon icon={faArrowRight} size="2xs" />
              {getTimecodeFromSeconds(range.et, {
                fps: MediaService.frameRate,
                tcOffsets: MediaService.tcOffsets,
              })}
            </Box>
            <Box className={"itemText"}>
              <ValidationList
                validation={{
                  errors: { [range.error as any]: range as any },
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box className={classNames("rangeIndex")}>
          {index - placeholderCount + 1}
        </Box>
      </Box>
    </ListItemButton>
  );
};

export default JobValidationsExplorerItem;

import { useEffect, useState } from "react";

export const useLazyLoad = (elementRef: any, enabled: boolean) => {
  const [hasIntersected, setHasIntersected] = useState(enabled ? false : true);

  useEffect(() => {
    if (!enabled || hasIntersected) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setHasIntersected(true);
          observer.disconnect();
        }
      },
      {
        root: null,
        rootMargin: "700px",
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [elementRef, enabled, hasIntersected]);

  return { hasIntersected, elementRef };
};

import { useTranslation } from "react-i18next";
import { Tooltip } from "@sumit-platforms/ui-bazar";
import { Box, IconButton } from "@mui/material";
import React from "react";
import {
  replaceAllIcon,
  replaceOneIcon,
} from "@sumit-platforms/common-assets/images";
import classNames from "classnames";

const FindAndReplaceButtonsContainer = ({
  onReplaceOne,
  isReplaceAllDisabled,
  isReplaceOneDisabled,
  onReplaceAll,
}: {
  isReplaceOneDisabled: boolean;
  onReplaceOne: () => void;
  isReplaceAllDisabled: boolean;
  onReplaceAll: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Box className={"FindAndReplaceButtonsContainer"}>
      <Tooltip title={t("replace_one")} hide={isReplaceOneDisabled}>
        <span>
          <IconButton
            sx={{ padding: " 6px" }}
            disabled={isReplaceOneDisabled}
            onClick={onReplaceOne}
          >
            <img
              src={replaceOneIcon}
              alt={"replace_one_icon"}
              className={classNames("replaceIcon", {
                disabled: isReplaceOneDisabled,
              })}
            />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={t("replace_all")} hide={isReplaceAllDisabled}>
        <span>
          <IconButton
            sx={{ padding: " 6px" }}
            disabled={isReplaceAllDisabled}
            onClick={onReplaceAll}
          >
            <img
              src={replaceAllIcon}
              alt={"replace_all_icon"}
              className={classNames("replaceIcon", {
                disabled: isReplaceAllDisabled,
              })}
            />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};
export default FindAndReplaceButtonsContainer;

import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Box, createTheme, InputLabel, ThemeProvider } from "@mui/material";
import { MuiTooltipVr2 } from "../../bazar-theme";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";

import { SearchInput } from "../SearchInput/SearchInput";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { Button } from "./../Button/Button";
import { AddNewOption } from "./AddNewOption";
import { SpinningLoader } from "../SpinningLoader/SpinningLoader";
import { MultiSelectOption } from "./MultiSelectOption";

import "./MultiSelect.scss";

interface Action {
  icon: IconProp;
  onClick: () => void;
  disabled: boolean;
}

export interface Option {
  value: string | number | Date | number[];
  label: string;
  iconElem?: JSX.Element;
  toolTip?: string;
  disabled?: boolean;
  key?: any;
  switch?: {
    label?: string;
    checked?: boolean;
  };
}

export interface MultiSelectProps {
  options: Option[];
  selected: any[];
  placeholder?: string;
  isMulti?: boolean;
  setSelected?: (selected: any[]) => void;
  actions?: Action[];
  onSearch?: (term: string) => void;
  className?: string;
  disabled?: boolean;
  hideSearch?: boolean;
  onChange?: (e: any) => void;
  label?: string;
  addNewOption?: {
    onApprove: (newValue: string) => any;
    label?: string;
  };
  loading?: boolean;
  onSwitchCheck?: ({
    selected,
    checked,
  }: {
    selected: string | number | Date;
    checked: boolean;
  }) => void;
}

export const MultiSelect = ({
  options,
  placeholder = "",
  selected,
  isMulti = false,
  onSearch,
  actions,
  className,
  disabled,
  hideSearch = false,
  onChange,
  setSelected,
  label,
  addNewOption,
  loading,
  onSwitchCheck,
}: MultiSelectProps) => {
  const [search, setSearch] = useState<string>("");
  const [filtered, setFiltered] = useState<Option[]>(options);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [creatingNew, setCreatingNew] = useState<boolean>(false);

  const getLableByValue = (value: any) => {
    return options?.filter((o) => o.value === value)[0]?.label;
  };

  useEffect(() => {
    const handleSearch = (value: string) => {
      let newFiltered = [];
      if (value) {
        newFiltered = options.filter(
          (i: Option) =>
            String(i.label)
              .toLowerCase()
              .indexOf(String(value).toLowerCase()) !== -1
        );
      } else {
        newFiltered = options;
      }
      setFiltered(newFiltered);
    };
    if (!onSearch) {
      handleSearch(search);
      return;
    }
    onSearch(search);
  }, [search]);

  useEffect(() => {
    setFiltered(options);
  }, [options]);

  const handleOpen = async () => {
    if (disabled || loading) return;
    setIsOpen(!isOpen);
  };

  const handleSelect = (option: Option) => {
    if (option.disabled) return;
    if (!isMulti) {
      setSelected && setSelected([option.value]);
      onChange &&
        onChange({
          target: {
            value: option.value,
          },
        });
      setIsOpen(false);
    } else {
      let newSelected = _.xor(selected, [option.value]);
      setSelected && setSelected(newSelected);
      onChange &&
        onChange({
          target: {
            value: newSelected,
          },
        });
    }
  };

  return (
    <ThemeProvider
      theme={createTheme({ components: { MuiTooltip: MuiTooltipVr2 } })}
    >
      {isOpen && (
        <div className="closeTrigger" onClick={() => setIsOpen(false)}></div>
      )}
      <div className="BazarMultiSelectHeightCover">
        {label && <InputLabel shrink>{label}</InputLabel>}
        <div
          className={classNames("BazarMultiSelect", className, {
            disabled: disabled || loading,
          })}
          style={{
            paddingTop: isOpen ? "10px" : "0px",
            paddingBottom: isOpen ? "10px" : "0px",
            zIndex: isOpen ? "10" : "1",
          }}
        >
          <div className="selectHeader">
            <div className="searchWrapper">
              {isOpen ? (
                !hideSearch && (
                  <SearchInput
                    autoFocus={true}
                    className={"searchInput"}
                    showTextInput={true}
                    placeholder={"Search"}
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    showSearchIcon={false}
                    style={{ height: "auto" }}
                  />
                )
              ) : (
                <div
                  className={classNames("selected", {
                    placeholder: !selected?.length || !selected[0],
                  })}
                  onClick={handleOpen}
                >
                  {!loading && selected.filter((v) => v).length > 0
                    ? selected
                        .map((s) => {
                          return getLableByValue(s);
                        })
                        ?.join(", ")
                    : placeholder}
                </div>
              )}
            </div>
            {!isOpen && actions && (
              <div className="actionsWrapper">
                {actions?.map((action: Action) => (
                  <Button
                    variant={"text"}
                    disabled={action.disabled}
                    onClick={action.onClick}
                  >
                    <FontAwesomeIcon icon={action.icon} />
                  </Button>
                ))}
              </div>
            )}
            <div
              className={"arrowWrapper"}
              onClick={() => !disabled && !loading && setIsOpen(!isOpen)}
            >
              {loading ? (
                <div className={classNames("spinner")}>
                  <Box maxWidth={"15px"}>
                    <SpinningLoader />
                  </Box>
                </div>
              ) : (
                <FontAwesomeIcon
                  className={classNames("arrow", { tilted: isOpen })}
                  icon={faChevronRight}
                />
              )}
            </div>
          </div>
          {isOpen && (
            <div className="listWrapper">
              {addNewOption?.onApprove && (
                <AddNewOption
                  creatingNew={creatingNew}
                  setCreatingNew={setCreatingNew}
                  addNewOption={addNewOption}
                  label={addNewOption.label}
                />
              )}
              {filtered?.map((option) => {
                return (
                  <div key={`${option.label}-${option.value}`}>
                    <MultiSelectOption
                      option={option}
                      handleSelect={handleSelect}
                      onSwitchCheck={onSwitchCheck}
                      selected={selected}
                      isMulti={isMulti}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

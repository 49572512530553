import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MultiSelect, Option } from "../../core/MultiSelect/MultiSelect";

import "./MultiSelectPopover.scss";

export interface MultiSelectPopoverProps {
  title: string;
  selected: number[];
  update: (idsToUpdate: number[], selectedOption: any[]) => Promise<void>;
  options: Option[];
  isMulti?: boolean;
  isLoading?: boolean;
  addNewOption?: { onApprove: (newValue: string) => any; label?: string };
}

export const MultiSelectPopover = ({
  title,
  selected,
  update,
  options,
  isMulti,
  isLoading,
  addNewOption,
}: MultiSelectPopoverProps) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOptions] = useState<any[]>(selected);

  const handleSelectChange = async (e: any) => {
    if (!e.target.value) return;
    await update(selected, [e.target.value]);
    setSelectedOptions([e.target.value]);
  };

  return (
    <div className="MultiSelectPopover">
      <div className="action">
        <div className="title">{`${t(title)}:`}</div>
        <MultiSelect
          loading={isLoading}
          options={options}
          onChange={handleSelectChange}
          selected={selectedOption}
          placeholder={t(title)}
          isMulti={isMulti}
          addNewOption={addNewOption}
        />
      </div>
    </div>
  );
};

export const scrollInto = (
  elementId: string,
  scrollingElementId: string,
  behavior: ScrollBehavior = "smooth"
) => {
  setTimeout(() => {
    const rangeToScrollInto = document.getElementById(elementId);
    const elementToScroll = document.getElementById(scrollingElementId);
    if (rangeToScrollInto && elementToScroll) {
      rangeToScrollInto.scrollIntoView({
        behavior,
        block: "center",
      });
    } else {
      scrollInto(elementId, scrollingElementId);
    }
  }, 100);
};

export const focusAndSetCursor = (
  rangeIndexToFocus: number,
  cursorPosition = 0
) => {
  const rangeToFocus = document.getElementById(
    `range-${rangeIndexToFocus}`
  ) as HTMLInputElement;

  if (rangeToFocus) {
    const cursorPositionToSet =
      cursorPosition > -1 ? cursorPosition : rangeToFocus?.value.length + 1; // +1 to set cursor after the space
    setTimeout(() => {
      rangeToFocus?.focus();
      rangeToFocus?.setSelectionRange(cursorPositionToSet, cursorPositionToSet);
    }, 0);
  }
};

export const scrollToOffset = (
  elementId: string,
  offset: number,
  retries = 0
) => {
  setTimeout(() => {
    const rangeToScrollInto = document.getElementById(elementId);
    if (rangeToScrollInto) {
      rangeToScrollInto.scrollTop = offset;
    } else if (retries < 3) {
      scrollToOffset(elementId, offset, retries + 1);
    }
  }, 100);
};

export const scrollToRange = ({
  rangeId,
  focus = true,
  retries = 0,
  behavior = "smooth",
}: {
  rangeId?: string;
  focus?: boolean;
  retries?: number;
  behavior?: ScrollBehavior;
}) => {
  setTimeout(() => {
    const rangeFocuser = document.getElementById(`rangeFocuser-${rangeId}`);
    const rangeText = rangeFocuser?.nextElementSibling as HTMLInputElement;
    if (rangeFocuser && rangeText) {
      if (focus) {
        rangeText.focus({ preventScroll: true });
        rangeText.setSelectionRange(0, 0);
      }
      rangeFocuser.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (retries < 3) {
      scrollToRange({
        rangeId,
        focus,
        retries: retries + 1,
      });
    }
  }, 100);
};

import React from "react";
import { Tooltip as MuiTooltip, TooltipProps } from "@mui/material";
import { primaryColor, whiteColor } from "../../bazar-theme";

interface Tooltip {
  children: React.ReactElement<any, any>;
  title: string | JSX.Element;
  placement?: TooltipProps["placement"];
  style?: React.CSSProperties;
  hide?: boolean;
}

export const Tooltip = ({
  children,
  title,
  placement,
  style,
  hide,
}: Tooltip) => {
  const tooltipContent =
    typeof title === "string" && title.length ? (
      <div style={{ whiteSpace: "pre-line", fontSize: "0.75rem" }}>{title}</div>
    ) : (
      title
    );

  return (
    <MuiTooltip
      className={"tooltip"}
      title={hide ? null : tooltipContent}
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: primaryColor,
            color: whiteColor,
            ...style,
          },
        },
      }}
    >
      {children}
    </MuiTooltip>
  );
};

import config from "../../config";
import _ from "lodash";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import * as Types from "@sumit-platforms/types";
import {
  ClientJobMetadata,
  Invoice,
  InvoiceStatus,
  SpeakersDocxConfig,
  TableDocxConfig,
} from "@sumit-platforms/types";
import { flatten } from "flat";
import {
  AddNewConfigurationModal,
  Breadcrumbs,
  ClientJobMetadataModal,
  ClientOverview,
  ConfirmModal,
  ContextOption,
  DeleteProjectModal,
  GeneralTable,
  JobsDeliveryStatus,
  UpdateProjectModal,
  Pager,
  SettingsModal,
  TabView,
  Textarea,
  Views,
  errorColor,
} from "@sumit-platforms/ui-bazar";
import { useModal } from "@sumit-platforms/ui-bazar/store";
import {
  docxSpeakersDefaultSettings,
  docxTableDefaultSettings,
  sttDefaultSTTSettings,
  useHeadCells,
  useMetadata,
  useSTTModelSettings,
  useToast,
} from "@sumit-platforms/ui-bazar/hooks";
import useClients from "../../hooks/useClients";
import docxTemplateService from "../../services/docxTemplateService";
import validationPresetsService from "../../services/validationPresetsService";
import clientService from "../../services/clientService";
import { EditClientModal } from "./modals/EditClientModal/EditClientModal";
import { EditUserModal } from "./modals/EditUserModal/EditUserModal";
import { LinkUserModal } from "./modals/LinkUserModal/LinkUserModal";
import { useGlobalData } from "../../store/globalData";
import { EditClientServicesAccess } from "./modals/EditClientServicesAccess/EditClientServicesAccess";
import {
  faPenToSquare,
  faPlusCircle,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import {
  InvoiceService,
  ProjectService,
  UserService,
} from "@sumit-platforms/ui-bazar/services";
import { getInvoiceStatusVisual } from "@sumit-platforms/ui-bazar/utils";

import "./Client.scss";

interface TemplateRow {
  id: number;
  name: string;
  template: Types.DocxTemplate;
  type: Types.DocxFormatType;
  actions: any;
}

interface UserRow {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
  user: Types.User;
  actions: any;
}

interface InvoiceRow {
  id: number;
  invoiceNumber: string;
  jobsAmount?: number;
  status: InvoiceStatus;
  duration: number;
}

interface ValidationRow {
  id: number;
  name: string;
  jobType: Types.ValidationJobTypes;
  isDefault: boolean;
  createdAt: Date;
  actions: any;
}

interface ProjectRow {
  id: number;
  name: string;
  jobsAmount?: number;
  totalJobsDuration?: number;
  lastActivity?: Date;
}

const invoiceService = InvoiceService({ config });
const userService = UserService({ config });
const projectService = ProjectService({ config });

export const Client: FC = () => {
  const { setToast } = useGlobalData();
  const { toastInfo, toastSuccess, toastError } = useToast({ setToast });
  const { idClient } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { metadata } = useMetadata({ config });
  const { setModalContent, clearModalContent, setModalType } = useModal();
  const [availableUsersToLink, setAvailableUsersToLink] = useState<
    Types.User[]
  >([]);
  const { client, setClient } = useClients({
    idClient: Number(idClient),
    config,
  });
  const notes = useRef<string>("");

  const [templateRows, setTemplateRows] = useState<TemplateRow[]>([]);
  const [userRows, setUserRows] = useState<UserRow[]>([]);
  const [invoiceRows, setInvoiceRows] = useState<InvoiceRow[]>([]);
  const [validationRows, setValidationRows] = useState<ValidationRow[]>([]);
  const [projectRows, setProjectRows] = useState<ProjectRow[]>([]);

  const getAvailableUsersToLink = async (idClient: number) => {
    try {
      const _availableUsersToLink = await userService.getAvailableUsersToLink(
        idClient
      );
      setAvailableUsersToLink(_availableUsersToLink);
    } catch (err) {
      console.error(err);
    }
  };

  const handleRemoveUserFromClient = async (row: UserRow) => {
    try {
      if (client) {
        await userService.removeUserFromClient({
          idUser: row?.user.idUser,
          idClient: client.idClient,
        });
        setUserRows(
          client.users?.filter((u) => u.idUser !== row?.id).map(createUserRow)
        );
        toastSuccess(t("done"));
      } else {
        throw new Error("no client found");
      }
    } catch (err) {
      toastError(t("failed"));
      console.error(err);
    }
  };

  const removeUserFromClient = async (row: UserRow) => {
    openConfirmModal(
      t("are_you_sure"),
      t("action_is_irreversible"),
      () => handleRemoveUserFromClient(row),
      true
    );
  };

  const handleRemoveInvoice = async (paymentRow: InvoiceRow) => {
    toastInfo(t("deleting_invoice"));
    try {
      const result = await invoiceService.deleteInvoice(paymentRow.id);
      toastSuccess(t("invoice_deleted"));

      setInvoiceRows(invoiceRows.filter((i) => i.id !== paymentRow.id));
    } catch (e) {
      toastError(t("invoice_deletion_failed"));
      console.error("error while deleting invoice :", e);
    }
  };

  const handleSettingsChange = async (
    clientData: Partial<Types.Client>,
    idClient: number
  ) => {
    try {
      if (!client) return;
      toastInfo(t("edit_client"));
      await clientService.updateClientSettings(idClient, clientData);
      setClient({ ...client, ...clientData });
      toastSuccess(t("done"));
      closeModal();
    } catch (err) {
      toastError(t("failed"));
      console.error(err);
    }
  };

  const handleEditClient = async (
    _client: Partial<Types.Client>,
    idClient: number
  ) => {
    try {
      if (!client) return;
      toastInfo(t("edit_client"));
      await clientService.update(_client, idClient);
      setClient({ ...client, ..._client });
      toastSuccess(t("done"));
      closeModal();
    } catch (err) {
      toastError(t("failed"));
      console.error(err);
    }
  };

  const handleEditUser = async (
    user: Partial<Types.User>,
    idUser: number,
    idRole: Types.UserRole
  ) => {
    try {
      if (!client) return;
      toastInfo(t("edit_user"));
      await userService.update(user, idUser);
      await userService.setUserRole(idUser, idRole, client.idClient);

      setUserRows((prev) => {
        const newsRows = prev.map((u) => {
          if (u.id === idUser) {
            const userRow = {
              ...u.user,
              ...user,
            };
            userRow.roles = [{ idRole, idClient: client.idClient }];
            return createUserRow(userRow);
          }
          return u;
        });
        return newsRows;
      });
      closeModal();
      toastSuccess(t("done"));
    } catch (err) {
      toastError(t("failed"));
      console.error(err);
    }
  };

  const handleLinkUserToClient = async (
    idUser: number,
    idClient: number,
    idRole: Types.UserRole
  ) => {
    try {
      toastInfo(t("linking_user"));
      await userService.linkUserToClient(idUser, idClient);
      await userService.setUserRole(idUser, idRole, idClient);
      const user = availableUsersToLink.find((u) => u.idUser === idUser);
      if (user && client) {
        const _client = { ...client };
        _client.users?.push({ ...user, roles: [{ idRole, idClient }] });
        setClient(_client);
      }
      toastSuccess(t("done"));
      closeModal();
    } catch (err) {
      toastError(t("failed"));
      console.error(err);
    }
  };

  const handleNotesChange = _.debounce(() => {
    handleEditClient({ notes: notes.current }, Number(idClient));
  }, 2000);

  const handleRemoveTemplate = async (row: TemplateRow) => {
    try {
      toastInfo(t("deleting_template"));
      await docxTemplateService.deleteDocxTemplate(row.id);
      setTemplateRows((prev) => {
        const newsRows = prev.filter((t) => t.id !== row.id);
        return [...newsRows];
      });

      toastSuccess(t("done"));
    } catch (err) {
      toastError(t("failed"));
      console.error(err);
    }
  };

  const handleInvoiceChange = async (
    idInvoice: number,
    updates: Partial<Invoice>
  ) => {
    try {
      toastInfo(t("updating_invoice"));
      await invoiceService.updateInvoice({
        invoice: updates,
        idInvoice,
      });
      setInvoiceRows((prev) => {
        const newsRows = prev.map((i) =>
          i.id === idInvoice ? { ...i, ...updates } : i
        );
        return [...newsRows];
      });
      toastSuccess(t("invoice_updated"));
    } catch (err) {
      toastError(t("invoice_update_failed"));
      console.error(err);
    }
  };

  const handleRemoveValidation = async (idValidationPreset: number) => {
    try {
      toastInfo(t("deleting_preset"));
      await validationPresetsService.deleteValidationPreset(idValidationPreset);
      setValidationRows((prev) => {
        const newsRows = prev.filter((t) => t.id !== idValidationPreset);
        return [...newsRows];
      });
      toastSuccess(t("done"));
    } catch (err) {
      toastError(t("failed"));
      console.error(err);
    }
  };

  const handleAddValidation = async (
    presetName: string,
    jobType: keyof Types.ValidationJobTypes,
    idClient: number
  ) => {
    try {
      toastInfo(t("adding_preset"));
      const settings: Types.ValidationsConfig = {
        general_lang: new Types.ValidationGeneralPage({ presetName }),
      };
      const validation = await validationPresetsService.addValidationPreset({
        settings,
        idClient,
        jobType,
        isDefault: false,
      });
      setValidationRows((prev) => [createValidationRow(validation), ...prev]);
      toastSuccess(t("done"));
    } catch (err) {
      toastError(t("failed"));
      console.error(err);
    }
  };

  const handleAddDocxTemplate = async ({
    templateName,
    docxFormatType,
    idClient,
  }: {
    templateName: string;
    docxFormatType: Types.DocxFormatType;
    idClient: number;
  }) => {
    try {
      toastInfo(t("adding_preset"));
      let settings;
      if (docxFormatType === "speakers") {
        settings = docxSpeakersDefaultSettings;
      } else {
        settings = docxTableDefaultSettings;
      }
      settings.general.presetName = templateName;
      const template = await docxTemplateService.addDocxTemplate({
        settings: settings as SpeakersDocxConfig | TableDocxConfig,
        idClient,
        docxFormatType,
      });
      setTemplateRows((prev) => [createTemplateRow(template), ...prev]);
      toastSuccess(t("done"));
    } catch (err) {
      toastError(t("failed"));
      console.error(err);
    }
  };

  const closeModal = (): void => {
    setModalType("info");
    clearModalContent();
  };

  const openConfirmModal = (
    title: string,
    message: string,
    confirm: () => void,
    closeAfterConfirm = true
  ): void => {
    setModalType("danger");
    setModalContent(
      <ConfirmModal
        title={title}
        message={message}
        confirm={confirm}
        cancel={closeModal}
        closeAfterConfirm={closeAfterConfirm}
      />
    );
  };

  const openAddValidationPresetModal = () => {
    if (!client?.idClient) return;
    setModalContent(
      <AddNewConfigurationModal
        modalTitle={t("add_new_validation_set")}
        modalDescription={"add_new_validation_set_description"}
        onConfirm={async (
          presetName: string,
          jobType: Types.DocxFormatType | keyof Types.ValidationJobTypes
        ) => {
          await handleAddValidation(
            presetName,
            jobType as keyof Types.ValidationJobTypes,
            client?.idClient
          );
          closeModal();
        }}
        onCancel={closeModal}
        options={["subtitles"].map((_jobType) => ({
          label: t(_jobType),
          value: _jobType,
        }))}
        typePlaceholder={t("select_job_type")}
        namePlaceholder={t("preset_name")}
      />
    );
  };

  const handleEditProjectNameModal = async (
    name: string,
    idProject: number
  ) => {
    try {
      const project = await projectService.updateProject(
        {
          name,
        },
        idProject
      );
      const newProjectRows = projectRows.map((r) => {
        if (r.id === idProject) {
          r = createProjectRow(project);
        }
        return r;
      });
      setProjectRows(newProjectRows);
      toastSuccess(t("done"));
    } catch (err) {
      toastError(t("failed"));
      console.error(err);
    }
  };

  const handleAddNewProject = async (name: string, idClient: number) => {
    try {
      const project = await projectService.createNewProject({ name, idClient });
      const newProjectRow = createProjectRow({
        ...project,
        totalJobsDuration: 0,
        jobsCount: 0,
      });
      setProjectRows((prev) => [newProjectRow, ...prev]);
      toastSuccess(t("done"));
    } catch (err) {
      toastError(t("failed"));
      console.error(err);
    }
  };

  const handleRemoveProject = async (
    sourceIdProject: number,
    targetIdProject: number
  ) => {
    try {
      const targetProjectRow = await projectService.removeProject(
        sourceIdProject,
        targetIdProject
      );
      const newProjectRows = projectRows
        .filter((r) => r.id !== sourceIdProject)
        .map((r) => {
          if (r.id === targetProjectRow.idProject) {
            r = createProjectRow(targetProjectRow);
          }
          return r;
        });
      setProjectRows(newProjectRows);
      toastSuccess(t("done"));
    } catch (err) {
      toastError(t("failed"));
      console.error(err);
    }
  };

  const openAddNewProjectModal = () => {
    if (!client?.idClient) return;
    setModalContent(
      <UpdateProjectModal
        modalTitle={t("add_new_project")}
        confirm={async (name: string) => {
          await handleAddNewProject(name, client.idClient);
          closeModal();
        }}
        cancel={closeModal}
      />
    );
  };

  const openEditProjectNameModal = (projectRow: ProjectRow) => {
    if (!client?.idClient) return;
    setModalContent(
      <UpdateProjectModal
        modalTitle={t("edit_project_name")}
        name={projectRow.name}
        confirm={async (name: string) => {
          await handleEditProjectNameModal(name, projectRow.id);
          closeModal();
        }}
        cancel={closeModal}
      />
    );
  };

  const openDeleteProjectModal = useCallback(
    (row: ProjectRow) => {
      if (!client?.idClient) return;
      setModalType("danger");
      setModalContent(
        <DeleteProjectModal
          projects={projectRows.map((r) => ({ idProject: r.id, name: r.name }))}
          confirm={async (sourceIdProject: number, targetIdProject: number) => {
            await handleRemoveProject(sourceIdProject, targetIdProject);
            closeModal();
          }}
          cancel={closeModal}
          sourceIdProject={row.id}
          sourceProjectJobsNumber={row.jobsAmount || 0}
        />
      );
    },
    [projectRows]
  );

  const openAddDocxTemplateModal = () => {
    if (!client?.idClient) return;
    setModalContent(
      <AddNewConfigurationModal
        modalTitle={t("add_new_docx_template")}
        modalDescription={"add_new_docx_template_description"}
        onConfirm={async (
          templateName: string,
          docxFormatType: Types.DocxFormatType | keyof Types.ValidationJobTypes
        ) => {
          await handleAddDocxTemplate({
            templateName,
            docxFormatType: docxFormatType as Types.DocxFormatType,
            idClient: client?.idClient,
          });
          closeModal();
        }}
        onCancel={closeModal}
        options={["speakers", "table"].map((_docxFormatType) => ({
          label: t(_docxFormatType),
          value: _docxFormatType,
        }))}
        typePlaceholder={t("select_type")}
        namePlaceholder={t("template_name")}
      />
    );
  };

  const openEditClientModal = () => {
    if (!client) return;
    setModalContent(
      <EditClientModal
        confirm={(_client) => handleEditClient(_client, client.idClient)}
        cancel={closeModal}
        client={client}
      />
    );
  };

  const handleOnSTTSettingsDone = (sttModelSettings: any) => {
    if (!client) return;
    const prevClientSettings = client.settings || {};
    handleSettingsChange(
      { settings: { ...prevClientSettings, sttModel: sttModelSettings } },
      client.idClient
    );
  };

  function handleAccessServiceCofirm(
    servicesAccessSettings: Types.ClientServicesAccess
  ) {
    if (!client) return;
    const prevClientSettings = client.settings || {};
    handleSettingsChange(
      {
        settings: {
          ...prevClientSettings,
          servicesAccess: servicesAccessSettings,
        },
      },
      client.idClient
    );
  }

  const openClientSettingsModal = () => {
    if (!client) return;

    const mergedSttSettings = client.settings?.sttModel
      ? _.defaultsDeep(client.settings?.sttModel, sttDefaultSTTSettings)
      : sttDefaultSTTSettings;

    const sttModelSteps = [
      {
        options: Object.keys(mergedSttSettings), //stt model options
      },
      {
        options: Object.keys(mergedSttSettings.protocol), //language options
      },
    ];
    const _sttDefaultSTTSettings = flatten(mergedSttSettings, {
      maxDepth: sttModelSteps.length,
    });

    setModalContent(
      <Pager
        sx={{ minWidth: "850px", minHeight: "408px" }}
        pages={[
          {
            pageTab: t("stt_models"),
            pageContent: (
              <SettingsModal
                showActions={false}
                useSettings={useSTTModelSettings}
                defaultSettings={_sttDefaultSTTSettings}
                onDone={handleOnSTTSettingsDone}
                steps={sttModelSteps}
                metadata={metadata}
                onCancel={closeModal}
              />
            ),
          },
          {
            pageTab: t("client_services_access"),
            pageContent: (
              <EditClientServicesAccess
                servicesAccess={client.settings?.servicesAccess}
                onCancel={closeModal}
                onConfirm={handleAccessServiceCofirm}
              />
            ),
          },
        ]}
      />
    );
  };

  const openProjectSTTModal = (idProject: number) => {
    if (!client) return;

    const mergedSttSettings = client.settings?.sttModel
      ? _.defaultsDeep(client.settings?.sttModel, sttDefaultSTTSettings)
      : sttDefaultSTTSettings;

    const sttModelSteps = [
      {
        options: Object.keys(mergedSttSettings), //stt model options
      },
      {
        options: Object.keys(mergedSttSettings.protocol), //language options
      },
    ];
    const _sttDefaultSTTSettings = flatten(mergedSttSettings, {
      maxDepth: sttModelSteps.length,
    });

    setModalContent(
      <Pager
        sx={{ minWidth: "850px", minHeight: "408px" }}
        pages={[
          {
            pageTab: t("stt_models"),
            pageContent: (
              <SettingsModal
                showActions={false}
                useSettings={useSTTModelSettings}
                defaultSettings={_sttDefaultSTTSettings}
                onDone={handleOnSTTSettingsDone}
                steps={sttModelSteps}
                metadata={metadata}
                onCancel={closeModal}
              />
            ),
          },
        ]}
      />
    );
  };

  const openEditUserModal = (row: UserRow) => {
    setModalContent(
      <EditUserModal
        confirm={(_user: Partial<Types.User>, idRole: Types.UserRole) =>
          handleEditUser(_user, row.user.idUser, idRole)
        }
        cancel={closeModal}
        user={row.user}
      />
    );
  };

  const handleEditJobMetadata = async (jobMetadata?: ClientJobMetadata) => {
    if (!idClient) {
      return;
    }
    try {
      await clientService.update({ jobMetadata }, +idClient);
      setClient(
        (prevClient) => ({ ...prevClient, jobMetadata } as Types.Client)
      );
      toastSuccess(t("metadata_updated"));
      closeModal();
    } catch (e) {
      console.log("clientService.update failed :", e);
      toastError(t("metadata_update_failed"));
    }
  };

  const openEditJobMetadataModal = () => {
    setModalContent(
      <ClientJobMetadataModal
        onCancel={closeModal}
        onConfirm={handleEditJobMetadata}
        metadata={client?.jobMetadata}
      />
    );
  };

  const openLinkUserModal = () => {
    setModalContent(
      <LinkUserModal
        confirm={(idUser, idRole) =>
          handleLinkUserToClient(idUser, Number(idClient), idRole)
        }
        cancel={closeModal}
        users={availableUsersToLink}
      />
    );
  };

  const createTemplateRow = (template: Types.DocxTemplate): TemplateRow => {
    return {
      id: template.idDocxTemplate,
      name: (template.settings as any)?.general?.presetName || "no name",
      type: template.type,
      template,
      actions: undefined,
    };
  };

  const createUserRow = (user: Types.User): UserRow => {
    return {
      id: user.idUser,
      name: `${user.firstName} ${user.lastName}`,
      phoneNumber: user.phone,
      email: user.email,
      user,
      actions: undefined,
    };
  };

  const createInvoiceRow = (invoice: Invoice): InvoiceRow => {
    return {
      duration: _.sumBy(invoice.jobsInvoices, "job.duration"),
      id: invoice.idInvoice,
      invoiceNumber: invoice.invoiceNumber,
      jobsAmount: invoice.jobsInvoices?.length,
      status: invoice.status,
    };
  };

  const createProjectRow = (project: Types.ProjectTable): ProjectRow => {
    return {
      id: project.idProject,
      name: project.name,
      jobsAmount: project.jobsCount,
      totalJobsDuration: project.totalJobsDuration,
      lastActivity: project.lastActivity
        ? new Date(project.lastActivity)
        : undefined,
    };
  };

  const createValidationRow = (
    validation: Types.ValidationPreset
  ): ValidationRow => {
    return {
      id: validation.idValidationPreset,
      name: validation.settings.general_lang
        ? validation.settings.general_lang.presetName
        : t("no_name"),
      jobType: validation.jobType,
      isDefault: validation.isDefault,
      createdAt: new Date(validation.createdAt),
      actions: undefined,
    };
  };

  const templateHeadCells = useHeadCells({
    headCellsKeys: ["templateId", "templateName", "templateType", "actions"],
    cellLink: {
      templateName: (row) =>
        `/clients/${idClient}/template/${row.type}/${row.id}`,
    },
    cellActions: {
      actions: [
        {
          name: "trash",
          icon: faTrash,
          action: (row: TemplateRow) => {
            openConfirmModal(
              t("are_you_sure"),
              t("action_is_irreversible"),
              () => handleRemoveTemplate(row),
              true
            );
          },
        },
      ],
    },
    labelAction: {
      actions: openAddDocxTemplateModal,
    },
    labelIcon: {
      actions: faPlusCircle,
    },
    styles: {
      actions: {
        width: 20,
      },
    },
  });

  const getInvoiceStatusCellDropDown = useCallback((row: InvoiceRow) => {
    const { statusColor: awaitingColor, statusTitle: awaitingTitle } =
      getInvoiceStatusVisual(InvoiceStatus.AWAITING);
    const { statusColor: writeOffColor, statusTitle: writeOffTitle } =
      getInvoiceStatusVisual(InvoiceStatus.WRITE_OFF);
    const { statusColor: paidColor, statusTitle: paidTitle } =
      getInvoiceStatusVisual(InvoiceStatus.PAID);

    return [
      {
        name: awaitingTitle,
        bullet: { color: awaitingColor },
        action: () =>
          handleInvoiceChange(row.id, { status: InvoiceStatus.AWAITING }),
      },
      {
        name: writeOffTitle,
        bullet: { color: writeOffColor },
        action: () =>
          handleInvoiceChange(row.id, { status: InvoiceStatus.WRITE_OFF }),
      },
      {
        name: paidTitle,
        bullet: { color: paidColor },
        action: () =>
          handleInvoiceChange(row.id, { status: InvoiceStatus.PAID }),
      },
    ] as ContextOption[];
  }, []);

  const invoicesHeadCells = useHeadCells({
    headCellsKeys: [
      "invoiceNumber",
      "jobsAmount",
      "invoiceStatus",
      "duration",
      "contextMenu",
    ],
    cellLink: {
      invoiceNumber: (row) => {
        return `${config.envUrl}?clientIds=${idClient}&invoiceIds=${row.id}`;
      },
    },
    tableContextCallBack: (row: InvoiceRow) =>
      [
        {
          name: t("delete_invoice"),
          color: errorColor,
          action: () => {
            openConfirmModal(
              t("are_you_sure"),
              t("action_is_irreversible"),
              () => handleRemoveInvoice(row),
              true
            );
          },
        },
      ] as ContextOption[],
    statusCellDropDownCallBack: (row) => getInvoiceStatusCellDropDown(row),
    styles: {
      contextMenu: {
        width: "5%",
      },
      invoiceStatus: {
        width: "15%",
      },
      duration: {
        width: "13%",
      },
    },
  });

  const userHeadCells = useHeadCells({
    headCellsKeys: [
      "userId",
      "userName",
      "userEmail",
      "userPhoneNumber",
      "actions",
    ],
    cellActions: {
      actions: [
        {
          name: "trash",
          icon: faTrash,
          action: removeUserFromClient,
        },
        {
          name: "edit",
          icon: faPenToSquare,
          action: openEditUserModal,
        },
      ],
    },
    labelAction: {
      actions: openLinkUserModal,
    },
    labelIcon: {
      actions: faPlusCircle,
    },
    styles: {
      actions: {
        width: 20,
      },
    },
  });

  const validationHeadCells = useHeadCells({
    headCellsKeys: [
      "validationId",
      "validationName",
      "validationJobType",
      "validationCreatedAt",
      "actions",
    ],
    cellActions: {
      actions: [
        {
          name: "trash",
          icon: faTrash,
          action: (row: ValidationRow) => {
            openConfirmModal(
              t("are_you_sure"),
              t("action_is_irreversible"),
              () => handleRemoveValidation(row.id),
              true
            );
          },
        },
      ],
    },
    cellLink: {
      validationName: (r) => "/clients/" + idClient + "/validation/" + r.id,
    },
    labelAction: {
      actions: openAddValidationPresetModal,
    },
    labelIcon: {
      actions: faPlusCircle,
    },
    styles: {
      actions: {
        width: 20,
      },
    },
  });

  const projectHeadCells = useHeadCells({
    headCellsKeys: [
      "projectName",
      "projectVolume",
      "projectLastActivity",
      "contextMenu",
    ],
    tableContextCallBack: (row: ProjectRow) =>
      [
        {
          name: t("edit_project_name"),
          action: () => {
            openEditProjectNameModal(row);
          },
        },
        {
          name: t("delete_project"),
          color: errorColor,
          action: () => {
            openDeleteProjectModal(row);
          },
        },
      ] as ContextOption[],
    cellLink: {
      projectName: (r) => "/clients/" + idClient + "/project/" + r.id,
    },
    labelAction: {
      contextMenu: openAddNewProjectModal,
    },
    labelIcon: {
      contextMenu: faPlusCircle,
    },
    styles: {
      contextMenu: {
        width: 0,
      },
      projectName: {
        width: "60%",
      },
      projectVolume: {
        width: "20%",
      },
      projectLastActivity: {
        width: "20%",
      },
    },
  });

  const views: Views = {
    users: {
      tab:
        userRows.length > 0 ? `${t("users")}(${userRows.length})` : t("users"),
      view: (
        <GeneralTable
          rows={userRows}
          headCells={userHeadCells.headCells}
          allowSelect={false}
        />
      ),
    },
    projects: {
      tab:
        projectRows.length > 0
          ? `${t("projects")}(${projectRows.length})`
          : t("projects"),
      view: (
        <GeneralTable
          rows={projectRows}
          headCells={projectHeadCells.headCells}
          allowSelect={false}
        />
      ),
    },
    templates: {
      tab:
        templateRows.length > 0
          ? `${t("templates")}(${templateRows.length})`
          : t("templates"),
      view: (
        <GeneralTable
          rows={templateRows}
          headCells={templateHeadCells.headCells}
          allowSelect={false}
        />
      ),
    },
    validations: {
      tab:
        validationRows.length > 0
          ? `${t("validations")}(${validationRows.length})`
          : t("validations"),
      view: (
        <GeneralTable
          rows={validationRows}
          headCells={validationHeadCells.headCells}
          allowSelect={false}
        />
      ),
    },
    notes: {
      tab: t("notes"),
      view: (
        <Textarea
          defaultValue={notes.current}
          placeholder={t("client_notes", { name: client?.name })}
          onChange={(e: any) => {
            notes.current = e.target.value;
            handleNotesChange();
          }}
        ></Textarea>
      ),
    },
    payments: {
      tab: t("payments"),
      view: (
        <GeneralTable
          rows={invoiceRows}
          headCells={invoicesHeadCells.headCells}
          allowSelect={false}
        />
      ),
    },
  };

  useEffect(() => {
    if (client?.templates) {
      setTemplateRows(client.templates.map(createTemplateRow));
    }
    if (client?.users) {
      setUserRows(client.users.map(createUserRow));
    }
    if (client?.validations) {
      setValidationRows(client.validations.map(createValidationRow));
    }
    if (client?.idClient) {
      getAvailableUsersToLink(client?.idClient);
    }
    if (client?.invoices) {
      setInvoiceRows(client.invoices.map(createInvoiceRow));
    }
    notes.current = client?.notes ? client.notes : "";
  }, [client]);

  useEffect(() => {
    const getClientProjects = async (idClient: number) => {
      const { projects } = await projectService.getTableProjects({
        filters: { idClient },
      });
      const projectRows = projects.map(createProjectRow);
      setProjectRows(projectRows);
    };
    if (client?.idClient) {
      getClientProjects(client?.idClient);
    }
  }, [client]);

  useEffect(() => {
    const tab = searchParams.get("tab");
    if (!tab) {
      setSearchParams({ tab: "users" });
    }
  }, [searchParams]);

  return (
    <Grid
      className={"Client Page"}
      container
      display={"flex"}
      justifyContent={"center"}
    >
      <Grid item xs={11} my={2}>
        <Breadcrumbs
          crumbs={[
            { label: t("clients"), path: "/clients" },
            { label: client?.name as string },
          ]}
        />
        <Grid container mb={2} sx={{ height: "230px" }}>
          <Grid
            sx={{
              height: "100%",
            }}
            item
            xs={9}
            pr={3}
          >
            {client && (
              <ClientOverview
                client={client}
                onEditClientClick={openEditClientModal}
                onClientSettingsClick={openClientSettingsModal}
                onEditJobMetadataClick={openEditJobMetadataModal}
              />
            )}
          </Grid>
          <Grid
            sx={{
              height: "100%",
            }}
            item
            xs={3}
          >
            {client?.jobs && (
              <JobsDeliveryStatus
                jobs={client.jobs}
                onAddJobs={() =>
                  navigate(`/clients/uploads/${client.idClient}`)
                }
                onShowJobs={() => {
                  navigate(`/?clientIds=${client.idClient}`);
                }}
              />
            )}
          </Grid>
        </Grid>
        <TabView views={views}></TabView>
      </Grid>
    </Grid>
  );
};

import * as _ from "lodash";
import { Languages } from "../constants/languages";
import { JobTypes } from "./../entities/JobTypes";
import { DiarizeTypes } from "../Algo/diarize";
import { STTConfigPage } from "./STTConfig";

export class JobSettingsPage {
  jobType: keyof JobTypes = "subtitles";
  sourceLanguage: Languages[] = ["he-IL"];
  targetLanguage: Languages[] = [];
  deliveryTimeInDays = 3;
  communityMemberDeadlineInDays = 1;
  auto = false;
  burn = false;
  idValidation?: number = undefined;
  constructor(args?: JobSettingsPage) {
    if (args && typeof args !== "undefined") {
      Object.assign(this, _.defaultsDeep(args, this));
    }
  }
}

export class CustomFieldsPage {
  constructor(args?: Record<any, any>) {
    if (args && typeof args !== "undefined") {
      Object.assign(this, _.defaultsDeep(args, this));
    }
  }
}

export class SpeakersSplitPage {
  autoSpeakerSplit = true;
  splitBy: DiarizeTypes = DiarizeTypes.UNSUPRTVISED;
  constructor(args?: SpeakersSplitPage) {
    if (args && typeof args !== "undefined") {
      Object.assign(this, _.defaultsDeep(args, this));
    }
  }
}

export class ExtractPage {
  enabled = false;
  constructor(args?: ExtractPage) {
    if (args && typeof args !== "undefined") {
      Object.assign(this, _.defaultsDeep(args, this));
    }
  }
}

export interface ProjectConfig {
  jobSettings?: JobSettingsPage;
  customFields?: CustomFieldsPage;
  speakersSplit?: SpeakersSplitPage;
  extract?: ExtractPage;
  model?: STTConfigPage;
}

import { TFunction } from "i18next";
import { ExtractPage, Platform } from "@sumit-platforms/types";
import { PageSettings } from "../../../core/SettingsPage/SettingsPage";

export const getExtractPageInputs: (
  args: ExtractPage,
  t: TFunction,
  platform?: Platform
) => PageSettings<{ extract: ExtractPage }> = (args, t, platform) => {
  return {
    settings: { extract: args },
    pageTab: t("settings_project_extract"),
    tabLabel:
      platform === Platform.OPERA ? t("settings_project_features") : undefined,
    sections: [
      {
        title: t("settings_project_extract"),
        inputs: [
          {
            label: t("settings_project_extract_enabled"),
            key: "extract.enabled",
            type: "switch",
            style: { width: 200 },
          },
        ],
      },
    ],
  };
};

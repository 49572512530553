import _ from "lodash";
import * as React from "react";
import { useRef } from "react";
import classNames from "classnames";
import { SubtitleRangeElement } from "../../types";
import { useRecoilValue } from "recoil";
import { currentTimeState, directionState } from "../../store/states";
import { useSlateStatic } from "@sumit-platforms/slate-react";
import { NewTimerPicker } from "../NewTimerPicker/NewTimerPicker";

import "./RangeSubtitles.scss";

interface Props {
  attributes: any;
  children?: React.ReactNode;
  startIndex?: number | null;
  renderIndex?: number | null;
  hide?: boolean;
}

const RangeSubtitlePlaceholder = ({
  attributes,
  children,
  startIndex,
  renderIndex,
  hide,
}: Props) => {
  if (!_.isNumber(startIndex) || !_.isNumber(renderIndex)) {
    throw new Error("startIndex and renderIndex must be numbers");
  }

  const editor = useSlateStatic();
  const direction = useRecoilValue(directionState);

  const element = editor.children[
    (startIndex || 0) + (renderIndex || 0)
  ] as SubtitleRangeElement;

  const rangeRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      className={classNames(
        `SlateRangeSubtitle rangeSubtitlePlaceholder passed`,
        direction,
        {
          hide,
          // warning: !_.isEmpty(range.validation?.warnings),
        }
      )}
      {...attributes}
      contentEditable={false}
    >
      <div className="subtitleRangeTimes subtitles">
        <NewTimerPicker
          className={classNames({
            ltr: direction === "ltr",
            overlapping:
              element.range.validation?.errors?.overlapping_prev ||
              element.range.validation?.errors?.start_after_end,
          })}
          isFocused={false}
          value={element.range.st}
          disabled={true}
          step={1}
          isPlaceholderPicker={true}
        />
        <NewTimerPicker
          className={classNames({
            ltr: direction === "ltr",
            overlapping:
              element.range.validation?.errors?.overlapping_next ||
              element.range.validation?.errors?.start_after_end,
          })}
          isFocused={false}
          value={element.range.et}
          disabled={true}
          step={1}
          isPlaceholderPicker={true}
        />
      </div>

      <div className="textContainer">
        <div className={classNames("rangeText disabled")} ref={rangeRef}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default RangeSubtitlePlaceholder;

import * as _ from "lodash";
import { JobTypes } from "../entities/JobTypes";
import { Align, FontSettings, TextDecoration } from "./CommonConfig";

export type ValidationJobTypes = Pick<JobTypes, "subtitles">;

export enum ReadSpeed {
  CPS = "CPS",
  WPM = "WPM",
}
export class ValidationLanguagePage {
  limitSubtitlesLine = 0;
  limitLineLength = 0;
  font: FontSettings = {
    fontFamily: "Arial",
    fontSize: 14,
    align: Align.justify,
    color: "#fff",
    backgroundColor: "#fff",
    textDecoration: [TextDecoration.underline],
  };

  constructor(args?: Partial<ValidationGeneralPage>) {
    if (args && typeof args !== "undefined") {
      Object.assign(this, _.defaultsDeep(args, this));
    }
  }
}

export class ValidationGeneralPage extends ValidationLanguagePage {
  presetName = "";
  subtitlesLength: {
    min: number;
    max: number;
  } = {
    min: 0,
    max: 0,
  };
  readSpeed: {
    readSpeed: ReadSpeed;
    charsPerSecond: number;
    wordsPerMinute: number;
  } = {
    readSpeed: ReadSpeed.CPS,
    charsPerSecond: 0,
    wordsPerMinute: 0,
  };
  framesBetweenSubtitles = 0;
  framesBetweenSubtitlesToCutline = 0;

  constructor(args?: Partial<ValidationGeneralPage>) {
    super(args);
    if (args && typeof args !== "undefined") {
      Object.assign(this, _.defaultsDeep(args, this));
    }
  }
}

export type GeneralLang = "general_lang";

export enum ValidationLang {
  he_IL = "he_IL",
  en_US = "en_US",
  ar_IL = "ar_IL",
  es_ES = "es_ES",
  ru_RU = "ru_RU",
  pt_BR = "pt_BR",
  fr_FR = "fr_FR",
}
export type ValidationsConfig = Record<GeneralLang, ValidationGeneralPage> &
  Partial<Record<ValidationLang, ValidationLanguagePage>>;

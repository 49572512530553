import React, {
  ReactChild,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import _ from "lodash";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { EditorMode, JobTCOffsets, Media } from "@sumit-platforms/types";
import { CustomEditor, SubtitleRangeElement } from "../../types";
import { getTimecodeFromSeconds } from "@sumit-platforms/ui-bazar/utils";
import { useKeyboardShortcuts } from "@sumit-platforms/ui-bazar/hooks";
import MediaService from "../../services/MediaService";
import TimeService from "../../services/TimeService";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  currentTimeState,
  featureFlagsState,
  fpsState,
  isMediaPanelOpenState,
  mediaErrorState,
} from "../../store/states";
import { SpeedTimes } from "./SpeedTimes";
import { Times } from "./Times";
import EditorService from "../../services/EditorService";
import { MediaSwitcher } from "./MediaSwitcher";
import { MediaControllers } from "./MediaControllers";
import RangeNavigator from "./RangeNavigator";
import { useRapidTimeUpdate } from "../../hooks/useRapidTimeUpdate";

import "./MediaPlayer.scss";

interface MediaPlayerV3 {
  direction: "ltr" | "rtl";
  mediaSources: Media[];
  initialPlaybackPosition: number;
  displayText?: boolean;
  onCurrentTimeUpdate?: (currTime: number) => void;
  offsets?: { [key: string]: number };
  isStreaming?: boolean;
  children?: ReactChild;
  jobId: string | number;
  tcOffsets?: JobTCOffsets;
  downloadMedia?: (idMedia: number) => Promise<any>;
  editorController?: CustomEditor;
  mode: EditorMode;
  openVideoOnStart?: boolean;
}

const MediaPlayerV3 = ({
  direction,
  mediaSources,
  onCurrentTimeUpdate,
  initialPlaybackPosition = 0,
  displayText = false,
  offsets,
  isStreaming,
  children,
  jobId,
  tcOffsets,
  downloadMedia,
  editorController,
  mode,
  openVideoOnStart,
}: MediaPlayerV3) => {
  const featureFlags = useRecoilValue(featureFlagsState);
  const [, setCurrentTime] = useRecoilState(currentTimeState);
  const [mediaError, setMediaError] = useRecoilState(mediaErrorState);

  useRapidTimeUpdate({
    onChange: setCurrTime,
    enable: mode === "subtitles" && !!featureFlags.rapidTimeUpdate,
  });

  //MEMOIZED CONSTS
  const videoContainerId = useMemo(() => "videoPlayerContainer", []);
  const maxPlayerWidth = useMemo(() => 650, []);
  const maxPlayerHeight = useMemo(() => 380, []);
  const minPlayerWidth = useMemo(() => 380, []);
  const minPlayerHeight = useMemo(() => 120, []);
  const initialdimensions = useMemo(
    () => ({
      width: 455,
      height: 280,
    }),
    []
  );

  //SELECTORS
  // const { playerRewind, playerForward, playerRewindResume } = useSelector(
  //   (state: AppState) => state.userStore.settings
  // );
  const playerRewind = useMemo(() => 3, []);
  const playerForward = useMemo(() => 3, []);
  const playerRewindResume = useMemo(() => 0, []);

  //STATES
  const [currentCaptionsArrs, setCurrentCaptionsArrs] = useState<string[][]>(
    []
  );
  const [fps, setFps] = useRecoilState(fpsState);
  const [isOpen, setIsOpen] = useRecoilState(isMediaPanelOpenState);
  const [autoPlay, setAutoPlay] = useState<boolean>(false);
  const [mediaLength, setMediaLength] = useState(0);
  const [currSpeed, setCurrSpeed] = useState<number>(1);
  const [showWaveForm, setShowWaveForm] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const [isSourceListOpen, setIsSourceListOpen] = useState<boolean>(false);
  const [isDraggable, _setIsDraggable] = useState<boolean>(false);
  const timerEl = useRef<HTMLDivElement>(null);
  const timerTcEl = useRef<HTMLDivElement>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [selectedMedia, _setSelectedMedia] = useState<Media>(
    mediaSources.find((media: Media) => media.mimetype.includes("video")) ||
      mediaSources[0]
  );

  const [dimensions, setdimensions] = useState<{
    width: number;
    height: number;
  }>(initialdimensions);

  const hasVideo = useMemo(() => {
    return selectedMedia?.mimetype.includes("video") || false;
  }, [selectedMedia]);

  const renewWaveForm = (): Promise<void> => {
    return new Promise((res, rej) => {
      try {
        const tempCurrTime = MediaService?.currentTime
          ? MediaService.currentTime
          : 0;
        setShowWaveForm(false);
        setTimeout(() => {
          setShowWaveForm(true);
          setTimeout(() => {
            MediaService.setOffset(tempCurrTime);
            res();
          }, 100);
        }, 50);
      } catch (err) {
        rej(err);
      }
    });
  };

  const handleRepeatInterval = (time: number) => {
    if (!editorController) return;
    const currentPlayingRange = EditorService.getCurrentSubtitleRangePlaying({
      editor: editorController,
      currentTime: time,
    });

    if (currentPlayingRange > 0 && MediaService.currentRepeatedRange === -1) {
      MediaService.setCurrentRepeatedRange(currentPlayingRange);
    }
    if (MediaService.currentRepeatedRange > -1) {
      const range = (
        editorController?.children[
          MediaService.currentRepeatedRange
        ] as SubtitleRangeElement
      )?.range;
      if (!range) return;
      const { st, et } = range;
      if (time >= et) MediaService.setOffset(st);
    }
  };

  function setCurrTime(time: number) {
    if (timerEl.current) {
      timerEl.current.innerHTML = TimeService.getTimeStringFromSecs(time);
    }
    if (timerTcEl.current) {
      timerTcEl.current.innerHTML = getTimecodeFromSeconds(time, {
        tcOffsets: MediaService.tcOffsets,
      });
    }

    setCurrentTime(time);

    if (editorController?.handleTimeChange) {
      editorController?.handleTimeChange(time);
    }

    if (MediaService.repeat) {
      handleRepeatInterval(time);
    }
  }

  const onMediaLoadError = useCallback(
    (src: string) => {
      setMediaError({ ...mediaError, [src]: true });
    },
    [setMediaError, mediaError]
  );

  //EVENTS
  useEffect(() => {
    renewWaveForm();
  }, [isDraggable]);

  useEffect(() => {
    const _renewWaveFormAfterResizing = async () => {
      if (isDraggable) {
        await renewWaveForm();
      }
    };
    _renewWaveFormAfterResizing();
  }, [dimensions]);

  useEffect(() => {
    setTimeout(() => {
      if (isMounted) {
        setShowWaveForm(true);
      }
    }, 1000);
    setIsOpen(openVideoOnStart || false);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const setOffset = useCallback(
    (media: Media) => {
      if (offsets) {
        const _offset = offsets[media.name]
          ? Math.abs(offsets[media.name] / 1000)
          : 0;
        return _offset;
      }
      return;
    },
    [offsets]
  );

  useEffect(() => {
    // Updating the setCurrTime fn due to closure problem
    if (featureFlags.rapidTimeUpdate) return;
    MediaService.setSetCurrTime(setCurrTime, setOffset(selectedMedia));
  }, [selectedMedia, featureFlags, setOffset, setCurrTime]);

  useEffect(() => {
    if (!selectedMedia) return;
    const tempCurrTime = MediaService?.currentTime
      ? MediaService.currentTime
      : 0;
    MediaService.initializeMedia({
      media: selectedMedia,
      setMedia: setSelectedMedia,
      setCurrTime: featureFlags.rapidTimeUpdate ? _.noop : setCurrTime, // in subtitles, well use useRapidTimeUpdate instead
      setIsPlaying,
      setMediaLength,
      mediaLength,
      offset: setOffset(selectedMedia),
      autoPlay,
      isStreaming,
      jobId: jobId.toString(),
      fps,
      tcOffsets,
      onMediaLoadError,
    });
    setTimeout(() => {
      MediaService.setOffset(tempCurrTime);
      setAutoPlay(true);
    }, 100);
    return () => MediaService.pause();
  }, [selectedMedia, fps, editorController]);

  const setSelectedMedia = useCallback(
    (media: Media) => {
      const fps = media.fps || 25;
      setFps(fps);
      _setSelectedMedia(media);
      setMediaError({});
    },
    [setFps, setMediaError]
  );
  const handleTogglePlayerKeystroke = useCallback(
    (e: React.KeyboardEvent, isOpen: boolean) => {
      e.preventDefault();
      e.stopPropagation();
      setIsOpen(isOpen);
    },
    []
  );

  const handleDownloadMedia = useCallback(
    async (e: any, idMedia: number) => {
      e.stopPropagation();
      if (!idMedia || !downloadMedia) return;
      try {
        await downloadMedia(idMedia);
      } catch (e) {
        console.error(e);
      }
    },
    [downloadMedia]
  );

  //RENDERING

  const replay = useCallback(() => {
    MediaService.setOffset(0);
  }, []);

  useKeyboardShortcuts({
    handlers: {
      SHOW_PLAYER_CONTROLS: (e) => handleTogglePlayerKeystroke(e, true),
      HIDE_PLAYER_CONTROLS: (e) => handleTogglePlayerKeystroke(e, false),
    },
    disabled: !featureFlags?.useNewKeyboardShortcuts,
  });

  const renderMediaPlayerClasses = useCallback((): string => {
    if (isDraggable) {
      return "draggable";
    }

    return !isOpen ? "close" : "";
  }, [isDraggable, isOpen]);

  // CONTROLLERS
  // const goRewindFast = () => {
  //   MediaService.playRelative((playerRewind || 5) * -1 * 10);

  // };
  // const goForwardFast = () => {

  //   MediaService.playRelative((playerForward || 5) * 10);

  // };

  return (
    // <Draggable
    //   handle={`#${videoContainerId}`}
    //   bounds={"html"}
    //   axis={isDraggable ? "both" : "none"}
    // >
    <div
      className={classNames("SlateMediaPlayer", direction, {
        draggable: isDraggable,
        close: !isOpen,
      })}
      style={isDraggable ? { width: "fit-content" } : {}}
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className={`mediaPlayerV3Controls  ${!isOpen ? "close" : ""}`}>
        <div className="part-1">
          <SpeedTimes setCurrSpeed={setCurrSpeed} currSpeed={currSpeed} />
          <MediaSwitcher
            onSelectMedia={setSelectedMedia}
            handleDownloadMedia={handleDownloadMedia}
            mediaSources={mediaSources}
            selectedMedia={selectedMedia}
          />
        </div>
        <div className="part-2 controllers">
          <MediaControllers editor={editorController} />
        </div>
        <div className="part-3 time">
          <RangeNavigator editor={editorController} />

          <Times
            tcOffsets={tcOffsets}
            timerTcEl={timerTcEl}
            timerEl={timerEl}
            mediaLength={mediaLength}
          />
          {!isDraggable && (
            <FontAwesomeIcon
              onClick={() => setIsOpen(!isOpen)}
              className={`toggle ${!isOpen ? "close" : ""}`}
              icon={faAngleDown}
              size="2x"
            ></FontAwesomeIcon>
          )}
        </div>
      </div>
      <div className={`media-player-v3-arranger ${!isOpen ? "close" : ""}`}>
        {showWaveForm && children}
      </div>
    </div>
    // </Draggable>
  );
};

export default MediaPlayerV3;

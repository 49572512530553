import React, { FC, useEffect } from "react";
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { faBan } from "@fortawesome/pro-light-svg-icons";

import { sumitLogoBlack } from "@sumit-platforms/common-assets/images";
import {
  BreathingLoader,
  ErrorPage,
  PrivateRoute,
} from "@sumit-platforms/ui-bazar";
import {
  useKeyboardShortcuts,
  useProjectsSettings,
  useSpeakersDocxSettings,
  useTableDocxSettings,
  useValidationsSettings,
} from "@sumit-platforms/ui-bazar/hooks";

import useAuth from "./hooks/useAuth";
import { useUser } from "./store/user";

import Guest from "./layouts/Guest/Guest";
import { Dashboard } from "./layouts/Dashboard/Dashboard";
import { authMap } from "./layouts/Dashboard/menuItems";

//PAGES
import LoginPage from "./pages/Auth/LoginPage";
import SignUpPage from "./pages/Auth/SignUpPage";
import ResetPasswordPage from "./pages/Auth/ResetPasswordPage";
import { Jobs } from "./pages/Jobs/Jobs";
import { Clients } from "./pages/Clients/Clients";
import { Client } from "./pages/Client/Client";
import { NotFound } from "./pages/NotFound/NotFound";
import { SettingsEditor } from "./pages/SettingsEditor/SettingsEditor";
import UploadFiles from "./pages/UploadFiles/UploadFiles";
import { Community } from "./pages/Community/Community";
import MyJobs from "./pages/MyJobs/MyJobs";
import Wallet from "./pages/Wallet/Wallet";
import Job from "./pages/Job/Job";
import AvailableJobs from "./pages/AvailableJobs/AvailableJobs";
import "react-virtualized/styles.css";

export const Delivery: FC = () => {
  const { t } = useTranslation();
  const { userLoading } = useAuth();
  const { roles, user } = useUser();
  const { initShortcuts } = useKeyboardShortcuts();

  useEffect(() => {
    initShortcuts(user?.settings);
  }, [initShortcuts, user?.settings]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Dashboard />,
      children: [
        {
          path: "",
          element: (
            <PrivateRoute
              userStatus={user?.status}
              userRoles={roles}
              authConfig={authMap["/"]}
              fallbackRoute={"/my-jobs"}
            >
              <Jobs />
            </PrivateRoute>
          ),
        },
        {
          path: "/my-jobs",
          element: (
            <PrivateRoute
              userStatus={user?.status}
              userRoles={roles}
              authConfig={authMap.myJobs}
            >
              <MyJobs />
            </PrivateRoute>
          ),
        },
        {
          path: "available-jobs",
          element: (
            <PrivateRoute
              userStatus={user?.status}
              userRoles={roles}
              authConfig={authMap.availableJobs}
            >
              <AvailableJobs />
            </PrivateRoute>
          ),
        },
        {
          path: "job/:idJob",
          element: <Job />,
        },
        {
          path: "clients/*",
          element: (
            <PrivateRoute
              userStatus={user?.status}
              userRoles={roles}
              authConfig={authMap.clients}
              fallbackRoute={"/available-jobs"}
            >
              <Routes>
                <Route path="" element={<Clients />} />
                <Route path=":idClient" element={<Client />} />
                <Route path="/uploads/:idClient" element={<UploadFiles />} />
                <Route
                  path=":idClient/template/speakers/:id"
                  element={
                    <SettingsEditor
                      useSettings={useSpeakersDocxSettings}
                      settingsType={"template"}
                    />
                  }
                />
                <Route
                  path=":idClient/template/table/:id"
                  element={
                    <SettingsEditor
                      useSettings={useTableDocxSettings}
                      settingsType={"template"}
                    />
                  }
                />
                <Route
                  path=":idClient/validation/:id"
                  element={
                    <SettingsEditor
                      useSettings={useValidationsSettings}
                      settingsType={"validation"}
                    />
                  }
                />
                <Route
                  path=":idClient/project/:id"
                  element={
                    <SettingsEditor
                      useSettings={useProjectsSettings}
                      settingsType={"project"}
                    />
                  }
                />
              </Routes>
            </PrivateRoute>
          ),
        },
        {
          path: "community/*",
          element: (
            <PrivateRoute
              userStatus={user?.status}
              userRoles={roles}
              authConfig={authMap.community}
            >
              <Routes>
                <Route path="" element={<Community />} />
                <Route path=":idUser" element={<Community />} />
              </Routes>
            </PrivateRoute>
          ),
        },
        {
          path: "wallet",
          element: (
            <PrivateRoute
              userStatus={user?.status}
              userRoles={roles}
              authConfig={authMap.wallet}
            >
              <Wallet />
            </PrivateRoute>
          ),
        },
        {
          path: "unauthorized",
          element: (
            <ErrorPage
              title={t("unauthorized")}
              text={t("unauthorized_text")}
              icon={faBan}
              redirectToOnClick={"/"}
            />
          ),
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
    {
      path: "/",
      element: <Guest />,
      children: [
        {
          path: "signup",
          element: <SignUpPage />,
        },
        {
          path: "login",
          element: <LoginPage />,
        },
        {
          path: "password-reset",
          element: <ResetPasswordPage />,
        },
        {
          path: "*",
          element: <LoginPage />,
        },
      ],
    },
  ]);

  return userLoading ? (
    <BreathingLoader
      className={userLoading ? "fadeIn" : "fadeOut"}
      image={sumitLogoBlack}
    />
  ) : (
    <RouterProvider router={router}></RouterProvider>
  );
};

/**
 * @interface EnrichIndexPayload
 * @job_id {number} id job as saved in DB
 * @creation_time {Date} creation time of the job in DB
 * @group_id {number} equivalent to project id in DB
 * @media {string} path to media in bucket
 * @transcript {string} path to data.json in bucket
 * @checkpoint {string} path to checkpoint.json in bucket
 * @index {number} client id in DB as string
 * @tags {string[]} list of tags
 * @filename {string} name of the uploaded file
 */
interface EnrichIndexPayload {
  job_id: number;
  group_id: number;
  media: string;
  transcript: string;
  checkpoint: string;
  index: string;
  tags: string[];
  filename: string;
  tc_offset: number[][];
  creation_time: Date;
  output?: {
    frames?: string;
  };
  callback?: string;
  config?: any;
  callback_payload?: {
    id_job?: number;
    id_index_search?: number;
  };
}

const CHECKPOINT_FILENAME = "indexing_checkpoint.json";
const ENRICH_TIMEOUT_IN_HOURS = 12;

enum EnrichRequestStatus {
  PENDING = "PENDING",
  DONE = "DONE",
  FAILED = "FAILED",
}

interface EnrichModelStatus {
  model: string;
  success: boolean;
}

interface IndexSearch {
  idIndexSearch: number;
  idJob: number;
  status: EnrichRequestStatus;
  indexPrefix: string;
  models: EnrichModelStatus[];
  errorLogs: string;
  progress: number;
  createdAt: Date;
  updatedAt: Date;
}

export type { EnrichIndexPayload, EnrichModelStatus, IndexSearch };
export { CHECKPOINT_FILENAME, EnrichRequestStatus, ENRICH_TIMEOUT_IN_HOURS };

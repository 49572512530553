import _ from "lodash";
import { useTranslation } from "react-i18next";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { format } from "date-fns";
import { Box, Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import {
  DocxTemplate,
  EmptySubtitle,
  ExportEncoding,
  ExportEncodingOptions,
  ExportOptions,
  Job,
  ZeroSubtitle,
  ZeroSubtitleLine,
  zeroSubtitlesOptions,
} from "@sumit-platforms/types";

import { Tooltip } from "../../core/Tooltip/Tooltip";
import { DATE_FORMAT_FILENAME, VIDEO_FORMATS } from "../../constants/globals";
import { MultiSelect, Option } from "../../core/MultiSelect/MultiSelect";
import { ZeroSubtitleForm } from "../../core/ZeroSubtitleForm/ZeroSubtitleForm";
import Input from "../../core/Input/Input";
import { Switch } from "../../core/Switch/Switch";
import { Button } from "../../core/Button/Button";
import { tcRegex } from "../../utils/regex";
import { getTcString } from "../../utils/time";
import { isVideoFormat } from "../../utils/media";
import {
  docxSpeakersDefaultSettings,
  docxTableDefaultSettings,
} from "../../hooks/pageSettings/docx/docxDefaultSettings";

import "./ExportJobModal.scss";

const SPEAKERS_FORMAT = "default_speakers_format";
const TABLE_FORMAT = "default_table_format";
export interface ExportJobModalProps {
  jobName?: string;
  confirm: (fileName: string, options: ExportOptions) => Promise<void>;
  cancel: () => void;
  disabled: boolean;
  jobTypes: any[];
  jobType?: string;
  templates?: any[];
  tcOffset?: number | null;
  bulkExport?: boolean;
  selectedJobs?: Job[];
  selectedRanges?: number[];
}

interface JobTypeUIExportOptions {
  [jobType: string]: {
    timeCodeInput?: boolean;
    flipSubtitlesInput?: {
      active: boolean;
      excludeFormats: string[];
    };
    zeroSubtitleInput?: boolean;
    templateSelectInput?: boolean;
    intervalsInput?: boolean;
    advancedSettingsButton?: boolean;
    selectDocxFormat?: boolean;
    emptySubtitle?: boolean;
    autoBreak?: boolean;
  };
}
const bulkFileName = `Export ${format(new Date(), DATE_FORMAT_FILENAME)}`;

export const ExportJobModal = ({
  jobName,
  confirm,
  cancel,
  disabled,
  jobType,
  jobTypes,
  templates,
  tcOffset = 0,
  bulkExport,
  selectedJobs,
  selectedRanges,
}: ExportJobModalProps) => {
  const { t } = useTranslation();

  const [optionalFormats, setOptionalFormats] = useState<Option[]>([]);
  const [encodingOptions] = useState<Option[]>(
    ExportEncodingOptions.map((e) => ({ label: e, value: e }))
  );
  const currentJobTypeWithFormats = jobTypes.find(
    (_jobType) => _jobType.name === jobType
  );

  const [selectedFormat, setSelectedFormat] = useState<string>();
  const [exportSelectedRangeOnly, setExportSelectedRangeOnly] =
    useState<boolean>(!_.isEmpty(selectedRanges));

  useEffect(() => {
    if (currentJobTypeWithFormats) {
      setOptionalFormats(
        currentJobTypeWithFormats.formats.map((f: any) => ({
          label: VIDEO_FORMATS.includes(f.name)
            ? `${t(f.name)} - ${t("burn")}`
            : t(f.name),
          value: f.name,
        }))
      );
      setSelectedFormat(currentJobTypeWithFormats.formats[0].name);
    }
  }, [jobTypes, jobType]);

  const jobTypeUiExportOptions: JobTypeUIExportOptions = useMemo(() => {
    return {
      interview: {
        selectDocxFormat: true,
        timeCodeInput: true,
        autoBreak: true,
      },
      subtitles: {
        timeCodeInput: true,
        flipSubtitlesInput: {
          active: true,
          excludeFormats: ["cavena890", "pac"],
        },
        autoBreak: true,
        emptySubtitle: true,
        zeroSubtitleInput: true,
      },
      protocol: {
        autoBreak: true,
        selectDocxFormat: true,
      },
    };
  }, []);
  const [flipSubtitles, setFlipSubtitles] = useState(false);
  const [zeroSubtitleToggle, setZeroSubtitleToggle] = useState(false);
  const [emptySubtitleToggle, setEmptySubtitleToggle] = useState(false);
  const [autoBreakToggle, setAutoBreakToggle] = useState(false);

  const zeroSubtitleFormRef = useRef<ZeroSubtitleLine>(null);
  const [zeroSubtitle, setZeroSubtitle] = useState<ZeroSubtitle>({
    content: [],
    timeFormat: "frames",
    endTime: 8,
    startTime: 0,
  });
  const [fileName, setFileName] = useState<string>(jobName || bulkFileName);
  const [tc, setTc] = useState<string | null>(
    !_.isNil(tcOffset) ? getTcString(tcOffset || 0) : null
  );
  const [isLoadind, setIsLoading] = useState<boolean>(false);
  const [encoding, setEncoding] = useState<ExportEncoding>(
    encodingOptions[0].value as ExportEncoding
  );

  const docxTemplateOptions = useMemo(() => {
    const defaultDocxTemplatesOptions = [
      {
        label: t("default_speakers"),
        value: SPEAKERS_FORMAT,
      },
      {
        label: t("default_table"),
        value: TABLE_FORMAT,
      },
    ];

    return templates
      ? [
          ...templates.map((template: DocxTemplate) => ({
            label:
              (template?.settings as any)?.general?.presetName || t("no_name"),
            value: template.idDocxTemplate,
            toolTip: template.type ? t(template.type) : undefined,
          })),
          ...defaultDocxTemplatesOptions,
        ]
      : defaultDocxTemplatesOptions;
  }, [templates]);

  const [selectedDocxTemplates, setSelectedDocxTemplate] = useState<
    (number | string)[]
  >(!_.isEmpty(docxTemplateOptions) ? [docxTemplateOptions[0].value] : []);

  const isExportButtonDisabled: boolean = useMemo(() => {
    const invalidTimecode = _.isNil(tc)
      ? false
      : !!jobType &&
        !!jobTypeUiExportOptions[jobType]?.timeCodeInput &&
        !tcRegex.test(tc);

    return (
      disabled ||
      !selectedFormat ||
      !fileName ||
      (jobType && jobTypeUiExportOptions[jobType]?.templateSelectInput) ||
      invalidTimecode
    );
  }, [
    zeroSubtitleToggle,
    zeroSubtitle,
    disabled,
    selectedFormat,
    fileName,
    jobType,
    jobTypeUiExportOptions,
    tc,
  ]);

  const isBurn = useMemo(() => {
    const _isBurn = selectedFormat && isVideoFormat(selectedFormat);
    return !!_isBurn;
  }, [selectedFormat]);

  useEffect(() => {
    if (isBurn) {
      setFileName(jobName || bulkFileName);
    }
  }, [isBurn, jobName]);

  const getDocxDefaultSettings: () => any = () => {
    if (_.isEmpty(selectedDocxTemplates)) return;
    if (selectedDocxTemplates[0] === SPEAKERS_FORMAT)
      return docxSpeakersDefaultSettings;
    if (selectedDocxTemplates[0] === TABLE_FORMAT)
      return docxTableDefaultSettings;
    return;
  };

  const getTemplateId: () => number | undefined = () => {
    return !_.isEmpty(selectedDocxTemplates) &&
      _.isNumber(selectedDocxTemplates[0])
      ? selectedDocxTemplates[0]
      : undefined;
  };

  const getZeroSubtitleForm = (): ZeroSubtitle | undefined => {
    if (!zeroSubtitleToggle) return;
    const lastInputsPair = zeroSubtitleFormRef?.current || null;
    if (!lastInputsPair) return zeroSubtitle;
    return {
      ...zeroSubtitle,
      content: [...zeroSubtitle.content, lastInputsPair],
    };
  };

  const getEmptySubtitle = (): EmptySubtitle | undefined => {
    if (!emptySubtitleToggle) return;
    return { startTime: 0, endTime: 0, timeFormat: "ms" };
  };

  const handleConfirm = async () => {
    if (isExportButtonDisabled || !selectedFormat) return;
    try {
      setIsLoading(true);
      await confirm(fileName, {
        format: selectedFormat,
        selectedRanges: exportSelectedRangeOnly ? selectedRanges : [],
        flip: flipSubtitles,
        autoBreak: autoBreakToggle,
        tc,
        encoding,
        idDocxTemplate: getTemplateId(),
        docxDefaultSettings: getDocxDefaultSettings(),
        zeroSubtitle: getZeroSubtitleForm(),
        emptySubtitle: getEmptySubtitle(),
      });
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      throw new Error(err);
    }
  };

  const allowedAutoBreakFormats = useMemo(() => ["txt", "locators"], []);

  const formatZeroSubtitlesOptions = (options: any[]): Option[] => {
    const formattedDefaultOptions = options.map((option: any) => {
      return {
        label: t(option.label),
        value: option.jobKey,
      } as Option;
    });
    const formattedJobMetadata = (
      (selectedJobs ? selectedJobs[0].client.jobMetadata : []) || []
    ).map((metaDataKey, ix) => {
      return {
        label: metaDataKey.key,
        value: `customMetadata[${ix}].value`,
      } as Option;
    });
    const mergedOptions = [...formattedDefaultOptions, ...formattedJobMetadata];
    return mergedOptions;
  };

  const handleZeroSubtitlesChange = (zeroSubtitle: ZeroSubtitle) => {
    setZeroSubtitle(zeroSubtitle);
  };

  return (
    <Grid
      className={"ExportJobModal"}
      container
      justifyContent={"center"}
      py={3}
      px={4}
    >
      <Grid
        item
        xs={12}
        flexDirection={"column"}
        display={"flex"}
        justifyContent={"center"}
        pb={3}
        gap={1}
      >
        <label>{t("file_name")}:</label>
        <Input
          disabled={isBurn}
          type="text"
          value={fileName}
          onChange={(e) => setFileName(e.target?.value || "")}
          placeholder={t("file_name")}
        />
      </Grid>
      <Grid
        item
        xs={12}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        pb={3}
        gap={1}
      >
        <Box display={"flex"} alignItems={"center"}>
          <label>{t("formats")}:</label>
          {isBurn ? (
            <div className="burnAlert">{`(${t(
              "one_time_burn_in_fee_added"
            )})`}</div>
          ) : (
            <></>
          )}
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          {selectedFormat && isVideoFormat(selectedFormat) ? (
            <div className="burn">{`${t(
              "we_will_send_you_the_link_via_email"
            )}`}</div>
          ) : (
            <></>
          )}
        </Box>
        <MultiSelect
          setSelected={(value) => setSelectedFormat(value[0])}
          placeholder={
            _.isEmpty(selectedFormat) ? t("no_options") : t("select_formats")
          }
          selected={[selectedFormat]}
          isMulti={false}
          options={optionalFormats}
          disabled={_.isEmpty(selectedFormat)}
        ></MultiSelect>
      </Grid>
      {selectedFormat === "docx" && (
        <Grid
          item
          xs={12}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          pb={3}
          gap={1}
        >
          <label>{t("export_template")}:</label>
          <MultiSelect
            setSelected={setSelectedDocxTemplate}
            placeholder={t("sumit_docx_default")}
            selected={selectedDocxTemplates}
            isMulti={false}
            options={docxTemplateOptions}
          ></MultiSelect>
        </Grid>
      )}
      {selectedFormat === "locators" && (
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          pb={3}
          gap={2}
        >
          <MultiSelect
            setSelected={(s) => setEncoding(s[0])}
            selected={[encoding]}
            placeholder={t("select_encoding")}
            isMulti={false}
            options={encodingOptions}
          ></MultiSelect>
        </Grid>
      )}
      {selectedFormat &&
        selectedJobs &&
        selectedJobs.length < 2 &&
        jobType &&
        jobTypeUiExportOptions[jobType]?.timeCodeInput && (
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            pb={3}
            gap={2}
          >
            <label>{t("tc_offset")}:</label>
            <Input
              type="text"
              value={tc}
              onChange={(e) => setTc(e.target?.value || null)}
              placeholder={"HH:MM:SS"}
              regex={tcRegex}
            />
          </Grid>
        )}
      {selectedFormat &&
        jobType &&
        jobTypeUiExportOptions[jobType]?.flipSubtitlesInput?.active &&
        !jobTypeUiExportOptions[
          jobType
        ]?.flipSubtitlesInput?.excludeFormats?.includes(selectedFormat) && (
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            pb={3}
            gap={2}
          >
            <label>{t("flip_subtitles")}:</label>
            <Switch value={flipSubtitles} setValue={setFlipSubtitles} />
          </Grid>
        )}
      {selectedFormat &&
        jobType &&
        jobTypeUiExportOptions[jobType]?.emptySubtitle && (
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            pb={3}
            gap={2}
          >
            <label>{t("set_empty_subtitle")}:</label>
            <Switch
              value={emptySubtitleToggle}
              setValue={setEmptySubtitleToggle}
            />
          </Grid>
        )}
      {selectedFormat &&
        jobType &&
        jobTypeUiExportOptions[jobType]?.zeroSubtitleInput && (
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            pb={3}
            gap={2}
          >
            <label>{t("zero_subtitle")}:</label>
            <Switch
              value={zeroSubtitleToggle}
              setValue={setZeroSubtitleToggle}
            />
          </Grid>
        )}

      {selectedFormat && zeroSubtitleToggle && (
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          pb={3}
          gap={2}
        >
          <ZeroSubtitleForm
            zeroSubtitle={zeroSubtitle}
            valueOptions={formatZeroSubtitlesOptions(zeroSubtitlesOptions)}
            onChange={handleZeroSubtitlesChange}
            ref={zeroSubtitleFormRef}
          />
        </Grid>
      )}

      {selectedFormat &&
        jobType &&
        jobTypeUiExportOptions[jobType]?.autoBreak &&
        allowedAutoBreakFormats.includes(selectedFormat) && (
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            pb={3}
            gap={2}
          >
            <label>{t("auto_break")}:</label>
            <Switch value={autoBreakToggle} setValue={setAutoBreakToggle} />
          </Grid>
        )}
      {!_.isEmpty(selectedRanges) && (
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          pb={3}
          gap={2}
        >
          <label>
            {t("export_selected_ranges")} ({selectedRanges?.length}):{" "}
            <Tooltip
              title={t("export_selected_ranges_tooltip")}
              placement={"top"}
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={{ margin: "auto 0 auto 10px" }}
              />
            </Tooltip>
          </label>

          <Switch
            checked={exportSelectedRangeOnly}
            setValue={setExportSelectedRangeOnly}
          />
        </Grid>
      )}

      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        pb={2}
        gap={2}
      >
        <Button onClick={cancel} variant="outlined">
          {t("cancel")}
        </Button>
        <Button
          loading={isLoadind}
          disabled={isExportButtonDisabled}
          onClick={handleConfirm}
          color="primary"
        >
          {t("export")}
        </Button>
      </Grid>
    </Grid>
  );
};

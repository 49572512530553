import _ from "lodash";
import * as Sentry from "@sentry/react";
import React, { FC, useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { handleKeyboardShortcut } from "../../utils/keyboardShortcuts";
import { createVtt } from "../../utils/createVtt";
import {
  SubtitlesTranslationRange,
  Word,
  JobWithData,
  EditorFeatureFlags,
  ShortcutAction,
} from "@sumit-platforms/types";
import EditorService from "../../services/EditorService";
import MediaService from "../../services/MediaService";

import TranslationService from "../../services/TranslationService";

import RangeInfo from "../RangeInfo/RangeInfo";

import { focusAndSetCursor } from "../../utils/focusAndScroll";
import { faLanguage } from "@fortawesome/pro-light-svg-icons";
import { useKeyboardShortcuts } from "@sumit-platforms/ui-bazar/hooks";

interface Props {
  job: JobWithData;
  ranges: SubtitlesTranslationRange[];
  range: SubtitlesTranslationRange;
  rangeIndex: number;
  rangesCount: number;
  isCurrentPlayingRange: boolean;
  setFocusedRangeIndex?: (i: number) => void;
  onPressEnter: (options: {
    rangeIndex: number;
    updatedRangeWords: Word[];
    selectedWordIndex: number;
    wordCharIndex: number;
    event: React.KeyboardEvent;
    moveWordsToNextRange: boolean;
  }) => void;
  mergeRange: (
    rangeIndex: number,
    updatedRangeWords: Word[],
    mergeWithNextRange: boolean
  ) => void;
  deleteRange: (rangeIndex: number) => void;
  isPassed: boolean;
  updateRangeWords: (rangeIndex: number, rangeWords: Word[]) => void;
  direction: "ltr" | "rtl";
  disabled?: boolean;
  featureFlags?: EditorFeatureFlags;
}

const RangeSubtitlesTranslate: FC<Props> = ({
  job,
  ranges,
  isCurrentPlayingRange,
  range,
  rangeIndex,
  rangesCount,
  setFocusedRangeIndex,
  onPressEnter,
  isPassed,
  mergeRange,
  deleteRange,
  updateRangeWords,
  direction,
  disabled,
  featureFlags,
}) => {
  const { t } = useTranslation();

  const textInputRef = useRef<HTMLTextAreaElement>(null);
  const [isChanged, setIsChanged] = useState(false);
  const [oldPlainWords, setOldPlainWords] = useState("");
  const [plainWords, setPlainWords] = useState("");
  const [sourcePlainWords, setSourcePlainWords] = useState("");
  const [tempWords, setTempWords] = useState<Word[]>([]);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (
      !range ||
      !range.words ||
      !range.autoTranslationWords ||
      !range.sourceWords
    )
      return;
    setRangeAndPlainWords({ setOld: true });

    const _sourcePlainWords = range.sourceWords
      .map((word, i) => {
        let wordText = word.word;
        if (_.has(word, "line_ix") && range.sourceWords[i + 1]) {
          const lineBreak = word.line_ix !== range.sourceWords[i + 1].line_ix;
          wordText = lineBreak ? `${wordText}\n` : wordText;
        }
        return wordText;
      })
      .join(" ")
      .replace(/\n /g, "\n");

    setSourcePlainWords(_sourcePlainWords);
  }, []);

  useEffect(() => {
    if (!range) return;
    const isFocused = document.activeElement === textInputRef.current;
    if (!isFocused || !isChanged) {
      setRangeAndPlainWords({ setOld: true });
    }
    const vtt = createVtt(ranges);
    MediaService.setSubtitles(vtt);
  }, [job, range, range.words.length]);

  useEffect(() => {
    if (isCurrentPlayingRange && range.id === ranges[rangeIndex].id) {
      const rangeString = range.words.map((word) => word.word).join(" ");
      if (plainWords !== rangeString) {
        const subtitles = createVtt(ranges, true, plainWords, rangeIndex);
        MediaService.setSubtitles(subtitles);
      }
    }
  }, [isCurrentPlayingRange, plainWords]);

  const getCursorFixedPositionAfterTrim = useCallback(
    (cursorPosition: number) => {
      const isBeginningOfWord =
        cursorPosition === 0 || plainWords[cursorPosition - 1] === " ";
      const rangeFirstHalfTrimmedLength = plainWords
        .slice(0, cursorPosition)
        .trim()
        .split(" ")
        .filter((word) => word)
        .join(" ").length;
      const cursorDiff =
        rangeFirstHalfTrimmedLength +
        (isBeginningOfWord ? 1 : 0) -
        cursorPosition;
      return cursorDiff;
    },
    [plainWords]
  );

  const jumpToWord = useCallback(
    (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent) => {
      EditorService.jumpToWord(e, plainWords, range);
    },
    [plainWords, range.words]
  );

  const getSelectedWordIndex = useCallback(
    (cursorPosition: number, words: Word[]) => {
      const cursorDiff =
        cursorPosition > 0
          ? getCursorFixedPositionAfterTrim(cursorPosition)
          : 0;
      let rangeWordIndex = 0;
      let wordCharIndex: number = cursorPosition + cursorDiff;

      while (
        wordCharIndex > 0 &&
        wordCharIndex > words[rangeWordIndex].word.length
      ) {
        const wordToCheck = words[rangeWordIndex].word.length;
        wordCharIndex = wordCharIndex - (wordToCheck + 1); // +1 for space
        rangeWordIndex++;
      }

      return { rangeWordIndex, wordCharIndex };
    },
    [getCursorFixedPositionAfterTrim]
  );

  const handlePressEnter = useCallback(
    async (event: React.KeyboardEvent, moveWordsToNextRange = false) => {
      event.preventDefault();
      event.stopPropagation();
      const { selectionStart, selectionEnd } =
        event.target as HTMLTextAreaElement;
      const textLength = _.get(event, "target.textLength");

      const updatedRangeWords =
        TranslationService.getRangeWordsFromMultilineStringForSubTrans({
          rangeWords: range.words,
          newInputString: plainWords,
          oldInputString: oldPlainWords,
          rangesCount: ranges.length,
          range,
          rangeIndex,
        });

      const { rangeWordIndex, wordCharIndex } = getSelectedWordIndex(
        selectionStart || 0,
        updatedRangeWords
      );

      onPressEnter({
        rangeIndex,
        updatedRangeWords,
        selectedWordIndex: rangeWordIndex,
        wordCharIndex,
        event,
        moveWordsToNextRange,
      });
    },
    [
      getSelectedWordIndex,
      oldPlainWords,
      onPressEnter,
      plainWords,
      range,
      rangeIndex,
      ranges.length,
    ]
  );

  const handleNewBreakRange = useCallback(
    (e: React.KeyboardEvent) => {
      handlePressEnter(e, true);
    },
    [handlePressEnter]
  );

  const setRangeAndPlainWords = useCallback(
    ({
      rangeWords = range.words,
      isRangeEmpty = false,
      setOld = false,
    }: {
      rangeWords?: Word[];
      isRangeEmpty?: boolean;
      setOld?: boolean;
    } = {}) => {
      if (rangeWords) {
        const rangeMultilineString = rangeWords
          .map((word, i) => {
            let wordText = word.word;
            if (_.has(word, "line_ix") && rangeWords[i + 1]) {
              const lineBreak = word.line_ix !== rangeWords[i + 1].line_ix;
              wordText = lineBreak ? `${wordText}\n` : wordText;
            }
            return wordText;
          })
          .join(" ")
          .replace(/\n /g, "\n");

        if (isRangeEmpty) {
          return;
        }
        setPlainWords(rangeMultilineString);
        if (setOld) setOldPlainWords(rangeMultilineString);
      }
    },
    [range.words]
  );

  const autoTranslateRange = useCallback(() => {
    const autoTranslationString = range.autoTranslationWords
      .map((word) => word.word)
      .join(" ");
    const updatedRangeWords =
      TranslationService.getRangeWordsFromMultilineStringForSubTrans({
        rangeWords: range.words,
        newInputString: plainWords,
        oldInputString: oldPlainWords,
        rangesCount: ranges.length,
        range,
        rangeIndex: rangeIndex,
      });
    if (!tempWords.length) {
      setTempWords(updatedRangeWords);
    }

    updateRangeWords(rangeIndex, range.autoTranslationWords);
    setOldPlainWords(autoTranslationString);
    setRangeAndPlainWords({ rangeWords: range.autoTranslationWords });
    setIsChanged(true);

    // TrackingService.reportEvent("translate_range", {}, job);
  }, [
    oldPlainWords,
    plainWords,
    range,
    rangeIndex,
    ranges.length,
    setRangeAndPlainWords,
    tempWords.length,
    updateRangeWords,
  ]);

  const handleRevealTranslationKeystroke = useCallback(
    (e: React.KeyboardEvent) => {
      e.stopPropagation();
      e.preventDefault();
      autoTranslateRange();
    },
    [autoTranslateRange]
  );

  const handleArrowsKeystroke = useCallback(
    (e: React.KeyboardEvent) => {
      const { selectionStart, selectionEnd } = e.target as HTMLTextAreaElement;
      const textLength = _.get(e, "target.textLength");

      const goBack =
        selectionStart === 0 &&
        ((direction === "ltr" && e.key === "ArrowLeft") ||
          (direction === "rtl" && e.key === "ArrowRight"));
      const goForward =
        selectionStart === textLength &&
        ((direction === "ltr" && e.key === "ArrowRight") ||
          (direction === "rtl" && e.key === "ArrowLeft"));

      if (goBack) {
        const rangeIndexToFocus = rangeIndex - 1;
        const rangeToFocus = document.getElementById(
          `range-${rangeIndexToFocus}`
        );
        const rangeToFocusLength = _.get(rangeToFocus, "value.length");
        focusAndSetCursor(rangeIndexToFocus, rangeToFocusLength || 0);
      }
      if (goForward) {
        const rangeIndexToFocus = rangeIndex + 1;
        focusAndSetCursor(rangeIndexToFocus, 0);
      }

      if (goBack || goForward) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [direction, rangeIndex]
  );

  const handlePressShiftEnter = useCallback(
    async (
      event: React.KeyboardEvent,
      selectionStart: number,
      selectionEnd?: number
    ) => {
      const updatedRangeWords =
        TranslationService.getRangeWordsFromMultilineStringForSubTrans({
          rangeWords: range.words,
          newInputString: plainWords,
          oldInputString: oldPlainWords,
          rangesCount: ranges.length,
          range: range,
          rangeIndex: rangeIndex,
        });

      const { rangeWordIndex, wordCharIndex } = getSelectedWordIndex(
        selectionStart || 0,
        updatedRangeWords
      );

      onPressEnter({
        rangeIndex,
        updatedRangeWords,
        selectedWordIndex: rangeWordIndex,
        wordCharIndex,
        event,
        moveWordsToNextRange: false,
      });
    },
    [
      getSelectedWordIndex,
      oldPlainWords,
      onPressEnter,
      plainWords,
      range,
      rangeIndex,
      ranges.length,
    ]
  );

  const updateRanges = useCallback(() => {
    if (isChanged) {
      // setFocusedRangeIndex(-1);
      const updatedRangeWords =
        TranslationService.getRangeWordsFromMultilineStringForSubTrans({
          rangeWords: range.words,
          newInputString: plainWords,
          oldInputString: oldPlainWords,
          rangesCount: ranges.length,
          range: range,
          rangeIndex: rangeIndex,
        });
      updateRangeWords(rangeIndex, updatedRangeWords);
      setOldPlainWords(plainWords);
    }
    // TrackingService.reportEvent(
    //   "text_edit_end",
    //   { text_changed: isChanged },
    //   job
    // );
  }, [
    isChanged,
    oldPlainWords,
    plainWords,
    range,
    rangeIndex,
    ranges.length,
    updateRangeWords,
  ]);

  const handleMergeRange = useCallback(
    async (mergeWithNextRange = false) => {
      const updatedRangeWords =
        TranslationService.getRangeWordsFromMultilineStringForSubTrans({
          rangeWords: range.words,
          newInputString: plainWords,
          oldInputString: oldPlainWords,
          rangesCount: ranges.length,
          range: range,
          rangeIndex: rangeIndex,
        });
      mergeRange(rangeIndex, updatedRangeWords, mergeWithNextRange);
    },
    [mergeRange, oldPlainWords, plainWords, range, rangeIndex, ranges.length]
  );

  const handleMergeRangeKeystroke = useCallback(
    async (e: React.KeyboardEvent) => {
      const { selectionStart, selectionEnd } = e.target as HTMLTextAreaElement;
      if (selectionStart !== 0 || selectionEnd !== 0) return;
      if (rangeIndex === 0) return;
      e.preventDefault();
      e.stopPropagation();

      handleMergeRange();
    },
    [handleMergeRange, rangeIndex]
  );

  const handleMergeWithNextRangeKeystroke = useCallback(
    async (e: React.KeyboardEvent) => {
      const { selectionStart } = e.target as HTMLTextAreaElement;
      const textLength = _.get(e, "target.textLength");
      if (selectionStart !== textLength) return;
      e.preventDefault();
      e.stopPropagation();
      handleMergeRange(true);
      // focusAndSetCursor(rangeIndex, textLength);
    },
    [handleMergeRange]
  );

  const handleDeleteRange = useCallback(
    async (e: React.KeyboardEvent) => {
      e.preventDefault();
      e.stopPropagation();
      deleteRange(rangeIndex);
    },
    [deleteRange, rangeIndex]
  );

  const revertRange = useCallback(() => {
    const tempWordsString = range.autoTranslationWords
      .map((word) => word.word)
      .join(" ");
    updateRangeWords(rangeIndex, tempWords);
    setOldPlainWords(tempWordsString);
    setRangeAndPlainWords({ rangeWords: tempWords });
    setTempWords([]);
    setIsChanged(true);
  }, [
    range.autoTranslationWords,
    rangeIndex,
    setRangeAndPlainWords,
    tempWords,
    updateRangeWords,
  ]);

  useKeyboardShortcuts({
    disabled: !featureFlags?.useNewKeyboardShortcuts,
    handlers: {
      SAVE_JOB: updateRanges,
      PREVENT_CUT: EditorService.preventCut,
      BREAK_RANGE: handlePressEnter,
      BREAK_TO_NEW_RANGE: handleNewBreakRange,
      MERGE_RANGE: handleMergeRangeKeystroke,
      MERGE_WITH_NEXT_RANGE: handleMergeWithNextRangeKeystroke,
      DELETE_ENTIRE_RANGE: handleDeleteRange,
      CURSOR_RIGHT_LEFT: handleArrowsKeystroke,
      REVEAL_TRANSLATION: handleRevealTranslationKeystroke,
    },
    ref: textInputRef.current,
  });

  if (!range) return null;

  // --- Range Manipulators ---

  // const focusAndSetCursor = (
  //   rangeIndexToFocus: number,
  //   cursorPosition: number
  // ) => {
  //   const rangeToFocus = document.getElementById(
  //     `range-${rangeIndexToFocus}`
  //   ) as HTMLInputElement;
  //   if (rangeToFocus) {
  //     const cursorPositionToSet =
  //       cursorPosition > -1 ? cursorPosition : rangeToFocus.value.length + 1; // +1 to set cursor after the space
  //     setTimeout(() => {
  //       rangeToFocus.focus();
  //       rangeToFocus.setSelectionRange(
  //         cursorPositionToSet,
  //         cursorPositionToSet
  //       );
  //     }, 0);
  //   } else {
  //     // Retry - for creating new range at the end
  //     setTimeout(
  //       () => focusAndSetCursor(rangeIndexToFocus, cursorPosition),
  //       10
  //     );
  //   }
  // };

  // --- Event Handlers ---

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (featureFlags?.useNewKeyboardShortcuts) return;
    const { selectionStart, selectionEnd } = e.target as HTMLTextAreaElement;
    const textLength = _.get(e, "target.textLength");

    if (handleKeyboardShortcut(e, ["Enter", "NumpadEnter"], [!e.shiftKey])) {
      handlePressEnter(e);
      return;
    }

    if (
      handleKeyboardShortcut(
        e,
        ["Backspace"],
        [
          selectionStart === 0,
          selectionEnd === 0,
          true,
          // FeatureFlagsService.isEnabled(
          //   "subtitlesTranslationBreakRange",
          //   loggedInUser
          // ),
        ]
      )
    ) {
      if (rangeIndex === 0) return;
      handleMergeRange();
      return;
    }

    if (
      handleKeyboardShortcut(
        e,
        ["Backspace"],
        [selectionStart === 0, selectionEnd === textLength, rangesCount > 1]
      )
    ) {
      // Deletion of entire range
      // deleteRange();
    }

    if (
      handleKeyboardShortcut(
        e,
        ["Delete"],
        [selectionStart === textLength, true]
      )
    ) {
      handleMergeRange(true);
      // focusAndSetCursor(rangeIndex, textLength);
    }

    if (
      handleKeyboardShortcut(
        e,
        ["Delete" || "Backspace"],
        [e.ctrlKey || e.metaKey, e.shiftKey, true, true]
      )
    ) {
      // detlete entire range
      handleDeleteRange(e);
    }

    if (handleKeyboardShortcut(e, ["Tab"])) {
      if (
        (e.nativeEvent.shiftKey && rangeIndex === 0) ||
        (!e.nativeEvent.shiftKey && rangeIndex === ranges.length - 1)
      ) {
        return;
      }
      const rangeIndexToFocus = e.nativeEvent.shiftKey
        ? rangeIndex - 1
        : rangeIndex + 1;
      focusAndSetCursor(rangeIndexToFocus, 0);
    }

    if (handleKeyboardShortcut(e, ["ArrowLeft", "ArrowRight"], [], false)) {
      const goBack =
        selectionStart === 0 &&
        ((direction === "ltr" && e.nativeEvent.code === "ArrowLeft") ||
          (direction === "rtl" && e.nativeEvent.code === "ArrowRight"));
      const goForward =
        selectionStart === textLength &&
        ((direction === "ltr" && e.nativeEvent.code === "ArrowRight") ||
          (direction === "rtl" && e.nativeEvent.code === "ArrowLeft"));

      if (goBack) {
        const rangeIndexToFocus = rangeIndex - 1;
        const rangeToFocus = document.getElementById(
          `range-${rangeIndexToFocus}`
        );
        const rangeToFocusLength = _.get(rangeToFocus, "value.length");
        focusAndSetCursor(rangeIndexToFocus, rangeToFocusLength);
      }
      if (goForward) {
        const rangeIndexToFocus = rangeIndex + 1;
        focusAndSetCursor(rangeIndexToFocus, 0);
      }

      if (goBack || goForward) {
        e.preventDefault();
        e.stopPropagation();
      }
    }

    if (handleKeyboardShortcut(e, ["KeyS"], [e.ctrlKey], false)) {
      updateRanges();
    }

    if (handleKeyboardShortcut(e, ["KeyX"], [e.ctrlKey || e.metaKey])) {
      return;
    }
    if (handleKeyboardShortcut(e, ["KeyQ"], [e.ctrlKey || e.metaKey])) {
      autoTranslateRange();
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (featureFlags?.useNewKeyboardShortcuts) return;
    const { selectionStart, selectionEnd } = e.target as HTMLTextAreaElement;
    const textLength = _.get(e, "target.textLength");

    if (handleKeyboardShortcut(e, ["Enter", "NumpadEnter"], [e.shiftKey])) {
      handlePressShiftEnter(e, selectionStart);
      return;
    }
  };

  const handleDoubleClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    jumpToWord(e);
  };

  const handleFocus = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setIsFocused(true);
    // TrackingService.reportEvent("text_edit_start", {}, job);
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newInputString = e.target.value;

    setIsChanged(true);
    setPlainWords(newInputString);
    // setFocusedRangeIndex(rangeIndex);
  };

  const handleBlur = async () => {
    // setFocusedRangeIndex(-1);
    setIsFocused(false);
    updateRanges();
  };

  return (
    <div className="RangeSubtitlesTranslate">
      <div
        className={classNames("textRange target", direction, {
          passed: isPassed,
          overlapping:
            range.validation?.errors?.overlapping_prev ||
            range.validation?.errors?.overlapping_next ||
            range.validation?.errors?.start_after_end ||
            range.validation?.errors?.out_of_range,
        })}
      >
        <div className="rangeText">
          <div className={classNames("textContainer", { disabled })}>
            <textarea
              id={`range-${rangeIndex}`}
              ref={textInputRef}
              className="textRangeField"
              value={plainWords}
              onChange={handleOnChange}
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDown}
              onDoubleClick={handleDoubleClick}
              onBlur={handleBlur}
              onFocus={handleFocus}
              readOnly={disabled}
            />
            <div className="dummyRange">{plainWords}</div>
          </div>
        </div>

        <RangeInfo
          validation={range.validation}
          text={plainWords}
          rangeNumber={rangeIndex + 1}
          isFocused={isFocused}
          direction={direction}
        >
          <div className="iconsContainer">
            <div className="translateRange">
              {tempWords.length ? (
                <div
                  className={classNames("restoreIcon", direction)}
                  onClick={revertRange}
                >
                  <FontAwesomeIcon icon={["fal", "redo"]}></FontAwesomeIcon>
                </div>
              ) : (
                ""
              )}
              <div
                className={`translateIcon ${disabled ? "range-disabled" : ""}`}
                onClick={autoTranslateRange}
              >
                <FontAwesomeIcon icon={faLanguage} />
              </div>
            </div>
          </div>
        </RangeInfo>
      </div>
      <div
        className={classNames("textRange source", direction, {
          passed: isPassed,
        })}
      >
        <div className="rangeText">
          <div className={classNames("textContainer", "disabled", direction)}>
            <div className="dummyRange">{sourcePlainWords}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RangeSubtitlesTranslate;

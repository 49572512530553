import { useState, useEffect, useRef } from "react";
import _ from "lodash";

interface UseKeyPressProps {
  listen: boolean;
}

export const useKeyPressRecorder = ({ listen }: UseKeyPressProps) => {
  const [keysPressed, setKeysPressed] = useState<string>("");
  const [pressedKeys, setPressedKeys] = useState<Set<string>>(new Set());
  const [allKeysReleased, setAllKeysReleased] = useState(false);

  const clearState = () => {
    setKeysPressed("");
    setPressedKeys(new Set());
    setAllKeysReleased(false);
  };

  const getKeyCombination = (event: KeyboardEvent): string => {
    const keys = [];
    if (event.ctrlKey) keys.push("Control");
    if (event.metaKey) keys.push("Meta");
    if (event.altKey) keys.push("Alt");
    if (event.shiftKey) keys.push("Shift");
    keys.push(event.code);
    return _.uniq(keys).join("+");
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      const keyCombination = getKeyCombination(event);
      setPressedKeys((prev) => {
        const newSet = new Set(prev);
        newSet.add(keyCombination);
        return newSet;
      });
      setKeysPressed(keyCombination);
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      const keyCombination = getKeyCombination(event);
      setPressedKeys((prev) => {
        const newSet = new Set(prev);
        newSet.delete(keyCombination);

        return newSet;
      });
      if (pressedKeys.size === 0) {
        setAllKeysReleased(true);
      }
    };

    if (listen) {
      window.addEventListener("keydown", handleKeyDown);
      window.addEventListener("keyup", handleKeyUp);
    }
    return () => {
      clearState();
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [listen]);

  return { keysPressed, allKeysReleased };
};

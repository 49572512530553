import React, { useCallback, useEffect, useRef, useState } from "react";
import { Node } from "@sumit-platforms/slate";
import { VirtualizedListRef, VirtualizedList } from "@sumit-platforms/ui-bazar";
import { useSlateStatic } from "@sumit-platforms/slate-react";
import Box from "@mui/material/Box";
import { useRecoilValue } from "recoil";
import { CustomEditor, SubtitleRangeElement } from "../../types";
import JobScriptItem from "./JobScriptItem";
import { SyncScroll } from "./SyncScroll";
import { directionState } from "../../store/states";

import "./JobScript.scss";

const itemHeight = 75;
const maxHeight = itemHeight * 8;

export const JobScript = () => {
  const editor = useSlateStatic() as CustomEditor;
  const [syncScroll, setSyncScroll] = useState(true);
  const dir = useRecoilValue(directionState);
  const listRef = useRef<VirtualizedListRef>(null);
  const [listRefState, setListRef] = useState<HTMLDivElement | null>(null);

  const getScriptRanges = useCallback(() => {
    const scriptRanges = editor.children
      ?.filter((child) => (child as any)?.type === "subtitleRange")
      ?.map((children, index) => {
        return {
          text: Node.string(children),
          st: (children as SubtitleRangeElement)?.range.st,
          et: (children as SubtitleRangeElement)?.range.et,
          index,
        };
      });
    return scriptRanges;
  }, [editor.children]);

  const [ranges, setRanges] = useState(getScriptRanges());

  const updateRanges = useCallback(() => {
    const scriptRanges = getScriptRanges();
    setRanges(scriptRanges);
  }, [getScriptRanges]);

  const handleEditorChange = useCallback(() => {
    updateRanges();
  }, [updateRanges]);

  // Listen to changes in the Slate editor and update ranges
  useEffect(() => {
    // Store the original apply method
    const { apply } = editor;
    // We use requestAnimationFrame to wait until first paint to prevent unnecessary re-renders
    const rafId = requestAnimationFrame(() => {
      // Custom logic after the first paint
      editor.apply = (operation) => {
        apply(operation); // Call the original apply method

        const changeTextOperations = [
          "insert_text",
          "remove_text",
          "remove_node",
          "insert_node",
          "split_node",
          "merge_node",
          "set_node",
        ];

        if (changeTextOperations.includes(operation.type)) {
          handleEditorChange();
        }
      };
    });
    return () => {
      cancelAnimationFrame(rafId); // Clean up the animation frame if the component unmounts
      editor.apply = apply; // Restore the original apply method
    };
  }, [editor, handleEditorChange]);

  const onListWheel = useCallback(() => {
    if (syncScroll) {
      setSyncScroll(false);
    }
  }, [syncScroll]);

  useEffect(() => {
    if (!listRefState) {
      setListRef(listRef.current?.listRef || null);
    }
  }, [listRef.current?.listRef]);

  return (
    <div>
      <Box px={0}>
        <SyncScroll
          syncScroll={syncScroll}
          setSyncScroll={setSyncScroll}
          listRef={listRefState}
          ranges={ranges}
          itemHeight={itemHeight}
          dir={dir}
        />
      </Box>

      <VirtualizedList
        items={ranges || []}
        maxHeight={maxHeight}
        itemCount={ranges?.length || 0}
        itemHeight={itemHeight}
        dir={dir}
        ref={listRef}
        render={(range, idx) => <JobScriptItem range={range} idx={idx} />}
        onWheel={onListWheel}
      />
    </div>
  );
};

import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import { Button, Input } from "@sumit-platforms/ui-bazar";
import { Client } from "@sumit-platforms/types";
import { faBuildingColumns } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useToast } from "@sumit-platforms/ui-bazar/hooks";
import { useGlobalData } from "../../../../store/globalData";

import "./AddClientModal.scss";

export interface AddClientModalProps {
  confirm: (job: Partial<Client>) => Promise<Client>;
  cancel: () => void;
}

export const AddClientModal: FC<AddClientModalProps> = ({
  confirm,
  cancel,
}) => {
  const { t } = useTranslation();
  const { setToast } = useGlobalData();
  const { toastSuccess, toastError, toastInfo } = useToast({ setToast });
  const [isLoading, setIsLoading] = useState(false);
  const [clientName, setClientName] = useState<string>("");

  const handleOnConfirm = async () => {
    setIsLoading(true);
    try {
      toastInfo(t("creating_new_client"));
      const client = await confirm({ name: clientName });
      if (client) {
        toastSuccess(t("new_client_success"));
      }
    } catch (e) {
      console.error(e);
      toastError(t("new_client_failed"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid className={"AddClientModal"} container width={"30rem"}>
      <Grid
        container
        width={"100%"}
        px={4}
        pt={4}
        pb={3}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Box pb={3}>
          <h4>{t("create_new_client")}</h4>
        </Box>
        <Box>
          <p>{t("add_client_modal_instructions")}</p>
        </Box>
      </Grid>
      <Grid container justifyContent={"center"} px={4} pb={4}>
        <Grid item pt={2}>
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Box className={"inputLabel"}>
              {
                <FontAwesomeIcon
                  className={"formIcon"}
                  icon={faBuildingColumns}
                />
              }
            </Box>
            <Box px={2}>
              <Input
                value={clientName}
                onChange={(e) => setClientName(e.target?.value || "")}
                placeholder={t("client_name")}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        pb={4}
        gap={2}
      >
        <Button onClick={cancel} variant="outlined">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleOnConfirm}
          color="primary"
          loading={isLoading}
          disabled={clientName === ""}
        >
          {t("create")}
        </Button>
      </Grid>
    </Grid>
  );
};

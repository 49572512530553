import React, { FC, useState, useEffect, useRef, useMemo } from "react";
import _ from "lodash";
import { useRecoilValue } from "recoil";
import classNames from "classnames";

import { RangeValidationResults } from "@sumit-platforms/types";

import { subtitlesValidationState } from "../../store/states/SubtitlesValidation";

import RangeValidation from "../RangeValidation/RangeValidation";

import "./RangeInfo.scss";

interface Props {
  validation: RangeValidationResults | undefined;
  text: string;
  rangeNumber: number;
  isFocused: boolean;
  direction: "ltr" | "rtl";
  disabled?: boolean;
  children?: React.ReactNode;
}

const RangeInfo: FC<Props> = ({
  validation,
  text,
  rangeNumber,
  isFocused,
  direction,
  disabled,
  children,
}) => {
  const subtitlesValidations = useRecoilValue(subtitlesValidationState);

  const maxCharsInLine = useMemo(
    () =>
      _.get(
        subtitlesValidations as any,
        "isMoreThanCharsInLine.maxCharsInLine"
      ),
    [subtitlesValidations]
  );
  const rangeLineCount = useMemo(() => {
    return text.split("\n").map((line) => {
      const lineCount = maxCharsInLine
        ? `${line.length}/${maxCharsInLine}`
        : line.length;
      return {
        lineCount,
        error: line.length > maxCharsInLine,
      };
    });
  }, [text]);

  return (
    <div className={classNames("RangeInfo", direction, { disabled })}>
      <div className="rangeActions">
        <div className="rangeNumber">{rangeNumber}</div>
        {children}
      </div>
      <div className="validations">
        {validation && (
          <RangeValidation validation={validation} direction={direction} />
        )}
      </div>
      <div className="charCounters">
        {(isFocused || _.some(rangeLineCount, (l) => l.error)) &&
          _.map(rangeLineCount, (line, i) => {
            return (
              <div
                key={i}
                className={classNames("lineCount", { error: line.error })}
              >
                {line.lineCount}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default RangeInfo;

import { atom } from "recoil";

export type RangeSelectorState = {
  enable: boolean;
  selected: number[];
};

export const defaultRangeSelectorState = {
  enable: true,
  selected: [],
};

export const rangeSelectorState = atom<RangeSelectorState>({
  key: "rangeSelectorState",
  default: defaultRangeSelectorState,
});

import { User } from "./User";
import { JobData } from "../Job/JobData";

enum SaveTriggers {
  SAVE_BUTTON = "SAVE_BUTTON",
  AUTO_SAVE = "AUTO_SAVE",
  KEYBOARD_SHORTCUT = "KEYBOARD_SHORTCUT",
  JOB_LEAVE_CONFIRMATION_MODAL = "JOB_LEAVE_CONFIRMATION_MODAL",
  TRANSCRIPT_DONE = "TRANSCRIPT_DONE",
  SKIP_STT_DONE = "SKIP_STT_DONE",
  IMPORT_SUBTITLES_DONE = "IMPORT_SUBTITLES_DONE",
  TRANSCRIBE_DONE = "TRANSCRIBE_DONE",
  JOB_DONE = "JOB_DONE",
  ALIGNER_REQUEST = "ALIGNER_REQUEST",
  ALIGNER_DONE = "ALIGNER_DONE",
  SPLIT_REQUEST = "SPLIT_REQUEST",
  SPLIT_DONE = "SPLIT_DONE",
  AUTO_TRANSLATE_DONE = "AUTO_TRANSLATE_DONE",
  KIT_CONCAT = "KIT_CONCAT",
  KIT_RECORD_END = "KIT_RECORD_END",
  OOONA_FINISH_TRANSCRIBE = "OOONA_FINISH_TRANSCRIBE",
  OOONA_FINISH_REVIEW = "OOONA_FINISH_REVIEW",
}

interface JobRevision {
  idJobRevision: number;
  idJob: number;
  idUser: number;
  path: string;
  isBaseRevision: boolean;
  size?: number;
  trigger: SaveTriggers;
  isClientRevision: boolean;
  version?: number; // Version will be V.<event>.<manual_save>.<auto_save>.
  restoredAt?: Date;
  user?: User;
  createdAt: Date;
}

interface RestoreJobRequestParams {
  idJobRevision: number;
}
interface RestoreJobResponse {
  revision: JobRevision;
  dataJson: JobData;
}

interface CreateJobRevisionRequestParams {
  idJobRevision: number;
  idJob: number;
  idUser: number;
  path: string;
  trigger: SaveTriggers;
}

interface JobRevisionsRequestParams {
  idJob: number;
  excludeSaveTriggers?: SaveTriggers[];
}

export type {
  JobRevision,
  RestoreJobRequestParams,
  RestoreJobResponse,
  CreateJobRevisionRequestParams,
  JobRevisionsRequestParams,
};
export { SaveTriggers };

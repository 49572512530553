import React, { useCallback, useMemo } from "react";
import { Box, ListItemButton, ListItemText } from "@mui/material";
import Typography from "@mui/material/Typography";
import { MarkWordMetadata } from "../../types";
import { Node } from "@sumit-platforms/slate";
import { getTimeStringFromSecs } from "@sumit-platforms/ui-bazar/utils";
import { useRecoilValue } from "recoil";
import { directionState } from "../../store/states";
import _ from "lodash";

interface FindItemProps {
  term: string;
  word: MarkWordMetadata;
  selectedWord: MarkWordMetadata | null;
  onClick: (word: MarkWordMetadata) => void;
  onHover?: (word: MarkWordMetadata) => void;
  maxLength?: number;
}
const FindAndReplaceHighlightItem = ({
  term,
  word,
  selectedWord,
  maxLength = 15,
  onClick,
  onHover,
}: FindItemProps) => {
  const dir = useRecoilValue(directionState);

  const text = useMemo(() => Node.string(word.node), []);
  const offset = useMemo(() => word.anchor.offset, []);
  const termLength = useMemo(() => offset + term.length, []);

  const isBoundaryCharacter = useCallback(
    (char: string) => /\s|[.,#!$%^&*;:{}=\-_`~()]/.test(char),
    []
  );

  const findEndPoint = useCallback(() => {
    let end = termLength + maxLength;
    while (end < text.length && !isBoundaryCharacter(text.charAt(end))) {
      end++;
    }
    return end;
  }, [termLength, maxLength, text, isBoundaryCharacter]);

  const findStartPoint = useCallback(() => {
    let start = offset - maxLength;
    if (start < 0) return 0;
    while (start > 0 && !isBoundaryCharacter(text.charAt(start))) {
      start--;
    }
    return start;
  }, [offset, maxLength, text, isBoundaryCharacter]);

  const startPoint = useMemo(() => findStartPoint(), []);
  const endPoint = useMemo(() => findEndPoint(), []);

  const isSelected = useMemo(
    () =>
      selectedWord
        ? JSON.stringify(selectedWord.anchor) +
            JSON.stringify(selectedWord.focus) ===
          JSON.stringify(word.anchor) + JSON.stringify(word.focus)
        : false,
    [selectedWord, word.anchor, word.focus]
  );

  const handleClick = useCallback(() => {
    if (onClick) onClick(word);
  }, [onClick, word]);

  const subtext = _.isNil(word.subtext)
    ? null
    : _.isNumber(word.subtext)
    ? getTimeStringFromSecs(word.subtext)
    : _.capitalize(word.subtext);

  return (
    <ListItemButton
      onClick={handleClick}
      disableRipple={true}
      sx={{
        width: "100%",
        margin: 0,
        height: "100%",
        textAlign: dir === "rtl" ? "right" : "left",
      }}
      selected={isSelected}
      onMouseEnter={(e: any) => onHover && onHover(word)}
    >
      <ListItemText
        primary={
          <Box
            component={"div"}
            display={"flex"}
            flexDirection={"column"}
            dir={dir}
          >
            {word.subtext ? (
              <Box height={"20px"}>
                <Typography
                  component={"span"}
                  sx={{ fontSize: 12 }}
                  color="textSecondary"
                >
                  {subtext}
                </Typography>
              </Box>
            ) : null}
            <Box>
              <Typography component={"span"}>
                {text.substring(startPoint, offset)}
              </Typography>
              <Typography component={"span"} className={"highlightGreen"}>
                {text.substring(offset, termLength)}
              </Typography>
              <Typography component={"span"}>
                {text.substring(termLength, endPoint)}
              </Typography>
            </Box>
          </Box>
        }
      />
    </ListItemButton>
  );
};

export default FindAndReplaceHighlightItem;

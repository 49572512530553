import React, { FC, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import useOutsideClick from "../../hooks/useOutsideClick";
import { Annotation } from "@sumit-platforms/types";
import { AnnotationRangeElement, CustomEditor } from "../../types";
import {
  faChevronLeft,
  faChevronRight,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { isDisabledState, directionState } from "../../store/states";
import { useRecoilValue } from "recoil";
import EditorService from "../../services/EditorService";
import { useSlateStatic } from "@sumit-platforms/slate-react";
import useKeyPress from "../../../hooks/useKeyPress";

import "./RangeAnnotation.scss";

interface Props {
  element: AnnotationRangeElement;
  children?: React.ReactNode;
  annotation: Annotation;
}

const RangeAnnotation: FC<Props> = ({ element, children, annotation }) => {
  const editor = useSlateStatic() as CustomEditor;
  const direction = useRecoilValue(directionState);
  const disabled = useRecoilValue(isDisabledState);
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(element.focusOnInit);
  const typeSelectorRef = useRef<HTMLDivElement>(null);

  const annotationTypes = ["note", "subject", "decision", "task"];
  const [focusedTypeIndex, setFocusedTypeIndex] = useState(0);

  useOutsideClick(typeSelectorRef, () => {
    if (editMode) {
      setEditMode(false);
    }
  });

  useEffect(() => {
    focusTypeSelector();
    setFocusedTypeIndex(0);
  }, []);

  const arrowDownPressed = useKeyPress("ArrowDown", [typeSelectorRef]);
  const enterPressed = useKeyPress("Enter", [typeSelectorRef]);
  const arrowUpPressed = useKeyPress("ArrowUp", [typeSelectorRef]);

  const handleArrowNavigation = (arrow: "up" | "down" | "enter") => {
    const typesLength = annotationTypes.length;
    if (arrow === "down") {
      if (focusedTypeIndex === typesLength - 1) {
        setFocusedTypeIndex(0);
        return;
      }
      setFocusedTypeIndex(focusedTypeIndex + 1);
    }

    if (arrow === "up") {
      if (focusedTypeIndex === 0) {
        setFocusedTypeIndex(typesLength - 1);
        return;
      }
      setFocusedTypeIndex(focusedTypeIndex - 1);
    }

    if (arrow === "enter") {
      if (focusedTypeIndex < 0 || focusedTypeIndex >= typesLength || !editMode)
        return;
      handleChangeType(annotationTypes[focusedTypeIndex]);
      setEditMode(false);
      // focusRangeText();
      setFocusedTypeIndex(-1);
    }
  };

  useEffect(() => {
    if (!arrowUpPressed) return;
    handleArrowNavigation("up");
  }, [arrowUpPressed]);

  useEffect(() => {
    if (!arrowDownPressed) return;
    handleArrowNavigation("down");
  }, [arrowDownPressed]);

  useEffect(() => {
    if (!annotation?.temp || !enterPressed) return;
    handleArrowNavigation("enter");
  }, [enterPressed]);

  useEffect(() => {
    if (element.focusOnInit) {
      setTimeout(() => {
        editor.deselect();
      }, 0);
    }
  }, [element.focusOnInit]);

  const handleChangeType = (type: Annotation["type"]) => {
    EditorService.updateNodeData({
      element,
      data: { annotationType: type, temp: false },
      editor,
    });
    EditorService.focusByPathOrElement(editor, { element });
    setEditMode(false);
  };

  const handleDeleteAnnotation = (e: any) => {
    e.stopPropagation();
    EditorService.removeNode(editor, element);
  };

  const focusTypeSelector = () => {
    if (typeSelectorRef.current) {
      typeSelectorRef.current.focus();
    } else {
      setTimeout(focusTypeSelector, 100);
    }
  };

  return (
    <div className={classNames("PsiqueRangeAnnotation", direction)}>
      <div className={classNames("annotation", { disabled })}>
        <div
          className={classNames("annotationTypeContainer noSelect", {
            disabled,
          })}
          onClick={() => {
            setEditMode(!editMode);
            focusTypeSelector();
            setFocusedTypeIndex(0);
          }}
          contentEditable={false}
        >
          <FontAwesomeIcon
            className="deleteAnnotation"
            icon={faTrash}
            onClick={handleDeleteAnnotation}
          />
          <div className="typeName">{t(element?.annotationType || "")}</div>
          <div className={classNames("typeIcon", { open: editMode })}>
            <FontAwesomeIcon
              icon={direction === "ltr" ? faChevronRight : faChevronLeft}
            />
          </div>
        </div>
        <div
          className={classNames("typeSelector", {
            open: editMode,
            new: annotation?.temp,
          })}
          ref={typeSelectorRef}
          tabIndex={0}
          onClick={focusTypeSelector}
          contentEditable={false}
        >
          {_.map(annotationTypes, (type: Annotation["type"], i: number) => (
            <div
              className={classNames("typeOption", {
                focused: annotation?.temp && focusedTypeIndex === i,
              })}
              onClick={() => handleChangeType(type)}
              key={i}
            >
              {type && t(type.toLowerCase())}
            </div>
          ))}
        </div>
        <div
          className={classNames("annotationContent textContainer", {
            disabled,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default RangeAnnotation;

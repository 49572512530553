import React, { HTMLAttributes, useEffect, useState } from "react";
import { Box, Popover, ThemeProvider } from "@mui/material";
import classNames from "classnames";

import { SpinningLoader } from "../../core/SpinningLoader/SpinningLoader";
import { bazarTheme } from "../../bazar-theme";

import "./PopoverAction.scss";

export interface PopoverActionProps extends HTMLAttributes<HTMLDivElement> {
  trigger: JSX.Element;
  action?: () => void;
  renderContent?: () => React.ReactNode;
  onClose?: () => void;
  popoverClassName?: string;
  isLoading?: boolean;
  disabled?: boolean;
  onPopoverOpenStateChange?: (isOpen: boolean) => void;
}

export const PopoverAction = ({
  trigger,
  action,
  renderContent,
  onClose,
  popoverClassName,
  isLoading,
  disabled,
  onPopoverOpenStateChange,
}: PopoverActionProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    onPopoverOpenStateChange && onPopoverOpenStateChange(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
    onClose && onClose();
  };

  const handleBoxClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (action) {
      action();
      return;
    } else if (renderContent) {
      setAnchorEl(e.currentTarget);
      setIsOpen(!isOpen);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      setIsOpen(false);
    }
  };

  return (
    <ThemeProvider theme={bazarTheme}>
      <Box
        className={classNames("PopoverAction", {
          disabled,
        })}
        id="col-action"
        onClick={handleBoxClick}
      >
        <div className="triggerWrapper">{trigger}</div>
        <Popover
          disableScrollLock={true}
          onKeyDown={handleKeyDown}
          className={classNames("PopoverAction", popoverClassName)}
          onClose={handleClose}
          anchorEl={anchorEl}
          open={isOpen}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: 0,
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: 0,
          }}
        >
          {renderContent && renderContent()}
          {isLoading && <SpinningLoader className="loader" />}
        </Popover>
      </Box>
    </ThemeProvider>
  );
};

import { useTranslation } from "react-i18next";
import { SearchInput } from "@sumit-platforms/ui-bazar";
import React, { useLayoutEffect, useRef } from "react";
import { Box } from "@mui/material";
import { faArrowRightArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";

const FindAndReplaceInputs = ({
  replace,
  find,
  onFindChange,
  onReplaceChange,
  findInputDirty,
}: {
  find: string;
  onFindChange: (e: any) => void;
  replace: string;
  onReplaceChange: (e: any) => void;
  findInputDirty: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Box className={"FindAndReplaceInputs"}>
      <SearchInput
        value={find}
        autoFocus={true}
        onChange={onFindChange}
        placeholder={t("find")}
        className={classNames("findAndReplaceInput")}
        showTextInput={true}
        style={{ height: "30px" }}
        dirty={findInputDirty}
        dirtyTooltipText={t("please_enter_at_least_3_characters")}
        iconSize={"sm"}
      />
      <SearchInput
        value={replace}
        onChange={onReplaceChange}
        placeholder={t("replace")}
        className={"findAndReplaceInput"}
        showTextInput={true}
        icon={faArrowRightArrowLeft}
        iconSize={"sm"}
        style={{ height: "30px" }}
      />
    </Box>
  );
};

export default FindAndReplaceInputs;

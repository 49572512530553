import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackward,
  faBackwardStep,
  faForward,
  faForwardStep,
  faPauseCircle,
  faPlayCircle,
  faRotateRight,
} from "@fortawesome/pro-light-svg-icons";
import MediaService from "../../services/MediaService";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { Tooltip } from "@sumit-platforms/ui-bazar";
import { useTranslation } from "react-i18next";
import EditorService from "../../services/EditorService";
import { CustomEditor } from "../../types";
import { useRecoilState, useSetRecoilState } from "recoil";
import { currentTimeState, repeatState } from "../../store/states";

export const MediaControllers = ({ editor }: { editor?: CustomEditor }) => {
  const { t } = useTranslation();
  const [repeat, setRepeat] = useRecoilState(repeatState);
  const setTime = useSetRecoilState(currentTimeState);

  const playerRewind = useMemo(() => 3, []);
  const playerForward = useMemo(() => 3, []);
  const playerRewindResume = useMemo(() => 0, []);

  const goRewind = useCallback(() => {
    MediaService.playRelative((playerRewind || 5) * -1);
  }, [playerRewind]);

  const goForward = useCallback(() => {
    MediaService.playRelative(playerForward || 5);
  }, [playerForward]);

  const toggleRepeat = useCallback(() => {
    const repeat = MediaService.setIsRepeat(!MediaService.isRepeat);
    setRepeat(repeat);
  }, [setRepeat]);

  useEffect(() => {
    if (repeat !== MediaService.isRepeat) setRepeat(MediaService.isRepeat);
  }, [MediaService.isRepeat]);

  const nextSubtitle = useCallback(() => {
    const result = EditorService.onSkipSubtitle({ editor });
    if (result?.st && result?.newIndex && editor) {
      setTime(result.st);
      EditorService.focusByPathOrElement(editor, { path: [result.newIndex] });
    }
  }, [editor, setTime]);

  const prevSubtitle = useCallback(() => {
    const result = EditorService.onPrevSubtitle({ editor });
    if (result?.st && result?.newIndex && editor) {
      setTime(result.st);
      EditorService.focusByPathOrElement(editor, { path: [result.newIndex] });
    }
  }, [editor, setTime]);

  return (
    <>
      <Tooltip title={t("prev_subtitle")} placement={"top"}>
        <FontAwesomeIcon
          className="left"
          onClick={prevSubtitle}
          icon={faBackwardStep}
          style={{ fontSize: "24px" }}
        ></FontAwesomeIcon>
      </Tooltip>
      <Tooltip title={t("rewind")} placement={"top"}>
        <FontAwesomeIcon
          className="left"
          onClick={goRewind}
          icon={faBackward}
          style={{ fontSize: "24px" }}
        ></FontAwesomeIcon>
      </Tooltip>
      <Tooltip title={t("toggle_play")} placement={"top"}>
        <div
          onClick={() => {
            MediaService.togglePlay((playerRewindResume || 0) * -1);
          }}
          className="play-pause"
        >
          {MediaService.isPlaying ? (
            <FontAwesomeIcon
              style={{ fontSize: "32px" }}
              icon={faPauseCircle}
            />
          ) : (
            <FontAwesomeIcon style={{ fontSize: "32px" }} icon={faPlayCircle} />
          )}
        </div>
      </Tooltip>
      <Tooltip title={t("forward")} placement={"top"}>
        <FontAwesomeIcon
          className="right"
          onClick={goForward}
          icon={faForward}
          style={{ fontSize: "24px" }}
        ></FontAwesomeIcon>
      </Tooltip>
      <Tooltip title={t("next_subtitle")} placement={"top"}>
        <FontAwesomeIcon
          className="right"
          onClick={nextSubtitle}
          icon={faForwardStep}
          style={{ fontSize: "24px" }}
        ></FontAwesomeIcon>
      </Tooltip>
      <Tooltip title={t("repeat")} placement={"top"}>
        <FontAwesomeIcon
          className={classNames("repeatButton right", { repeating: repeat })}
          onClick={toggleRepeat}
          icon={faRotateRight}
          style={{ fontSize: "22px" }}
        ></FontAwesomeIcon>
      </Tooltip>
    </>
  );
};

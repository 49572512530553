import _ from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, ThemeProvider } from "@mui/material";
import { EnrichRequestStatus, IndexSearch } from "@sumit-platforms/types";
import { bazarTheme } from "../../bazar-theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faTimesCircle } from "@fortawesome/pro-light-svg-icons";

import "./IndexSearchStatusSummary.scss";

interface IndexSearchStatusSummaryProp {
  indexSearch: IndexSearch;
}

type ModelScope = "text_scope" | "audio_scope" | "visual_scope";

interface ModelSummary {
  type: ModelScope;
  subType: string;
  success?: boolean;
}

const faceRecognition: ModelSummary = {
  type: "visual_scope",
  subType: "face_recognition",
};

const objectsIdentification: ModelSummary = {
  type: "visual_scope",
  subType: "objects_identification",
};

const frameDescription: ModelSummary = {
  type: "visual_scope",
  subType: "frame_description",
};

const silenceIdentification: ModelSummary = {
  type: "audio_scope",
  subType: "silence_identification",
};

const paragraphSentencesSearch: ModelSummary = {
  type: "text_scope",
  subType: "paragraph_sentences_search",
};

const semanticSearch: ModelSummary = {
  type: "text_scope",
  subType: "semantic_search",
};

const modelSummaryMap: Record<string, ModelSummary> = {
  Sentiment: semanticSearch,
  Sentences: paragraphSentencesSearch,
  SentencesParagraphs: paragraphSentencesSearch,
  Topic: paragraphSentencesSearch,
  TextAnalyze: paragraphSentencesSearch,
  TextAnalyzeParagraphsFusion: paragraphSentencesSearch,
  Vad: silenceIdentification,
  SilenceFusion: silenceIdentification,
  ShotChange: frameDescription,
  Clip: frameDescription,
  Blip: frameDescription,
  FrameLabel: frameDescription,
  OcrModel: frameDescription,
  Objects: objectsIdentification,
  faceDetection: faceRecognition,
};

export const IndexSearchStatusSummary = ({
  status,
  hasDeficiencies,
  color,
}: {
  status: EnrichRequestStatus;
  hasDeficiencies: boolean;
  color: string;
}) => {
  const { t } = useTranslation();

  const enrichmentStatus = useMemo(() => {
    let statusString;
    let _color = color;
    if (status === EnrichRequestStatus.PENDING) {
      statusString = t("is_in_progress");
      _color = "inherit";
    } else if (status === EnrichRequestStatus.DONE) {
      statusString = t("succeed").toLowerCase();
    }

    if (hasDeficiencies) {
      statusString = t("failed").toLowerCase();
    }

    return (
      <Box className={"enrichmentStatus"}>
        {`${t("media_enrichment")} `}
        <span style={{ color: _color }}>{` ${statusString}`}</span>
      </Box>
    );
  }, [status, hasDeficiencies, color]);

  /* TODO: use this lines to reflect higher resolution of models */
  // const [modelsGroupedByType, setModelsGroupedByType] =
  //   useState<Record<string, ModelSummary[]>>();

  // const [failedSubTypes, setFailedSubTypes] =
  //   useState<Record<string, boolean>>();

  // useEffect(() => {
  //   const mappedModels = indexSearch.models
  //     .filter((m) => modelSummaryMap[m.model])
  //     .map((m) => ({ ...modelSummaryMap[m.model], success: m.success }));

  //   setFailedSubTypes(getFailedSubTypes(mappedModels));
  //   setModelsGroupedByType(
  //     _.groupBy(_.uniqBy(mappedModels, "subType"), "type")
  //   );
  // }, [indexSearch]);

  // const getFailedSubTypes = useCallback((modelsSummary: ModelSummary[]) => {
  //   const _failedSubTypes: Record<string, boolean> = {};
  //   const groupedBySubType = _.groupBy(modelsSummary, "subType");
  //   _.keys(groupedBySubType).map((subType: string) => {
  //     if (_.isUndefined(_failedSubTypes[subType])) {
  //       if (
  //         _.some(
  //           groupedBySubType[subType],
  //           (modelSummary) => modelSummary.success === false
  //         )
  //       ) {
  //         _failedSubTypes[subType] = false;
  //       } else {
  //         _failedSubTypes[subType] = true;
  //       }
  //     }
  //   });
  //   return _failedSubTypes;
  // }, []);
  /* TODO: use this lines to reflect higher resolution */

  return (
    <ThemeProvider theme={bazarTheme}>
      <Box className={"IndexSearchStatusSummary"}>
        <Box className={"section"}>
          <Box className={"title"}>
            <Box>{t("available_on_extract")}</Box>
          </Box>
          {enrichmentStatus}
          <Box className={"statusIcon"}>
            {hasDeficiencies || status === EnrichRequestStatus.FAILED ? (
              <FontAwesomeIcon icon={faTimesCircle} color={color} />
            ) : (
              status === EnrichRequestStatus.DONE &&
              !hasDeficiencies && (
                <FontAwesomeIcon icon={faCircleCheck} color={color} />
              )
            )}
          </Box>
          {/* TODO: use this lines to reflect higher resolution */}
          {/* {!_.isUndefined(modelsGroupedByType) &&
            !_.isUndefined(failedSubTypes) &&
            _.keys(modelsGroupedByType).map((key) => (
              <Box key={key}>
                <Box className={"typeScope"}>{t(key)}</Box>
                {modelsGroupedByType[key]?.map((modelSummary: ModelSummary) => (
                  <Box className="subType" key={modelSummary.subType}>
                    <Box>{t(modelSummary.subType)}</Box>
                    <Box
                      color={
                        !failedSubTypes[modelSummary.subType]
                          ? failedColor2
                          : successColor2
                      }
                    >
                      {!failedSubTypes[modelSummary.subType]
                        ? t("failed")
                        : t("successful")}
                    </Box>
                  </Box>
                ))}
              </Box>
            ))} */}
          {/* TODO: use this lines to reflect higher resolution */}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

import _ from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { Button } from "../../core/Button/Button";
import { Modal } from "../../core/Modal/Modal";
import Input from "../../core/Input/Input";

export interface SummarizeJobModalProps {
  idJob: number;
  jobName: string;
  onApprove: (idJob: number, fileName: string) => Promise<void>;
  onCancel: () => void;
}

export const SummarizeJobModal = ({
  idJob,
  jobName,
  onApprove,
  onCancel,
}: SummarizeJobModalProps) => {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState(jobName);
  const [loading, setLoading] = useState(false);

  const handleOnApprove = async () => {
    setLoading(true);
    try {
      await onApprove(idJob, fileName);
    } catch (e) {
      console.log("e :", e);
    } finally {
      setLoading(false);
    }
  };
  const handleOnFileNameChange = (e: any) => {
    setFileName(e.target?.value);
  };

  return (
    <Modal type={"info"} closeModal={onCancel}>
      <Grid
        container
        display={"flex"}
        flexDirection={"column"}
        py={2}
        px={4}
        width={"25rem"}
        textAlign={"center"}
      >
        <Grid item pt={1}>
          <Typography sx={{ fontSize: 22 }} pb={1}>
            {t("brief_modal_title")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>{t("brif_modal_description")}</Typography>
        </Grid>
        <Grid item pt={2} pb={3} px={3}>
          <Input
            value={fileName}
            onChange={handleOnFileNameChange}
            placeholder={t("file_name")}
          />
        </Grid>
        <Grid item display="flex" justifyContent={"center"} gap={3}>
          <Button onClick={onCancel} variant="outlined">
            {t("cancel") as string}
          </Button>
          <Button loading={loading} onClick={handleOnApprove} color="primary">
            {t("export") as string}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

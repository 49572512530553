import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ReactEditor,
  RenderElementProps,
  Slate,
  withReact,
} from "@sumit-platforms/slate-react";
import { createEditor, Transforms } from "@sumit-platforms/slate";
import { withHistory } from "@sumit-platforms/slate-history";
import Range from "./components/Slate/Range";
import EditorService from "./services/EditorService";
import { EditorMode, Job, JobSettings } from "@sumit-platforms/types";
import {
  CustomEditor,
  CustomElement,
  EditorAction,
  LeafProps,
  SubtitleRangeElement,
} from "./types";
import { useKeyboardShortcuts } from "@sumit-platforms/ui-bazar/hooks";
import {
  featureFlagsState,
  isDisabledState,
  isFindAndReplaceOpenState,
  isJobScriptOpenState,
  isValidationsOpenState,
  repeatState,
} from "./store/states";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { UpdateTcOffsetFn } from "./editor";
import Leaf from "./components/Slate/Leaf";
import { withRangeBreakOrMerge } from "./interceptors/withRangeBreakOrMerge";
import { withPlainTextPaste } from "./interceptors/withPlainTextPaste";
import TranscriptEditor from "./TranscriptEditor";
import SubtitlesEditor from "./SubtitlesEditor";
import _ from "lodash";
import MediaService from "./services/MediaService";
import { withJobSettings } from "./interceptors/withJobSettings";
import { withEdgeNav } from "./interceptors/withEdgeNav";

export type SlateForwardedRef = {
  editor: CustomEditor;
  updateLastRangeInput: () => void;
  onCurrentTimeUpdate?: (time: number) => void;
  lastFindAndReplaceTerm?: string;
};

export type EditorForwardedRef = {
  editorRef?: HTMLElement | null;
  lastFindAndReplaceTerm?: string;
};

interface SlateEditorProps {
  job: Job;
  updateTcOffset: UpdateTcOffsetFn;
  isFindAndReplaceOpen: boolean;
  toggleIsFindAndReplaceOpen: () => void;
  handleReadOnlyClick: () => void;
  mode: EditorMode;
  jobSettings: JobSettings;
  addActions: (actions: EditorAction[]) => void;
}

const subtitleRangeHeight = 80;

export interface ContentScrollProps {
  onIndexChange: (index: number) => void;
  onScrollStop: (index: number) => void;
  onScrollStart: (index: number) => void;
  subtitleRangeHeight: number;
  multipleMiddleRangeHeight: number;
  visibleRanges: number;
}

const SlateEditor = (
  {
    job,
    updateTcOffset,
    mode,
    jobSettings,
    handleReadOnlyClick,
    addActions,
  }: SlateEditorProps,
  ref: React.Ref<SlateForwardedRef>
) => {
  const featureFlags = useRecoilValue(featureFlagsState);
  const isDisabled = useRecoilValue(isDisabledState);
  const setRepeat = useSetRecoilState(repeatState);
  const [isFindAndReplaceOpen, setIsFindAndReplaceOpen] = useRecoilState(
    isFindAndReplaceOpenState
  );

  const [isJobScriptOpen, setIsJobScriptOpen] =
    useRecoilState(isJobScriptOpenState);
  const [isValidationOpen, setIsValidationOpen] = useRecoilState(
    isValidationsOpenState
  );

  const [startIndex, setStartIndex] = useState(0);

  const [editorController] = useState(() =>
    withEdgeNav(
      withJobSettings(
        withPlainTextPaste(
          withRangeBreakOrMerge(
            withHistory(withReact(createEditor() as CustomEditor))
          )
        )
      )
    )
  );

  const editorRef = useRef<EditorForwardedRef>(null);
  const isMediaWasPlayingOnScrollStart = useRef(false);

  const visibleRanges = useMemo(() => 3, []);
  const mediaIndex = useMemo(
    () => Math.floor(visibleRanges / 2),
    [visibleRanges]
  );
  const multipleMiddleRangeHeight = useMemo(() => 6, []);

  const updateLastRangeInput = useCallback(
    (rangeIndex?: number) => {
      const entry = _.isNumber(rangeIndex)
        ? {
            element: editorController.children[rangeIndex] as CustomElement,
            path: rangeIndex,
          }
        : null;
      EditorService.updateElementRange({
        editor: editorController,
        entry,
      });
    },
    [editorController]
  );
  const onSpeakersRangeBlur = useCallback(
    (rangeIndex?: number) => {
      updateLastRangeInput(rangeIndex);
    },
    [updateLastRangeInput]
  );

  const onSubtitlesRangeBlur = useCallback(
    (rangeIndex?: number) => {
      if (!_.isNumber(rangeIndex)) {
        console.error("rangeIndex does not provided");
        return;
      }

      updateLastRangeInput(rangeIndex);
    },
    [updateLastRangeInput]
  );

  const handleFindAndReplaceKeystroke = useCallback(
    (e: React.KeyboardEvent) => {
      if (isDisabled || !featureFlags.findAndReplace) return;
      if (isFindAndReplaceOpen) {
        setIsFindAndReplaceOpen(false);
      } else {
        e.preventDefault();

        if (mode === "subtitles") {
          ReactEditor.deselect(editorController);
        }

        setIsFindAndReplaceOpen(true);

        if (isJobScriptOpen) {
          setIsJobScriptOpen(false);
        }
        if (isValidationOpen) {
          setIsValidationOpen(false);
        }
      }
    },
    [
      isDisabled,
      featureFlags.findAndReplace,
      isFindAndReplaceOpen,
      setIsFindAndReplaceOpen,
      isJobScriptOpen,
      setIsJobScriptOpen,
      isValidationOpen,
      setIsValidationOpen,
    ]
  );

  const handleBlur = useCallback(
    (rangeIndex?: number) => {
      if (mode === "subtitles") onSubtitlesRangeBlur(rangeIndex);
      if (mode === "transcript") onSpeakersRangeBlur(rangeIndex);
    },
    [mode, onSpeakersRangeBlur, onSubtitlesRangeBlur]
  );
  const jumpToWord = useCallback(() => {
    EditorService.jumpToSlateWord(editorController);
  }, [editorController]);

  const handleJumpToWordKeystroke = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      jumpToWord();
    },
    [jumpToWord]
  );

  const handleCloseActionsSectionKeystroke = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (isDisabled) return;

      if (isFindAndReplaceOpen) {
        setIsFindAndReplaceOpen(false);
      }
    },
    [isDisabled, isFindAndReplaceOpen, setIsFindAndReplaceOpen]
  );

  const onCurrentTimeUpdate = useCallback(
    (time: number) => {
      if (editorController.handleTimeChange) {
        editorController.handleTimeChange(time);
      }
    },
    [editorController]
  );

  useImperativeHandle(
    ref,
    () => ({
      editor: editorController,
      updateLastRangeInput,
      onCurrentTimeUpdate,
      lastFindAndReplaceTerm: editorRef.current?.lastFindAndReplaceTerm,
    }),
    [editorController, updateLastRangeInput, onCurrentTimeUpdate]
  );

  useKeyboardShortcuts({
    handlers: {
      PREVENT_CUT: EditorService.preventCut,
      JUMP_TO_WORD: handleJumpToWordKeystroke,
    },
    ref: editorRef.current?.editorRef,
    disabled: !featureFlags?.useNewKeyboardShortcuts,
  });

  useKeyboardShortcuts({
    handlers: {
      CLOSE_MODAL: handleCloseActionsSectionKeystroke,
      FIND_AND_REPLACE: handleFindAndReplaceKeystroke,
    },
    disabled: !featureFlags?.useNewKeyboardShortcuts,
  });

  useLayoutEffect(() => {
    requestAnimationFrame(() => {
      if (editorController.history.undos.length) {
        // Clear first mount operations from history
        editorController.history.redos = [];
        editorController.history.undos = [];
      }
    });
  }, []);

  useEffect(() => {
    if (jobSettings && editorController) {
      editorController.jobSettings = jobSettings;
    }
  }, [jobSettings, editorController]);

  useEffect(() => {
    if (!editorController) return;
    try {
      if (editorRef.current && editorRef.current?.editorRef?.scrollTop !== 0) {
        editorRef.current.editorRef?.scrollTo(0, 0);
      }
    } catch (e) {
      console.error("e :", e);
    }
  }, [editorRef.current]);

  const renderElement = useCallback(
    (props: RenderElementProps) => {
      return (
        <Range
          updateTcOffset={updateTcOffset}
          hideTimecode={["protocol", "brief"].includes(job?.type.typeName)}
          handleBlur={handleBlur}
          startIndex={startIndex}
          attributes={props.attributes}
          children={props.children}
          element={props.element}
          renderIndex={props.renderIndex}
          isMediaRange={props.isMiddleRange}
          isPlaceholder={props.isPlaceholder}
          isElementSelected={props.selected}
        />
      );
    },
    [updateTcOffset, job?.type.typeName, handleBlur, startIndex]
  );

  const renderLeaf = useCallback(
    (props: any) => <Leaf {...(props as LeafProps)} />,
    []
  );

  const undo = useCallback(() => {
    editorController.undo();
    EditorService.createWaveformRanges(editorController, isDisabled);
  }, [editorController, isDisabled]);

  const redo = useCallback(() => {
    editorController.redo();
    EditorService.createWaveformRanges(editorController, isDisabled);
  }, [editorController, isDisabled]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter" && event.shiftKey) {
        event.preventDefault();
        Transforms.insertText(editorController, "\n", {});
      }
      if (event.key === "Enter" && mode === "subtitles") {
        event.preventDefault(); // Modify under "BREAK_RANGE" shortcut action
      }
      if (event.key === "״") {
        event.preventDefault();
        Transforms.insertText(editorController, '"');
      }
      if (event.key === "`") {
        event.preventDefault();
        Transforms.insertText(editorController, "'");
      }
      if (event.code === "KeyZ" && event.metaKey) {
        //Prevent native undo/redo from user
        event.preventDefault();
        event.stopPropagation();
        ReactEditor.deselect(editorController);
        if (event.shiftKey) redo();
        else undo();
        return;
      }
      if (event.code === "ArrowDown" && mode === "subtitles") {
        EditorService.onArrowUpAndDown(editorController, "next");
      }
      if (event.code === "ArrowUp" && mode === "subtitles") {
        EditorService.onArrowUpAndDown(editorController, "prev");
      }

      if (event.code === "Backspace" && mode === "subtitles") {
        EditorService.onBackspace(editorController, event);
      }
    },
    [editorController, redo, undo, mode]
  );

  const setMediaContentScrollOffset = useCallback(
    (index: number) => {
      const st = (
        editorController.children[index + mediaIndex] as SubtitleRangeElement
      )?.range?.st;
      MediaService.setOffset(st);
    },
    [mediaIndex, editorController.children]
  );

  const handleContentScrollIndexChange = useCallback(
    (index: number) => {
      setStartIndex(index);
    },
    [setStartIndex]
  );

  const handleContentScrollStop = useCallback(
    (index: number) => {
      setMediaContentScrollOffset(index);
      if (isMediaWasPlayingOnScrollStart.current) MediaService.play();
    },
    [setMediaContentScrollOffset, isMediaWasPlayingOnScrollStart]
  );

  const handleContentScrollStart = useCallback(
    (index: number) => {
      if (MediaService.repeat) {
        const repeat = MediaService.setIsRepeat(!MediaService.isRepeat);
        setRepeat(repeat);
      }
      isMediaWasPlayingOnScrollStart.current = MediaService.isPlaying;
    },
    [isMediaWasPlayingOnScrollStart, setRepeat]
  );

  return (
    <Slate
      editor={editorController}
      initialValue={EditorService.formatJobDataToEditorValue(job, mode)}
    >
      {mode === "transcript" && (
        <TranscriptEditor
          editorController={editorController}
          job={job}
          className={"transcriptRangesContainer"}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={onKeyDown}
          ref={editorRef}
          handleBlur={() => handleBlur()}
          handleReadOnlyClick={handleReadOnlyClick}
        />
      )}
      {mode === "subtitles" && (
        <SubtitlesEditor
          className={"subtitlesRangesContainer"}
          addActions={addActions}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={onKeyDown}
          ref={editorRef}
          handleBlur={(rangeIndex) => handleBlur(rangeIndex)}
          contentScroll={
            {
              onIndexChange: handleContentScrollIndexChange,
              onScrollStop: handleContentScrollStop,
              onScrollStart: handleContentScrollStart,
              subtitleRangeHeight,
              multipleMiddleRangeHeight,
              visibleRanges,
            } as ContentScrollProps
          }
        />
      )}
    </Slate>
  );
};

export default forwardRef(SlateEditor);

import _ from "lodash";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { FC, useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { TimecodePicker, TimerPicker } from "@sumit-platforms/ui-bazar";

import { handleKeyboardShortcut } from "../../utils/keyboardShortcuts";

// import { Word } from "../../models";
// import { JobData } from "../../models/job";
// import { JobRange, SpeakerRange } from "../../models/range";

import EditorService from "../../services/EditorService";
import MediaService from "../../services/MediaService";
import TimeService from "../../services/TimeService";
// import TrackingService from "../../services/TrackingService";

import SpeakerNameEditor from "../SpeakerNameEditor/SpeakerNameEditor";
import RangeValidation from "../RangeValidation/RangeValidation";

import {
  EditorFeatureFlags,
  JobRange,
  JobSpeaker,
  JobWithData,
  ShortcutAction,
  SpeakerRange,
  Word,
} from "@sumit-platforms/types";
import {
  getSecondsFromTimecode,
  getTcString,
  getTimecodeFromSeconds,
} from "@sumit-platforms/ui-bazar/utils";
import { useKeyboardShortcuts } from "@sumit-platforms/ui-bazar/hooks";
import { featureFlagsState } from "../../store/states";
import { useRecoilValue } from "recoil";

interface Props {
  job: JobWithData;
  ranges: SpeakerRange[];
  range: SpeakerRange;
  rangeIndex: number;
  setFocusedRangeIndex: (i: number) => void;
  rangesCount: number;
  changeRangeSpeaker: (rangeIndex: number, speaker: JobSpeaker | null) => void;
  onPressEnter: (options: {
    rangeIndex: number;
    updatedRangeWords: Word[];
    selectedWordIndex: number;
    wordCharIndex: number;
    range: JobRange;
    event: React.KeyboardEvent;
    createAnnotation: boolean;
  }) => void;
  mergeRange: (
    rangeIndex: number,
    words: Word[],
    mergeWithNextRange: boolean
  ) => void;
  updateRangeTimes: (options: {
    rangeIndex: number;
    start?: number;
    end?: number;
    method: "button" | "text";
  }) => void;
  isPassed: boolean;
  updateRangeWords: (rangeIndex: number, rangeWords: Word[]) => void;
  tcOffset: number;
  updateTcOffset: (options: {
    rangeIndex: number;
    startTime: number;
    tcOffset: number;
  }) => void;
  isTemporarySpeaker: boolean;
  direction: "ltr" | "rtl";
  disabled?: boolean;
  allowTimeEdit?: boolean;
  fps?: number;
}

const RangeSpeaker: FC<Props> = ({
  job,
  ranges,
  range,
  rangeIndex,
  setFocusedRangeIndex,
  rangesCount,
  changeRangeSpeaker,
  onPressEnter,
  mergeRange,
  isPassed,
  updateRangeWords,
  updateRangeTimes,
  tcOffset,
  updateTcOffset,
  direction,
  isTemporarySpeaker,
  disabled,
  allowTimeEdit,
  fps = 25,
}) => {
  const { t } = useTranslation();
  const [speakerName, setSpeakerName] = useState(range.speakerName);
  const featureFlags = useRecoilValue(featureFlagsState);
  const textInputRef = useRef<HTMLTextAreaElement>(null);
  const [isChanged, setIsChanged] = useState(false);
  const [oldPlainWords, setOldPlainWords] = useState("");

  const [plainWords, setPlainWords] = useState("");
  const [speakersPaneOpen, setSpeakersPaneOpen] = useState(false);
  const [isEditingSpeakerName, setIsEditingSpeakerName] =
    useState(isTemporarySpeaker);

  const [isEditingTime, setIsEditingTime] = useState<boolean | string>(false);
  const [rangeTimes, setRangeTimesState] = useState<{ [key: string]: string }>({
    start: "",
    end: "",
  });

  const getCursorFixedPositionAfterTrim = useCallback(
    (cursorPosition: number) => {
      const isBeginningOfWord =
        cursorPosition === 0 || plainWords[cursorPosition - 1] === " ";
      const rangeFirstHalfTrimmedLength = plainWords
        .slice(0, cursorPosition)
        .trim()
        .split(" ")
        .filter((word) => word)
        .join(" ").length;
      const cursorDiff =
        rangeFirstHalfTrimmedLength +
        (isBeginningOfWord ? 1 : 0) -
        cursorPosition;
      return cursorDiff;
    },
    [plainWords]
  );

  const getSelectedWordIndex = useCallback(
    (cursorPosition: number, words: Word[]) => {
      const trimmedWordCount = plainWords
        .trim()
        .split(" ")
        .filter((word) => word).length;
      const cursorDiff =
        cursorPosition > 0
          ? getCursorFixedPositionAfterTrim(cursorPosition)
          : 0;
      let rangeWordIndex = 0;
      let wordCharIndex: number = cursorPosition + cursorDiff;

      while (
        wordCharIndex > 0 &&
        wordCharIndex > words[rangeWordIndex].word.length
      ) {
        const wordToCheck = words[rangeWordIndex].word.length;
        wordCharIndex = wordCharIndex - (wordToCheck + 1); // +1 for space
        rangeWordIndex++;
      }

      return { rangeWordIndex, wordCharIndex };
    },
    [getCursorFixedPositionAfterTrim, plainWords]
  );

  const breakRange = useCallback(
    (event: React.KeyboardEvent, createAnnotation = false) => {
      event.preventDefault();
      event.stopPropagation();

      const { selectionStart, selectionEnd } =
        event.target as HTMLTextAreaElement;
      const updatedRangeWords = EditorService.getRangeWordsFromString(
        range.words,
        plainWords,
        oldPlainWords,
        rangesCount
      );

      const { rangeWordIndex, wordCharIndex } = getSelectedWordIndex(
        selectionStart || 0,
        updatedRangeWords
      );

      onPressEnter({
        rangeIndex,
        updatedRangeWords,
        selectedWordIndex: rangeWordIndex,
        wordCharIndex,
        range,
        event,
        createAnnotation,
      });
    },
    [
      getSelectedWordIndex,
      oldPlainWords,
      onPressEnter,
      plainWords,
      range,
      rangeIndex,
      rangesCount,
    ]
  );

  const breakAnnotation = useCallback(
    (event: React.KeyboardEvent) => {
      breakRange(event, true);
    },
    [breakRange]
  );

  const updateRanges = useCallback(() => {
    if (isChanged) {
      // TODO: in large ranges, f.e over 90K words, this can be slow till app crashes
      // we need to think on a new update method to speed up the internal state updates.
      // my suggestion:
      // breaking to chunks of 1K-10K words
      // each chunk will processed in parallel
      // merge the results in the end, then update the state
      const updatedRangeWords = EditorService.getRangeWordsFromString(
        range.words,
        plainWords,
        oldPlainWords,
        rangesCount
      );

      updateRangeWords(rangeIndex, updatedRangeWords);
      setOldPlainWords(plainWords);
    }

    // TrackingService.reportEvent(
    //   "text_edit_end",
    //   { text_changed: isChanged },
    //   job
    // );
  }, [
    isChanged,
    oldPlainWords,
    plainWords,
    range.words,
    rangeIndex,
    rangesCount,
    updateRangeWords,
  ]);

  const handleMergeRange = useCallback(
    async (mergeWithNextRange = false) => {
      const updatedRangeWords = EditorService.getRangeWordsFromString(
        range.words,
        plainWords,
        oldPlainWords,
        rangesCount
      );

      mergeRange(rangeIndex, updatedRangeWords, mergeWithNextRange);
    },
    [
      mergeRange,
      oldPlainWords,
      plainWords,
      range.words,
      rangeIndex,
      rangesCount,
    ]
  );

  const handleMergeRangeKeystroke = useCallback(
    (e: React.KeyboardEvent) => {
      const textLength = _.get(e, "target.textLength");
      const { selectionStart, selectionEnd } = e.target as HTMLTextAreaElement;
      if (selectionStart !== 0 || selectionEnd !== 0) return;

      if (rangeIndex === 0 || !_.isEmpty(ranges[rangeIndex - 1].annotations))
        return;
      handleMergeRange();
      focusAndSetCursor(rangeIndex - 1, -(textLength || 0));
      return;
    },
    [handleMergeRange, rangeIndex, ranges]
  );

  const handleMergeWithNextRangeKeystroke = useCallback(
    (e: React.KeyboardEvent) => {
      const { selectionStart } = e.target as HTMLTextAreaElement;
      const textLength = _.get(e, "target.textLength");

      if (selectionStart !== textLength) return;
      handleMergeRange(true);
      focusAndSetCursor(rangeIndex, textLength || 0);
    },
    [handleMergeRange, rangeIndex]
  );

  const handleJumpToNextRangeKeystroke = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault();
      if (rangeIndex === ranges.length - 1) {
        return;
      }
      const rangeIndexToFocus = rangeIndex + 1;
      focusAndSetCursor(rangeIndexToFocus, 0);
    },
    [rangeIndex, ranges.length]
  );

  const handleJumpToPrevRangeKeystroke = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault();
      if (rangeIndex === 0) {
        return;
      }
      const rangeIndexToFocus = rangeIndex - 1;
      focusAndSetCursor(rangeIndexToFocus, 0);
    },
    [rangeIndex]
  );

  const handleArrowsKeystroke = useCallback(
    (e: React.KeyboardEvent) => {
      const { selectionStart, selectionEnd } = e.target as HTMLTextAreaElement;
      const textLength = _.get(e, "target.textLength");

      const goBack =
        selectionStart === 0 &&
        ((direction === "ltr" && e.key === "ArrowLeft") ||
          (direction === "rtl" && e.key === "ArrowRight"));
      const goForward =
        selectionStart === textLength &&
        ((direction === "ltr" && e.key === "ArrowRight") ||
          (direction === "rtl" && e.key === "ArrowLeft"));

      if (goBack) {
        const rangeIndexToFocus = rangeIndex - 1;
        const rangeToFocus = document.getElementById(
          `range-${rangeIndexToFocus}`
        );
        const rangeToFocusLength = _.get(rangeToFocus, "value.length");
        focusAndSetCursor(rangeIndexToFocus, rangeToFocusLength || 0);
      }
      if (goForward) {
        const rangeIndexToFocus = rangeIndex + 1;
        focusAndSetCursor(rangeIndexToFocus, 0);
      }

      if (goBack || goForward) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [direction, rangeIndex]
  );

  const handleToggleSpeakersPaneKeystroke = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault();
      e.stopPropagation();

      updateRanges();
      setSpeakersPaneOpen(!speakersPaneOpen);
    },
    [speakersPaneOpen, updateRanges]
  );

  const handleUserTextCut = useCallback((e: React.KeyboardEvent) => {
    // prevent cut from user
    e.preventDefault();
    e.stopPropagation();
    return;
  }, []);

  const jumpToWord = useCallback(
    (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent) => {
      EditorService.jumpToWord(e, plainWords, range);
    },
    [plainWords, range.words]
  );

  const handleDoubleClick = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      jumpToWord(e);
    },
    [jumpToWord]
  );

  const handleJumpToWordKeyStroke = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault();
      e.stopPropagation();

      jumpToWord(e);
    },
    [jumpToWord]
  );

  useKeyboardShortcuts({
    handlers: {
      SAVE_JOB: updateRanges,
      BREAK_RANGE: breakRange,
      CREATE_NEW_ANNOTATION: breakAnnotation,
      MERGE_RANGE: handleMergeRangeKeystroke,
      MERGE_WITH_NEXT_RANGE: handleMergeWithNextRangeKeystroke,
      JUMP_TO_NEXT_RANGE: handleJumpToNextRangeKeystroke,
      JUMP_TO_PREV_RANGE: handleJumpToPrevRangeKeystroke,
      CURSOR_RIGHT_LEFT: handleArrowsKeystroke,
      OPEN_SPEAKERS: handleToggleSpeakersPaneKeystroke,
      PREVENT_CUT: handleUserTextCut,
      JUMP_TO_WORD: handleJumpToWordKeyStroke,
    },
    ref: textInputRef.current,
    disabled: !featureFlags?.useNewKeyboardShortcuts,
  });

  useEffect(() => {
    if (!range) return;
    setRangeAndPlainWords({ setOld: true });
    setRangeTimes();
  }, []);

  useEffect(() => {
    if (!range) return;
    const isFocused = document.activeElement === textInputRef.current;
    if (!isFocused || !isChanged) {
      setRangeAndPlainWords({ setOld: true });
      setSpeakerName(range.speakerName);
    }
  }, [job, range, range.words.length]);

  useEffect(() => {
    setIsEditingSpeakerName(isTemporarySpeaker);
    setSpeakerName(range.speakerName);
  }, [isTemporarySpeaker]);

  if (!range) return null;

  const setRangeAndPlainWords = ({
    rangeWords = range.words,
    isRangeEmpty = false,
    setOld = false,
  }: {
    rangeWords?: Word[];
    isRangeEmpty?: boolean;
    setOld?: boolean;
  } = {}) => {
    const rangeString = rangeWords.map((word) => word.word).join(" ");
    if (isRangeEmpty) {
      handleMergeRange();
      return;
    }
    setPlainWords(rangeString);
    if (setOld) setOldPlainWords(rangeString);
  };

  if (_.isEmpty(range.words)) return null;

  // --- Range Manipulators ---
  const handleChangeRangeSpeaker = async (speaker?: JobSpeaker) => {
    setSpeakerName(speaker ? speaker.name : null);
    changeRangeSpeaker(rangeIndex, speaker || null);
  };

  const focusAndSetCursor = (
    rangeIndexToFocus: number,
    cursorPosition: number
  ) => {
    const rangeToFocus = document.getElementById(
      `range-${rangeIndexToFocus}`
    ) as HTMLInputElement;
    if (rangeToFocus) {
      const cursorPositionToSet =
        cursorPosition > -1 ? cursorPosition : rangeToFocus.value.length + 1; // +1 to set cursor after the space
      setTimeout(() => {
        rangeToFocus.setSelectionRange(
          cursorPositionToSet,
          cursorPositionToSet
        );
        rangeToFocus.focus();
      }, 0);
    }
  };

  // --- Range Manipulators ---

  /* --- Time Manipulators --- */

  function setRangeTimes() {
    try {
      const start = TimeService.getTimeStringFromSecs(
        range.st + tcOffset,
        false,
        true
      );
      const end = TimeService.getTimeStringFromSecs(
        range.et + tcOffset,
        false,
        true
      );
      setRangeTimesState({
        start,
        end,
      });
    } catch (err) {
      Sentry.captureException({
        err,
        rangesCount,
        rangeLength: range.words.length,
        rangeIndex,
      });
    }
  }

  const handleRangeTimeChange = (
    time: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    position: "start" | "end",
    method: "button" | "text"
  ) => {
    const newTime = _.isString(time) ? time : time.target.value;

    setRangeTimesState({
      ...rangeTimes,
      [position]: newTime,
    });
  };

  const handleRangeTimeBlur = (position: "start" | "end", time?: string) => {
    const timeInSec = TimeService.getTimeNumberFromString(
      time || rangeTimes[position]
    );
    updateRangeTimes({ rangeIndex, [position]: timeInSec, method: "text" });
    setIsChanged(true);
  };

  const addSubtractRangeTime = (
    operator: "add" | "subtract",
    position: "start" | "end"
  ) => {
    let timeToAdd = MediaService.frameLength || 0.1;
    if (operator === "subtract") {
      timeToAdd = -timeToAdd;
    }

    const updatedTimeInSecs = TimeService.getFixedFrameRateTime({
      time: range[position === "start" ? "st" : "et"],
      timeToAdd,
      frameLength: MediaService.frameLength,
      operator,
    });

    const newTimeString = TimeService.getTimeStringFromSecs(
      updatedTimeInSecs + tcOffset,
      false,
      true
    );
    setRangeTimesState({
      ...rangeTimes,
      [position]: newTimeString,
    });

    updateRangeTimes({
      rangeIndex,
      [position]: updatedTimeInSecs + tcOffset,
      method: "button",
    });
  };

  /* --- Time Manipulators --- */

  // --- Event Handlers ---

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (featureFlags?.useNewKeyboardShortcuts) return;
    const { selectionStart, selectionEnd } = e.target as HTMLTextAreaElement;
    const textLength = _.get(e, "target.textLength");

    if (handleKeyboardShortcut(e, ["Enter", "NumpadEnter"])) {
      breakRange(e, e.ctrlKey);
      return;
    }

    if (
      handleKeyboardShortcut(
        e,
        ["Backspace"],
        [selectionStart === 0, selectionEnd === 0]
      )
    ) {
      handleMergeRangeKeystroke(e);
    }

    if (
      handleKeyboardShortcut(
        e,
        ["Backspace"],
        [selectionStart === 0, selectionEnd === textLength, rangesCount > 1]
      )
    ) {
      // Deletion of entire range
      // deleteRange();
    }

    if (
      handleKeyboardShortcut(e, ["Delete"], [selectionStart === textLength])
    ) {
      handleMergeWithNextRangeKeystroke(e);
    }

    if (handleKeyboardShortcut(e, ["Tab"])) {
      if (
        (e.nativeEvent.shiftKey && rangeIndex === 0) || // dont jump backward on first range
        (!e.nativeEvent.shiftKey && rangeIndex === ranges.length - 1) // dont jump forward on last range
      ) {
        return;
      }
      const rangeIndexToFocus = e.nativeEvent.shiftKey
        ? rangeIndex - 1
        : rangeIndex + 1;
      focusAndSetCursor(rangeIndexToFocus, 0);
    }

    if (handleKeyboardShortcut(e, ["ArrowLeft", "ArrowRight"], [], false)) {
      handleArrowsKeystroke(e);
    }

    if (handleKeyboardShortcut(e, ["KeyS"], [e.ctrlKey || e.metaKey], false)) {
      updateRanges();
    }

    if (handleKeyboardShortcut(e, ["KeyD"], [e.ctrlKey])) {
      handleToggleSpeakersPaneKeystroke(e);
    }

    if (handleKeyboardShortcut(e, ["KeyX"], [e.ctrlKey || e.metaKey])) {
      handleUserTextCut(e);
    }

    // -- Editor Shortcuts --
  };

  const handleFocus = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFocusedRangeIndex(rangeIndex);

    // TrackingService.reportEvent("text_edit_start", {}, job);
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newInputString = e.target.value === "" ? "." : e.target.value;

    setIsChanged(true);
    setPlainWords(newInputString);
    setFocusedRangeIndex(rangeIndex);
  };

  const handleBlur = async () => {
    setFocusedRangeIndex(-1);
    updateRanges();
  };

  const handleTimeChangeApprove = (time: string) => {
    handleRangeTimeChange(time as string, "start", "text");
    handleRangeTimeBlur("start", time as string);
    setIsEditingTime(false);
  };

  const handleTimeChangeCancel = () => setIsEditingTime(false);

  const handleTimecodeChangeApprove = (time: string) => {
    const timeInSec = featureFlags?.timecodePicker
      ? getSecondsFromTimecode(time)
      : TimeService.getTimeNumberFromString(time);

    updateTcOffset({
      rangeIndex,
      startTime: range.st,
      tcOffset: timeInSec,
    });
    setIsEditingTime(false);
  };

  return (
    <div
      className={classNames("textRange", "protocol", direction, {
        // error: !_.isEmpty(range.validation?.errors),
        // warning: !_.isEmpty(range.validation?.warnings),
      })}
    >
      <div
        className={classNames("rangeTimes protocol", {
          // overlapping:
          //   range.validation?.errors?.overlapping_start_prev ||
          //   range.validation?.errors?.overlapping_start_next ||
          //   range.validation?.errors?.start_after_end,
        })}
      >
        <div className={classNames("speakerBlockContainer", { disabled })}>
          <div className="speakerNameContainer">
            <SpeakerNameEditor
              speakerName={speakerName}
              speakers={job.data.speakers.filter(
                (s: JobSpeaker) =>
                  s.name !==
                  (isTemporarySpeaker ? "previousSpeakerName" : speakerName)
              )}
              handleSetNewSpeakerName={(speaker) => {
                handleChangeRangeSpeaker(speaker);
                setSpeakerName(speaker.name);
                setIsEditingSpeakerName(false);
                focusAndSetCursor(rangeIndex, 0);
                return true;
              }}
              cancelEdit={() => {
                handleChangeRangeSpeaker();
                setIsEditingSpeakerName(false);
                focusAndSetCursor(rangeIndex, 0);
              }}
              placeholder={t("choose_speaker")}
              isEditing={isEditingSpeakerName}
            />
          </div>
          <div className="speakerTime">
            {isEditingTime ? (
              <>
                {isEditingTime === "time" && (
                  <TimerPicker
                    className={classNames({
                      ltr: direction === "ltr",
                    })}
                    value={range.st}
                    handleChange={_.noop}
                    handleBlur={_.noop}
                    handleApprove={handleTimeChangeApprove}
                    handleCancel={handleTimeChangeCancel}
                    step={1}
                    disabled={disabled}
                  />
                )}
                {isEditingTime === "timecode" ? (
                  featureFlags?.timecodePicker ? (
                    <TimecodePicker
                      className={classNames({
                        ltr: direction === "ltr",
                      })}
                      value={getTimecodeFromSeconds(range.st, {
                        tcOffsets: job.tcOffsets,
                      })}
                      handleChange={_.noop}
                      handleBlur={_.noop}
                      handleApprove={handleTimecodeChangeApprove}
                      handleCancel={handleTimeChangeCancel}
                      fps={fps}
                      disabled={disabled}
                    />
                  ) : (
                    <TimerPicker
                      className={classNames({
                        ltr: direction === "ltr",
                      })}
                      value={getTcString(range.st, job.tcOffsets)}
                      handleChange={_.noop}
                      handleBlur={_.noop}
                      handleApprove={handleTimecodeChangeApprove}
                      handleCancel={handleTimeChangeCancel}
                      step={1}
                      disabled={disabled}
                      offset={tcOffset}
                    />
                  )
                ) : null}
              </>
            ) : (
              <div className="timeLabelsContainer">
                <span
                  className={classNames("startTime", { allowTimeEdit })}
                  onClick={() => {
                    if (allowTimeEdit) {
                      setIsEditingTime("time");
                    }
                  }}
                >
                  {TimeService.getTimeStringFromSecs(range.st)}
                </span>
                <span
                  className={classNames("startTimeTC", {
                    allowTimeEdit,
                  })}
                  onClick={() => {
                    if (allowTimeEdit) {
                      setIsEditingTime("timecode");
                    }
                  }}
                >
                  {featureFlags?.timecodePicker
                    ? getTimecodeFromSeconds(range.st, {
                        tcOffsets: job.tcOffsets,
                      })
                    : getTcString(range.st, job.tcOffsets, false)}
                </span>
              </div>
            )}
          </div>
          {job.data.speakers && !_.isEmpty(job.data.speakers) && (
            <SpeakerList
              speakers={job.data.speakers}
              currentSpeaker={speakerName}
              onSelect={handleChangeRangeSpeaker}
              isOpen={speakersPaneOpen}
              close={() => setSpeakersPaneOpen(false)}
            />
          )}
        </div>
      </div>

      <div className="rangeText">
        <div className={classNames("textContainer", { disabled })}>
          <div
            id={`rangeFocuser-${range.id}`}
            style={{ width: 1, height: 0 }}
          ></div>
          <textarea
            id={`range-${rangeIndex}`}
            ref={textInputRef}
            className="textRangeField"
            value={plainWords}
            onChange={handleOnChange}
            onKeyDown={handleKeyDown}
            onDoubleClick={handleDoubleClick}
            onBlur={handleBlur}
            onFocus={handleFocus}
            readOnly={disabled}
          />
          <div className="dummyRange">{plainWords}</div>
        </div>
      </div>
    </div>
  );
};

const SpeakerList: FC<{
  speakers: JobSpeaker[];
  currentSpeaker: string | null;
  onSelect: (speaker: JobSpeaker) => void;
  isOpen: boolean;
  close: () => void;
}> = ({ speakers, currentSpeaker, onSelect, isOpen, close }) => {
  const { t } = useTranslation();
  const speakerListRef = useRef<HTMLDivElement>(null);

  const [isCreateNewSpeakerOpen, setIsCreateNewSpeakerOpen] = useState(false);
  const [filteredSpeakers, setFilteredSpeakers] = useState<JobSpeaker[]>([]);

  useEffect(() => {
    setFilteredSpeakers(speakers.filter((s) => s.name !== currentSpeaker));
  }, []);

  const handleSetNewSpeakerName = (newSpeakerName: JobSpeaker) => {
    onSelect(newSpeakerName);
    setIsCreateNewSpeakerOpen(!isCreateNewSpeakerOpen);
    close();
    return true;
  };

  const handleCancelEdit = () => {
    setIsCreateNewSpeakerOpen(!isCreateNewSpeakerOpen);
    close();
  };

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!speakerListRef.current?.contains(event.target as Node)) {
        close();
      }
    };
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  return (
    <div className="speakerListContainer" ref={speakerListRef}>
      <div className={classNames("speakerList", { open: isOpen })}>
        {filteredSpeakers.map((speaker, i) => (
          <div
            className="speaker"
            onClick={() => {
              onSelect(speaker);
              close();
            }}
            key={i}
          >
            {speaker.name}
          </div>
        ))}
        {!isCreateNewSpeakerOpen ? (
          <div
            className="addSpeaker"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsCreateNewSpeakerOpen(!isCreateNewSpeakerOpen);
            }}
          >
            <span>+</span>
            <span>{t("create_new_speaker")}</span>
          </div>
        ) : (
          <SpeakerNameEditor
            speakers={filteredSpeakers}
            handleSetNewSpeakerName={handleSetNewSpeakerName}
            cancelEdit={handleCancelEdit}
            placeholder={t("create_new_speaker")}
            isEditing={true}
          />
        )}
      </div>
    </div>
  );
};

export default RangeSpeaker;

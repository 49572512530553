import { DiarizeTypes } from "../Algo/diarize";
import { SttModelProps } from "../config-settings/STTConfig";
import { JobStatus } from "../constants/statuses";

enum RerunTasks {
  TRANSCRIBE = "transcribe",
  ENRICH = "enrich",
}
interface RerunSTTOptions {
  sttModel: SttModelProps;
  enableNoiseCancelling?: boolean;
  diarize?: DiarizeTypes;
}
interface RerunEnrichParams {
  idJob: number;
}
interface RerunTranscribeOptions {
  idJob: number;
  sttOptions: RerunSTTOptions;
}
interface RerunTranscribeCallbackProps {
  idJob: number;
  prevStatus: JobStatus;
  success: boolean;
  progress: number;
}

interface RerunPayload {
  idJobs: number[];
  initTask?: RerunTasks;
  sttOptions?: RerunSTTOptions;
}

export type {
  RerunPayload,
  RerunSTTOptions,
  RerunTranscribeCallbackProps,
  RerunTranscribeOptions,
  RerunEnrichParams,
};

export { RerunTasks };

import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useState,
} from "react";
import _ from "lodash";
import {
  EditorType,
  Shortcut,
  ShortcutAction,
  UserSettings,
} from "@sumit-platforms/types";
import { keyboardShortcuts } from "../constants/keyboardShortcuts";

export interface ShortcutsContextProps {
  userShortcuts: Shortcut[];
  initShortcuts: (userSettings?: UserSettings) => void;
  editorType?: EditorType | null;
  initEditorType: (type: EditorType) => void;
}

export const ShortcutsContext = createContext<ShortcutsContextProps | null>(
  null
);

export const ShortcutsProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [userShortcuts, setUserShortcuts] = useState(keyboardShortcuts);
  const [editorType, setEditorType] = useState<EditorType | null>(null);

  const initShortcuts = useCallback((userSettings?: UserSettings) => {
    if (!userSettings?.keyboardShortcuts) return;
    const mergedShortcuts = keyboardShortcuts.map((defaultShortcut) => {
      const userShortcut = userSettings.keyboardShortcuts?.find(
        (s) => s?.action === defaultShortcut.action
      );
      if (userShortcut) {
        return {
          ...defaultShortcut,
          ..._.pick(userShortcut, ["keys", "action"]),
        };
      }
      return defaultShortcut;
    });

    setUserShortcuts(mergedShortcuts);
  }, []);

  const initEditorType = useCallback(
    (type: EditorType) => {
      if (editorType !== type) {
        setEditorType(type);
      }
    },
    [editorType]
  );

  return (
    <ShortcutsContext.Provider
      value={
        {
          userShortcuts,
          initShortcuts,
          editorType,
          initEditorType,
        } as ShortcutsContextProps
      }
    >
      {children}
    </ShortcutsContext.Provider>
  );
};

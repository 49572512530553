import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, MenuItem, Select } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { bazarTheme } from "../../bazar-theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faAngleLeft,
  faAngleRight,
  faArrowDownShortWide,
  faArrowUpShortWide,
} from "@fortawesome/pro-light-svg-icons";

import "./Sort.scss";

export interface SortOption {
  orderBy: string;
  label: string;
}

export interface SortProps {
  options: SortOption[];
  onSortChange?: (order: "ASC" | "DESC", orderBy: string) => void;
  direction?: "rtl" | "ltr" | string;
}
export interface SelectedSortOption extends SortOption {
  order: "ASC" | "DESC";
}

const arrowStyle = {
  position: "absolute !important",
  right: "0 !important",
  pointerEvents: "none !important",
};

export const Sort = ({ options, onSortChange, direction }: SortProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<SelectedSortOption>({
    ...options[0],
    order: "DESC",
  });

  const handleClick = (e: any, orderBy: string) => {
    e.stopPropagation();
    const selectedOrderBy = options.find((o) => o.orderBy === orderBy);
    if (selectedOrderBy) {
      setSelected((prev: SelectedSortOption) => {
        const newSelected: SelectedSortOption = {
          order: "DESC",
          ...selectedOrderBy,
        };
        if (prev?.orderBy === selectedOrderBy.orderBy) {
          newSelected.order = prev?.order === "ASC" ? "DESC" : "ASC";
        }
        onSortChange && onSortChange(newSelected.order, newSelected.orderBy);
        return newSelected;
      });
    }
  };
  return (
    <ThemeProvider theme={bazarTheme}>
      <Select
        sort-by={`${t("sort_by")}:`}
        disableUnderline={true}
        variant="standard"
        className={classNames("Sort", { open })}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={selected?.orderBy}
        IconComponent={() => (
          <Box px={2} fontStyle={arrowStyle}>
            <FontAwesomeIcon
              icon={direction === "ltr" ? faAngleRight : faAngleLeft}
            />
          </Box>
        )}
        sx={open ? { borderRadius: "10px 10px 0 0 !important" } : {}}
      >
        {options?.map((option: SortOption) => (
          <MenuItem
            className="sortItem"
            onClick={(e) => handleClick(e, option?.orderBy)}
            key={option.label + option.orderBy}
            value={option.orderBy}
          >
            <Box>{option.label}</Box>
            {option.orderBy === selected.orderBy &&
              (selected.order === "ASC" ? (
                <Box px={1} display={"flex"} alignItems={"center"}>
                  <FontAwesomeIcon icon={faArrowUpShortWide} />
                </Box>
              ) : (
                <Box px={1} display={"flex"} alignItems={"center"}>
                  <FontAwesomeIcon icon={faArrowDownShortWide} />
                </Box>
              ))}
          </MenuItem>
        ))}
      </Select>
    </ThemeProvider>
  );
};

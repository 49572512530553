import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconDefinition,
  faFaceDisappointed,
} from "@fortawesome/pro-light-svg-icons";
import { Button } from "../Button/Button";

import "./ErrorPage.scss";

export interface ErrorPageProps {
  title: string;
  text?: string;
  icon?: IconDefinition;
  redirectToOnClick?: string;
}
const maxTextLength = 350;
export const ErrorPage = ({
  title,
  text,
  icon,
  redirectToOnClick,
}: ErrorPageProps) => {
  const redirectToUrl = () => {
    if (redirectToOnClick) {
      window.location.href = redirectToOnClick;
    } else {
      window.location.reload();
    }
  };

  return (
    <div className="ErrorPage Page">
      <FontAwesomeIcon icon={icon || faFaceDisappointed} className="icon" />
      <div className="title">{title}</div>
      <div className="text">
        {text
          ? text.length > maxTextLength
            ? text.slice(0, maxTextLength) + "..."
            : text
          : ""}
      </div>
      {redirectToOnClick && <Button onClick={redirectToUrl}>Start over</Button>}
    </div>
  );
};

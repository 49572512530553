import _ from "lodash";
import { useTranslation } from "react-i18next";
import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Button } from "../../core/Button/Button";
import { MultiSelect, Option } from "../../core/MultiSelect/MultiSelect";

import "./DeleteProjectModal.scss";

interface ProjectToDelete {
  idProject: number;
  name: string;
}
[];

export interface DeleteProjectModalProps {
  cancel: () => void;
  confirm: (sourceIdProject: number, targetIdProject: number) => Promise<void>;
  sourceIdProject: number;
  projects: ProjectToDelete[];
  sourceProjectJobsNumber: number;
}

export const DeleteProjectModal = ({
  cancel,
  confirm,
  sourceIdProject,
  projects,
  sourceProjectJobsNumber,
}: DeleteProjectModalProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [targetIdProject, setTargetIdProject] =
    useState<number>(sourceIdProject);

  const isConfirmDisabled = useMemo(
    () => !_.isNil(targetIdProject) && targetIdProject !== sourceIdProject,
    [targetIdProject]
  );

  const projectOptions: Option[] = useMemo(
    () =>
      projects.map((project) => ({
        value: project.idProject,
        label: project.name,
      })),
    [projects]
  );

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      if (isConfirmDisabled) {
        await confirm(sourceIdProject, targetIdProject);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const projectsWithoutSourceProject = projects.filter(
      (p) => p.idProject !== sourceIdProject
    );
    if (
      sourceProjectJobsNumber == 0 &&
      projectsWithoutSourceProject.length > 0
    ) {
      setTargetIdProject(projectsWithoutSourceProject[0].idProject);
    }
  }, [projects, sourceIdProject]);

  return (
    <Box className="DeleteProjectModal" px={3} py={2}>
      <Box pt={2} px={3}>
        <Box width={"100%"}>
          <Box pb={2} display={"flex"} justifyContent={"center"}>
            <h4>{t("project_delete_title")}</h4>
          </Box>
          <Box py={1}>
            <p>{t("project_delete_description_1")}</p>
          </Box>
        </Box>
      </Box>
      {sourceProjectJobsNumber > 0 && (
        <Box pt={2} pb={4}>
          <Box pb={2} px={3}>
            <p>{t("project_delete_description_2")}</p>
          </Box>
          <Box>
            <MultiSelect
              selected={[targetIdProject]}
              setSelected={(selected) => setTargetIdProject(selected[0])}
              isMulti={false}
              options={projectOptions}
            />
          </Box>
        </Box>
      )}
      <Box display="flex" justifyContent={"flex-end"} gap={1}>
        <Button onClick={cancel} variant="outlined">
          {t("cancel")}
        </Button>
        <Button
          disabled={!isConfirmDisabled}
          loading={isLoading}
          onClick={handleConfirm}
          color="primary"
        >
          {t("confirm")}
        </Button>
      </Box>
    </Box>
  );
};

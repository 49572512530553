import React, { useEffect, useRef, useState } from "react";
import { Input } from "./../../core/Input/Input";

import "./TextInputPopover.scss";

export interface TextInputPopoverProps {
  onBlur: (value: string) => void;
  title: string;
  value?: string;
}

export const TextInputPopover = ({
  value,
  onBlur,
  title,
}: TextInputPopoverProps) => {
  const [changedValue, setChangedValue] = useState<string>(value || "");
  const newValue = useRef<string>("");

  useEffect(() => {
    return () => {
      onBlur(newValue.current.trim());
    };
  }, []);

  return (
    <div className="TextInputPopover">
      <div className="action">
        <div className="title">{title}</div>
        <Input
          value={changedValue}
          onChange={(e) => {
            setChangedValue(e.target?.value || "");
            newValue.current = e.target?.value || "";
          }}
        />
      </div>
    </div>
  );
};

import React, { FC, useRef, useState, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import useKeyPress from "../../hooks/useKeyPress";
import useOutsideClick from "../../hooks/useOutsideClick";

import {
  Annotation,
  EditorFeatureFlags,
  ShortcutAction,
} from "@sumit-platforms/types";
import {
  faChevronLeft,
  faChevronRight,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { useKeyboardShortcuts } from "@sumit-platforms/ui-bazar/hooks";

import "./RangeAnnotation.scss";
import { handleKeyboardShortcut } from "../../utils/keyboardShortcuts";
import { featureFlagsState } from "../../store/states";
import { useRecoilValue } from "recoil";

interface Props {
  rangeIndex: number;
  annotationIndex: number;
  annotation: Annotation;
  createNewAnnotation: (annotationIndex: number) => void;
  updateAnnotation: (
    rangeIndex: number,
    annotationIndex: number,
    annotation: Annotation
  ) => void;
  deleteAnnotation: (rangeIndex: number, annotationIndex: number) => void;
  direction: "ltr" | "rtl";
  isTemp: boolean;
  disabled: boolean;
  setFocusedRangeIndex: (i: number) => void;
}

const RangeAnnotation: FC<Props> = ({
  annotation,
  updateAnnotation,
  deleteAnnotation,
  rangeIndex,
  annotationIndex,
  direction,
  isTemp,
  disabled,
  createNewAnnotation,
  setFocusedRangeIndex,
}) => {
  const { t } = useTranslation();
  const featureFlags = useRecoilValue(featureFlagsState);
  const [editMode, setEditMode] = useState(false);
  const typeSelectorRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLTextAreaElement>(null);
  const annotationTypes = ["note", "subject", "decision", "task"];
  const [focusedTypeIndex, setFocusedTypeIndex] = useState(0);
  useKeyboardShortcuts({
    handlers: {
      CREATE_NEW_ANNOTATION: (e) => createNewAnnotation(annotationIndex),
    },
    ref: textRef.current,
    disabled: !featureFlags?.useNewKeyboardShortcuts,
  });

  const arrowDownPressed = useKeyPress("ArrowDown", [typeSelectorRef, textRef]);
  const enterPressed = useKeyPress("Enter", [typeSelectorRef, textRef]);
  const arrowUpPressed = useKeyPress("ArrowUp", [typeSelectorRef, textRef]);

  useOutsideClick(typeSelectorRef, () => {
    if (editMode) {
      setEditMode(false);
    }
  });

  useEffect(() => {
    if (!isTemp) return;
    focusTypeSelector();
    setFocusedTypeIndex(0);
    setEditMode(true);
  }, [isTemp]);

  useEffect(() => {
    if (!arrowUpPressed) return;
    handleArrowNavigation("up");
  }, [arrowUpPressed]);

  useEffect(() => {
    if (!arrowDownPressed) return;
    handleArrowNavigation("down");
  }, [arrowDownPressed]);

  useEffect(() => {
    if (!annotation?.temp || !enterPressed) return;
    handleArrowNavigation("enter");
  }, [enterPressed]);

  const handleArrowNavigation = (arrow: "up" | "down" | "enter") => {
    const typesLength = annotationTypes.length;

    if (arrow === "down") {
      if (focusedTypeIndex === typesLength - 1) {
        setFocusedTypeIndex(0);
        return;
      }
      setFocusedTypeIndex(focusedTypeIndex + 1);
    }

    if (arrow === "up") {
      if (focusedTypeIndex === 0) {
        setFocusedTypeIndex(typesLength - 1);
        return;
      }
      setFocusedTypeIndex(focusedTypeIndex - 1);
    }

    if (arrow === "enter") {
      if (focusedTypeIndex < 0 || focusedTypeIndex >= typesLength || !editMode)
        return;
      handleChangeType(annotationTypes[focusedTypeIndex]);
      setEditMode(false);
      focusRangeText();
      setFocusedTypeIndex(-1);
    }
  };

  const handleUpdateAnnotation = (text: string) => {
    const newAnnotation = { ...annotation, text } as Annotation;
    updateAnnotation(rangeIndex, annotationIndex, newAnnotation);
  };

  const handleFocus = (
    _e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFocusedRangeIndex(rangeIndex);
  };

  const handleBlur = async (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFocusedRangeIndex(-1);
    handleUpdateAnnotation(e.target.value);
  };

  const handleChangeType = (type: Annotation["type"]) => {
    updateAnnotation(rangeIndex, annotationIndex, {
      ...annotation,
      type,
      temp: false,
    });
    setEditMode(false);
  };

  const handleDeleteAnnotation = (e: any) => {
    e.stopPropagation();
    deleteAnnotation(rangeIndex, annotationIndex);
  };

  const focusTypeSelector = () => {
    if (typeSelectorRef.current) {
      typeSelectorRef.current.focus();
    } else {
      setTimeout(focusTypeSelector, 100);
    }
  };

  const focusRangeText = () => {
    textRef.current?.focus();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (handleKeyboardShortcut(e, ["Enter", "NumpadEnter"])) {
      if (e && e.ctrlKey) {
        createNewAnnotation && createNewAnnotation(annotationIndex);
      }
      return;
    }
  };

  return (
    <div className={classNames("RangeAnnotation", direction)}>
      <div className={classNames("annotation", { disabled })}>
        <div
          className={classNames("annotationTypeContainer", { disabled })}
          onClick={() => setEditMode(!editMode)}
        >
          <FontAwesomeIcon
            className="deleteAnnotation"
            icon={faTrash}
            onClick={handleDeleteAnnotation}
          />
          <div className="typeName">{t(annotation?.type || "")}</div>
          <div className={classNames("typeIcon", { open: editMode })}>
            <FontAwesomeIcon
              icon={direction === "ltr" ? faChevronRight : faChevronLeft}
            />
          </div>
        </div>
        <div
          className={classNames("typeSelector", {
            open: editMode,
            new: annotation?.temp,
          })}
          ref={typeSelectorRef}
          tabIndex={0}
        >
          {_.map(annotationTypes, (type: Annotation["type"], i: number) => (
            <div
              className={classNames("typeOption", {
                focused: annotation?.temp && focusedTypeIndex === i,
              })}
              onClick={() => handleChangeType(type)}
              key={i}
            >
              {type && t(type)}
            </div>
          ))}
        </div>
        <div
          className={classNames("annotationContent textContainer", {
            disabled,
          })}
        >
          {!disabled && (
            <textarea
              id={`range-${rangeIndex}`}
              onFocus={handleFocus}
              onBlur={handleBlur}
              className="textRangeField"
              defaultValue={annotation?.text || ""}
              placeholder={t("choose_annotation") || "Annotation"}
              ref={textRef}
              onKeyDown={
                featureFlags?.useNewKeyboardShortcuts
                  ? undefined
                  : handleKeyDown
              }
            />
          )}
          <div className="dummyRange">{annotation && annotation.text}</div>
        </div>
      </div>
    </div>
  );
};

export default RangeAnnotation;

import { format, isToday, isYesterday } from "date-fns";

export const monthNames = {
  1: "january",
  2: "february",
  3: "march",
  4: "april",
  5: "may",
  6: "june",
  7: "july",
  8: "august",
  9: "september",
  10: "october",
  11: "november",
  12: "december",
} as any;

export const formatMinAndMaxForDatetimeLocale = (date: Date) =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, -8);

export const formatMonthObject = (date: Date) => ({
  label: date.toLocaleDateString("en-US", { month: "long", year: "numeric" }),
  value: date,
});

const formatTimestamp = (createdAt: Date) => {
  const date = new Date(createdAt);
  let formattedDate = "";

  if (isToday(date)) {
    formattedDate = `Today, ${format(date, "HH:mm")}`;
  } else if (isYesterday(date)) {
    formattedDate = `Yesterday, ${format(date, "HH:mm")}`;
  } else {
    formattedDate = format(date, "MMMM dd yyyy, HH:mm");
  }

  return formattedDate;
};

export const formatRevisionToReadableDate = (revisionCreatedAt: Date) => {
  if (!revisionCreatedAt) return "";
  return formatTimestamp(revisionCreatedAt) || "";
};

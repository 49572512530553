import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import Input from "../../core/Input/Input";
import { MultiSelect, Option } from "../../core/MultiSelect/MultiSelect";
import { Button } from "../../core/Button/Button";
import { Trans, useTranslation } from "react-i18next";

import "./CommunityBonusForm.scss";
import {
  CommunityPayment,
  CommunityPaymentStatus,
  CommunityPaymentType,
} from "@sumit-platforms/types";
import _ from "lodash";

interface CommunityBonusFormProps {
  monthsOptions: Option[];
  onConfirm: (newBonus?: Partial<CommunityPayment>) => Promise<void>;
  cancel: () => void;
  userName: string;
}

export const CommunityBonusForm = ({
  monthsOptions,
  onConfirm,
  cancel,
  userName,
}: CommunityBonusFormProps) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState<any>("");
  const [date, setDate] = useState<Option | null>(
    monthsOptions ? monthsOptions[0] : null
  );
  const getSubTitle = () => {
    const subtitle = (
      <Trans
        i18nKey="add_bonus_modal_sub_title"
        values={{
          name: userName,
        }}
        components={{
          strong: <strong style={{ marginLeft: "3px" }} />,
        }}
      />
    );
    const monthSuffix =
      monthsOptions.length === 1 ? (
        <>
          {" "}
          {t("for")}{" "}
          <Typography display={"inline"} fontWeight={600}>
            {monthsOptions[0].label}
          </Typography>
        </>
      ) : (
        ""
      );
    return (
      <>
        {subtitle}
        {monthSuffix}
      </>
    );
  };

  const getNewBonus = () => {
    if (!date || !amount || !description) return;
    return {
      amount,
      date: date.value as Date,
      approvedDate: date.value as Date,
      currency: "ILS",
      description,
      paymentType: CommunityPaymentType.BONUS,
      status: CommunityPaymentStatus.APPROVED,
    } as CommunityPayment;
  };

  return (
    <Grid container px={4} pt={4} pb={2} width={"30rem"}>
      <Grid
        item
        xs={13}
        pb={2}
        px={2}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Typography sx={{ fontSize: 22 }} pb={2}>
          {t("add_bonus_modal_title")}
        </Typography>
        <Typography>{getSubTitle()}</Typography>
      </Grid>
      <Grid item xs={13} pb={2} px={2}>
        <Input
          label={t("description") as string}
          value={description}
          onChange={(e) => setDescription(e.target?.value || "")}
        />
      </Grid>
      <Grid item xs={13} pb={2} px={2}>
        <Input
          label={t("amount") as string}
          value={amount}
          type={"number"}
          onChange={(e) => setAmount(e.target?.value || "")}
        />
      </Grid>
      <Grid item xs={6} pb={6} px={2}>
        <MultiSelect
          label={t("select_month") as string}
          className={"communityBonusMonthSelect"}
          isMulti={false}
          options={monthsOptions}
          selected={[date?.value]}
          setSelected={([date]) => {
            const relevantDate = monthsOptions.find(
              (month) => month.value === date
            );
            if (relevantDate) {
              setDate(relevantDate);
            }
          }}
          disabled={monthsOptions.length === 1}
          hideSearch={true}
        />
      </Grid>
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        pb={4}
        gap={2}
      >
        <Button onClick={cancel} variant="outlined">
          {t("cancel") as string}
        </Button>
        <Button
          onClick={async () => {
            setLoading(true);
            await onConfirm(getNewBonus());
            setLoading(false);
          }}
          color="primary"
          disabled={!description || !amount}
          loading={loading}
        >
          {t("confirm") as string}
        </Button>
      </Grid>
    </Grid>
  );
};

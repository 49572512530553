import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Box, Grid, InputLabel, Typography } from "@mui/material";
import { Button } from "../../core/Button/Button";
import { Modal } from "../../core/Modal/Modal";
import { useMetadata } from "../../hooks/useMetadata";
import {
  DiarizeTypes,
  Job,
  RerunPayload,
  RerunTasks,
  RerunSTTOptions,
  ResolvedJobStatuses,
  ProjectPage,
} from "@sumit-platforms/types";
import { SelectSimple } from "../../core/SelectSimple/SelectSimple";
import { Switch } from "../../core/Switch/Switch";
import { Tooltip } from "../../core/Tooltip/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";

import "./RerunModal.scss";

export interface RerunModalProps {
  jobs: Job[];
  config: any;
  onApprove: (rerunPayload: RerunPayload) => Promise<void>;
  onCancel: () => void;
}

export const RerunModal = ({
  jobs,
  onApprove,
  onCancel,
  config,
}: RerunModalProps) => {
  const { t } = useTranslation();
  const { metadata } = useMetadata({ config });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //PROCESSES
  const [isTranscribe, setIsTranscribe] = useState<boolean>(false);
  const [isEnrich, setIsEnrich] = useState<boolean>(false);

  const {
    activeModelId,
    hasExtractAccess,
    supportSplitSubtitles,
    supportSpeakerSplit,
    isEnrichAvailable,
  } = useMemo(() => {
    let activeModelId;
    if (jobs.length === 1) {
      activeModelId = jobs[0].sttModel;
    }
    //DEPENDS ON SAME CLIENT
    let hasExtractAccess =
      jobs[0].client?.settings?.servicesAccess?.extractVer3;
    const supportSplitSubtitles = jobs.every(
      (job) => job.type.typeName === "subtitles"
    );
    const allJobsHasProjectExtractSettings = jobs.every(
      (job) => (job.project as ProjectPage)?.settings?.extract
    );
    if (allJobsHasProjectExtractSettings) {
      hasExtractAccess = jobs.every(
        (job) => (job.project as ProjectPage)?.settings?.extract?.enabled
      );
    }
    const supportSpeakerSplit = jobs.every(
      (job) =>
        job.type.typeName === "interview" || job.type.typeName === "protocol"
    );
    const isEnrichAvailable = jobs.every((job) =>
      ResolvedJobStatuses.includes(job.status)
    );
    return {
      activeModelId,
      hasExtractAccess,
      supportSplitSubtitles,
      supportSpeakerSplit,
      isEnrichAvailable,
    };
  }, [jobs]);

  //SELECT OPTIONS
  const sttModelOptions = useMemo(
    () =>
      metadata?.sttModels?.map((m) => ({
        label: t(m.name),
        value: m.model,
      })) || [],

    [metadata]
  );

  const notCompatibleTooltip = useMemo(() => {
    return (
      <Box display={"inline-flex"} px={1}>
        <Tooltip title={t("not_compatible_with_feature")}>
          <FontAwesomeIcon style={{ fontSize: "12px" }} icon={faInfoCircle} />
        </Tooltip>
      </Box>
    );
  }, []);

  const diarizeOptions = useMemo(
    () => [
      {
        label: t(DiarizeTypes.UNSUPRTVISED),
        value: DiarizeTypes.UNSUPRTVISED,
      },
      {
        label: t(DiarizeTypes.BANK_DIARIZE),
        value: DiarizeTypes.BANK_DIARIZE,
      },
    ],
    []
  );

  //STT OPTIONS
  const [isNoiseCancelling, setIsNoiseCancelling] = useState<boolean>(true);
  const [isSubtitlesSplit, setIsSubtitlesSplit] = useState<boolean>(false);
  const [isSpeakerSplit, setIsSpeakerSplit] = useState<boolean>(false);
  const [sttModelId, setSttModelId] = useState<string>(activeModelId || "");
  const [diarize, setDiarize] = useState<DiarizeTypes>(
    DiarizeTypes.UNSUPRTVISED
  );

  const rerunPayload = useMemo(() => {
    const initTask = isTranscribe ? RerunTasks.TRANSCRIBE : RerunTasks.ENRICH;

    let sttOptions: RerunSTTOptions | undefined;

    if (isTranscribe) {
      sttOptions = {
        sttModel: {
          sttModelId,
          splitSubtitles: isSubtitlesSplit,
        },
      };
      if (isSpeakerSplit) {
        sttOptions.diarize = diarize;
      }
      if (isNoiseCancelling) {
        sttOptions.enableNoiseCancelling = isNoiseCancelling;
      }
    }

    const _rerunPayload: RerunPayload = {
      idJobs: jobs.map((j) => j.idJob),
      initTask,
      sttOptions,
    };

    return _rerunPayload;
  }, [
    isTranscribe,
    isEnrich,
    isNoiseCancelling,
    isSubtitlesSplit,
    isSpeakerSplit,
    sttModelId,
    diarize,
  ]);

  const isSubmitDisabled = useMemo(() => {
    const noSelecteSttModelIdForTranscribe =
      rerunPayload.initTask === RerunTasks.TRANSCRIBE &&
      !rerunPayload.sttOptions?.sttModel.sttModelId;
    const noSelectedProcess = !isEnrich && !isTranscribe;
    return noSelecteSttModelIdForTranscribe || noSelectedProcess;
  }, [rerunPayload, isEnrich, isTranscribe]);

  useEffect(() => {
    if (isTranscribe && hasExtractAccess && isEnrichAvailable) {
      setIsEnrich(true);
    } else {
      setIsEnrich(false);
      resetAllSttOptions();
    }
  }, [isTranscribe]);

  useEffect(() => {
    if (activeModelId) setSttModelId(activeModelId);
  }, [activeModelId]);

  const resetAllSttOptions = useCallback(() => {
    setIsNoiseCancelling(true);
    setIsSpeakerSplit(false);
    setIsSubtitlesSplit(false);
    setDiarize(DiarizeTypes.UNSUPRTVISED);
    setSttModelId(
      activeModelId ||
        (!_.isEmpty(sttModelOptions) ? sttModelOptions[0].value : "")
    );
  }, [sttModelOptions]);

  //HANDLERS
  const handleRerun = async () => {
    setIsLoading(true);
    await onApprove(rerunPayload);
    setIsLoading(false);
  };

  const handleIsTranscribeChange = (checked: boolean) => {
    setIsTranscribe(checked);
  };

  const handleIsNoiseCancellingChange = (checked: boolean) => {
    setIsNoiseCancelling(checked);
  };

  const handleIsSubtitlesSplitChange = (checked: boolean) => {
    setIsSubtitlesSplit(checked);
  };

  const handleIsSpeakerSplitChange = (checked: boolean) => {
    setIsSpeakerSplit(checked);
  };

  const handleEnrichChange = (checked: boolean) => {
    setIsEnrich(checked);
  };

  const handleSttModelChange = (value: any) => {
    if (value) setSttModelId(value);
  };

  const handleDiarizeOptionChange = (value: any) => {
    if (value) setDiarize(value);
  };

  return (
    <Modal type={"info"} closeModal={onCancel}>
      <Grid
        className={"RerunModal"}
        container
        display={"flex"}
        flexDirection={"column"}
        py={3}
        px={2}
        width={"25rem"}
        textAlign={"start"}
      >
        <Grid item alignSelf={"center"}>
          <Typography sx={{ fontSize: 22 }} pb={1}>
            {t("rerun")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>{t("rerun_description")}</Typography>
        </Grid>
        <Grid item display={"flex"} alignItems={"center"}>
          <Switch
            checked={isTranscribe}
            onChange={(e) => handleIsTranscribeChange(e.target.checked)}
            id={"stt_model"}
          />
          <InputLabel htmlFor={"stt_model"} className="label">
            {t("stt_model")}
          </InputLabel>
        </Grid>
        <Grid item px={1} py={1}>
          <SelectSimple
            value={sttModelId}
            options={sttModelOptions}
            onChange={handleSttModelChange}
            disabled={!isTranscribe}
          />
        </Grid>
        <Grid
          item
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <InputLabel
            htmlFor={"noise_cancelling"}
            className={classNames("label", {
              disabledLabel: !isTranscribe,
            })}
          >
            {t("noise_cancelling")}
          </InputLabel>
          <Switch
            checked={isNoiseCancelling}
            onChange={(e) => handleIsNoiseCancellingChange(e.target.checked)}
            disabled={!isTranscribe}
            id="noise_cancelling"
          />
        </Grid>
        <Grid item display={"flex"} alignItems={"center"}>
          <Switch
            checked={isSubtitlesSplit}
            onChange={(e) => handleIsSubtitlesSplitChange(e.target.checked)}
            disabled={!isTranscribe || !supportSplitSubtitles}
            id={"split_subtitles"}
          />
          <InputLabel
            htmlFor={"split_subtitles"}
            className={classNames("label", {
              disabledLabel: !isTranscribe || !supportSplitSubtitles,
            })}
          >
            {t("split_subtitles")}
          </InputLabel>
          {!supportSplitSubtitles && notCompatibleTooltip}
        </Grid>
        <Grid item display={"flex"} alignItems={"center"}>
          <Switch
            checked={isSpeakerSplit}
            onChange={(e) => handleIsSpeakerSplitChange(e.target.checked)}
            disabled={!isTranscribe || !supportSpeakerSplit}
            id={"speaker_recognition"}
          />
          <InputLabel
            htmlFor={"speaker_recognition"}
            className={classNames("label", {
              disabledLabel: !isTranscribe || !supportSpeakerSplit,
            })}
          >
            {t("speaker_recognition")}
          </InputLabel>
          {!supportSpeakerSplit && notCompatibleTooltip}
        </Grid>
        <Grid item px={1} py={1}>
          <SelectSimple
            value={diarize}
            options={diarizeOptions}
            onChange={handleDiarizeOptionChange}
            disabled={!isSpeakerSplit}
          />
        </Grid>
        <Grid item display={"flex"} alignItems={"center"}>
          <Switch
            checked={isEnrich}
            onChange={(e) => handleEnrichChange(e.target.checked)}
            disabled={isTranscribe || !hasExtractAccess || !isEnrichAvailable}
            id="index_for_extract"
          />
          <InputLabel
            htmlFor={"index_for_extract"}
            className={classNames("label", {
              disabledLabel:
                isTranscribe || !hasExtractAccess || !isEnrichAvailable,
            })}
          >
            {t("index_for_extract")}
          </InputLabel>
          {(!hasExtractAccess || !isEnrichAvailable) && notCompatibleTooltip}
        </Grid>
        <Grid item display="flex" justifyContent={"center"} gap={3} pt={2}>
          <Button disabled={isLoading} onClick={onCancel} variant="outlined">
            {t("cancel")}
          </Button>
          <Button
            disabled={isSubmitDisabled}
            onClick={handleRerun}
            color="primary"
            loading={isLoading}
          >
            {t("run")}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

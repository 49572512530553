import React, { useEffect, useCallback, useRef } from "react";
import _ from "lodash";
import MediaService from "../services/MediaService";
import { EditorMode } from "@sumit-platforms/types";
import { CustomEditor } from "../types";
export const useRapidTimeUpdate = ({
  onChange,
  msToUpdate = 150,
  enable,
}: {
  onChange: (time: number) => void;
  msToUpdate?: number;
  enable: boolean;
}) => {
  const lastTimeTriggers = useRef(0);

  const throttledUpdate = useCallback(
    _.throttle((time: number) => {
      if (!enable) return;
      if (lastTimeTriggers.current === time) return;
      onChange(time);

      lastTimeTriggers.current = time;
    }, msToUpdate),
    [onChange, msToUpdate]
  );

  useEffect(() => {
    if (!enable) return;

    const video = MediaService.media;
    let animationFrameId: number;

    const update = () => {
      throttledUpdate(video?.currentTime);
      animationFrameId = requestAnimationFrame(update);
    };

    if (video) {
      update();
    }

    return () => {
      cancelAnimationFrame(animationFrameId);
      throttledUpdate?.cancel();
    };
  }, [MediaService.media, enable]);
};

import _ from "lodash";
import React, { FC, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import { Button } from "../../core/Button/Button";
import { Input } from "../../core/Input/Input";
import { MultiSelect, Option } from "../../core/MultiSelect/MultiSelect";
import { DocxFormatType, ValidationJobTypes } from "@sumit-platforms/types";

import "./AddNewConfigurationModal.scss";

export interface AddNewConfigurationModalProps {
  onConfirm: (
    presetName: string,
    type: DocxFormatType | keyof ValidationJobTypes
  ) => void;
  onCancel: () => void;
  options: Option[];
  modalTitle: string;
  modalDescription: string;
  namePlaceholder?: string;
  typePlaceholder?: string;
}

export const AddNewConfigurationModal: FC<AddNewConfigurationModalProps> = ({
  onConfirm,
  onCancel,
  options,
  modalTitle,
  modalDescription,
  namePlaceholder,
  typePlaceholder,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [presetName, setPresetName] = useState<string>("");
  const [type, setType] = useState<
    (DocxFormatType | keyof ValidationJobTypes)[]
  >([]);

  useEffect(() => {
    if (options?.length === 0) return;
    setType([options[0].value as DocxFormatType | keyof ValidationJobTypes]);
  }, [options]);

  const isValid = useMemo<boolean>(() => {
    return !_.isEmpty(type) && !_.isEmpty(presetName);
  }, [presetName, type]);

  const handleOnConfirm = async () => {
    if (!isValid) return;
    setIsLoading(true);
    await onConfirm(presetName, type[0]);
    setIsLoading(false);
  };

  return (
    <Grid className={"AddNewConfigurationModal"} container width={"30rem"}>
      <Grid container px={4} pt={4} pb={3}>
        <Box width={"100%"}>
          <Box pb={3} display={"flex"} justifyContent={"center"}>
            <h4>{modalTitle}</h4>
          </Box>
          <Box>
            <p>
              <Trans i18nKey={modalDescription} />
            </p>
          </Box>
        </Box>
      </Grid>
      <Grid container px={4} pb={3}>
        <Grid item xs={8} pb={2}>
          <Box>
            <Input
              value={presetName}
              type="text"
              onChange={(e) => setPresetName(e.target?.value || "")}
              placeholder={namePlaceholder}
            />
          </Box>
        </Grid>

        <Grid item xs={8} pb={2}>
          <Box className={"typeInput"}>
            <MultiSelect
              selected={type}
              setSelected={(selected) => setType(selected)}
              placeholder={typePlaceholder}
              isMulti={false}
              options={options}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        pb={4}
        gap={2}
      >
        <Button onClick={onCancel} variant="outlined">
          {t("cancel")}
        </Button>
        <Button
          disabled={!isValid}
          onClick={handleOnConfirm}
          color="primary"
          loading={isLoading}
        >
          {t("save_changes")}
        </Button>
      </Grid>
    </Grid>
  );
};

import _ from "lodash";
import { TFunction } from "i18next";
import { PageSettings } from "../../../core/SettingsPage/SettingsPage";
import { Platform, STTConfigPage } from "@sumit-platforms/types";
import { ProjectSettingsPagesMetadata } from "../../useProjectSettings";

export const getSttModelPageInputs: (
  args: STTConfigPage,
  t: TFunction,
  metadata?: ProjectSettingsPagesMetadata,
  platform?: Platform
) => PageSettings<{ model: STTConfigPage }> = (args, t, metadata, platform) => {
  const sttPriority =
    metadata?.sttPriority?.map((p: number) => ({
      value: p,
      label: t(`settings_stt_priority_${p}`),
    })) || [];
  return {
    settings: { model: args },
    pageTab: t("settings_stt_stt_model"),
    sections: [
      {
        title: t("settings_stt_stt_model"),
        inputs: [
          {
            label: t("settings_stt_stt_model"),
            type: "select",
            options: metadata?.sttModelOptions || [],
            key: `model.sttModelId`,
            isColumn: true,
            style: { width: 200 },
          },
        ],
      },
      {
        inputs: [
          {
            label: t("settings_stt_priority"),
            type: "select",
            options: sttPriority,
            key: `model.priority`,
            isColumn: true,
            style: { width: 200 },
          },
        ],
      },
      {
        inputs: [
          {
            label: t("settings_stt_express"),
            type: "switch",
            key: `model.express`,
            style: { width: 200 },
          },
        ],
      },
    ],
  };
};

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import {
  AttachmentsModal,
  Button,
  ConfirmModal,
  DatePickerPopover,
  GeneralTable,
  HeadCell,
  JobTypeSelectPopover,
  LanguageSelectPopover,
  MergeUploadModal,
  PopoverAction,
  RedirectModal,
  TableIconActions,
  TagsModal,
  TextInputPopover,
  UnmergeModal,
  UploadSettingsModal,
  MultiSelectPopover,
} from "../../../index";
import {
  ActiveUploadTableRow,
  Attachment,
  AttachmentType,
  Client,
  JobSettingsPage,
  JobTypeMeta,
  jobTypesOptions,
  MulterFileInfo,
  Project,
  ProjectPage,
  Tag,
  Toast,
  Upload,
  UploadSettings,
  UploadStatus,
  UploadTableRow,
  User,
  validMediaMergeFormats,
} from "@sumit-platforms/types";
import _ from "lodash";
import {
  extractFileExtension,
  formatLanguageString,
  secondsToTimeString,
} from "../../../utils";
import {
  faGear,
  faLayerGroup,
  faNotes,
  faTag,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { addDays, format } from "date-fns";
import { DATE_FORMAT_1 } from "../../constants/globals";
import { useTranslation } from "react-i18next";
import { useToast, useUploads } from "../../../hooks";
import {
  UploadService,
  AttachmentService,
  JobService,
  ProjectService,
} from "../../../services";
import { useModal } from "../../../store";

import "./UploadFilesTable.scss";

export interface UploadFilesTableFeatureFlags {
  skipStt?: boolean;
}

interface Props {
  config: any;
  user: User | null;
  selectedClient: Client | null;
  setToast: (toast: Toast | null) => void;
  tagService: any;
  featureFlags?: UploadFilesTableFeatureFlags;
  disableSubmit?: boolean;
}

export const UploadFilesTable = ({
  config,
  user,
  selectedClient,
  setToast,
  tagService,
  featureFlags,
  disableSubmit,
}: Props) => {
  const projectService = useMemo(() => {
    return ProjectService({
      config,
    });
  }, [config]);
  const uploadService = useMemo(() => {
    return UploadService({
      config,
    });
  }, [config]);
  const attachmentService = useMemo(() => {
    return AttachmentService({
      config,
    });
  }, [config]);
  const jobService = useMemo(() => {
    return JobService({
      config,
    });
  }, [config]);
  //TODO: This is a quick fix to enable accessing certain modals who uses custom select
  const [paddingBottom, setPaddingBottom] = useState<number>(0);
  const { setModalContent, setModalType, clearModalContent } = useModal();
  const [selected, setSelected] = useState<number[]>([]);
  const [submittingUploads, _setsubmittingUploads] = useState<
    Record<string, boolean>
  >({});
  const { toastError, toastSuccess, toastInfo } = useToast({ setToast });
  const { t } = useTranslation();
  const [loadingRows, _setLoadingRows] = useState<{ [id: string]: boolean }>(
    {}
  );

  const {
    getUploads,
    uploads,
    activeUploads,
    progress,
    deleteUploads,
    updateUploads,
    mergeUploads,
    unmergeUploads,
  } = useUploads({
    config,
    user,
    idClient: selectedClient?.idClient,
  });
  const [uploadRows, setUploadRows] = useState<UploadTableRow[]>([]);
  const [projects, setProjects] = useState<ProjectPage[]>([]);
  const projectOptions = useMemo(
    () => projects.map((p) => ({ value: p.idProject, label: p.name })),
    [projects]
  );
  const [activeUploadRows, setActiveUploadRows] = useState<
    ActiveUploadTableRow[]
  >([]);

  useEffect(() => {
    const _activeUploadRows = activeUploads.map((u) =>
      uploadService.createActiveUploadRow(u)
    );
    const _uploadRows = uploads.map((u) => uploadService.createUploadRow(u));
    setUploadRows(_.orderBy(_uploadRows, ["id"], ["desc"]));
    setActiveUploadRows(_activeUploadRows);
  }, [uploads, activeUploads]);

  useEffect(() => {
    const getProjects = async () => {
      if (!selectedClient?.idClient || !projectService) return;
      const projects = await projectService?.getClientProjects(
        selectedClient.idClient,
        true
      );
      setProjects(projects as ProjectPage[]);
    };
    getProjects();
  }, [selectedClient, projectService]);

  const selectedUploadsRows = useMemo(() => {
    return uploadRows.filter((u) => selected.includes(+u.id));
  }, [selected, uploadRows]);
  const selectedUploads = useMemo(() => {
    return uploads.filter((u) => selected.includes(u.idUpload));
  }, [selected, uploadRows]);

  const isMergeAllowed = useMemo(
    () =>
      selected.length >= 2 &&
      selectedUploads.every((upload: Upload) =>
        validMediaMergeFormats.includes(
          extractFileExtension(upload.name)?.toLowerCase() || ""
        )
      ),
    [selected.length, selectedUploads]
  );

  const setTags = async (uploadIds: number[], tags: Tag[]): Promise<void> => {
    try {
      toastInfo(t("tags_update_start"));
      await uploadService.setTags(
        uploadIds,
        tags.map((t) => t.idTag)
      );
      toastSuccess(t("tags_update_done"));
      setUploadRows((prev) =>
        prev.map((u) => (uploadIds.includes(+u.id) ? { ...u, tags } : u))
      );
      closeModal();
    } catch (err) {
      toastError(t("tags_update_failed"));
    }
  };

  const handleProjectCreate = useCallback(
    async (projectName: string) => {
      try {
        if (!selectedClient?.idClient || !projectService) return;
        const project = await projectService.createNewProject({
          idClient: selectedClient.idClient,
          name: projectName,
        });

        setProjects((prev) => [{ ...project, settings: {} }, ...prev]);
        toastSuccess(t("project_creation_succeed"));
      } catch (err) {
        toastError(t("project_creation_failedd"));
      }
    },
    [selectedClient, projectService]
  );

  const setJobSettings = (
    jobSettings: JobSettingsPage,
    dataToUpdate: Partial<Upload>
  ): Partial<Upload> => {
    dataToUpdate.inputLanguage = jobSettings.sourceLanguage;
    dataToUpdate.outputLanguage = jobSettings.targetLanguage;
    dataToUpdate.idValidationPreset = jobSettings.idValidation;
    dataToUpdate.jobType = {
      typeName: jobSettings.jobType,
      autoTranscript: jobSettings.auto,
      burn: jobSettings.burn,
    };
    dataToUpdate.delivery = addDays(new Date(), jobSettings.deliveryTimeInDays);
    return dataToUpdate;
  };

  const handleProjectChange = async (
    uploadIds: number[],
    projectIds: any[]
  ) => {
    if (_.isEmpty(projectIds) || _.isEmpty(uploadIds)) return;
    let dataToUpdate: Partial<Upload> = {
      idProject: projectIds[0],
    };
    const settings = projects.find(
      (p) => p.idProject === projectIds[0]
    )?.settings;
    const speakersSplit = settings?.speakersSplit;
    const jobSettings = settings?.jobSettings;
    if (jobSettings) {
      dataToUpdate = setJobSettings(jobSettings, dataToUpdate);
    }
    if (speakersSplit && speakersSplit.autoSpeakerSplit) {
      _.set(dataToUpdate, "diarize.diarize", speakersSplit.splitBy);
      _.set(dataToUpdate, "diarize.diarize_param", {});
    } else {
      _.set(dataToUpdate, "diarize", {});
    }
    await handleUpdateUploads(uploadIds, dataToUpdate);
  };

  const handleUploadAttachment = async (
    type: AttachmentType,
    attachment: File,
    uploadIds: number[]
  ): Promise<Attachment> => {
    if (!selectedClient) throw new Error(t("no_client_were_selected"));
    if (uploadIds.length > 1) {
      closeModal();
    }
    setLoadingRows(uploadIds, true);
    const _attachment: Attachment = await attachmentService.create(
      type,
      uploadIds,
      selectedClient?.idClient,
      attachment
    );
    const newUploadRows = uploadRows.map((u: UploadTableRow) => {
      if (uploadIds.includes(u.id as number)) {
        u.attachments = [...u.attachments, _attachment];
        return u;
      }
      return u;
    });
    setUploadRows(newUploadRows);
    setLoadingRows(uploadIds, false);
    setSelected([]);
    return _attachment;
  };

  const handleRemoveAttachment = async (
    idUpload: number,
    idAttachment: number
  ): Promise<void> => {
    try {
      setLoadingRows([idUpload], true);
      await uploadService.removeAttachmentFromUpload(idUpload, idAttachment);
      const newUploadRows = uploadRows.map((u: UploadTableRow) => {
        if (u.id === idUpload) {
          u.attachments = u.attachments.filter(
            (a) => a.idAttachment !== idAttachment
          );
          return u;
        }
        return u;
      });
      setUploadRows(newUploadRows);
      setLoadingRows([idUpload], false);
    } catch (err) {
      toastError(t("remove_attachment_failed"));
    }
  };

  const closeModal = (): void => {
    setModalType("info");
    clearModalContent();
  };

  const handleUnmergeUploads = async (idUpload: number, idMedia: number) => {
    try {
      toastInfo(t("unmerge_start"));

      await unmergeUploads(idUpload, idMedia);

      toastSuccess(t("unmerge_done"));

      closeModal();
      setSelected([]);
    } catch (err: any) {
      toastError(t("unmerge_error"));
    }
  };

  const openConfirmModal = (
    title: string,
    message: string,
    confirm: () => void,
    closeAfterConfirm = true
  ): void => {
    setModalType("danger");
    setModalContent(
      <ConfirmModal
        title={title}
        message={message}
        confirm={confirm}
        cancel={closeModal}
        closeAfterConfirm={closeAfterConfirm}
      />
    );
  };

  const openUnmergeModal = (uploadRow: UploadTableRow): void => {
    const upload = uploads.find((u) => u.idUpload === uploadRow.id);
    upload &&
      setModalContent(
        <UnmergeModal
          unmergeMedia={(idMedia: number) =>
            openConfirmModal(
              t("unmerge_media"),
              t("are_you_sure"),
              () =>
                uploadRow &&
                uploadRow.id &&
                typeof uploadRow.id === "number" &&
                handleUnmergeUploads(uploadRow.id, idMedia),
              true
            )
          }
          cancel={closeModal}
          upload={upload}
        />
      );
  };

  const setLoadingRows = (ids: string[] | number[], isLoading = true) => {
    const updatedLoadingRows: { [id: string]: boolean } = {};
    _.each(ids, (id) => {
      const _id = _.toString(id || "");
      updatedLoadingRows[_id] = isLoading;
    });
    _setLoadingRows((prev) => ({ ...prev, ...updatedLoadingRows }));
  };

  const isUploadSubmittable = (row: UploadTableRow) =>
    !loadingRows[row.id] && row.isSubmitable;
  const isSelectedUploadsSubmittable = () => {
    const isAllUploadSelectedSubmittable = selectedUploadsRows.every((row) =>
      isUploadSubmittable(row)
    );
    const isSubmitCurrentlyRunning = _.some(submittingUploads, (isSubmitting) =>
      Boolean(isSubmitting)
    );
    return isAllUploadSelectedSubmittable && !isSubmitCurrentlyRunning;
  };

  const updateUploadsJobType = async (
    uploadIds: number[],
    jobType: JobTypeMeta,
    idValidationPreset?: number
  ): Promise<void> => {
    try {
      if (_.isEmpty(uploadIds)) {
        throw new Error("No selected");
      }

      const dataToUpdate: Partial<Upload> = {
        jobType,
      };

      if (jobType.autoTranscript) {
        dataToUpdate.delivery = uploadService.AUTO_TRANSCRIPT_DELIVERY_MIN_DATE;
      }

      if (idValidationPreset) {
        dataToUpdate.idValidationPreset = idValidationPreset;
      } else if (jobType.typeName !== "subtitles") {
        dataToUpdate.idValidationPreset = null;
      }

      toastInfo(t("updating_uploads"));
      setLoadingRows(uploadIds, true);

      await updateUploads(uploadIds, dataToUpdate);
      toastSuccess(t("updating_uploads_success"));
    } catch (err) {
      toastError(t("upload_update_failed"));
    } finally {
      setLoadingRows(uploadIds, false);
    }
  };

  const handleDeleteUploads = async () => {
    try {
      toastInfo(t("delete_start"));
      deleteUploads(selected);
      toastSuccess(t("delete_done"));
      setSelected([]);
    } catch (err: any) {
      toastError(t("delete_failed"));
    }
  };

  const handleUpdateUploads = async (
    uploadIds: number[],
    uploadData: Partial<Upload>
  ): Promise<void> => {
    try {
      toastInfo(t("updating_uploads"));
      await updateUploads(uploadIds, uploadData);
      toastSuccess(t("updating_uploads_success"));
    } catch (err: any) {
      toastError(t("updating_uploads_fail"));
    }
  };

  const renderProjectNameColAction = () => (
    <div onClick={(e) => e.stopPropagation()}>
      <MultiSelectPopover
        title={`insert_project_name`}
        update={(_uploadIds, projectIds) =>
          handleProjectChange(selected, projectIds)
        }
        selected={[]}
        options={projectOptions}
        addNewOption={{ onApprove: handleProjectCreate }}
      />
    </div>
  );

  const setSubmitingDownloads = ({
    isLoading,
    ids,
  }: {
    ids: string[] | number[];
    isLoading: boolean;
  }) => {
    const updatedSubmittingUploads: { [id: string]: boolean } = {};
    _.each(ids, (id) => {
      const _id = _.toString(id || "");
      updatedSubmittingUploads[_id] = isLoading;
    });
    _setsubmittingUploads((prev) => ({ ...prev, ...updatedSubmittingUploads }));
  };

  const handleMergeUploads = async () => {
    try {
      toastInfo(t("merge_start"));
      await mergeUploads(selectedUploads);

      toastSuccess(t("merge_done"));

      closeModal();
      setSelected([]);
    } catch (err: any) {
      toastError(t(err.message));
    }
  };

  const openMergeUploadsModal = (): void => {
    setModalContent(
      <MergeUploadModal
        submit={handleMergeUploads}
        cancel={closeModal}
        uploads={uploads}
        selected={selected}
        setSelected={setSelected}
      />
    );
  };

  const openTagsModal = (uploadIds: number[], tags: Tag[]): void => {
    if (selectedClient?.idClient)
      setModalContent(
        <TagsModal
          entity="job"
          submit={(_tags) => setTags(uploadIds, _tags)}
          cancel={closeModal}
          tags={tags}
          idClient={selectedClient?.idClient}
          tagService={tagService}
        />
      );
  };

  const handleDownloadAttachment = async (attachment: Attachment) => {
    await attachmentService.handleDownloadAttachment(attachment.idAttachment);
  };

  const openAttachmentsModal = (
    row: UploadTableRow | null,
    selected: number[] | null
  ): void => {
    setModalContent(
      <AttachmentsModal
        cancel={closeModal}
        removeAttachment={(idAttachment: number) =>
          openConfirmModal(
            t("delete_attachment"),
            t("are_you_sure"),
            () =>
              row &&
              row.id &&
              typeof row.id === "number" &&
              handleRemoveAttachment(row.id, idAttachment),
            true
          )
        }
        attachments={row && row.attachments?.length > 0 ? row.attachments : []}
        upload={(attachment: File) =>
          handleUploadAttachment(
            AttachmentType.NOTE,
            attachment,
            selected || [row?.id as number]
          )
        }
        handleDownloadAttachment={handleDownloadAttachment}
        update={async (note: string) => {
          await handleUpdateUploads(selected || [row?.id as number], { note });
        }}
        note={row?.note || ""}
      />
    );
  };

  const updateUploadSettings = async (
    uploads: UploadTableRow[],
    uploadSettings: UploadSettings
  ): Promise<void> => {
    try {
      const uploadIds = uploads.map((u) => Number(u.id));
      const settings = await uploadService.updateUploadSettings(
        uploadIds,
        uploadSettings
      );

      updateUploads(uploadIds, { settings });
    } catch (err) {
      toastError(t("upload_update_failed"));
    }
  };

  const handleUploadSettingUploadFile = async (
    idUpload: number | string,
    file: File
  ): Promise<MulterFileInfo | null> => {
    try {
      const uploadedFile = await uploadService.uploadSettingsFile(
        idUpload,
        file
      );

      return uploadedFile;
    } catch (err) {
      toastError(t("upload_update_failed"));
      return null;
    }
  };

  const openUploadSettingsModal = (
    uploads: UploadTableRow[],
    isMultiple = false
  ): void => {
    setModalContent(
      <UploadSettingsModal
        settings={uploads[0]?.settings}
        cancel={closeModal}
        save={async (uploadSettings: UploadSettings) => {
          await updateUploadSettings(uploads, uploadSettings);
          closeModal();
        }}
        uploadFile={(file) =>
          handleUploadSettingUploadFile(uploads[0].id, file)
        }
        isMultiple={isMultiple}
        title={t("advanced_settings")}
      />
    );
  };

  const openJobCreationModal = (): void => {
    setModalContent(
      <RedirectModal
        closeModal={closeModal}
        path={""}
        title={t("job_creation_successfully")}
        text={t("jobs_can_be_found_in_jobs_page")}
      />
    );
  };

  const onPopoverOpenStateChangeHandler = (isOpen: boolean) => {
    if (isOpen) {
      setPaddingBottom(380);
    } else {
      setPaddingBottom(0);
    }
  };

  const submitUploads = async (uploadIds: number[]) => {
    try {
      setSubmitingDownloads({ ids: uploadIds, isLoading: true });
      await jobService.create(uploadIds);
      await getUploads();
      toastSuccess(t("job_submit_success"));
      setSelected([]);
      openJobCreationModal();
    } catch (err) {
      toastError(t("job_submit_fail"));
    } finally {
      setSubmitingDownloads({ ids: uploadIds, isLoading: false });
    }
  };

  const getValidationsByClientAndJobTypes = useCallback(
    (row?: UploadTableRow) => {
      return uploadService.getValidationsByClientAndJobTypes(
        selectedUploads,
        selectedClient,
        row
      );
    },
    [selectedClient, selectedUploads]
  );

  const headCells: HeadCell<any>[] = [
    {
      id: "name",
      label: t("name"),
      formatter: (r: UploadTableRow) => {
        return (
          <div className="firstCell">
            <div className={!r.name ? "lightGray" : ""}>
              <PopoverAction
                popoverClassName="tablePopover"
                trigger={
                  <p className="ellipsis">
                    {r.name ? r.name : t("not_selected")}
                  </p>
                }
                renderContent={() => {
                  return (
                    <div onClick={(e) => e.stopPropagation()}>
                      <TextInputPopover
                        title={`${t("insert_name")}:`}
                        value={r.name || ""}
                        onBlur={(name: string) => {
                          if (_.isEmpty(name)) return;
                          handleUpdateUploads([r.id as number], {
                            name,
                          });
                        }}
                      />
                    </div>
                  );
                }}
                isLoading={loadingRows[r.id]}
              />
            </div>
            <div className="lightGray">
              <div className="status">
                {r.status === UploadStatus.pending && (
                  <span className={"pendingUpload"}>
                    {t("upload_status_pending")}
                  </span>
                )}
                {r.status === UploadStatus.uploading && (
                  <div className="uploadingStatus">
                    <span className="percentage">
                      {`${progress[r.id]?.progress}%`}
                    </span>
                    <span className="sum">
                      {/* {`(${prettyBytes(
                        progress[r.id]?.loaded
                      )} of ${prettyBytes(progress[r.id].total)})`} */}
                      "a"
                    </span>
                  </div>
                )}
                {r.status === UploadStatus.error && (
                  <span className={"uploadFailed"}>
                    {t("upload_status_failed")}
                  </span>
                )}
                {r.status === UploadStatus.success
                  ? `${secondsToTimeString(r.duration || 0)}`
                  : r.status === UploadStatus.convert
                  ? t("converting")
                  : ""}
                {r.status === UploadStatus.convertFail && (
                  <span className={"uploadFailed"}>
                    {t("upload_convert_failed")}
                  </span>
                )}
                {r.status === UploadStatus.mergeFail && (
                  <span className={"uploadFailed"}>
                    {t("upload_merge_failed")}
                  </span>
                )}
              </div>
              {r.media && r.media.length > 1 && (
                <div className="media" onClick={() => openUnmergeModal(r)}>
                  {`(${r.media?.length} ${t("media")})`}
                </div>
              )}
            </div>
          </div>
        );
      },
      barColActions: [
        {
          name: "layers",
          icon: faLayerGroup,
          action: openMergeUploadsModal,
          disabled: !isMergeAllowed,
        },
        {
          name: "paperClip",
          icon: faNotes,
          action: () => openAttachmentsModal(null, selected),
          disabled: selected.length < 2,
        },
        {
          name: "tag",
          icon: faTag,
          action: () => openTagsModal(selected, []),
          disabled: selected.length < 2,
        },
        {
          name: "settings",
          icon: faGear,
          action: () =>
            openUploadSettingsModal(
              uploadRows.filter((u) => selected.includes(u.id as number)),
              true
            ),
          disabled: selected.length < 2,
        },
        {
          name: "trash",
          icon: faTrash,
          action: () =>
            openConfirmModal(
              t("delete_uploads"),
              t("upload_delete_message"),
              handleDeleteUploads
            ),
          disabled: selected.length < 2,
        },
      ],
    },
    {
      id: "projectName",
      label: t("project"),
      barColAction: {
        trigger: <>{t("select_for_all")}</>,
        renderContent: renderProjectNameColAction,
      },
      style: {
        width: 150,
        maxWidth: 150,
      },
      formatter: (r: UploadTableRow) => {
        return (
          <div className={!r.project?.idProject ? "lightGray" : ""}>
            <PopoverAction
              popoverClassName="tablePopover"
              trigger={
                <p className="ellipsis">
                  {r.project ? r.project.name : t("not_selected")}
                </p>
              }
              renderContent={() => {
                return (
                  <div onClick={(e) => e.stopPropagation()}>
                    <MultiSelectPopover
                      title={`insert_project_name`}
                      update={(_uploadIds, projectIds) =>
                        handleProjectChange([r.id as number], projectIds)
                      }
                      selected={
                        r.project?.idProject ? [r.project?.idProject] : []
                      }
                      options={projectOptions}
                      addNewOption={{ onApprove: handleProjectCreate }}
                    />
                  </div>
                );
              }}
              isLoading={loadingRows[r.id]}
            />
          </div>
        );
      },
    },
    {
      id: "jobType",
      label: t("type"),
      sortProperty: "jobType.typeName",
      barColAction: {
        trigger: <>{t("select_for_all")}</>,
        renderContent: () => {
          const validations = getValidationsByClientAndJobTypes();
          return (
            <div onClick={(e) => e.stopPropagation()}>
              <JobTypeSelectPopover
                validations={validations}
                rows={selectedUploads}
                update={updateUploadsJobType}
                uploadAttachment={handleUploadAttachment}
                removeAttachment={handleRemoveAttachment}
                allowAutoTranscript={true}
                shouTranscriptionTypeRadio={true}
                isLoading={
                  !_.isEmpty(selectedUploads) &&
                  loadingRows[selectedUploads[0].idUpload]
                }
                featureFlags={featureFlags}
                toast={{
                  success: toastSuccess,
                  error: toastError,
                  info: toastInfo,
                }}
              />
            </div>
          );
        },
      },
      style: {
        width: 180,
        maxWidth: 180,
      },
      formatter: (r: UploadTableRow) => {
        return (
          <div className={!r.jobType ? "lightGray" : ""}>
            <PopoverAction
              onPopoverOpenStateChange={onPopoverOpenStateChangeHandler}
              popoverClassName="tablePopover"
              trigger={
                <p>
                  {r.jobType?.typeName
                    ? `${t(r.jobType.typeName)}${
                        r.jobType.autoTranscript ? " (Auto)" : ""
                      }`
                    : t("not_selected")}
                </p>
              }
              renderContent={() => {
                const validations = getValidationsByClientAndJobTypes(r);
                return (
                  <div onClick={(e) => e.stopPropagation()}>
                    <JobTypeSelectPopover
                      validations={validations}
                      rows={[{ ...r, idUpload: r.id }]}
                      update={updateUploadsJobType}
                      uploadAttachment={handleUploadAttachment}
                      uploadSourceFile={uploadService.uploadSourceFile}
                      removeSourceFile={uploadService.removeSourceFile}
                      allowAutoTranscript={true}
                      shouTranscriptionTypeRadio={true}
                      removeAttachment={handleRemoveAttachment}
                      isLoading={loadingRows[r.id]}
                      featureFlags={featureFlags}
                      toast={{
                        success: toastSuccess,
                        error: toastError,
                        info: toastInfo,
                      }}
                    />
                  </div>
                );
              }}
              isLoading={loadingRows[r.id]}
            />
          </div>
        );
      },
    },
    {
      id: "language",
      label: t("language"),
      sortProperty: "language.input[0]",
      barColAction: {
        trigger: <>{t("select_for_all")}</>,
        renderContent: () => {
          return (
            <div onClick={(e) => e.stopPropagation()}>
              <LanguageSelectPopover
                selected={selected}
                update={handleUpdateUploads}
              />
            </div>
          );
        },
      },
      style: {
        width: 160,
        maxWidth: 160,
      },
      formatter: (r: UploadTableRow) => {
        const langStrings = formatLanguageString(r.language, t);
        return (
          <div className={r.language.input.length === 0 ? "lightGray" : ""}>
            <PopoverAction
              onPopoverOpenStateChange={onPopoverOpenStateChangeHandler}
              popoverClassName="tablePopover"
              trigger={
                r.language.input && r.language.input.length > 0 ? (
                  <Tooltip title={langStrings.longString}>
                    <p>{langStrings.shortString}</p>
                  </Tooltip>
                ) : (
                  <p>{t("not_selected")}</p>
                )
              }
              renderContent={() => {
                return (
                  <div onClick={(e) => e.stopPropagation()}>
                    <LanguageSelectPopover
                      language={r.language}
                      selected={[r.id as number]}
                      update={handleUpdateUploads}
                    />
                  </div>
                );
              }}
              isLoading={loadingRows[r.id]}
            />
          </div>
        );
      },
    },
    {
      id: "delivery",
      label: t("delivery"),
      barColAction: {
        trigger: <>{t("select_for_all")}</>,
        renderContent: () => {
          return (
            <div onClick={(e) => e.stopPropagation()}>
              <DatePickerPopover
                selected={selected}
                update={handleUpdateUploads}
                min={uploadService.DELIVERY_MIN_DATE}
                defaultDate={uploadService.DELIVERY_MIN_DATE}
              />
            </div>
          );
        },
      },
      formatter: (r: UploadTableRow) => {
        return r.jobType?.autoTranscript ? (
          <div className="autoSelectedDelivery lightGray">
            {r.delivery
              ? format(new Date(r.delivery), DATE_FORMAT_1)
              : t("not_selected")}
          </div>
        ) : (
          <div className={!r.delivery ? "lightGray" : ""}>
            <PopoverAction
              popoverClassName="tablePopover"
              trigger={
                <p>
                  {r.delivery
                    ? format(new Date(r.delivery), DATE_FORMAT_1)
                    : t("not_selected")}
                </p>
              }
              renderContent={() => {
                return (
                  <div onClick={(e) => e.stopPropagation()}>
                    <DatePickerPopover
                      row={r}
                      selected={[r.id as number]}
                      update={handleUpdateUploads}
                      min={uploadService.DELIVERY_MIN_DATE}
                      defaultDate={uploadService.DELIVERY_MIN_DATE}
                    />
                  </div>
                );
              }}
              isLoading={loadingRows[r.id]}
            />
          </div>
        );
      },
    },
    {
      id: "actions",
      sortable: false,
      label: "",
      style: {
        width: 140,
        maxWidth: 140,
      },
      formatter: (row: UploadTableRow) => {
        return (
          <TableIconActions
            row={row}
            onRowActions={[
              // {
              //   name: 'cut',
              //   icon: faScissors,
              //   action: console.log,
              // },
              {
                name: "paperClip",
                icon: faNotes,
                action: () => openAttachmentsModal(row, null),
              },
              {
                name: "tag",
                icon: faTag,
                action: () => openTagsModal([row.id as number], row.tags),
              },
              {
                name: "settings",
                icon: faGear,
                action: () => openUploadSettingsModal([row]),
                indicator: !_.isEmpty(row?.settings?.aaf)
                  ? { tooltip: t("aaf_linked") }
                  : null,
              },
              {
                name: "trash",
                icon: faTrash,
                action: () =>
                  openConfirmModal(
                    t("delete_upload"),
                    t("upload_delete_message"),
                    () =>
                      deleteUploads(
                        row && typeof row.id === "number" ? [row.id] : []
                      )
                  ),
              },
            ]}
          />
        );
      },
    },
    {
      id: "submit",
      sortable: false,
      label: "",
      style: {
        width: 120,
        maxWidth: 120,
      },
      formatter: (row: UploadTableRow) => {
        return (
          <Button
            disabled={disableSubmit || !isUploadSubmittable(row)}
            onClick={() =>
              typeof row.id === "number" && submitUploads([row.id])
            }
            color="primary"
            loading={submittingUploads[row.id]}
          >
            {t("submit")}
          </Button>
        );
      },
      barColAction: {
        trigger: <>{t("submit_all")}</>,
        action: () => submitUploads(selected),
        disabled: disableSubmit || !isSelectedUploadsSubmittable(),
      },
    },
  ];
  return (
    <Box className="uploadFilesTableWrapper" style={{ paddingBottom }}>
      <GeneralTable
        headCells={headCells}
        rows={uploadRows}
        selected={selected}
        setSelected={setSelected}
      />
    </Box>
  );
};

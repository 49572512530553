import { Languages } from "../constants/languages";
import { JobTypes } from "../entities/JobTypes";

export class STTConfigPage {
  sttModelId = "multilang_gen";
  splitSubtitles = false;
  priority?: number = SttPriority.DEFAULT;
  express?: boolean = false;
  constructor(args?: Partial<STTConfigPage>) {
    if (args && typeof args !== "undefined") {
      Object.assign(this, args);
    }
  }
}

export type SttModelProps = {
  sttModelId: string;
  splitSubtitles?: boolean;
  priority?: number;
  express?: boolean;
};

export enum SttPriority {
  DEFAULT = 0,
  LOW = 1,
  NORMAL = 2,
  HIGH = 3,
  VERY_HIGH = 4,
  URGENT = 5,
}

export type STTModel = Record<Languages, SttModelProps>;

export type ClientSTT = Partial<Record<keyof JobTypes, STTModel>>;

const defaultSTTModel: STTModel = {
  "ar-IL": {
    sttModelId: "multilang_gen",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
  "en-US": {
    sttModelId: "multilang_gen",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
  "es-ES": {
    sttModelId: "multilang_gen",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
  "fr-FR": {
    sttModelId: "multilang_gen",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
  "he-IL": {
    sttModelId: "he_gen_v2",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
  "hi-IN": {
    sttModelId: "multilang_gen",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
  "pt-BR": {
    sttModelId: "multilang_gen",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
  "ru-RU": {
    sttModelId: "multilang_gen",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
  "de-DE": {
    sttModelId: "multilang_gen",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
  "pt-PT": {
    sttModelId: "multilang_gen",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
  "zh-CN": {
    sttModelId: "multilang_gen",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
  "cs-CZ": {
    sttModelId: "multilang_gen",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
  "nl-NL": {
    sttModelId: "multilang_gen",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
  "fi-FI": {
    sttModelId: "multilang_gen",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
  "id-ID": {
    sttModelId: "multilang_gen",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
  "it-IT": {
    sttModelId: "multilang_gen",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
  "ja-JP": {
    sttModelId: "multilang_gen",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
  "ko-KR": {
    sttModelId: "multilang_gen",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
  "ms-MY": {
    sttModelId: "multilang_gen",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
  "no-NO": {
    sttModelId: "multilang_gen",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
  "pl-PL": {
    sttModelId: "multilang_gen",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
  "es-419": {
    sttModelId: "multilang_gen",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
  "sv-SE": {
    sttModelId: "multilang_gen",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
  "tr-TR": {
    sttModelId: "multilang_gen",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
};

export const defaultClientSttSettings: ClientSTT = {
  protocol: defaultSTTModel,
  interview: defaultSTTModel,
  subtitles: defaultSTTModel,
  brief: defaultSTTModel,
};

export const sttPriorityOptions = [
  SttPriority.URGENT,
  SttPriority.VERY_HIGH,
  SttPriority.HIGH,
  SttPriority.NORMAL,
  SttPriority.LOW,
  SttPriority.DEFAULT,
];

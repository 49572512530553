import React, { FC, ReactElement, useEffect, useState } from "react";
import _ from "lodash";

import { EditorAction } from "../../types/EditorAction";
import { Button, Tooltip } from "@sumit-platforms/ui-bazar";

// import FeatureFlagsService from "../../services/FeatureFlagsService";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { JobWithData } from "@sumit-platforms/types";
import { mainYellow } from "@sumit-platforms/ui-bazar/constants";

import "./JobActionPanel.scss";

interface Props {
  getJobData: () => JobWithData;
  actions: EditorAction[];
  children?: ReactElement;
  editorDirection?: "rtl" | "ltr";
  disabled?: boolean;
}

const JobActionPanel: FC<Props> = ({
  getJobData,
  actions,
  // user,
  children,
  editorDirection,
  disabled,
}): JSX.Element => {
  const [allowedActions, setAllowedActions] = useState<EditorAction[]>([]);

  useEffect(() => {
    setAllowedActions(actions.filter((a) => isActionAllowed(a)));
  }, [actions]);

  const isActionAllowed = (action: EditorAction) => {
    return true;
    // const isRoleAllowed = action.roles
    //   ? action.roles.includes(user.role)
    //   : true;
    // const isFFallowed = action.featureFlag
    //   ? FeatureFlagsService.isEnabled(action.featureFlag, user)
    //   : true;
    // return isRoleAllowed && isFFallowed;
  };

  const handleActionClick = (action: EditorAction) => {
    if (disabled || action.disabled) return;
    const updatedJob = getJobData();
    action.onClick(updatedJob);
  };

  const getNestedChildren = (children: EditorAction) => {
    const element = (
      <>
        <div
          className="actionButton children"
          onClick={() => handleActionClick(children)}
          key={children.key}
        >
          <div className="actionLabel">{children.label}</div>
          <div className="actionIcon">
            <FontAwesomeIcon
              icon={children.icon}
              style={{
                color: children.selected ? mainYellow : children.color,
              }}
            />
          </div>
        </div>
      </>
    );
    return children.tooltipText ? (
      <Tooltip title={children.tooltipText}>
        <span>{element}</span>
      </Tooltip>
    ) : (
      element
    );
  };

  return (
    <div className="JobActionPanel">
      {_.map(
        allowedActions.filter((a) => !a.hide),
        (action, ix) =>
          action.fullButton ? (
            <Button
              className="providerBtn"
              variant="outlined"
              color={"inherit"}
              endIcon={action.icon && <FontAwesomeIcon icon={action.icon} />}
              onClick={() => handleActionClick(action)}
              disabled={disabled}
              key={ix}
            >
              {action.label}
            </Button>
          ) : (
            <div
              className={classNames("action", action.className, {
                disabled: disabled || action.disabled,
              })}
              key={action.key}
            >
              {action.children
                ? action.children.map((children) => {
                    return getNestedChildren(children);
                  })
                : null}
              <div
                className="actionButton"
                onClick={() => handleActionClick(action)}
              >
                <div className="actionLabel">{action.label}</div>
                <div className="actionIcon">
                  <FontAwesomeIcon
                    icon={action.icon}
                    style={{
                      color: action.selected ? mainYellow : action.color,
                    }}
                  />
                </div>
              </div>
            </div>
          )
      )}
      {children}
    </div>
  );
};

export default JobActionPanel;

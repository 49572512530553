import React, { FC, useCallback, useState } from "react";
import { useSlateStatic } from "@sumit-platforms/slate-react";
import stc from "string-to-color";
import { Job, JobSpeaker } from "@sumit-platforms/types";
import { CustomEditor } from "../../types";
import EditorService from "../../services/EditorService";
import LegacySpeakerNameEditor from "../SpeakerNameEditor/LegacySpeakerNameEditor";
import { useRecoilState } from "recoil";
import { speakersState } from "../../store/states";

import "./JobSpeakers.scss";

interface Props {
  disabled?: boolean;
  job: Job;
  editorRef?: HTMLElement | null;
}

const JobSpeakersPanel: FC<Props> = ({ disabled, job, editorRef }) => {
  const editor = useSlateStatic() as CustomEditor;

  const [speakers, setSpeakers] = useRecoilState(speakersState);

  const updateSpeakers = useCallback(() => {
    const speakers = EditorService.getSpeakers(editor);
    setSpeakers(speakers);
  }, [editor, setSpeakers]);

  const renameSpeaker = useCallback(
    (oldSpeaker: string, newSpeaker: string) => {
      EditorService.renameSpeaker({
        editor,
        oldSpeaker,
        newSpeaker,
      });
      updateSpeakers();
    },
    [editor, updateSpeakers]
  );

  // const renameSpeaker = (
  //   oldSpeaker: string,
  //   newSpeaker: string,
  //   allSpeakers: JobSpeaker[],
  //   mergeSpeakerRanges = false
  // ) => {
  //   const scrollTopBeforeChange = editorRef?.scrollTop;
  //   const updatedRanges: SpeakerRange[] = [];
  //   const ranges = EditorService.formatEditorValueToJobData(
  //     editor.children as CustomElement[],
  //     "transcript"
  //   ) as SpeakerRange[];
  //   let shouldMergeRange = false;
  //   for (const [i, range] of ranges.entries()) {
  //     //TODO: this loop is created for merging ranges if same speaker is detected
  //     // i think this merging is not work, leading to expensive re editor render and unwanted scrolling behavior
  //     // consider to re-factor this whole function if things are starting to get messy
  //     const speakerName = range.speakerName;
  //     const updatedRange = { ...range };
  //     if (
  //       speakerName?.trim() &&
  //       [oldSpeaker, newSpeaker].includes(speakerName.trim())
  //     ) {
  //       const lastRange = _.last(updatedRanges);
  //       if (mergeSpeakerRanges && shouldMergeRange && lastRange) {
  //         lastRange.words = [...lastRange.words, ...range.words];
  //         lastRange.et = range.et;
  //         continue;
  //       } else {
  //         updatedRange.speakerName = newSpeaker.trim();
  //         if (_.isEmpty(range.annotations)) {
  //           shouldMergeRange = true;
  //         } else {
  //           shouldMergeRange = false;
  //         }
  //       }
  //     } else {
  //       shouldMergeRange = false;
  //     }
  //     updatedRanges.push(updatedRange);
  //   }
  //
  //   const newSpeakers = speakers.filter(
  //     (s: any) => ![oldSpeaker, newSpeaker.trim()].includes(s.name)
  //   );
  //
  //   newSpeakers.push({ name: newSpeaker.trim() });
  //   const updatedJob: Job = {
  //     ...job,
  //     data: {
  //       ...job.data,
  //       ranges: updatedRanges,
  //       speakers,
  //     } as JobData,
  //     tcOffsets: job.tcOffsets,
  //   };
  //   const newSlateState = EditorService.formatJobDataToEditorValue(
  //     updatedJob,
  //     "transcript"
  //   );
  //
  //   EditorService.expensivelyResetEditorState(editor, newSlateState);
  //   setSpeakers(newSpeakers);
  //   if (_.isNumber(scrollTopBeforeChange) && editorRef) {
  //     setTimeout(() => editorRef?.scrollTo({ top: scrollTopBeforeChange }), 0);
  //   }
  // };

  const handleSetNewSpeakerName = useCallback(
    ({
      newSpeaker,
      oldSpeaker,
    }: {
      newSpeaker: JobSpeaker;
      oldSpeaker: JobSpeaker;
    }) => {
      //legacy: renameSpeaker(oldSpeaker.name, newSpeaker.name, speakers, false);
      renameSpeaker(oldSpeaker.name, newSpeaker.name);
    },
    [renameSpeaker]
  );

  return (
    <div className="JobSpeakers">
      <div className="speakersContainer">
        {speakers?.map((speaker, i) => (
          <span
            className="speaker"
            style={{
              color: stc(speaker),
            }}
            key={i}
          >
            <LegacySpeakerNameEditor
              speakerName={speaker.name}
              autocomplete={false}
              handleSetNewSpeakerName={(newSpeaker) =>
                handleSetNewSpeakerName({ newSpeaker, oldSpeaker: speaker })
              }
              placeholder={speaker.name}
              isEditing={false}
              isDisabled={disabled}
              speakers={speakers}
            />
          </span>
        ))}
      </div>
    </div>
  );
};

export default JobSpeakersPanel;

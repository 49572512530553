//Default scheme is at "default-shortcuts.ts"

import { Job } from "../entities/Job";
import { JobTypes } from "../entities/JobTypes";
import { EditorMode, EditorType } from "../Job/JobData";

enum ShortcutType {
  PLAYER = "PLAYER",
  EDITING = "EDITING",
  TIMING = "TIMING",
  NAVIGATION = "NAVIGATION",
  GLOBAL = "GLOBAL",
}

enum ShortcutAction {
  CREATE_NEW_ANNOTATION = "CREATE_NEW_ANNOTATION",
  BREAK_RANGE = "BREAK_RANGE",
  GLITCH_TEXT_TO_NEXT_RANGE = "GLITCH_TEXT_TO_NEXT_RANGE",
  BREAK_TO_NEW_RANGE = "BREAK_TO_NEW_RANGE",
  BREAK_RANGE_LINE = "BREAK_RANGE_LINE",
  ANNOTATION_FROM_ANNOTATION = "ANNOTATION_FROM_ANNOTATION",
  CURSOR_RIGHT_LEFT = "CURSOR_RIGHT_LEFT",
  DELETE_ENTIRE_RANGE = "DELETE_ENTIRE_RANGE",
  UNDO = "UNDO",
  REDO = "REDO",
  JUMP_TO_WORD = "JUMP_TO_WORD",
  JUMP_TO_NEXT_RANGE = "JUMP_TO_NEXT_RANGE",
  JUMP_TO_PREV_RANGE = "JUMP_TO_PREV_RANGE",
  MERGE_RANGE = "MERGE_RANGE",
  MERGE_WITH_NEXT_RANGE = "MERGE_WITH_NEXT_RANGE",
  PLAY_BACKWARD = "PLAY_BACKWARD",
  PLAY_FORWARD = "PLAY_FORWARD",
  SKIP_TO_NEXT_SUBTITLE = "SKIP_TO_NEXT_SUBTITLE",
  SKIP_TO_PREV_SUBTITLE = "SKIP_TO_PREV_SUBTITLE",
  REPEAT = "REPEAT",
  PLAY_PAUSE = "PLAY_PAUSE",
  PREVENT_CUT = "PREVENT_CUT",
  SAVE_JOB = "SAVE_JOB",
  MARK_CURRENT_WORD = "MARK_CURRENT_WORD",
  LOCK_MODE = "LOCK_MODE",
  FOCUS_RANGE_NAVIGATOR = "FOCUS_RANGE_NAVIGATOR",
  OPEN_SPEAKERS = "OPEN_SPEAKERS",
  FIND_AND_REPLACE = "FIND_AND_REPLACE",
  REVEAL_TRANSLATION = "REVEAL_TRANSLATION",
  SHOW_PLAYER_CONTROLS = "SHOW_PLAYER_CONTROLS",
  HIDE_PLAYER_CONTROLS = "HIDE_PLAYER_CONTROLS",
  TOGGLE_PLAY = "TOGGLE_PLAY",
  OPEN_KEYBOARD_SHORTCUTS_MODAL = "OPEN_KEYBOARD_SHORTCUTS_MODAL",
  ADD_START_TIME_FRAME = "ADD_START_TIME_FRAME",
  SUB_START_TIME_FRAME = "SUB_START_TIME_FRAME",
  ADD_END_TIME_FRAME = "ADD_END_TIME_FRAME",
  SUB_END_TIME_RANGE = "SUB_END_TIME_RANGE",
  CLOSE_MODAL = "CLOSE_MODAL",
}

interface Shortcut {
  action: ShortcutAction;
  keys: string[]; // represent combination. e.x ["Control+s", "Meta+s"]
  type?: ShortcutType;
  event?: "keydown" | "keyup";
  editable?: boolean;
  editorModes?: EditorMode[]; // specific editor type shortcut
  editorTypes: EditorType[];
  hide?: boolean;
  disabled?: boolean;
}

type ShortcutHandler = Partial<
  Record<ShortcutAction, (event: React.KeyboardEvent) => void>
>;

export type { Shortcut, ShortcutHandler };
export { ShortcutType, ShortcutAction };

import { TFunction } from "i18next";
import {
  JobSettingsPage,
  jobTypes,
  languages,
  Platform,
} from "@sumit-platforms/types";
import { PageSettings } from "./../../../core/SettingsPage/SettingsPage";
import { ProjectSettingsPagesMetadata } from "../../useProjectSettings";

export const getJobSettingsPageInputs: (
  args: JobSettingsPage,
  t: TFunction,
  metadata?: ProjectSettingsPagesMetadata,
  platform?: Platform
) => PageSettings<{ jobSettings: JobSettingsPage }> = (
  args,
  t,
  metadata,
  platform
) => {
  const jobTypeOptions = jobTypes.map((jobType) => ({
    label: t(jobType),
    value: jobType,
  }));

  return {
    settings: { jobSettings: args },
    pageTab: t("settings_project_job_settings"),
    tabLabel:
      platform === Platform.OPERA ? t("settings_project_defaults") : undefined,
    sections: [
      {
        title: t("settings_project_job_settings_title"),
        paragraph: t("settings_project_job_settings_paragraph_1"),
        isColumn: true,
        inputs: [
          {
            label: t("settings_project_job_job_type"),
            type: "select",
            key: "jobSettings.jobType",
            options: jobTypeOptions,
            isColumn: true,
            style: { width: 200 },
          },
        ],
      },
      {
        inputs: [
          {
            label: t("settings_project_job_source_language"),
            type: "multi-select",
            key: "jobSettings.sourceLanguage",
            options: languages,
            isColumn: true,
            style: { width: 200 },
          },
        ],
      },
      {
        inputs: [
          {
            label: t("settings_project_job_target_language"),
            type: "multi-select",
            key: "jobSettings.targetLanguage",
            options: languages,
            isColumn: true,
            style: { width: 200 },
            isMulti: true,
          },
        ],
      },
      {
        inputs: [
          {
            label: t("settings_project_job_delivery_time"),
            type: "number",
            min: 1,
            key: "jobSettings.deliveryTimeInDays",
            isColumn: true,
            style: { width: 200 },
          },
        ],
      },
      {
        inputs: [
          {
            label: t("settings_project_job_community_member_deadline"),
            type: "number",
            min: 1,
            key: "jobSettings.communityMemberDeadlineInDays",
            isColumn: true,
            style: { width: 200 },
            hide: platform === Platform.MUST,
          },
        ],
      },
      {
        isColumn: true,
        inputs: [
          {
            label: t("settings_project_job_auto"),
            type: "switch",
            key: "jobSettings.auto",
            style: { width: 200 },
          },
          {
            label: t("settings_project_job_burn"),
            type: "switch",
            key: "jobSettings.burn",
            style: { width: 200 },
          },
        ],
      },
      {
        inputs: [
          {
            label: t("settings_project_job_validation"),
            type: "select",
            options: metadata?.validationOptions || [],
            key: "jobSettings.idValidation",
            isColumn: true,
            style: { width: 200 },
          },
        ],
      },
    ],
  };
};

import React, {
  forwardRef,
  KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  useSlate,
} from "@sumit-platforms/slate-react";
import EditorService from "./services/EditorService";
import {
  CustomEditor,
  EditorAction,
  MarkWordMetadata,
  SubtitleRangeElement,
} from "./types";
import { useKeyboardShortcuts } from "@sumit-platforms/ui-bazar/hooks";
import {
  directionState,
  featureFlagsState,
  isDisabledState,
  isJobScriptOpenState,
  isValidationsOpenState,
  repeatState,
  focusRangeNavigatorInputState,
  currentTimeState,
  isFindAndReplaceOpenState,
} from "./store/states";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { ContentScrollProps, EditorForwardedRef } from "./SlateEditor";
import { ActionElement } from "./components/ActionElement/ActionElement";
import _ from "lodash";
import MediaService from "./services/MediaService";
import { FindAndReplace } from "./components/FindAndReplace/FindAndReplace";
import { JobScript } from "./components/JobScript/JobScript";
import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons";
import { JobValidationsExplorer } from "./components/JobValidations/JobValidationsExplorer";

import "./SubtitlesEditor.scss";

interface TranscriptEditorProps {
  className?: string;
  renderElement: (props: RenderElementProps) => JSX.Element;
  renderLeaf: (props: RenderLeafProps) => JSX.Element;
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  handleBlur: (rangeIndex?: number) => void;
  addActions: (actions: EditorAction[]) => void;
  contentScroll: ContentScrollProps;
}

const SubtitlesEditor = (
  {
    renderElement,
    renderLeaf,
    onKeyDown,
    className,
    handleBlur,
    contentScroll,
    addActions,
  }: TranscriptEditorProps,
  ref: React.Ref<EditorForwardedRef>
) => {
  const editorController = useSlate() as CustomEditor;
  const featureFlags = useRecoilValue(featureFlagsState);
  const [isJobScriptOpen, setIsJobScriptOpen] =
    useRecoilState(isJobScriptOpenState);
  const rangeNavInput = useRecoilValue(focusRangeNavigatorInputState);
  const disabled = useRecoilValue(isDisabledState);
  const setRepeat = useSetRecoilState(repeatState);
  const setTime = useSetRecoilState(currentTimeState);
  const [isValidationOpen, setIsValidationOpen] = useRecoilState(
    isValidationsOpenState
  );
  const [isFindAndReplaceOpen, setIsFindAndReplaceOpen] = useRecoilState(
    isFindAndReplaceOpenState
  );

  const editorRef = useRef<HTMLElement | null>(null);

  const resetFindAndReplaceMarks = useCallback(() => {
    EditorService.removeMarks({
      editor: editorController,
      markKey: "highlightGreen",
    });
  }, [editorController]);

  const afterFindAndReplaceDecoration = useCallback(
    (search: string, marks: MarkWordMetadata[] | null) => {
      resetFindAndReplaceMarks();
      EditorService.addMarks({
        editor: editorController,
        marks,
        markKey: "highlightGreen",
        search,
      });
    },
    [editorController, resetFindAndReplaceMarks]
  );

  const onReplaceOne = useCallback(
    (
      markedWord: MarkWordMetadata | null,
      newText: string,
      marks: MarkWordMetadata[]
    ) => {
      EditorService.replaceOne({
        editor: editorController,
        markedWord,
        newText,
        shouldUpdateRangeWords: false,
        mode: "subtitles",
        marks,
      });
    },

    [editorController]
  );

  useImperativeHandle(
    ref,
    () => ({
      editorRef: editorRef.current,
    }),
    [editorRef]
  );

  const addValidationsEditorAction = () => {
    addActions([
      {
        key: "validations",
        label: "validations",
        icon: faCircleExclamation,
        onClick: handleValidationClick,
        selected: isValidationOpen,
      },
    ]);
  };
  const addEditorActions = useCallback(() => {
    addValidationsEditorAction();
  }, [addValidationsEditorAction]);

  useLayoutEffect(() => {
    addEditorActions();
  }, [isJobScriptOpen, isValidationOpen]);

  useEffect(() => {
    if (!editorController) return;
    try {
      const _editorRef = ReactEditor.toDOMNode(
        editorController,
        editorController
      );
      editorRef.current = _editorRef;

      if (_editorRef && _editorRef.scrollTop > 0) {
        _editorRef.scrollTo(0, 0);
      }
    } catch (e) {
      console.error("e :", e);
    }
  }, [editorController]);

  const handleValidationClick = useCallback(() => {
    const updatedValidationOpen = !isValidationOpen;
    setIsValidationOpen(updatedValidationOpen);
    if (updatedValidationOpen) {
      if (isJobScriptOpen) {
        setIsJobScriptOpen(false);
      }
      if (isFindAndReplaceOpen) {
        setIsFindAndReplaceOpen(false);
      }
    }
  }, [
    isValidationOpen,
    setIsValidationOpen,
    isJobScriptOpen,
    setIsJobScriptOpen,
    isFindAndReplaceOpen,
    setIsFindAndReplaceOpen,
  ]);

  const handleJumpToNextRangeKeystroke = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      EditorService.focusToNextOrPrevSubtitle(editorController, "next");
    },

    [editorController]
  );

  const handleJumpToPrevRangeKeystroke = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      EditorService.focusToNextOrPrevSubtitle(editorController, "prev");
    },
    [editorController]
  );

  const onBlur = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      const lastCursoredElement =
        EditorService.getLastCursoredElement(editorController);
      if (!lastCursoredElement?.element) return;
      const isChanged = EditorService.isRangeChanged({
        element: lastCursoredElement.element as SubtitleRangeElement,
      });
      if (!isChanged) return;
      handleBlur(lastCursoredElement.path);
    },
    [editorController, handleBlur]
  );

  const handleGlitchTextToNextRange = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      const entry = EditorService.getLastCursoredElement(editorController);
      if (!_.isNumber(entry?.path)) return;
      EditorService.glitchTextToNextRange(editorController, entry);
    },
    [editorController, handleBlur]
  );

  const handleAddFrames = useCallback(
    _.throttle((framesToAdd: number, position: "start" | "end") => {
      const rangeIndex =
        editorController.selection?.anchor?.path[0] ||
        editorController.playingRangeIndex;
      if (editorController?.isBetweenRanges || !_.isNumber(rangeIndex)) {
        return;
      }

      EditorService.handleAddFrames({
        editor: editorController,
        framesToAdd,
        position,
        rangeIndex,
        element: editorController.children[rangeIndex] as SubtitleRangeElement,
        disabled,
      });
    }, 150),
    [editorController, disabled]
  );

  const handleAddFramesKeystroke = useCallback(
    (e: KeyboardEvent, framesToAdd: number, position: "start" | "end") => {
      e.preventDefault();
      e.stopPropagation();
      handleAddFrames(framesToAdd, position);
    },
    [handleAddFrames]
  );

  const handleFocusRangeNavigatorKeystroke = useCallback(
    (e: KeyboardEvent) => {
      if (rangeNavInput) {
        e.preventDefault();
        e.stopPropagation();
        rangeNavInput?.select();
      }
    },
    [rangeNavInput]
  );

  const handleDeleteRange = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      EditorService.deleteEntireRange(editorController);
    },
    [editorController]
  );
  const handleNewBreakRange = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      editorController.splitNodes({ always: true });
    },
    [editorController]
  );

  const handleSkipToNextRange = useCallback(
    _.throttle(() => {
      const result = EditorService.onSkipSubtitle({ editor: editorController });
      return result;
    }, 100),
    [editorController]
  );

  const handleSkipToPrevRangeKeystroke = useCallback(
    _.throttle(() => {
      const result = EditorService.onPrevSubtitle({ editor: editorController });
      return result;
    }, 100),
    [editorController]
  );

  const handleSkipToNextRangeKeystroke = useCallback(
    (e: KeyboardEvent, to: "prev" | "next") => {
      e.preventDefault();
      e.stopPropagation();
      let result;
      if (to === "next") {
        result = handleSkipToNextRange();
      }
      if (to === "prev") {
        result = handleSkipToPrevRangeKeystroke();
      }

      if (result?.st && result?.newIndex && editorController) {
        setTime(result.st);
        EditorService.focusByPathOrElement(editorController, {
          path: [result.newIndex],
        });
      }
    },
    [
      editorController,
      handleSkipToNextRange,
      handleSkipToPrevRangeKeystroke,
      setTime,
    ]
  );

  const handleRepeatKeystroke = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      const repeat = MediaService.setIsRepeat(!MediaService.isRepeat);
      setRepeat(repeat);
    },
    [setRepeat]
  );

  const editorHeight = useMemo(() => {
    const { subtitleRangeHeight, visibleRanges, multipleMiddleRangeHeight } =
      contentScroll;
    const height =
      subtitleRangeHeight * (visibleRanges + multipleMiddleRangeHeight - 1);
    return height;
  }, [contentScroll]);

  const actionSectionElement = useMemo(() => {
    if (isFindAndReplaceOpen) {
      return (
        <FindAndReplace
          onReplaceOne={onReplaceOne}
          close={() => setIsFindAndReplaceOpen(false)}
          afterTriggerDecorations={afterFindAndReplaceDecoration}
          onResetState={resetFindAndReplaceMarks}
          mode={"subtitles"}
        />
      );
    }
    if (isJobScriptOpen) {
      return <JobScript />;
    }

    if (isValidationOpen) {
      return <JobValidationsExplorer />;
    }

    return null;
  }, [
    isFindAndReplaceOpen,
    isJobScriptOpen,
    onReplaceOne,
    setIsFindAndReplaceOpen,
    afterFindAndReplaceDecoration,
    resetFindAndReplaceMarks,
    isValidationOpen,
  ]);

  useKeyboardShortcuts({
    disabled: !featureFlags?.useNewKeyboardShortcuts,
    handlers: {
      JUMP_TO_NEXT_RANGE: handleJumpToNextRangeKeystroke,
      JUMP_TO_PREV_RANGE: handleJumpToPrevRangeKeystroke,
      GLITCH_TEXT_TO_NEXT_RANGE: handleGlitchTextToNextRange,
      DELETE_ENTIRE_RANGE: handleDeleteRange,
      BREAK_RANGE: handleNewBreakRange,
    },
    ref: editorRef.current,
  });

  useKeyboardShortcuts({
    disabled: !featureFlags?.useNewKeyboardShortcuts,
    handlers: {
      SKIP_TO_NEXT_SUBTITLE: (e: any) =>
        handleSkipToNextRangeKeystroke(e, "next"),
      SKIP_TO_PREV_SUBTITLE: (e: any) =>
        handleSkipToNextRangeKeystroke(e, "prev"),
      REPEAT: handleRepeatKeystroke,
      ADD_START_TIME_FRAME: (e: any) => handleAddFramesKeystroke(e, 1, "start"),
      SUB_START_TIME_FRAME: (e: any) =>
        handleAddFramesKeystroke(e, -1, "start"),
      ADD_END_TIME_FRAME: (e: any) => handleAddFramesKeystroke(e, 1, "end"),
      SUB_END_TIME_RANGE: (e: any) => handleAddFramesKeystroke(e, -1, "end"),
      FOCUS_RANGE_NAVIGATOR: (e: any) => handleFocusRangeNavigatorKeystroke(e),
    },
  });

  return (
    <div className={"subtitlesEditorContent"} onKeyDown={onKeyDown}>
      <Editable
        className={className}
        readOnly={disabled}
        renderLeaf={renderLeaf}
        renderElement={renderElement}
        contentScroll={contentScroll}
        onBlur={onBlur}
        style={{
          height: editorHeight,
        }}
      />
      <ActionElement hide={!actionSectionElement}>
        {actionSectionElement}
      </ActionElement>
    </div>
  );
};

export default forwardRef(SubtitlesEditor);

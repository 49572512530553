import React, { FC, useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";

// import FeatureFlagsService from "../../services/FeatureFlagsService";
// import Logger from "../../services/Logger";
// import FirebaseService from "../../services/FirebaseService";

// import { SpeakerSample, Word } from "../../models";
// import { AppState } from "../../store/rootReducer";
// import { JobData } from "../../models/job";
// import { Action } from "../../models/editor";
// import { Annotation } from "../../models/annotations";
// import { SpeakerRange, JobRange } from "../../models/range";

import {
  Word,
  RangeValidationConfig,
  SubtitlesRange,
  JobWithData,
  JobRange,
  SpeakerRange,
  Annotation,
  JobSpeaker,
  SaveTriggers,
  EditorFeatureFlags,
} from "@sumit-platforms/types";

import JobSpeakersPanel from "./components/JobSpeakers/JobSpeakersComponent";
import RangeSpeaker from "./components/Ranges/RangeSpeaker";
// import MessageModal from "./components/MessageModal/MessageModal";
// import SpeakerSamples from "./components/SpeakerSamples/SpeakerSamples";
import RangeAnnotation from "./components/RangeAnnotation/RangeAnnotation";

import {
  focusAndSetCursor,
  scrollToRange,
  scrollInto,
  scrollToOffset,
} from "./utils/focusAndScroll";
import { EditorAction } from "./types/EditorAction";
import { getTcOffsetByStartTime } from "@sumit-platforms/ui-bazar/utils";
import EditorService from "./services/EditorService";
import { useRecoilValue } from "recoil";
import { fpsState } from "./store/states";

// const logger = Logger("EditorPage");

interface Props {
  job: JobWithData;
  setJob: (job: JobWithData) => void;
  saveJob: (saveMethod: SaveTriggers) => Promise<void>;
  ranges: SpeakerRange[];
  updateRanges: (ranges: SpeakerRange[], rangeIndex?: number) => void;
  focusedRangeIndex: number;
  setFocusedRangeIndex: (rangeIx: number) => void;
  setFocusedRangeWordsString: React.Dispatch<React.SetStateAction<string>>;
  addActions: (actions: EditorAction[]) => void;
  currentTime: number;
  updateRangeTimes: (options: {
    rangeIndex: number;
    start?: number;
    end?: number;
    method: "button" | "text";
    tcOffset?: number;
  }) => void;
  updateTcOffset: (options: {
    rangeIndex: number;
    startTime: number;
    tcOffset: number;
  }) => void;
  editorDirection: "rtl" | "ltr";
  setIsLoading: (isLoading: string | boolean) => void;
  isDisabled: boolean;
  toast: (msg: string, type: "success" | "error" | "info") => void;
  allowTimeEdit?: boolean;
}

const Protocol: FC<Props> = ({
  job,
  setJob,
  saveJob,
  ranges,
  updateRanges,
  focusedRangeIndex,
  setFocusedRangeIndex,
  setFocusedRangeWordsString,
  addActions,
  currentTime,
  updateRangeTimes,
  updateTcOffset,
  editorDirection,
  setIsLoading,
  isDisabled,
  toast,
  allowTimeEdit,
}): JSX.Element => {
  const { t } = useTranslation();
  const fps = useRecoilValue(fpsState);
  //   null
  // );

  // const [showSpeakerSamplesModal, setShowSpeakerSamplesModal] = useState(false);
  // const [showRemapModal, setShowRemapModal] = useState(false);

  // const protocolActions = [
  // {
  //   key: "speakerSamples",
  //   label: t("speakers_samples"),
  //   icon: ["fal", "users"],
  //   onClick: () => setShowSpeakerSamplesModal(true),
  // },
  // {
  //   key: "remap",
  //   label: t("remap"),
  //   icon: ["fal", "stream"],
  //   onClick: () => setShowRemapModal(true),
  // },
  // ];

  useEffect(() => {
    // addActions(protocolActions);
    // FirebaseService.getSpeakerSamples(job.clientId).then((samples) =>
    //   setSpeakerSamples(samples)
    // );
    const { cursorPosition, rangeIx, scrollOffsetTop } =
      EditorService.getLastPosition(job.idJob);
    scrollToOffset("editorContainer", scrollOffsetTop);

    focusAndSetCursor(rangeIx, cursorPosition);
  }, []);

  const getRangeTcOffset = useCallback(getTcOffsetByStartTime, [job.tcOffsets]);

  // KeyPress Handlers
  const onPressEnter = ({
    rangeIndex,
    updatedRangeWords,
    selectedWordIndex,
    wordCharIndex,
    range,
    event,
    createAnnotation,
  }: {
    rangeIndex: number;
    updatedRangeWords: Word[];
    selectedWordIndex: number;
    wordCharIndex: number;
    range: JobRange;
    event: React.KeyboardEvent;
    createAnnotation: boolean;
  }) => {
    const nextRangeId = breakRange({
      rangeIndex,
      updatedRangeWords,
      selectedWordIndex,
      wordCharIndex,
      range,
      event,
      createAnnotation,
    });

    // scrollInto(`rangeContainer-${rangeIndex + 1}`, "editorContainer");
    scrollToRange({ rangeId: nextRangeId, focus: false });
  };

  // Action Handlers

  // const handleRemap = async () => {
  //   try {
  //     setIsLoading(t("loading_remap") as string);
  //     await saveJob("remap");
  //     const remap = await EditorService.remapJob(job.roomId, job.meetingLength);
  //     if (remap.success) {
  //       toast(t("remap_success"), "success");
  //       setIsLoading(t("reloading...") as string);
  //       setTimeout(() => location.reload(), 2000);
  //     } else {
  //       throw new Error(remap.msg);
  //     }
  //   } catch (error) {
  //     toast(t("remap_fail" as string), "error");
  //     setIsLoading(false);
  //   }
  // };

  // Handlers

  // KeyPress Handlers

  // -Protocol Range Handlers
  const changeRangeSpeaker = (
    rangeIndex: number,
    newSpeaker: JobSpeaker | null
  ) => {
    const updatedRanges = _.clone(ranges);
    updatedRanges[rangeIndex].speakerName = newSpeaker ? newSpeaker.name : null;
    updatedRanges[rangeIndex].speakerNameEdit = false;

    updateRanges(updatedRanges);

    if (
      newSpeaker &&
      !job.data.speakers.find((s) => s.name === newSpeaker.name)
    ) {
      const jobSpeakers = [...job.data.speakers, newSpeaker];
      const updatedJob = {
        ...job,
        data: {
          ...job.data,
          ranges: updatedRanges,
          speakers: jobSpeakers,
        },
      };

      setJob(updatedJob);
    }
    // TRACKING SERVICE
  };

  const renameSpeaker = (
    oldSpeaker: string,
    newSpeaker: string,
    allSpeakers: JobSpeaker[],
    mergeSpeakerRanges = false
  ) => {
    const updatedRanges: SpeakerRange[] = [];

    let shouldMergeRange = false;
    for (const [i, range] of ranges.entries()) {
      const speakerName = range.speakerName;
      const updatedRange = { ...range };
      if (
        speakerName?.trim() &&
        [oldSpeaker, newSpeaker].includes(speakerName.trim())
      ) {
        const lastRange = _.last(updatedRanges);
        if (mergeSpeakerRanges && shouldMergeRange && lastRange) {
          lastRange.words = [...lastRange.words, ...range.words];
          lastRange.et = range.et;
          continue;
        } else {
          updatedRange.speakerName = newSpeaker.trim();
          if (_.isEmpty(range.annotations)) {
            shouldMergeRange = true;
          } else {
            shouldMergeRange = false;
          }
        }
      } else {
        shouldMergeRange = false;
      }
      updatedRanges.push(updatedRange);
    }

    const newSpeakers = job.data.speakers.filter(
      (s: any) => ![oldSpeaker, newSpeaker.trim()].includes(s.name)
    );

    newSpeakers.push({ name: newSpeaker.trim() });

    updateRanges(updatedRanges);
    setJob({
      ...job,
      data: {
        ...job.data,
        speakers: newSpeakers,
        ranges: updatedRanges,
      },
    });

    return true;
  };

  const breakRange = ({
    rangeIndex,
    updatedRangeWords,
    selectedWordIndex,
    wordCharIndex,
    range,
    event,
    createAnnotation,
  }: {
    rangeIndex: number;
    updatedRangeWords: Word[];
    selectedWordIndex: number;
    wordCharIndex: number;
    range?: JobRange;
    event: React.KeyboardEvent;
    createAnnotation: boolean;
  }) => {
    const { selectionStart, selectionEnd } =
      event.target as HTMLTextAreaElement;
    if (!updatedRangeWords[selectedWordIndex]) return; //created in live-interview - check it
    const textLength = _.get(event.target, "textLength");
    const isCarretInEndOfWord =
      updatedRangeWords[selectedWordIndex].word.length === wordCharIndex;
    const breakIndex = isCarretInEndOfWord
      ? selectedWordIndex + 1
      : selectedWordIndex;

    if (selectionStart === textLength) {
      if (createAnnotation) {
        createNewAnnotation(rangeIndex, true);
      }
      return;
    }

    if (selectionStart === 0 && rangeIndex > 0) {
      if (createAnnotation) {
        createNewAnnotation(rangeIndex - 1);
      }
      return;
    }

    const wordToBreak =
      wordCharIndex > 0 &&
      !isCarretInEndOfWord &&
      _.clone(updatedRangeWords[breakIndex]);

    const firstPartWords = _.clone(updatedRangeWords);
    const secondPartWords = firstPartWords.splice(breakIndex);

    if (wordToBreak) {
      wordToBreak.word = wordToBreak.word.slice(0, wordCharIndex);
      secondPartWords[0].word = secondPartWords[0].word.slice(
        wordCharIndex,
        secondPartWords[0].word.length
      );
      firstPartWords.push(wordToBreak);
    }

    const existingRange = ranges[rangeIndex] as SpeakerRange;

    const firstPartRange = EditorService.createNewSpeakerRange({
      words: firstPartWords,
      speakerName: existingRange.speakerName,
      speakerId: existingRange.speakerId,
      six: 0,
      eix: 0,
    });

    const secondPartRange = EditorService.createNewSpeakerRange({
      words: secondPartWords,
      six: 0,
      eix: 0,
      speakerNameEdit: true,
      annotations: existingRange.annotations,
    });

    if (range?.time_edit) {
      firstPartRange.time_edit = true;
      // REVERT OMIT AFTER ALGO TAKES time_edit FROM RANGE (INSTEAD OF WORD)
      firstPartRange.words[0].time_edit = true;
    }

    if (event && createAnnotation) {
      // Create annotation between ranges
      const newAnnotation = EditorService.createNewAnnotation(rangeIndex);
      firstPartRange.annotations.push(newAnnotation);
      secondPartRange.speakerName = firstPartRange.speakerName;
      secondPartRange.speakerNameEdit = false;
    }

    if (_.isEmpty(firstPartRange.words) || _.isEmpty(secondPartRange.words)) {
      console.error("breakRange: Empty words range!!!");
    }

    const updatedRanges = _.clone(ranges);
    updatedRanges.splice(rangeIndex, 1, firstPartRange, secondPartRange);

    updateRanges(updatedRanges);

    return secondPartRange.id;
  };

  const mergeRange = (
    rangeIndex: number,
    words: Word[],
    mergeWithNextRange = false
  ) => {
    const updatedRanges = _.clone(ranges);
    updatedRanges.splice(rangeIndex, 1);
    const rangeToMergeInto = updatedRanges[
      mergeWithNextRange ? rangeIndex : rangeIndex - 1
    ] as SpeakerRange;

    rangeToMergeInto.words = mergeWithNextRange
      ? [...words, ...rangeToMergeInto.words]
      : [...rangeToMergeInto.words, ...words];

    rangeToMergeInto.speakerName =
      ranges[mergeWithNextRange ? rangeIndex : rangeIndex - 1].speakerName;

    if (_.isEmpty(rangeToMergeInto.words)) {
      console.error("mergeRange: Empty words range!!!");
    }

    updateRanges(updatedRanges);
  };

  const updateRangeWords = (rangeIndex: number, rangeWords: Word[]) => {
    const updatedRanges = _.clone(ranges);
    const updatedRange = { ...ranges[rangeIndex], words: rangeWords };
    _.set(updatedRanges, `[${rangeIndex}]`, updatedRange);

    updateRanges(updatedRanges, rangeIndex);
  };
  // Protocol Range Handlers

  // Annotations Handlers
  const createNewAnnotation = (
    rangeIndex: number,
    unshift = false,
    annotationIndex?: number
  ) => {
    const updatedRanges = _.clone(ranges);
    const newAnnotation = EditorService.createNewAnnotation(rangeIndex);
    //Make sure updatedRanges[rangeIndex].annotations has an annotation array
    if (!_.isArray(updatedRanges[rangeIndex].annotations)) {
      updatedRanges[rangeIndex].annotations = [];
    }
    if (unshift) {
      updatedRanges[rangeIndex].annotations.unshift(newAnnotation);
    } else {
      updatedRanges[rangeIndex].annotations.splice(
        annotationIndex ? annotationIndex + 1 : 1,
        0,
        newAnnotation
      );
    }
    updateRanges(updatedRanges);
  };

  const updateAnnotation = (
    rangeIndex: number,
    annotationIndex: number,
    annotation: Annotation
  ) => {
    const updatedRanges = _.clone(ranges);

    updatedRanges[rangeIndex].annotations[annotationIndex] = annotation;

    updateRanges(updatedRanges);
  };

  const deleteAnnotation = (rangeIndex: number, annotationIndex: number) => {
    const updatedRanges = _.clone(ranges);

    updatedRanges[rangeIndex].annotations.splice(annotationIndex, 1);

    updateRanges(updatedRanges);
  };
  // -Annotations Handlers

  return (
    <>
      <JobSpeakersPanel
        speakers={job.data.speakers}
        renameSpeaker={renameSpeaker}
        disabled={isDisabled}
      />
      <div className="ProtocolEditor">
        <div id="editorContainer" className="editorRanges protocolRanges">
          {ranges.map((range, rangeIndex) => (
            <div
              id={`rangeContainer-${rangeIndex}`}
              key={
                range.id ||
                `${range.words
                  .slice(0, 10)
                  .map((w) => w.word)
                  .join("")}-${range.st}-${range.et}`
              }
            >
              <LazyLoad
                height={100}
                offset={
                  // Determain divs height by its word count
                  ((range.words && range.words.length * 1.3) || 400) + 1000
                }
                scrollContainer="#editorContainer"
                unmountIfInvisible={rangeIndex !== focusedRangeIndex}
                key={range.id}
              >
                <RangeSpeaker
                  job={job}
                  ranges={ranges}
                  range={range as SpeakerRange}
                  updateRangeWords={updateRangeWords}
                  rangesCount={
                    _.filter(ranges, (r) => r.type === "speaker").length
                  }
                  changeRangeSpeaker={changeRangeSpeaker}
                  onPressEnter={onPressEnter}
                  mergeRange={mergeRange}
                  updateRangeTimes={updateRangeTimes}
                  updateTcOffset={updateTcOffset}
                  tcOffset={getRangeTcOffset(job.tcOffsets, range.st)}
                  isPassed={Number(currentTime) > Number(range.et)}
                  rangeIndex={rangeIndex}
                  setFocusedRangeIndex={setFocusedRangeIndex}
                  isTemporarySpeaker={!!range.speakerNameEdit}
                  direction={editorDirection}
                  disabled={isDisabled}
                  allowTimeEdit={allowTimeEdit}
                  fps={fps}
                />
                {_.map(
                  range.annotations,
                  (annotation: Annotation, i: number) => (
                    <RangeAnnotation
                      annotation={annotation}
                      rangeIndex={rangeIndex}
                      annotationIndex={i}
                      updateAnnotation={updateAnnotation}
                      deleteAnnotation={deleteAnnotation}
                      direction={editorDirection}
                      isTemp={!!annotation.temp}
                      disabled={isDisabled}
                      setFocusedRangeIndex={setFocusedRangeIndex}
                      key={annotation.id}
                      createNewAnnotation={(annotationIndex: number) => {
                        createNewAnnotation(rangeIndex, false, annotationIndex);
                      }}
                    />
                  )
                )}
              </LazyLoad>
            </div>
          ))}
        </div>
        {/* <MessageModal
          className="speakerSamples"
          title={t("speakers_samples")}
          body={<SpeakerSamples speakers={speakerSamples} />}
          showModal={showSpeakerSamplesModal}
          cancel={{
            text: t("close"),
            action: () => setShowSpeakerSamplesModal(false),
          }}
        /> */}
      </div>
    </>
  );
};

export default Protocol;

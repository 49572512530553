import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useErrorInterceptor = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const errorInterceptor = (error: any) => {
      if (error.response && error.response.status === 403) {
        navigate("/unauthorized");
      }
      return Promise.reject(error);
    };

    const interceptor = axios.interceptors.response.use(
      (response) => response,
      errorInterceptor
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);
};

import _ from "lodash";
import config from "../../config";
import { version } from "../../../project.json";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ErrorBoundary } from "@sentry/react";

import {
  ErrorPage,
  Layout,
  Modal,
  Navigation,
  Profile,
  Toaster,
  UploadManager,
} from "@sumit-platforms/ui-bazar";

import {
  sumitLogoShort,
  sumitLogoWhite,
  userImage,
} from "@sumit-platforms/common-assets/images";
import { getDayPeriod } from "@sumit-platforms/ui-bazar/utils";
import {
  useAttachFFContext,
  useErrorInterceptor,
  useFeatureFlag,
} from "@sumit-platforms/ui-bazar/hooks";
import { uploadStore, useModal } from "@sumit-platforms/ui-bazar/store";
import { Alert } from "@mui/material";

import { useTranslation } from "react-i18next";

import {
  useUser,
  clientStore,
  useNavigationBlocker,
  useGlobalData,
} from "../../store";

import authService from "../../services/authService";

import { useMenuItems } from "./menuItems";

import "./Dashboard.scss";

export const Dashboard: FC = () => {
  const { t } = useTranslation();
  const languageSwitchFF = useFeatureFlag("opera_languageSwitch");
  const { user } = useUser();
  const { client } = clientStore();
  const attachFFContext = useAttachFFContext();

  const { fetchGlobalData, toast } = useGlobalData();
  const { blockNavModal } = useNavigationBlocker();

  useEffect(() => {
    fetchGlobalData();
  }, []);

  const {
    modalContent,
    popModalContent,
    clearModalContent,
    modalType,
    setModalType,
  } = useModal();

  const {
    pendingUploads,
    activeUploads,
    succeedUploads,
    progress,
    uploadInfoText,
    clearSucceedUploads,
  } = uploadStore();

  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const menuItems = useMenuItems(t, onNavigationMenuItemClick);

  const closeModal = useCallback(() => {
    setModalType("info");
    popModalContent();
  }, [clearModalContent, setModalType]);

  useEffect(() => {
    if (!authService?.auth?.currentUser) {
      navigate("/login");
    }
  }, [authService?.auth?.currentUser]);

  useEffect(() => {
    attachFFContext({
      userId: user?.email || user?.uid || "",
      properties: {
        clientId: client?.idClient?.toString() || "",
      },
    });
  }, [client, user]);

  function onNavigationMenuItemClick(to: string) {
    if (blockNavModal) {
      return blockNavModal({ to });
    }
    return navigate(to);
  }

  useErrorInterceptor();

  return (
    <div className={"Dashboard"}>
      <Layout
        isOpen={isMenuOpen}
        navigation={
          <Navigation
            languageSwitchFF={languageSwitchFF}
            isOpen={isMenuOpen}
            openStateLogo={sumitLogoWhite}
            closeStateLogo={sumitLogoShort}
            menuItems={menuItems}
            toggleIsOpen={() =>
              setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen)
            }
            versionHistoryLink={config.versionHistory}
            version={version}
            profile={
              <Profile
                greeting={t("greetings_period", {
                  period: t(getDayPeriod()),
                })}
                showDetails={isMenuOpen}
                fullName={user?.firstName + " " + user?.lastName}
                avatarUrl={userImage}
              />
            }
          />
        }
      >
        <ErrorBoundary
          showDialog
          fallback={({ error, resetError }) => (
            <ErrorPage
              title={t("something_went_wrong")}
              text={error.message}
              redirectToOnClick={"/"}
            ></ErrorPage>
          )}
          onReset={() => navigate("/")}
        >
          <Outlet />
        </ErrorBoundary>
      </Layout>
      {modalContent.map((modal, i: number) => (
        <div key={i + "modal"}>
          <Modal closeModal={closeModal} type={modalType}>
            {modal}
          </Modal>
        </div>
      ))}
      {!_.isEmpty([...pendingUploads, ...activeUploads, ...succeedUploads]) && (
        <UploadManager
          pendingUploads={pendingUploads}
          activeUploads={activeUploads}
          succeedUploads={succeedUploads}
          clearSucceedUploads={clearSucceedUploads}
          progress={progress}
          uploadInfoText={uploadInfoText}
        />
      )}
      <Toaster />
      {toast && (
        <div className="Toaster">
          <Alert severity={toast.severity}>{toast.text}</Alert>
        </div>
      )}
    </div>
  );
};

import { Word } from "@sumit-platforms/types";

// Spreading word times evenly by start and end times
const getWordsByRangeTimes = (
  words: Word[],
  start: number,
  end: number
): Word[] => {
  const rangeDuration = end - start;
  const wordDuration = Math.abs(rangeDuration / words.length);

  const updatedWords = [];

  for (const [i, word] of words.entries()) {
    const wordStart = wordDuration * i + start;
    const wordEnd = i === words.length - 1 ? end : wordStart + wordDuration;

    const wordObj = {
      ...word,
      start_time: Number(wordStart.toFixed(2)),
      end_time: Number(wordEnd.toFixed(2)),
    };
    updatedWords.push(wordObj);
  }
  return updatedWords;
};

export { getWordsByRangeTimes };

import _ from "lodash";
import { faGoogleDrive } from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useDrivePicker from "react-google-drive-picker";
import {
  CallbackDoc,
  PickerCallback,
} from "react-google-drive-picker/dist/typeDefs";

import { Box, Grid } from "@mui/material";
import { faFolder } from "@fortawesome/pro-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  allowUploadTypes,
  applicationExtentions,
  audioMimeTypesAccept,
  videoMimeTypesAccept,
  applicationMimeTypesAccept,
  fileInputAccept,
  mediaMimeTypes,
  MediaProvider,
} from "@sumit-platforms/types";

import { AlertMessage } from "../../core/Alert/Alert";
import { List, ListItem } from "../../core/List/List";
import { DragAndDrop } from "../../core/DragAndDrop/DragAndDrop";
import { Button } from "../../core/Button/Button";

import { useModal } from "../../store/modal";
import { ContactUsModal } from "../ContactUsModal/ContactUsModal";
import { extractFileNameWithoutExtension } from "../../../utils";

import "./UploadFilesModal.scss";

const supportedFormats = {
  ...audioMimeTypesAccept,
  ...videoMimeTypesAccept,
  ...applicationMimeTypesAccept,
};

export interface UploadFilesModalProps {
  closeModal: () => void;
  onUpload: ({
    files,
    attachedFiles,
    googleDriveToken,
  }: {
    files: File[] | CallbackDoc[];
    attachedFiles?: Record<string, File[]>;
    googleDriveToken?: string;
  }) => void;
  firebase: { clientId: string; apiKey: string };
}
type UploadFile = File & { hasMatchedAaf?: boolean };

export const UploadFilesModal = ({
  closeModal,
  onUpload,
  firebase,
}: UploadFilesModalProps) => {
  const { t } = useTranslation();
  const { pushModalContent } = useModal();

  const [provider, setProvider] = useState<MediaProvider>(
    MediaProvider.fileInput
  );
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [aafs, setAafs] = useState<File[]>([]);
  const [attachedFiles] = useState<Record<string, File[]>>({});
  const [uploadListItems, setUploadListItems] = useState<ListItem[]>([]);
  const inputFileRef = useRef<any>(null);
  const inputAAFFileRef = useRef<any>(null);
  const [openPicker, authResponse] = useDrivePicker();
  const [googleDriveFiles, setGoogleDriveFiles] = useState<CallbackDoc[]>([]);
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [indicationMsg, setAlertMsg] = useState<AlertMessage>({
    title: "",
    content: "",
  });
  const { matchedAafs, unMatchedAafs } = useMemo(updateAafs, [aafs]);

  const handleGoogleDrivePick = (data: PickerCallback) => {
    if (data?.docs) {
      const allowedFiles = data.docs.filter((d) =>
        allowUploadTypes.includes(d.mimeType)
      );
      if (data?.docs.length > allowedFiles.length) {
        setIsAlertOpen(true);
        setAlertMsg({
          title: t("could_not_load_files"),
          content: t("files_are_not_allowed"),
        });
      }
      setGoogleDriveFiles(allowedFiles);
    }
  };

  const handleOpenPicker = () => {
    openPicker({
      clientId: firebase.clientId,
      developerKey: firebase.apiKey,
      showUploadView: true,
      viewMimeTypes: mediaMimeTypes.join(","), // TODO: enable aafs in the google drive upload
      viewId: "FOLDERS",
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: handleGoogleDrivePick,
    });
  };

  useEffect(() => {
    setProvider(MediaProvider.fileInput);
    const newItems: ListItem[] = files.map((f) => ({
      name: f.name,
      item: f,
      indicator: f.hasMatchedAaf,
      tooltip: t("aaf_linked") as string,
    }));
    setUploadListItems(newItems);
  }, [files]);

  function getMatchedFileToAff(aaf: File) {
    const aafName = extractFileNameWithoutExtension(aaf.name);
    const matchedFile = files.find((file) => {
      const fileName = extractFileNameWithoutExtension(file.name);
      return aafName === fileName;
    });
    return matchedFile;
  }

  function updateAafs() {
    const matchedAafs: File[] = [];
    const unMatchedAafs: File[] = [];
    aafs.forEach((aaf) => {
      const matchedFile = getMatchedFileToAff(aaf);
      if (matchedFile) {
        matchedAafs.push(aaf);
        matchedFile.hasMatchedAaf = true;
      } else {
        unMatchedAafs.push(aaf);
      }
    });
    return { matchedAafs, unMatchedAafs };
  }

  const attachAafsToFiles = () => {
    for (const aaf of aafs) {
      const matchedFile = getMatchedFileToAff(aaf);
      if (!matchedFile) continue;

      const fileName = matchedFile.name;
      if (_.isEmpty(attachedFiles[fileName])) {
        attachedFiles[fileName] = [];
      }
      const alreadyExist = attachedFiles[fileName].some((file) => {
        return file.name === aaf.name;
      });
      if (!alreadyExist) {
        attachedFiles[fileName].push(aaf);
      }
    }
  };

  const updateAttachedFiles = () => {
    // Update this function if we want to add more attached files in the future except aafs
    attachAafsToFiles();
  };

  useEffect(() => {
    updateAttachedFiles();
  }, [aafs]);

  useEffect(() => {
    setProvider(MediaProvider.googleDrive);
    const newItems: ListItem[] = googleDriveFiles.map((f) => ({
      name: f.name,
      item: f,
    }));
    setUploadListItems(newItems);
  }, [googleDriveFiles]);

  const handleNewFiles = (newFiles: File[]) => {
    const { newAafs, newMediaFiles } = _.groupBy(newFiles, (file) =>
      file.name.toLowerCase().endsWith(".aaf") ? "newAafs" : "newMediaFiles"
    );
    setFiles(_.uniqBy([...files, ...(newMediaFiles || [])], "name"));
    setAafs(_.uniqBy([...aafs, ...(newAafs || [])], "name"));
  };

  const onDrop = useCallback((files: File[]) => {
    handleNewFiles(files);
  }, []);

  const onDelete = (item: ListItem) => {
    if (provider === MediaProvider.googleDrive) {
      onGoogleDriveFileDelete(item);
    } else if (provider === MediaProvider.fileInput) {
      onFileDelete(item);
    }
  };

  const onFileDelete = (item: ListItem) => {
    const newFiles = files.filter((file: File) => {
      return file.name !== item.name;
    });
    setFiles(newFiles);
  };

  const onGoogleDriveFileDelete = (item: ListItem) => {
    const newGoogleDriveFiles = googleDriveFiles.filter((file: CallbackDoc) => {
      return file.id !== (item.item as CallbackDoc).id;
    });
    setGoogleDriveFiles(newGoogleDriveFiles);
  };

  const inputClick = (inputRef: any) => {
    if (inputRef.current && inputRef.current.click) {
      inputRef.current.click();
    }
  };

  const handleBrowse = (aaf?: boolean) => {
    inputClick(aaf ? inputAAFFileRef : inputFileRef);
  };

  const handleBrowseChange = (e: any) => {
    handleNewFiles(Array.from(e.target.files));
  };

  const handleUpload = () => {
    closeModal();
    if (provider === MediaProvider.fileInput) {
      onUpload({ files, attachedFiles: attachedFiles });
    } else if (
      provider === MediaProvider.googleDrive &&
      authResponse?.access_token
    ) {
      onUpload({
        files: googleDriveFiles,
        googleDriveToken: authResponse.access_token,
        attachedFiles: attachedFiles,
      });
    }
  };

  const openContactUs = () => {
    pushModalContent(<ContactUsModal />);
  };

  return (
    <Grid className={"UploadFilesModal"} container width={"38rem"}>
      <input
        style={{ display: "none" }}
        type={"file"}
        ref={inputFileRef}
        onChange={handleBrowseChange}
        multiple
        accept={fileInputAccept(allowUploadTypes)}
      />
      <input
        style={{ display: "none" }}
        type={"file"}
        ref={inputAAFFileRef}
        onChange={handleBrowseChange}
        multiple
        accept={fileInputAccept(applicationExtentions)}
      />
      <Grid item xs={12} display={"flex"} justifyContent={"center"} py={4}>
        <span className="modalName">{t("upload_files")}</span>
      </Grid>
      <Grid item container px={5}>
        <Grid
          item
          xs={8}
          px={1}
          display={"flex"}
          flexDirection={"column"}
          className={"dragAndDropWrapper"}
        >
          <Grid item display={"flex"} className={"filesLoadedBrief"}>
            {files.length ? `${files.length} ${t("files_loaded")}` : ""}
            {matchedAafs.length
              ? ` | ${matchedAafs.length} ${t("matched_aaf")} ${
                  unMatchedAafs.length ? "|" : ""
                }`
              : ""}
            {unMatchedAafs.length
              ? ` ${unMatchedAafs.length} ${t("unmatched")}`
              : ""}
          </Grid>
          {uploadListItems?.length === 0 ? (
            <DragAndDrop
              label={t("drag_and_drop") as string}
              handleOnDrop={onDrop}
              accept={supportedFormats}
            />
          ) : (
            <>
              {/* <Box
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                py={1}
              >
                <div className="numberOfItems">
                  {items?.length} {t("loaded")}
                </div>
              </Box> */}
              <Box mt={2} width={"20rem"}>
                <List
                  onDelete={onDelete}
                  items={uploadListItems}
                  loadedLabel={t("loaded") as string}
                  searchLabel={t("search") as string}
                />
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={4} display={"flex"} flexDirection={"column"}>
          <Box display="flex" pb={2}>
            <p>{t("select_files")}:</p>
          </Box>
          <Box pb={1} display={"flex"} justifyContent={"stretch"}>
            <Button
              className="providerBtn"
              startIcon={<FontAwesomeIcon icon={faGoogleDrive as IconProp} />}
              onClick={() => handleOpenPicker()}
              variant="outlined"
            >
              {t("google_drive")}
            </Button>
          </Box>
          <Box display={"flex"} justifyContent={"stretch"} mb={"auto"}>
            <Button
              className="providerBtn"
              variant="outlined"
              startIcon={<FontAwesomeIcon icon={faFolder} />}
              onClick={() => handleBrowse(false)}
            >
              {t("browse")}
            </Button>
          </Box>
          <Box display={"flex"} justifyContent={"stretch"}>
            <Button
              className="providerBtn"
              variant="outlined"
              onClick={() => handleBrowse(true)}
              infoTooltip={t("attach_aaf_tooltip")}
            >
              {t("attach_aaf")}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} py={3} px={5}>
        <Box display="flex" alignItems={"center"}>
          <Box className="phrase">{t("something_wrong")}</Box>
          <Box
            className={"highlightPhrase"}
            paddingX={1}
            onClick={openContactUs}
          >
            {t("talk_to_us")}
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"flex-end"}
        pb={4}
        gap={2}
        px={5}
      >
        <Button onClick={closeModal} variant="outlined">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleUpload}
          color="primary"
          disabled={_.isEmpty(uploadListItems)}
        >
          {t("upload")}
        </Button>
      </Grid>
    </Grid>
  );
};

import _ from "lodash";
import { useTranslation } from "react-i18next";
import { RangeValidationResults } from "@sumit-platforms/types";
import { TOptions } from "i18next";

export const ValidationList = ({
  validation,
}: {
  validation: RangeValidationResults;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {_.map(_.keys(validation.errors), (error) => (
        <div className="error" key={error}>
          {t(
            `validation_${error}`,
            validation.errors && (validation.errors[error] as TOptions)
          )}{" "}
        </div>
      ))}
    </>
  );
};

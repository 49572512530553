import React, { useCallback, useEffect, useState, useRef } from "react";
import { CustomEditor, SubtitleRangeElement } from "../../types";
import { useSlateStatic } from "@sumit-platforms/slate-react";
import { VirtualizedList, VirtualizedListRef } from "@sumit-platforms/ui-bazar";
import JobValidationsExplorerItem from "./JobValidationsExplorerItem";
import { RangeValidation } from "@sumit-platforms/types";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons";
import { directionState } from "../../store/states";
import { useRecoilValue } from "recoil";
import { SyncScroll } from "../JobScript/SyncScroll";

import "./JobValidationsExplorer.scss";

const itemHeight = 65;
const maxHeight = itemHeight * 9;

export interface JobValidationsRange {
  error: RangeValidation;
  st: number;
  et: number;
}

export const JobValidationsExplorer = () => {
  const editor = useSlateStatic() as CustomEditor;
  const dir = useRecoilValue(directionState);
  const [syncScroll, setSyncScroll] = useState(true);

  const listRef = useRef<VirtualizedListRef>(null);
  const [listRefState, setListRef] = useState<HTMLDivElement | null>(null);

  const getValidationsRanges = useCallback(() => {
    const filteredValidationRanges = editor.children?.filter((child) => {
      const _child = child as SubtitleRangeElement;
      return _child?.type === "subtitleRange" && !!_child?.range?.validation;
    });

    const validationElementRanges = filteredValidationRanges?.map(
      (children) => {
        const child = children as SubtitleRangeElement;
        return {
          element: children,
          index: editor.children.indexOf(children),
          validation: child?.range?.validation,
          st: child?.range?.st,
          et: child?.range?.et,
        };
      }
    );

    const formattedValidationsRanges = validationElementRanges?.map(
      (validation) => {
        const rangeErrors = Object.keys(validation.validation?.errors || {});
        return rangeErrors.map((error) => {
          return {
            ...((validation.validation?.errors &&
              (validation.validation?.errors[error] as any)) ||
              {}),
            ...validation,
            error,
            st: validation.st,
            et: validation.et,
          };
        });
      }
    );
    return formattedValidationsRanges.flat();
  }, [editor.children]);

  const [ranges, setRanges] = useState(getValidationsRanges());

  const updateRanges = useCallback(() => {
    const scriptRanges = getValidationsRanges();
    setRanges(scriptRanges);
  }, [getValidationsRanges]);

  useEffect(() => {
    // Store the original apply method
    const { apply } = editor;
    // We use requestAnimationFrame to wait until first paint to prevent unnecessary re-renders
    const rafId = requestAnimationFrame(() => {
      // Custom logic after the first paint
      editor.apply = (operation) => {
        apply(operation); // Call the original apply method

        const changeTextOperations = [
          "remove_node",
          "insert_node",
          "split_node",
          "merge_node",
          "set_node",
        ];

        if (changeTextOperations.includes(operation.type)) {
          updateRanges();
        }
      };
    });
    return () => {
      cancelAnimationFrame(rafId); // Clean up the animation frame if the component unmounts
      editor.apply = apply; // Restore the original apply method
    };
  }, [editor, updateRanges]);

  const onListWheel = useCallback(() => {
    if (syncScroll) {
      setSyncScroll(false);
    }
  }, [syncScroll]);

  useEffect(() => {
    if (!listRefState) {
      setListRef(listRef.current?.listRef || null);
    }
  }, [listRef.current?.listRef]);
  return (
    <Box className={"JobValidations"} dir={"ltr"}>
      <Box className={"validationsCounterWrapper"} px={0} pl={2}>
        <Box className={"errorValidationsCounter"}>
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className={"errorValidationsCounterIcon"}
          />
          {ranges?.length || null}
        </Box>
        <SyncScroll
          syncScroll={syncScroll}
          setSyncScroll={setSyncScroll}
          listRef={listRefState}
          ranges={ranges}
          itemHeight={itemHeight}
          dir={dir}
        />
      </Box>
      <VirtualizedList
        items={ranges || []}
        maxHeight={maxHeight}
        itemCount={ranges?.length || 0}
        itemHeight={itemHeight}
        dir={dir}
        ref={listRef}
        render={(range) => (
          <JobValidationsExplorerItem range={range} index={range.index} />
        )}
        onWheel={onListWheel}
      />
    </Box>
  );
};

import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { STTConfigPage, ClientSTT } from "@sumit-platforms/types";
import { PageSettings } from "../core/SettingsPage/SettingsPage";
import { getSTTModelPageInputs } from "./pageSettings/stt/sttPage";

export interface STTModelSetttingsMetaData {
  sttModels: any[];
  sttPriority: number[];
}

export interface STTModelSetttingsActions {
  onConfirm: () => void;
  onCancel: () => void;
}
export interface UseSTTModelSettingsInput<TMetaData, TActions> {
  settings?: ClientSTT;
  metadata?: TMetaData;
  actions?: TActions;
}
export const handleGetSTTModelPageInputs = ({
  key,
  value,
  t,
  metadata,
  actions,
}: {
  key: string;
  value: any;
  t: any;
  metadata?: STTModelSetttingsMetaData;
  actions?: STTModelSetttingsActions;
}) => {
  const pages = getSTTModelPageInputs({
    key,
    args: new STTConfigPage(value),
    t,
    metadata,
    actions,
  });
  return pages;
};

export const useSTTModelSettings = ({
  settings,
  metadata,
  actions,
}: UseSTTModelSettingsInput<
  STTModelSetttingsMetaData,
  STTModelSetttingsActions
>) => {
  const { t } = useTranslation();

  const [pages, setPages] = useState<PageSettings<ClientSTT>[]>(
    settings
      ? (_.entries(settings).map((entry) =>
          handleGetSTTModelPageInputs({
            key: entry[0],
            value: entry[1],
            t,
            metadata,
            actions,
          })
        ) as any) //TODO: fix that type
      : []
  );

  const [settingsReplica, setSettingsReplica] = useState<ClientSTT | null>(
    null
  );

  const resetPages = () => {
    if (settingsReplica) {
      setPages(
        settingsReplica
          ? (Object.entries(settingsReplica).map((entry) =>
              handleGetSTTModelPageInputs({
                key: entry[0],
                value: entry[1],
                t,
                metadata,
                actions,
              })
            ) as any) //TODO: fix that type
          : []
      );
    }
  };

  useEffect(() => {
    if (settings) {
      setPages(
        Object.entries(settings).map((entry) =>
          handleGetSTTModelPageInputs({
            key: entry[0],
            value: entry[1],
            t,
            metadata,
            actions,
          })
        ) as any // TODO: fix that type
      );
      if (!settingsReplica) {
        setSettingsReplica({ ...settings });
      }
    }
  }, [settings]);

  return { pages, setPages, resetPages };
};

import _ from "lodash";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { PageSettings, SettingsPage } from "../SettingsPage/SettingsPage";
import { useTranslation } from "react-i18next";

import "./SettingsPager.scss";

export interface SettingsPagerProps<T> {
  pages: PageSettings<T>[];
  setPages: React.Dispatch<React.SetStateAction<PageSettings<any>[]>>;
  imageUploadHandler?: (file: File) => Promise<string>;
  setIsValid?: (isValid: boolean) => void;
  listButtons?: React.ReactNode[];
  steps?: { options: string[] }[];
}

export const SettingsPager = <T,>({
  pages,
  setPages,
  imageUploadHandler,
  setIsValid,
  listButtons,
  steps,
}: SettingsPagerProps<T>) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [selectedSteps, setSelectedSteps] = useState<string[]>([]);

  useEffect(() => {
    if (!steps) return;
    setSelectedSteps(steps.map((s) => s.options[0]));
  }, [steps]);

  const handleOnChangeCurrentPage = (pageIndex: number) => {
    setCurrentPage(pageIndex);
  };

  const handleOnChangeCurrentStep = (depth: number, option: string) => {
    const _selectedSteps = [...selectedSteps];
    _selectedSteps[depth] = option;
    setSelectedSteps(_selectedSteps);
    const _indexOfSelectedStep = _.findIndex(
      pages,
      (p) => p.path === _selectedSteps.join(".")
    );
    setCurrentPage(_indexOfSelectedStep);
  };

  const handleOnSettingsChange = (
    index: number,
    newSettingsOfSinglePage: any
  ) => {
    setPages((prevPages: PageSettings<T>[]) => {
      if (prevPages[index]) {
        prevPages[index].settings = newSettingsOfSinglePage;
      }
      return [...prevPages];
    });
  };
  return (
    <Grid className={"SettingsPager"} container>
      {!_.isEmpty(steps) ? (
        steps?.map((s, i) => {
          return (
            <Grid item xs={3} key={i + "step"}>
              <List disablePadding className="settingsPagerPageList">
                {s.options.map((o, j) => {
                  return (
                    <ListItem disablePadding key={j + "stepOption"}>
                      <ListItemButton
                        onClick={() => handleOnChangeCurrentStep(i, o)}
                        className={classNames([
                          "settingsPagerPageListButton",
                          { current: selectedSteps[i] === o },
                        ])}
                      >
                        {t(o.replace("-", "_"))}
                      </ListItemButton>
                    </ListItem>
                  );
                })}
                {listButtons}
              </List>
            </Grid>
          );
        })
      ) : (
        <Grid item xs={3}>
          <List disablePadding className="settingsPagerPageList">
            {pages.map((p, i: number) => (
              <>
                {p.tabLabel && (
                  <ListItemText
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: "14px",
                        margin: "0 10px",
                        paddingTop: "5px",
                        paddingInlineStart: "15px",
                      },
                    }}
                    className="settingsPagerPageTabLabel"
                  >
                    {p.tabLabel}
                  </ListItemText>
                )}
                <ListItem disablePadding key={i + "nonstep"}>
                  <ListItemButton
                    onClick={() => handleOnChangeCurrentPage(i)}
                    className={classNames([
                      "settingsPagerPageListButton",
                      { current: currentPage === i },
                    ])}
                  >
                    {p.pageTab}
                  </ListItemButton>
                </ListItem>
              </>
            ))}
            {listButtons}
          </List>
        </Grid>
      )}
      <Grid
        item
        xs={steps?.length ? 12 - steps?.length * 3 : 9}
        paddingX={5}
        className="settingsPageWrapper"
      >
        <SettingsPage
          {...pages[currentPage]}
          onSettingsChange={(settings: any) => {
            handleOnSettingsChange(currentPage, settings);
          }}
          imageUploadHandler={imageUploadHandler}
          setIsValid={setIsValid}
        />
      </Grid>
    </Grid>
  );
};

import { TFunction } from "i18next";
import {
  SpeakersSplitPage,
  DiarizeTypes,
  Platform,
} from "@sumit-platforms/types";
import { PageSettings } from "../../../core/SettingsPage/SettingsPage";

export const getSpeakersSplitPageInputs: (
  args: SpeakersSplitPage,
  t: TFunction,
  platform?: Platform
) => PageSettings<{ speakersSplit: SpeakersSplitPage }> = (
  args,
  t,
  platform
) => {
  const diarizeOptions = Object.values(DiarizeTypes).map((diarizeType) => ({
    label: t(diarizeType as string),
    value: diarizeType,
  }));

  return {
    settings: { speakersSplit: args },
    pageTab: t("settings_project_speaker_splits"),
    sections: [
      {
        title: t("settings_project_speaker_splits_title"),
        inputs: [
          {
            label: t("settings_project_speakers_split_auto"),
            key: "speakersSplit.autoSpeakerSplit",
            type: "switch",
            style: { width: 200 },
          },
        ],
      },
      {
        inputs: [
          {
            label: t("settings_project_speakers_split_split_by"),
            type: "select",
            key: "speakersSplit.splitBy",
            options: diarizeOptions,
            style: { width: 200 },
          },
        ],
      },
    ],
  };
};

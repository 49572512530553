import ListItemButton from "@mui/material/ListItemButton";
import React, { useCallback } from "react";
import MediaService from "../../services/MediaService";
import { useSlateStatic } from "@sumit-platforms/slate-react";
import { CustomEditor } from "../../types";
import { directionState, currentTimeState } from "../../store/states";
import { useRecoilValue } from "recoil";
import { getTimecodeFromSeconds } from "@sumit-platforms/ui-bazar/utils";
import TextWithBreaks from "./RangeScriptText";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-light-svg-icons";
import classNames from "classnames";
import { ItemRange } from "./SyncScroll";
import EditorService from "../../services/EditorService";

interface Props {
  range: ItemRange;
  idx: number;
}

const JobScriptItem = ({ range, idx }: Props) => {
  const editor = useSlateStatic();
  const dir = useRecoilValue(directionState);
  const time = useRecoilValue(currentTimeState);

  const bold = time ? time >= range.st && time <= range.et : false;

  const handleScriptRangeClick = useCallback(
    (range: ItemRange) => {
      EditorService.navigateToSubtitle({
        editor: editor as CustomEditor,
        st: range.st,
      });
    },
    [editor]
  );

  return (
    <ListItemButton
      id={`range-item-${idx}`}
      onClick={() => handleScriptRangeClick(range)}
      sx={{
        padding: "4px 0",
        display: "inline-block",
        textAlign: dir === "rtl" ? "right" : "left",
        width: "100%",
        height: "100%",
      }}
      key={range.text}
      disableRipple
      dir={dir}
    >
      <Box className={"JobScript"}>
        <Box className={"wrapper"}>
          <Box className={classNames("rangeIndexTimes", { bold })}>
            {getTimecodeFromSeconds(range.st, {
              fps: MediaService.frameRate,
              tcOffsets: MediaService.tcOffsets,
            })}
            <FontAwesomeIcon
              icon={dir === "rtl" ? faArrowLeft : faArrowRight}
              size="2xs"
            />
            {getTimecodeFromSeconds(range.et, {
              fps: MediaService.frameRate,
              tcOffsets: MediaService.tcOffsets,
            })}
          </Box>
          <Box>
            <TextWithBreaks dir={dir} text={range.text} bold={bold} />
          </Box>
        </Box>
        <Box className={classNames("rangeIndex", { bold })}>{idx + 1}</Box>
      </Box>
    </ListItemButton>
  );
};

export default JobScriptItem;

import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { PageSettings } from "../core/SettingsPage/SettingsPage";
import {
  CustomFieldsPage,
  ExtractPage,
  JobSettingsPage,
  Platform,
  ProjectConfig,
  SpeakersSplitPage,
  STTConfigPage,
  SttPriority,
} from "@sumit-platforms/types";
import { getJobSettingsPageInputs } from "./pageSettings/project/jobSettingsPage";
import { getSpeakersSplitPageInputs } from "./pageSettings/project/speakersSplitPage";
import { getExtractPageInputs } from "./pageSettings/project/extractPage";
import { getSttModelPageInputs } from "./pageSettings/project/modelPage";
import { Option } from "../core/MultiSelect/MultiSelect";

export type ProjectSettingsPagesMetadata = {
  validationOptions?: Option[];
  sttModelOptions?: Option[];
  sttPriority?: SttPriority[];
};

export type ProjectSettingsPages =
  | {
      jobSettings: JobSettingsPage;
    }
  | { customFields: CustomFieldsPage }
  | { speakersSplit: SpeakersSplitPage }
  | { extract: ExtractPage }
  | {
      model: STTConfigPage;
    };

export const useProjectsSettings = ({
  settings,
  metadata,
  platform,
}: {
  settings: ProjectConfig;
  metadata?: ProjectSettingsPagesMetadata;
  platform?: Platform;
}) => {
  const { t } = useTranslation();
  const [pages, setPages] = useState<PageSettings<ProjectSettingsPages>[]>([]);

  const [settingsReplica, setSettingsReplica] = useState<ProjectConfig | null>(
    null
  );

  const resetPages = useCallback(() => {
    if (settingsReplica) {
      const pages = [
        getJobSettingsPageInputs(
          new JobSettingsPage(settingsReplica?.jobSettings),
          t,
          metadata,
          platform
        ),
        getSpeakersSplitPageInputs(
          new SpeakersSplitPage(settingsReplica?.speakersSplit),
          t,
          platform
        ),
      ];
      if (platform === Platform.OPERA) {
        pages.push(
          getExtractPageInputs(
            new ExtractPage(settingsReplica?.extract),
            t,
            platform
          ) as any
        );
        pages.push(
          getSttModelPageInputs(
            new STTConfigPage(settings.model),
            t,
            metadata,
            platform
          ) as any
        );
      }
      setPages(pages);
    }
  }, [metadata, setSettingsReplica]);

  useEffect(() => {
    const pages = [
      getJobSettingsPageInputs(
        new JobSettingsPage(settings?.jobSettings),
        t,
        metadata,
        platform
      ),
      getSpeakersSplitPageInputs(
        new SpeakersSplitPage(settings?.speakersSplit),
        t,
        platform
      ),
    ];
    if (platform === Platform.OPERA) {
      pages.push(
        getExtractPageInputs(
          new ExtractPage(settings?.extract),
          t,
          platform
        ) as any
      );
      pages.push(
        getSttModelPageInputs(
          new STTConfigPage(settings?.model),
          t,
          metadata,
          platform
        ) as any
      );
    }
    setPages(pages);
    if (!settingsReplica) {
      setSettingsReplica({ ...settings });
    }
  }, [settings, metadata]);

  return { pages, setPages, resetPages };
};

import React, { useEffect, useState } from "react";
import { faMinusCircle, faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import { Box, Grid, ThemeProvider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bazarTheme } from "../../bazar-theme";
import Input from "../Input/Input";

import "./TextArray.scss";

export interface TextArrayProp {
  values: string[];
  onChange: (value: string[]) => void;
}

export const TextArray = ({ values, onChange }: TextArrayProp) => {
  const handleOnInputChange = (index: number, value: string) => {
    const _values = [...values];
    _values[index] = value;
    onChange(_values);
  };

  const handleAddNewInput = () => {
    onChange([...values, ""]);
  };

  const handleRemoveInput = () => {
    const _values = [...values];
    if (_values.length > 0) {
      _values.pop();
      onChange(_values);
    }
  };

  return (
    <ThemeProvider theme={bazarTheme}>
      <Box className={"TextArray"}>
        <Box py={1}>
          <FontAwesomeIcon
            className="plusBtn"
            onClick={handleAddNewInput}
            icon={faPlusCircle}
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            className="minusBtn"
            onClick={handleRemoveInput}
            icon={faMinusCircle}
          ></FontAwesomeIcon>
        </Box>
        <Box
          maxWidth={390}
          display={"flex"}
          justifyContent={"center"}
          flexWrap={"wrap"}
        >
          {values.map((v, index) => (
            <Grid width={130} key={index} p={1}>
              <Input
                type={"text"}
                value={v}
                onChange={(e) =>
                  handleOnInputChange(index, e.target?.value || "")
                }
              />
            </Grid>
          ))}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

import { useCallback, useEffect, useRef } from "react";
import _ from "lodash";
import {
  ActiveUpload,
  Client,
  DiarizeTypes,
  MediaProvider,
  PendingUpload,
  Upload,
  UploadStatus,
  User,
} from "@sumit-platforms/types";
import { uploadStore } from "../store/uploads.store";

import UploadService from "./../services/uploadService";

import { getMediaProviderByFile } from "../utils/helpers";
import { generateId } from "../utils/generators";
import { checkMediaGap, loadMedia } from "../utils/media";
import { CallbackDoc } from "react-google-drive-picker/dist/typeDefs";
import { t } from "i18next";
import prettyBytes from "pretty-bytes";

const ACTIVE_UPLOADS_LIMIT = 1;

export const useUploads = ({
  config,
  user,
  idClient,
}: {
  config: any;
  user: User | null;
  idClient?: number;
}): {
  getUploads: typeof getUploads;
  uploads: typeof uploads;
  handleCreateUpload: typeof handleCreateUpload;
  activeUploads: typeof activeUploads;
  succeedUploads: typeof succeedUploads;
  progress: typeof progress;
  addUploadsToQueue: typeof addUploadsToQueue;
  startUploadQueue: typeof startUploadQueue;
  uploadFile: typeof uploadFile;
  deleteUploads: typeof deleteUploads;
  updateUploads: typeof updateUploads;
  mergeUploads: typeof mergeUploads;
  unmergeUploads: typeof unmergeUploads;
} => {
  const uploadService = useRef(UploadService({ config })).current;

  const {
    uploads,
    pendingUploads,
    activeUploads,
    succeedUploads,
    progress,
    getPendingUploads,
    getActiveUploads,
    setUploads,
    setPendingUploads,
    setActiveUploads,
    setSucceedUploads,
    setProgress,
    setUploadInfoText,
  } = uploadStore();

  useEffect(() => {
    getUploads();
  }, [idClient]);

  // --- ACTIONS ---

  const getUploads = useCallback(async () => {
    if (idClient) {
      const newUploads = await uploadService.getClientUploads(idClient);
      setUploads(() => newUploads);
    }
  }, [idClient, setUploads, uploadService]);

  const addUploadsToQueue = async ({
    files,
    attachedFiles,
    getUserToken,
    googleDriveToken,
    client,
    duration,
    mediaProvider,
    onFail,
  }: {
    files: File[] | CallbackDoc[];
    attachedFiles?: Record<string, File[]>;
    client: Client;
    getUserToken: () => Promise<string | undefined>;
    googleDriveToken?: string;
    duration?: number;
    mediaProvider?: MediaProvider;
    onFail: () => void;
  }) => {
    if (!user) return;
    const newUploads = files.map((file) => {
      const idUpload = generateId();
      const newUpload: PendingUpload = {
        idUpload,
        name: file.name,
        status: UploadStatus.pending,
        client,
        user,
        startUpload: async () => {
          uploadFile({
            idUpload,
            getUserToken,
            googleDriveToken,
            client,
            file,
            duration,
            mediaProvider,
            onFail,
            attachedFiles: attachedFiles && attachedFiles[file.name],
          });
        },
      };
      return newUpload;
    });
    setPendingUploads((prevUploads: PendingUpload[]) => [
      ...prevUploads,
      ...newUploads,
    ]);
  };

  const handleCreateUpload = async ({
    files,
    client,
    onFail,
    attachedFiles,
    googleDriveToken,
    getToken,
    duration,
    mediaProvider,
  }: {
    files: File[] | CallbackDoc[];
    client: Client;
    onFail: () => void;
    attachedFiles?: Record<string, File[]>; // additional files to upload to same bucket path such as aaf
    googleDriveToken?: string;
    getToken: (forceRefresh?: boolean) => Promise<string | undefined>;
    duration?: number;
    mediaProvider?: MediaProvider;
  }) => {
    if (!idClient || !user?.idUser) {
      onFail && onFail();
      return;
    }
    await addUploadsToQueue({
      getUserToken: () => getToken(true),
      googleDriveToken,
      files: files as File[],
      attachedFiles,
      client: client,
      duration,
      mediaProvider,
      onFail: () => onFail && onFail(),
    });
    startUploadQueue();
  };

  const startUploadQueue = async () => {
    const _pendingUploads = getPendingUploads();
    const _activeUploads = getActiveUploads();
    const _stableActiveUploads = _activeUploads.filter(
      (upload) => upload.status !== UploadStatus.error
    );
    if (
      _stableActiveUploads.length === ACTIVE_UPLOADS_LIMIT ||
      _.isEmpty(_pendingUploads)
    )
      return;

    const nextUploadToActivate = _pendingUploads[0];
    nextUploadToActivate.status = UploadStatus.uploading;
    await nextUploadToActivate.startUpload();
    setPendingUploads(() => _pendingUploads.splice(1));

    setActiveUploads(() => [nextUploadToActivate, ..._activeUploads]);
  };

  const updateActiveUpload = (updates: Partial<ActiveUpload>) => {
    setActiveUploads((prevActiveUploads) => {
      const updatedActiveUploads = [...prevActiveUploads];
      updatedActiveUploads[0] = { ...updatedActiveUploads[0], ...updates };
      return updatedActiveUploads;
    });
  };

  const beforeUploadAttachedFilesCallback = (idUpload: string) => {
    setProgress(idUpload, null);
    updateActiveUpload({ status: UploadStatus.attaching_files });
    setUploadInfoText(idUpload, t("attaching_files"));
  };

  const uploadFile = async ({
    idUpload,
    getUserToken,
    googleDriveToken,
    client,
    file,
    onFail,
    attachedFiles,
    duration,
    mediaProvider,
  }: {
    idUpload: string;
    getUserToken: () => Promise<string | undefined>;
    googleDriveToken?: string;
    client: Client;
    file: File | CallbackDoc;
    duration?: number;
    mediaProvider?: MediaProvider;
    onFail: () => void;
    attachedFiles?: File[]; // change to metadata
  }) => {
    if (!user) return;

    const userToken = await getUserToken();
    if (!userToken) throw Error("no user token");

    const _mediaProvider = mediaProvider || getMediaProviderByFile(file);

    switch (_mediaProvider) {
      case MediaProvider.googleDrive:
        uploadService
          .uploadFilesWithGoogleDrive({
            fileId: (file as CallbackDoc).id,
            token: googleDriveToken as string,
            idUser: user.idUser,
            idClient: client.idClient,
            uploadTempId: idUpload,
            progress: (progressEvent) => {
              onUploadProgress(idUpload, progressEvent);
            },
          })
          .then((res) => handleUploadSuccess(res, idUpload))
          .catch((err) => handleUploadFail(err, idUpload));
        break;

      case MediaProvider.premierePlugin:
        uploadService
          .uploadFile({
            file: file as File,
            attachedFiles,
            duration: duration || 0,
            idUser: user.idUser,
            idClient: client.idClient,
            progress: (progressEvent) =>
              onUploadProgress(idUpload, progressEvent),
            userToken,
            beforeUploadAttachedFilesCallback: () =>
              beforeUploadAttachedFilesCallback(idUpload),
            provider: MediaProvider.premierePlugin,
          })
          .then((upload) => handleUploadSuccess(upload, idUpload))
          .catch((err) => {
            handleUploadFail(err, idUpload);
            if (onFail) {
              onFail();
            }
          });
        break;
      default: {
        const media = await loadMedia(file as File);
        uploadService
          .uploadFile({
            file: file as File,
            attachedFiles,
            duration: media?.duration,
            idUser: user.idUser,
            idClient: client.idClient,
            progress: (progressEvent) =>
              onUploadProgress(idUpload, progressEvent),
            userToken,
            beforeUploadAttachedFilesCallback: () =>
              beforeUploadAttachedFilesCallback(idUpload),
          })
          .then((upload) => handleUploadSuccess(upload, idUpload))
          .catch((err) => {
            handleUploadFail(err, idUpload);
            if (onFail) {
              onFail();
            }
          });
      }
    }
  };

  const deleteUploads = async (uploadIds: number[]): Promise<void> => {
    if (uploadIds.length > 0) {
      await uploadService.remove(uploadIds);

      setUploads((prevUploads: Upload[]) =>
        prevUploads.filter((u) => !uploadIds.includes(u.idUpload))
      );
    } else {
      throw new Error("No uploads selected");
    }
  };

  const updateUploads = async (
    uploadIds: number[],
    uploadData: Partial<Upload>
  ): Promise<void> => {
    if (uploadIds.length < 1) {
      throw new Error("No uploads selected");
    }

    if (uploadData.jobType?.typeName === "interview") {
      _.set(uploadData, "diarize.diarize", DiarizeTypes.UNSUPRTVISED);
    }

    const updatedUploads: Upload[] = await uploadService.updateMany(
      uploadIds,
      uploadData
    );

    setUploads((prevUploads) => {
      const _prevUploads = prevUploads.filter(
        (u) => !uploadIds.includes(u.idUpload)
      );
      return [..._prevUploads, ...updatedUploads];
    });
  };

  const mergeUploads = async (uploads: Upload[]): Promise<void> => {
    const uploadIds = uploads.map((u) => u.idUpload);
    const filesToGetMerged = _.flatMapDeep(uploads.map((u) => u.media));

    if (!checkMediaGap(filesToGetMerged.map((f) => f.duration)))
      throw new Error("files_can_not_be_merged");

    const mergedUpload = await uploadService.merge(uploadIds);

    setUploads((prevUploads) => {
      const updatedUploads = [
        ...prevUploads.filter((u) => !uploadIds.includes(u.idUpload)),
        mergedUpload,
      ];
      return updatedUploads;
    });
  };

  const unmergeUploads = async (idUpload: number, idMedia: number) => {
    const uploadToUnmerge = uploads.find((u) => u.idUpload === idUpload);
    if (!uploadToUnmerge) return;
    const { originUpload, upload } = await uploadService.unmerge(idMedia);
    setUploads((prevUploads) => {
      const updatedUploads = prevUploads.filter(
        (u: Upload) => u.idUpload !== idUpload
      );
      return [originUpload, upload, ...updatedUploads];
    });
  };

  // --- ACTIONS ---

  // --- HANDLERS ---

  const onUploadProgress = (
    idUpload: string,
    progressInfo: {
      total: number;
      loaded: number;
      speed: number;
    }
  ) => {
    if (
      progressInfo &&
      _.isNumber(progressInfo.total) &&
      _.isNumber(progressInfo.loaded)
    ) {
      const uploadProgress = Math.round(
        (progressInfo.loaded / progressInfo.total) * 100
      );
      setProgress(idUpload, {
        progress: uploadProgress,
        speed: progressInfo.speed,
        total: progressInfo.total,
        loaded: progressInfo.loaded,
      });
      const uploadInfoText = `(${prettyBytes(
        progressInfo.loaded
      )} of ${prettyBytes(progressInfo.total)})`;
      setUploadInfoText(idUpload, uploadInfoText);
    }
  };

  const handleUploadSuccess = (upload: Upload, idUpload: string) => {
    setActiveUploads((prevUploads) =>
      prevUploads.filter((u) => u.idUpload !== idUpload)
    );
    setUploads((prevUploads) => [upload, ...prevUploads]);
    setSucceedUploads((prevUploads) => [upload, ...prevUploads]);
    setProgress(idUpload, null);
    setUploadInfoText(idUpload, "");
    startUploadQueue();
  };

  const handleUploadFail = (err: any, idUpload: string | number) => {
    setActiveUploads((prevActiveUploads) => {
      const updatedActiveUploads = _.map(prevActiveUploads, (u) => {
        const updatedActiveUpload = u;
        if (u.idUpload === idUpload) {
          updatedActiveUpload.status = UploadStatus.error;
        }
        return updatedActiveUpload;
      });
      return updatedActiveUploads;
    });
    startUploadQueue();
  };

  // --- HANDLERS ---

  return {
    getUploads,
    uploads,
    activeUploads,
    succeedUploads,
    progress,
    handleCreateUpload,
    addUploadsToQueue,
    startUploadQueue,
    uploadFile,
    deleteUploads,
    updateUploads,
    mergeUploads,
    unmergeUploads,
  };
};

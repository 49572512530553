import { bazarTheme } from "../../bazar-theme";
import _ from "lodash";
import classNames from "classnames";
import React, { FC, useMemo, useState } from "react";
import {
  InputAdornment,
  InputBaseProps,
  InputLabel,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { generateId } from "../../utils/generators";
import { MultiSelect, Option } from "../MultiSelect/MultiSelect";
import { useTranslation } from "react-i18next";
import { PasswordReveal } from "../PasswordReveal/PasswordReveal";

import "./Input.scss";

interface Props {
  value: any;
  onChange?: (e: Partial<React.ChangeEvent<HTMLInputElement>>) => void;
  defaultValue?: any;
  className?: string;
  placeholder?: string | number;
  error?: string | null;
  required?: boolean;
  autofocus?: boolean;
  disabled?: boolean;
  min?: number | string;
  max?: number | string;
  type?:
    | "text"
    | "number"
    | "password"
    | "email"
    | "date"
    | "datetime-local"
    | "select"
    | "time";
  onBlur?: InputBaseProps["onBlur"];
  refs?: React.MutableRefObject<HTMLInputElement | null>;
  suffix?: string;
  prefix?: string | JSX.Element;
  regex?: RegExp;
  style?: React.CSSProperties;
  label?: string;
  options?: Option[];
  optional?: boolean;
}

export const Input: FC<Props> = ({
  value,
  onChange,
  defaultValue,
  className,
  placeholder,
  error,
  autofocus,
  required = false,
  disabled = false,
  min,
  max,
  type = "text",
  onBlur,
  refs,
  suffix,
  prefix,
  regex,
  style,
  label,
  options,
  optional,
}): JSX.Element => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const labelId = useMemo(() => {
    return generateId();
  }, []);
  const optionalText = useMemo(() => {
    const text = t("optional");
    return label ? ` (${text})` : text;
  }, [label]);

  return (
    <ThemeProvider theme={bazarTheme}>
      <div className={classNames("Input", className, { error: !!error })}>
        <InputLabel shrink htmlFor={labelId}>
          {label && label}
          {optional && optionalText}
        </InputLabel>
        {type !== "select" && (
          <TextField
            id={labelId}
            placeholder={placeholder?.toString()}
            autoFocus={autofocus}
            required={required}
            disabled={disabled}
            type={showPassword ? "text" : type}
            color={regex && !regex?.test(value) ? "warning" : "primary"}
            value={value}
            defaultValue={defaultValue}
            onChange={(e: any) => {
              if (onChange) onChange(e);
            }}
            InputProps={{
              inputProps: {
                min,
                max,
              },
              endAdornment: suffix && (
                <InputAdornment position="end">
                  <div color={"red"}>{suffix}</div>
                </InputAdornment>
              ),
              startAdornment: prefix && (
                <InputAdornment position="start">
                  <div color={"red"}>{prefix}</div>
                </InputAdornment>
              ),
              sx: { ...style },
            }}
            onBlur={onBlur}
            ref={refs}
          />
        )}
        {type === "select" && (
          <MultiSelect
            disabled={disabled}
            options={options || []}
            selected={
              _.isArray(value) ? value : !_.isEmpty(value) ? [value] : []
            }
            onChange={onChange}
            placeholder={(placeholder as string) || ""}
          />
        )}
        {type === "password" && (
          <PasswordReveal
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
        )}
        {error && <div className="inputError">{error}</div>}
      </div>
    </ThemeProvider>
  );
};

export default Input;

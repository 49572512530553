import React, { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHourglassClock,
  faMicrophone,
} from "@fortawesome/pro-light-svg-icons";

import { Job, JobStatus } from "@sumit-platforms/types";

import { useEllipsisObserver } from "@sumit-platforms/ui-bazar/hooks";

import "./JobInfoPanel.scss";

interface Props {
  job: Job;
}

const JobInfoPanel: FC<Props> = ({ job }) => {
  const { t } = useTranslation();
  const jobNameRef = useRef<HTMLDivElement>(null);
  const { isEllipsised } = useEllipsisObserver(jobNameRef);

  return (
    <div className="JobInfoPanel">
      <Tooltip title={isEllipsised ? <span>{job.name}</span> : null}>
        <div className="infoPanel">
          {job.status === JobStatus.kit_recording && (
            <div className="recording jobInfoItem">
              <FontAwesomeIcon icon={faMicrophone} color={"#FDB810"} />
            </div>
          )}
          {job.status === JobStatus.stt && (
            <div className="stt jobInfoItem">
              <FontAwesomeIcon icon={faHourglassClock} color={"#FDB810"} />
            </div>
          )}
          <div className="jobName jobInfoItem ellipsis">
            <span className="ellipsis" ref={jobNameRef}>
              {job.name}
            </span>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default JobInfoPanel;

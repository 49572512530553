import * as React from "react";
import { useEffect } from "react";
import _ from "lodash";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { TableRow, ThemeProvider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/pro-light-svg-icons";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import { TableBar } from "../TableBar/TableBar";
import { PopoverActionProps } from "../../popovers/PopoverAction/PopoverAction";
import { IconAction } from "../TableIconActions/TableIconActions";
import { bazarTheme, grayColor3, whiteColor } from "../../bazar-theme";
import { SpinningLoader } from "../SpinningLoader/SpinningLoader";
import { NoDataTablePlaceholder } from "../NoDataTablePlaceholder/NoDataTablePlaceholder";

import "./GeneralTable.scss";

export type Order = "asc" | "desc";

const HEAD_CELL_PADDING = "8px 8px 8px 10px";

export interface HeadCell<TData> {
  id: keyof TData;
  label: any;
  hideColumnContent?: boolean;
  hideColumn?: boolean;
  onLabelClick?: () => void;
  showLabel?: boolean;
  sortable?: boolean;
  sortProperty?: string;
  style?: React.CSSProperties;
  formatter?: (data: any) => any;
  barColAction?: PopoverActionProps;
  barColData?: () => any;
  barColActions?: IconAction[];
}

interface GeneralTableHeadProps<TData> {
  headCells: HeadCell<TData>[];
  allowSelect: boolean;
}

const GeneralTableHead = <TData,>({
  headCells,
  allowSelect,
}: Partial<GeneralTableHeadProps<TData>>) => {
  const headCellLabelClickHandler = (headCell: HeadCell<TData>) => {
    if (!headCell.onLabelClick) return;
    headCell.onLabelClick();
  };

  return (
    <TableHead>
      <TableRow
        sx={{
          background: "#3C5069",
        }}
      >
        {allowSelect ? (
          <TableCell
            variant="head"
            style={{ width: "1%" }}
            sx={{
              padding: HEAD_CELL_PADDING,
            }}
          ></TableCell>
        ) : null}
        {headCells
          ?.filter((headCell) => !headCell.hideColumn)
          .map((headCell, i) => {
            return (
              <TableCell
                onClick={() => headCellLabelClickHandler(headCell)}
                variant="head"
                style={headCell.style}
                sx={{
                  padding: HEAD_CELL_PADDING,
                  color: whiteColor,
                  cursor: "default",
                }}
                key={`${i}-table-key`}
              >
                {headCell.showLabel !== false && headCell.label}
              </TableCell>
            );
          })}
      </TableRow>
    </TableHead>
  );
};

export interface GeneralTableProps<TData> {
  headCells: HeadCell<TData>[];
  rows: { [key in keyof TData]: any }[];

  selected?: any[];
  setSelected?: (selected: any[]) => void;

  allowSelect?: boolean;

  loading?: boolean;
  hasMore?: boolean;
  onLoadMore?: (offset: number) => void;
  selectBy?: keyof TData;
  disablePointerEventsOnRowDisabled?: boolean;
}

export const GeneralTable = <
  TData extends {
    id: string | number;
    disabled?: boolean;
    onClick?: (_?: any) => any;
  }
>({
  headCells,
  rows,
  selected,
  setSelected,
  allowSelect = true,
  loading,
  hasMore,
  onLoadMore,
  selectBy = "id",
  disablePointerEventsOnRowDisabled = false,
}: GeneralTableProps<TData>) => {
  const seenOffsetsMap = React.useRef<Map<number, number>>(new Map());

  const handleScroll = _.throttle(() => {
    if (loading) return;
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    const isCloseToBottom = scrollTop + clientHeight >= scrollHeight - 150;
    if (isCloseToBottom) {
      if (
        hasMore &&
        _.isFunction(onLoadMore) &&
        !seenOffsetsMap.current.has(rows.length)
      ) {
        seenOffsetsMap.current.set(rows.length, rows.length);
        onLoadMore(rows.length);
      }
    }
  }, 500);

  const showEmptyState = React.useMemo(() => {
    return !loading && !hasMore && rows?.length === 0;
  }, [rows, hasMore, loading]);

  useEffect(() => {
    if (!rows?.length) return;
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, rows?.length]);

  useEffect(() => {
    const resetSeenOffsetsMap = () => {
      if (rows.length === 0) {
        seenOffsetsMap.current = new Map();
      }
    };
    resetSeenOffsetsMap();
  }, [rows]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!selected || !setSelected) return;
    if (event.target.checked) {
      const newSelected = rows
        .filter((r) => r.disabled !== true)
        .map((r) => r[selectBy]);
      setSelected(newSelected as string[]);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    _event: React.MouseEvent<unknown>,
    id: string | number
  ) => {
    if (!selected || !setSelected) return;
    const isAlreadySelected = selected.includes(id);
    if (!isAlreadySelected) {
      selected.push(id);
    } else if (isAlreadySelected) {
      selected = selected.filter((s) => s !== id);
    }
    const newSelected = [...selected];
    setSelected(newSelected);
  };

  const isSelected = (id: string | number) => selected?.includes(id);

  const allSelected = () => {
    if (!selected || !setSelected) return false;
    return (
      rows?.length > 0 &&
      selected?.length === rows.filter((r) => r.disabled !== true)?.length &&
      rows.filter((r) => r.disabled !== true)?.length > 0
    );
  };

  const moreThanOneSelected = (): boolean => {
    if (!selected || !setSelected) return false;
    return (
      rows?.length > 0 &&
      selected?.length > 1 &&
      rows.filter((r) => r.disabled !== true)?.length > 0
    );
  };
  return (
    <ThemeProvider theme={bazarTheme}>
      <div className={"BazarGeneralTable"} style={{ width: "100%" }}>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <GeneralTableHead
                  headCells={headCells}
                  allowSelect={allowSelect}
                />
                {(allowSelect || setSelected) && (
                  <TableBar
                    moreThanOneSelected={moreThanOneSelected()}
                    allSelected={allSelected()}
                    headCells={headCells}
                    handleSelectAllClick={handleSelectAllClick}
                    allowSelect={allowSelect}
                  />
                )}
                <TableBody>
                  {rows?.length
                    ? rows?.map((r: any, index: number) => {
                        const isItemSelected = isSelected(r[selectBy]);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            className={
                              r.disabled
                                ? "rowDisabled"
                                : r.onClick
                                ? "pointer"
                                : "rowEnabled"
                            }
                            sx={
                              r.disabled
                                ? {
                                    pointerEvents:
                                      disablePointerEventsOnRowDisabled
                                        ? "none"
                                        : "auto",
                                  }
                                : {}
                            }
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={r.key || r.id || r.name || r.uploaded}
                            selected={isItemSelected}
                            onClick={() => {
                              r.onClick && r.onClick();
                            }}
                          >
                            {allowSelect ? (
                              <TableCell style={{ width: "1%" }}>
                                <Checkbox
                                  onClick={(event) => {
                                    if (r.disabled) return;
                                    handleClick(event, r[selectBy]);
                                  }}
                                  icon={
                                    <FontAwesomeIcon
                                      icon={faSquare}
                                      color={"#FDB810"}
                                    />
                                  }
                                  checkedIcon={
                                    <FontAwesomeIcon
                                      icon={faCheckSquare}
                                      color={"#FDB810"}
                                    />
                                  }
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell>
                            ) : null}

                            {headCells
                              ?.filter((headCell) => !headCell.hideColumn)
                              .map((hc: HeadCell<TData>) => {
                                const formatter = hc?.formatter;
                                const cellContent = r[hc.id];
                                return (
                                  <TableCell
                                    key={hc?.id as string}
                                    style={hc?.style}
                                  >
                                    {!hc?.hideColumnContent &&
                                      (formatter ? formatter(r) : cellContent)}
                                  </TableCell>
                                );
                              })}
                          </TableRow>
                        );
                      })
                    : showEmptyState && <NoDataTablePlaceholder />}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
        {loading && (
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Box width={35} paddingY={2}>
              <SpinningLoader />
            </Box>
          </Box>
        )}
      </div>
    </ThemeProvider>
  );
};

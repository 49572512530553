import React from "react";
import MediaService from "../../services/MediaService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTachometerAlt } from "@fortawesome/pro-light-svg-icons";

interface Props {
  setCurrSpeed: (currSpeed: number) => void;
  currSpeed: number;
}

export const SpeedTimes = ({ currSpeed, setCurrSpeed }: Props) => {
  return (
    <div className="speed-control">
      <div className="speed-control-section">
        {[2, 1.75, 1.5, 1.25, 1, 0.75, 0.5].map((speed: number) => {
          return (
            <div
              key={speed}
              className="speed"
              onClick={() => {
                MediaService.changeSpeed(speed);
                setCurrSpeed(speed);
              }}
            >
              {speed}
            </div>
          );
        })}
      </div>

      <FontAwesomeIcon icon={faTachometerAlt} />
      <p className="unselectable">{currSpeed}</p>
    </div>
  );
};

import { Box } from "@mui/material";
import { AssignedUser, AssignmentTask, Job } from "@sumit-platforms/types";
import { Dispatch, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getPriceStringWithCurrency } from "@sumit-platforms/ui-bazar/utils";
import { Checkbox } from "@sumit-platforms/ui-bazar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/pro-light-svg-icons";

import "./ConfirmUnassign.scss";

interface ConfirmUnassignProps {
  job: Job;
  previousCommunityMember: AssignedUser;
  setPayPreviousCommunityMember: Dispatch<boolean>;
  payPreviousCommunityMember: boolean;
  newCommunityMember?: AssignedUser;
}

export const ConfirmUnassign = ({
  job,
  previousCommunityMember,
  setPayPreviousCommunityMember,
  payPreviousCommunityMember,
  newCommunityMember,
}: ConfirmUnassignProps) => {
  const { t } = useTranslation();

  const price = useMemo(() => {
    return previousCommunityMember.task === AssignmentTask.qc
      ? job.qcPrice
      : job.transcribePrice;
  }, [job, previousCommunityMember]);

  return (
    <Box className={"ConfirmUnassign"}>
      {newCommunityMember && (
        <Box className="dangerLine">
          {t("change_cm_danger_message", {
            from: `${previousCommunityMember.firstName} ${previousCommunityMember.lastName}`,
            to: `${newCommunityMember.firstName} ${newCommunityMember.lastName}`,
          })}
        </Box>
      )}
      <Box className="actionLine">
        <Checkbox
          icon={<FontAwesomeIcon icon={faSquare} color={"#3C5069"} />}
          checkedIcon={
            <FontAwesomeIcon icon={faCheckSquare} color={"#3C5069"} />
          }
          checked={payPreviousCommunityMember}
          onChange={(e) => {
            setPayPreviousCommunityMember((e.target as any)?.checked || "");
          }}
        ></Checkbox>
        {t("change_cm_action_message", {
          from: `${previousCommunityMember.firstName} ${previousCommunityMember.lastName}`,
          price: getPriceStringWithCurrency({ price, currency: "ILS" }),
        })}
      </Box>
    </Box>
  );
};

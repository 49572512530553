import {
  JobRevisionsRequestParams,
  JobFilterOptions,
  JobStatus,
  SaveTriggers,
} from "@sumit-platforms/types";
import { TODAY_MIDNIGHT } from "./globals";

export const jobDefaultQuery: JobFilterOptions = {
  status: [],
  projectIds: [],
  type: [],
  inputLanguage: [],
  outputLanguage: [],
  speakerIds: [],
  durationStart: 0,
  durationEnd: 0,
  deliveryStart: TODAY_MIDNIGHT,
  deliveryEnd: TODAY_MIDNIGHT,
  uploadedStart: TODAY_MIDNIGHT,
  uploadedEnd: TODAY_MIDNIGHT,
  clientIds: [],
  tagIds: [],
  uploaders: [],
};

export const availableStatusesMap = {
  must: {
    deliveries: [
      JobStatus.pending,
      JobStatus.pending_source_transcription,
      JobStatus.pending_splits_transcription,
      JobStatus.stt,
      JobStatus.aligning,
      JobStatus.splitting,
      JobStatus.transcribe,
      JobStatus.stt_fail,
      JobStatus.ready,
      JobStatus.pending_review,
      JobStatus.review,
      JobStatus.kit_recording,
      JobStatus.archive,
      JobStatus.done,
    ],
  },
  opera: {
    jobs: [
      JobStatus.pending,
      JobStatus.pending_source_transcription,
      JobStatus.pending_splits_transcription,
      JobStatus.stt,
      JobStatus.aligning,
      JobStatus.splitting,
      JobStatus.transcribe,
      JobStatus.stt_fail,
      JobStatus.ready,
      JobStatus.done,
      JobStatus.archive,
      JobStatus.pending_review,
      JobStatus.review,
      JobStatus.kit_recording,
    ],
    availableJobs: [JobStatus.ready, JobStatus.pending_review],
  },
};

export const archivedStatuses = {
  // archived status will be a status that no longer relevant or out of scope of a specific user
  transcriber: [
    JobStatus.transcribe,
    JobStatus.pending_review,
    JobStatus.review,
    JobStatus.done,
    JobStatus.archive,
  ],
  qc: [JobStatus.review, JobStatus.done, JobStatus.archive],
};

export const defaultJobRevisionsQuery: Partial<JobRevisionsRequestParams> = {
  excludeSaveTriggers: [SaveTriggers.AUTO_SAVE],
};

import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Client,
  CommunityPaymentStatus,
  Job,
  JobStatus,
  JobWithPayment,
  WalletMonthMetaData,
} from "@sumit-platforms/types";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useFilters, useHeadCells } from "../../../hooks";
import { Breadcrumbs } from "../../core/Breadcrumbs/Breadcrumbs";
import { monthNames } from "../../utils/date";
import { SearchAndFilters } from "../../core/SearchAndFilters/SearchAndFilters";
import { GeneralTable } from "../../core/GeneralTable/GeneralTable";
import {
  formatPaymentTypeFromJobWithPayment,
  getPriceStringWithCurrency,
} from "../../utils/formatters";
import {
  isAfter,
  isBefore,
  isValid,
  isWithinInterval,
  parseISO,
} from "date-fns";

interface WalletMonthProps {
  month: string;
  year: string;
  metaData: WalletMonthMetaData;
  defaultQuery: Record<string, any>;
  jobs: JobWithPayment[];
  isJobsLoading: boolean;
}

interface WalletMonthRow {
  paymentType: string;
  id: number;
  key: string;
  name: string;
  client: Client;
  type: "protocol" | "interview" | "subtitles";
  status: JobStatus;
  duration: number;
  uploaded: Date;
  delivery: Date;
  totalPaymentsString: string;
  totalPayments: number;
  inputLanguage: string;
  outputLanguage: string;
  projectName: string;
  disabled: boolean;
  job: Job;
}

export const WalletMonth = ({
  month,
  year,
  metaData,
  defaultQuery,
  jobs,
  isJobsLoading,
}: WalletMonthProps) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [rows, setRows] = useState<WalletMonthRow[]>([]);
  const initialRows = useRef<WalletMonthRow[]>([]);
  const { headCells } = useHeadCells({
    headCellsKeys: [
      "id",
      "name",
      "client",
      "paymentType",
      "duration",
      "transcribeDelivery",
      "totalPayments",
    ],
    styles: {
      name: { width: "25%" },
      client: { width: "20%" },
      duration: { width: "10%" },
      transcribeDelivery: { width: "10%" },
      type: { width: "11%" },
    },
  });
  const { filtersScheme } = useFilters({
    filterKeys: ["languages", "length"],
    metaData,
  });

  const updatedDefaultQuery = useMemo(() => {
    const _defaultQuery = { ...defaultQuery };
    _defaultQuery["durationStart"] = 0;
    _defaultQuery["durationEnd"] = metaData.maxDuration;
    return _defaultQuery;
  }, [defaultQuery, metaData]);

  useEffect(() => {
    const rows = jobs?.length ? jobs.map((job) => createJobRow(job)) : [];
    setRows(rows);
    initialRows.current = rows;
  }, [jobs]);

  useEffect(() => {
    const { durationStart, durationEnd, uploadedStart, uploadedEnd } =
      Object.fromEntries(searchParams.entries());
    const inputLanguage = searchParams.getAll("inputLanguage");
    const outputLanguage = searchParams.getAll("outputLanguage");
    const rows = [...initialRows.current];
    const newRows = rows.filter((row) => {
      const inputLanguageFilter = filterByLanguage(inputLanguage, row);
      const outputLanguageFilter = filterByLanguage(outputLanguage, row);
      const rowDuration = row.duration;
      const durationFilter = filterByDuration(
        durationStart,
        rowDuration,
        durationEnd
      );
      // Date filter is currently not supported because of ui bugs, but the
      // logic is ready
      const uploadedFilter = filterByDateRange(row, uploadedStart, uploadedEnd);
      return (
        inputLanguageFilter &&
        outputLanguageFilter &&
        durationFilter &&
        uploadedFilter
      );
    });
    setRows(newRows);
  }, [searchParams]);

  const onWalletBreadcrumbsClick = () => {
    setSearchParams({}); // will cause to redirect back to wallet.tsx
  };
  const onSearch = (e: any) => {
    const newRows = initialRows.current.filter((r) =>
      r.name.toLowerCase()?.includes(e.target.value)
    );
    setRows(newRows);
  };

  const createJobRow = (jobWithPayment: JobWithPayment) => {
    const paymentType = formatPaymentTypeFromJobWithPayment(jobWithPayment, t);
    return {
      key: `${jobWithPayment?.idJob}-${jobWithPayment?.task}-${jobWithPayment?.isBonus}`,
      jobTask: `${t(jobWithPayment?.task)} ${
        jobWithPayment?.isBonus ? `(${t("bonus")})` : ""
      }`,
      paymentType,
      isBonus: jobWithPayment?.isBonus,
      id: jobWithPayment?.idJob,
      name: jobWithPayment?.name || jobWithPayment?.paymentDescription,
      client: jobWithPayment?.client,
      status: jobWithPayment?.status,
      type: jobWithPayment?.type?.typeName || "-",
      duration: jobWithPayment?.duration || 0,
      uploaded: jobWithPayment?.createdAt
        ? new Date(jobWithPayment?.createdAt)
        : null,
      delivery: jobWithPayment?.delivery
        ? new Date(jobWithPayment?.delivery)
        : null,
      totalPaymentsString: getPriceStringWithCurrency({
        price: jobWithPayment?.payment as number,
        currency: "ILS",
      }),
      totalPayments: jobWithPayment?.payment,
      inputLanguage: jobWithPayment?.inputLanguage
        ? jobWithPayment?.inputLanguage[0]
        : null,
      outputLanguage: jobWithPayment?.outputLanguage
        ? jobWithPayment?.outputLanguage[0]
        : null,
      projectName: jobWithPayment?.project?.name,
      disabled:
        jobWithPayment?.paymentStatus === CommunityPaymentStatus.PENDING_QC,
      job: jobWithPayment,
    } as WalletMonthRow;
  };

  const filterByDuration = (
    durationStart: string,
    rowDuration: number,
    durationEnd: string
  ) =>
    (!durationStart || rowDuration >= +durationStart) &&
    (!durationEnd || rowDuration <= +durationEnd);

  const filterByLanguage = (language: string[], row: WalletMonthRow) =>
    !language ||
    !language.length ||
    language.some((lang) => row.outputLanguage?.includes(lang));

  const filterByDateRange = (
    row: any,
    uploadedStart: string,
    uploadedEnd: string
  ) => {
    const rowUploadedDate = parseISO(row.uploadedAt);
    const isStartDateValid = isValid(parseISO(uploadedStart));
    const isEndDateValid = isValid(parseISO(uploadedEnd));

    if (isStartDateValid && isEndDateValid) {
      return isWithinInterval(rowUploadedDate, {
        start: parseISO(uploadedStart),
        end: parseISO(uploadedEnd),
      });
    }

    if (isStartDateValid) {
      return isAfter(rowUploadedDate, parseISO(uploadedStart));
    }

    if (isEndDateValid) {
      return isBefore(rowUploadedDate, parseISO(uploadedEnd));
    }

    return true;
  };

  return (
    <Grid
      className={"WalletMonth Page"}
      container
      display={"flex"}
      justifyContent={"center"}
    >
      <Grid item xs={11} mb={3}>
        <Breadcrumbs
          crumbs={[
            {
              label: t("wallet"),
              path: "/wallet",
            },
            {
              label: t(`${t(monthNames[month])} ${year.toString().slice(-2)}`),
            },
          ]}
        />
        <h3 className="pageSubTitle">
          {`${t("showing")} ${rows.length} ${t("from")} ${
            initialRows.current.length
          } ${t("jobs").toLowerCase()}`}
        </h3>
      </Grid>
      <Grid item xs={11} mb={3}>
        <SearchAndFilters
          direction={t("dir") as any}
          onSearch={onSearch}
          isLoading={false}
          searchPlaceholder={`${t("search")} ${t("job").toLowerCase()}`}
          filterBtnTitle={t("filters") as string}
          title={t("filter_by") as string}
          defaultQuery={updatedDefaultQuery}
          filters={filtersScheme}
        />
      </Grid>
      <Grid item xs={11} mb={3}>
        <GeneralTable
          headCells={headCells}
          rows={rows}
          allowSelect={false}
          loading={isJobsLoading}
          hasMore={false}
        />
      </Grid>
    </Grid>
  );
};

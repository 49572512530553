import axios from "axios";
import {
  Project,
  ProjectConfig,
  ProjectDto,
  ProjectPage,
  ProjectTable,
  ProjectTableQuery,
  QueryParams,
} from "@sumit-platforms/types";

const ProjectService = ({ config }: { config: any }) => {
  const projectsEndpoint = config.server.host + "/" + config.server.projects;

  const getProjects = async (): Promise<Project[]> => {
    try {
      const response = await axios.get<Project[]>(`${projectsEndpoint}`);
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getClientProjects = async (
    idClient: number,
    includeSettings?: boolean
  ): Promise<Project[] | ProjectPage[]> => {
    try {
      const response = await axios.get<Project[]>(
        `${projectsEndpoint}/client/${idClient}`,
        {
          params: {
            includeSettings,
          },
        }
      );
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getMyJobsProjects = async (): Promise<Project[]> => {
    try {
      const response = await axios.get<Project[]>(
        `${projectsEndpoint}/getMyJobsProjects`
      );
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getTableProjects = async (
    query?: QueryParams<ProjectTableQuery>
  ): Promise<{
    totalProjects: number;
    projects: ProjectTable[];
  }> => {
    try {
      const response = await axios.get<{
        totalProjects: number;
        projects: ProjectTable[];
      }>(`${projectsEndpoint}/getTableProjects`, {
        params: query,
      });
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getProjectsQueryMetaData = async () => {
    try {
      const response = await axios.get(
        `${projectsEndpoint}/projectsQueryMetaData`
      );
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getProjectPage = async (idProject: number): Promise<ProjectPage> => {
    try {
      const response = await axios.get(`${projectsEndpoint}/${idProject}`);
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const clientsGetProjectPage = async (
    idProject: number
  ): Promise<ProjectPage> => {
    try {
      const response = await axios.get(
        `${projectsEndpoint}/clientsGetProjectPage/${idProject}`
      );
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const createNewProject = async (project: ProjectDto): Promise<Project> => {
    try {
      const response = await axios.post(projectsEndpoint, {
        project,
      });
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const updateProject = async (
    project: Partial<Project | ProjectPage>,
    idProject: number
  ) => {
    try {
      const response = await axios.put(`${projectsEndpoint}/${idProject}`, {
        project,
      });
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const clientUpdateProjectSettings = async ({
    settings,
    idClient,
    idProject,
  }: {
    settings: ProjectConfig;
    idClient: number;
    idProject: number;
  }) => {
    try {
      const response = await axios.put(
        `${projectsEndpoint}/clientUpdateProjectSettings`,
        {
          settings,
          idClient,
          idProject,
        }
      );
      if (response.status !== 200) throw new Error(response.statusText);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const removeProject = async (
    sourceIdProject: number,
    targetIdProject: number
  ): Promise<ProjectTable> => {
    try {
      const response = await axios.delete<ProjectTable>(
        `${projectsEndpoint}/${sourceIdProject}`,
        {
          params: { targetIdProject },
        }
      );
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    getProjects,
    getClientProjects,
    getMyJobsProjects,
    getTableProjects,
    getProjectsQueryMetaData,
    getProjectPage,
    clientsGetProjectPage,
    createNewProject,
    updateProject,
    clientUpdateProjectSettings,
    removeProject,
  };
};

export default ProjectService;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "./resources";
import { LocalStorageService } from "@sumit-platforms/ui-bazar/services";

const lng: string = LocalStorageService.getItem("lang")
  ? (LocalStorageService.getItem("lang") as string)
  : "en";

i18n.use(initReactI18next).init({
  resources,
  lng,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

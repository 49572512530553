import { useState } from "react";
import { Alert } from "@mui/material";
import { sumitLogoBlack } from "@sumit-platforms/common-assets/images";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { EnchancedAlert } from "./../../store/alert";
import { Button } from "./../../core/Button/Button";
import { FormInput } from "./../../core/FormInput/FormInput";

type LoginEmailInputs = {
  email: string;
  password: string;
};

type Link = {
  to: string;
  label: string;
};

export const LoginEmailForm = ({
  authService,
  links,
  logoSrc = sumitLogoBlack,
}: {
  authService: any;
  links: Link[];
  logoSrc?: string;
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<EnchancedAlert>();
  const [signInWithEmailAndPassword] = useSignInWithEmailAndPassword(
    authService.auth
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<LoginEmailInputs>({ mode: "onChange" });

  const handleEmailLogin = async (data: {
    email: string;
    password: string;
  }) => {
    try {
      setIsLoading(true);
      const result = await signInWithEmailAndPassword(
        data.email,
        data.password
      );
      if (!result) {
        throw new Error(t("wrong_details"));
      }
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      setError({ message: err.message, severity: "error", title: "" });
    }
  };

  return (
    <div className="authContainer">
      <div className="headerTitle">
        <img src={logoSrc} alt="Sumit-AI" className="headerLogo" />
      </div>
      <div className="splitContainer">
        <div className="userPasswordContainer">
          <form
            className="styledForm"
            onSubmit={handleSubmit(handleEmailLogin)}
          >
            <FormInput
              className="styledInput alignEnd"
              placeholder={t("email")}
              autofocus={true}
              disabled={isLoading}
              error={isDirty && !isValid ? errors.email?.message : ""}
              register={register("email", {
                required: { value: true, message: t("required_field") },
              })}
            />
            <FormInput
              className="styledInput alignEnd"
              placeholder={t("password")}
              type="password"
              disabled={isLoading}
              error={isDirty && !isValid ? errors.password?.message : ""}
              register={register("password", {
                required: { value: true, message: t("required_field") },
              })}
            />
            <Button
              className={"fullWidth dark"}
              loading={isLoading}
              disabled={!isValid}
              type="submit"
              onClick={handleSubmit(handleEmailLogin)}
            >
              {t("sign_in")}
            </Button>
            <div className="linksContainer">
              {links.map((l, i) => (
                <Link key={`${i}-link`} className="labelLink" to={l.to}>
                  {l.label}
                </Link>
              ))}
            </div>
            {error && <Alert severity={error.severity}>{error.message}</Alert>}
          </form>
        </div>
      </div>
      <div id="verifier-container" style={{ visibility: "hidden" }}></div>
    </div>
  );
};

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { FixedSizeList as List } from "react-window";

interface VirtualizedListProps {
  items: any[];
  maxHeight: number;
  itemCount: number;
  itemHeight: number;
  render: (item: any, idx: number) => JSX.Element;
  className?: string;
  dir?: "rtl" | "ltr";
  onWheel?: (e: WheelEvent) => void;
}
export type VirtualizedListRef = {
  listRef: HTMLDivElement | null;
};

export const VirtualizedList = forwardRef(
  (
    {
      render,
      items,
      maxHeight,
      itemCount,
      itemHeight,
      className,
      dir,
      onWheel,
    }: VirtualizedListProps,
    ref: React.Ref<VirtualizedListRef>
  ) => {
    const listRef = useRef<null | HTMLDivElement>(null);

    useImperativeHandle(
      ref,
      () => {
        return {
          listRef: listRef.current,
        };
      },
      [listRef]
    );

    const ListItem = ({ index, style }: any) => (
      <div style={style}>{render(items[index], index)}</div>
    );

    const height = useMemo(() => {
      if (items.length > 4) return maxHeight;
      return items.length * itemHeight;
    }, [items.length, maxHeight, itemHeight]);

    useEffect(() => {
      if (!listRef?.current || !onWheel) return;
      listRef.current.addEventListener("wheel", onWheel);

      return () => {
        if (!listRef.current || !onWheel) return;
        listRef.current.removeEventListener("wheel", onWheel);
      };
    }, [listRef, onWheel]);

    return (
      <List
        height={height}
        itemCount={itemCount}
        itemSize={itemHeight || 35}
        width={"auto"}
        className={className}
        direction={dir || "ltr"}
        outerRef={listRef}
      >
        {ListItem}
      </List>
    );
  }
);

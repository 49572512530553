import { Editor } from "../interfaces/editor";
import { Transforms } from "../interfaces/transforms";
import { Range } from "../interfaces/range";
import { WithEditorFirstArg } from "../utils/types";

export const deleteBackward: WithEditorFirstArg<Editor["deleteBackward"]> = (
  editor,
  unit
) => {
  const { selection } = editor;

  if (selection && Range.isCollapsed(selection)) {
    Transforms.delete(editor, { unit, reverse: true });
  }
};

import React from "react";
import { InputLabel, MenuItem, Theme } from "@mui/material";
import Select, { SelectProps } from "@mui/material/Select";
import { ThemeProvider } from "@emotion/react";
import { bazarTheme, grayColor3 } from "../../bazar-theme";
import { useTranslation } from "react-i18next";

import "./SelectSimple.scss";

export interface SelectSimpleOption {
  label: string;
  value: any;
}

export interface SelectSimpleProps extends Omit<SelectProps, "onChange"> {
  options: SelectSimpleOption[];
  onChange?: (option: SelectSimpleOption) => any;
  onBlur?: (event: any) => any;
  disabled?: boolean;
  label?: string;
  style?: React.CSSProperties;
  theme?: Theme;
}

export const SelectSimple = ({
  options,
  value,
  onChange,
  onBlur,
  label,
  disabled,
  style = {},
  theme = bazarTheme,
}: SelectSimpleProps) => {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={theme}>
      {label && <InputLabel shrink>{label}</InputLabel>}
      <Select
        className="SelectSimple"
        size="small"
        inputProps={{ "aria-label": "Without label" }}
        sx={{
          maxWidth: "100%",
          ...style,
        }}
        onChange={(e: any) => {
          if (onChange) onChange(e.target?.value);
        }}
        value={value}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: "250px",
              overflow: "scroll !important",
              borderRadius: "10px",
              border: `1px solid ${grayColor3}`,
              "& .MuiMenuItem-root": {
                height: "35px",
              },
            },
          },
        }}
        disabled={disabled}
        onBlur={onBlur}
      >
        {options.map((option: any, i: number) => (
          <MenuItem key={i} value={option.value}>
            {t(option.label)}
          </MenuItem>
        ))}
      </Select>
    </ThemeProvider>
  );
};

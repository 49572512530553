export const MAX_GAP_FOR_MERGE = 5 * 60;
export const TODAY_MIDNIGHT = new Date(new Date().setHours(0, 0, 0, 0));
export const DAY_MONTH_YEAR = "dd/MM/yyyy";
export const DATE_FORMAT_1 = "dd/MM/yyyy - HH:mm";
export const DATE_FORMAT_2 = "dd.MM.yy";
export const DATE_FORMAT_FILENAME = "dd-MM-yyyy HH-mm";
export const DATETIME_INPUT_FORMAT = "yyyy-MM-dd'T'HH:mm";
export const DATE_INPUT_FORMAT = "yyyy-MM-dd";
export const VIDEO_FORMATS = ["mp4", "mov"];

export const JOBS_INITIAL_QUERY_LIMIT = 20;
export const JOBS_INITIAL_QUERY_OFFSET = 0;
export const JOBS_INITIAL_ORDER_BY = "created_at";
export const JOBS_INITIAL_ORDER = "DESC";

export const CLIENTS_INITIAL_QUERY_LIMIT = 40;
export const CLIENTS_INITIAL_QUERY_OFFSET = 0;
export const CLIENTS_INITIAL_ORDER_BY = "yearly_target";
export const CLIENTS_INITIAL_ORDER = "DESC";

export const USERS_INITIAL_QUERY_LIMIT = 20;
export const USERS_INITIAL_QUERY_OFFSET = 0;
export const USERS_INITIAL_ORDER_BY = "created_at";
export const USERS_INITIAL_ORDER = "DESC";

export const PROJECTS_INITIAL_QUERY_LIMIT = 20;
export const PROJECTS_INITIAL_QUERY_OFFSET = 0;
export const PROJECTS_INITIAL_ORDER_BY = "created_at";
export const PROJECTS_INITIAL_ORDER = "DESC";

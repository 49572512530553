import React from "react";
import {
  Ancestor,
  BaseElement,
  BaseText,
  Descendant,
  Editor,
  Element,
  NodeEntry,
  Range,
} from "@sumit-platforms/slate";
import {
  CustomChildrenProps,
  RenderElementProps,
  RenderLeafProps,
  RenderPlaceholderProps,
} from "../components/editable";

import ElementComponent from "../components/element";
import TextComponent from "../components/text";
import { ReactEditor } from "../plugin/react-editor";
import { NODE_TO_INDEX, NODE_TO_PARENT } from "../utils/weak-maps";
import { useDecorate } from "./use-decorate";
import { SelectedContext } from "./use-selected";
import { useSlateStatic } from "./use-slate-static";
import ScrollableSubtitlesEditor from "./scrollable-subtitles-editor";

const getDecorate = (
  p: number[],
  decorate: (entry: NodeEntry) => Range[],
  n: BaseElement | BaseText,
  editor: any
) => {
  const [idx] = p;
  const { startIndex, visibleRanges } = editor;
  if (idx < startIndex || idx > startIndex + visibleRanges) {
    return [];
  }
  return decorate([n, p]);
};

/**
 * Children.
 */

const useContentScrollChildren = (
  props: {
    decorations: Range[];
    node: Ancestor;
    renderElement?: (props: RenderElementProps) => JSX.Element;
    renderPlaceholder: (props: RenderPlaceholderProps) => JSX.Element;
    renderLeaf?: (props: RenderLeafProps) => JSX.Element;
    selection: Range | null;
  } & CustomChildrenProps
) => {
  const {
    decorations,
    node,
    renderElement,
    renderPlaceholder,
    renderLeaf,
    selection,
  } = props;
  const decorate = useDecorate();
  const editor = useSlateStatic() as ReactEditor;
  const path = ReactEditor.findPath(editor, node);
  const children: any[] = [];
  const isLeafBlock =
    Element.isElement(node) &&
    !editor.isInline(node) &&
    Editor.hasInlines(editor, node);

  for (let i = 0; i < node.children.length; i++) {
    const p = path.concat(i);
    const n = node.children[i] as Descendant;
    const key = ReactEditor.findKey(editor, n);
    const range = Editor.range(editor, p);
    const sel = selection && Range.intersection(range, selection);
    const ds = getDecorate(p, decorate, n, editor); //TODO: skip if not visible

    for (const dec of decorations) {
      const d = Range.intersection(dec, range);

      if (d) {
        ds.push(d);
      }
    }

    if (Element.isElement(n)) {
      children.push(
        <SelectedContext.Provider key={`provider-${key.id}`} value={!!sel}>
          <ElementComponent
            decorations={ds}
            element={n}
            key={key.id}
            renderElement={renderElement}
            renderPlaceholder={renderPlaceholder}
            renderLeaf={renderLeaf}
            selection={sel}
          />
        </SelectedContext.Provider>
      );
    } else {
      // We should get inside here
      children.push(
        <TextComponent
          decorations={ds}
          key={key.id}
          isLast={isLeafBlock && i === node.children.length - 1}
          parent={node}
          renderPlaceholder={renderPlaceholder}
          renderLeaf={renderLeaf}
          text={n}
        />
      );
    }

    NODE_TO_INDEX.set(n, i);
    NODE_TO_PARENT.set(n, node);
  }

  return (
    <ScrollableSubtitlesEditor
      children={children}
      contentScroll={props.contentScroll}
    />
  );
};

export default useContentScrollChildren;

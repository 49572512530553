import { useContext, useEffect, useMemo } from "react";
import { KeyBindingMap, tinykeys } from "tinykeys";
import {
  ShortcutAction,
  ShortcutHandler,
  ShortcutType,
} from "@sumit-platforms/types";
import {
  ShortcutsContext,
  ShortcutsContextProps,
} from "./useKeyboardShortcutsContext";

type UseShortcutProps = {
  handlers?: ShortcutHandler;
  ref?: SVGTextElement | HTMLElement | Window | null;
  disabled: boolean; // Mandatory until FF is stable
};

export const useKeyboardShortcuts = (
  props?: UseShortcutProps
): ShortcutsContextProps => {
  if (props?.disabled) return {} as ShortcutsContextProps;

  const context = useContext(ShortcutsContext);
  if (!context) {
    throw new Error(
      "useKeyboardShortcuts must be used within a ShortcutsProvider"
    );
  }

  const keymap: KeyBindingMap = useMemo(() => {
    const _keymap: Record<string, any> = {};
    const handlers = props?.handlers || {};

    for (const handler in handlers) {
      const shortcutFunction = handlers[handler as ShortcutAction];
      const shortcut = context.userShortcuts.find((s) => {
        if (
          context?.editorType &&
          s.editorTypes &&
          s.type !== ShortcutType.GLOBAL &&
          !s.editorTypes.includes(context?.editorType)
        ) {
          return false;
        }
        return s.action === handler;
      });
      if (!shortcut) continue;
      if (shortcut.disabled === true) continue;
      const { keys } = shortcut;
      for (const combination of keys) {
        if (!combination) continue;
        _keymap[combination] = shortcutFunction;
      }
    }
    return _keymap;
  }, [props?.handlers]);

  useEffect(() => {
    if (!context.userShortcuts) return;
    const target = props?.ref instanceof HTMLElement ? props.ref : window;
    const unsubscribe = tinykeys(target, keymap);
    return unsubscribe;
  }, [props?.ref, keymap]);

  return context;
};

import * as _ from "lodash";
import { Annotation } from "../Job/Annotation";
import {
  Align,
  CalendarsTypes,
  FontSettings,
  ListTypeName,
  LogoSettings,
  TextDecoration,
} from "./CommonConfig";

export const markedSpeakers: string[] = ["מראיין", "מראיינת", "interviewer"];

export type DocxFormatType = "speakers" | "table";

export enum TranscriptType {
  annotation = "annotation",
  speaker = "speaker",
}

export type DocxTranscriptRange = {
  text: string;
  type?: TranscriptType;
  speaker?: string;
  annotationType?: Annotation["type"];
  time?: string | undefined;
}[];

export type Transcript = {
  text: string;
  type?: TranscriptType;
  speaker?: string;
  annotationType?: Annotation["type"];
  time?: number | undefined;
  tcTime?: number;
};

export type IntervalTranscript = {
  timecode: string;
  transcripts: Transcript[];
};

export type keyOfSpeakersDocxConfig = keyof SpeakersDocxConfig;

export type keyOfTableDocxConfig = keyof TableDocxConfig;

export class GeneralPage {
  presetName?: string = "";
  format: "pages" | "audio" = "audio";
  font: Partial<FontSettings> = {
    fontFamily: "Arial",
    fontSize: 12,
    align: Align.start,
  };
  rowCount = false;

  constructor(args?: GeneralPage) {
    if (args && typeof args !== "undefined") {
      Object.assign(this, _.defaultsDeep(args, this));
    }
  }
}

export class HeaderFooterPage {
  firstPageHeader: {
    isActive: boolean;
    logo: LogoSettings;
    jobName: { isActive: boolean; align: Align };
    jobDate: { isActive: boolean; align: Align };
  } = {
    isActive: true,
    logo: {
      isActive: false,
      align: Align.center,
      size: 180,
      url: "",
      file: "",
    },
    jobName: {
      isActive: true,
      align: Align.center,
    },
    jobDate: {
      isActive: false,
      align: Align.center,
    },
  };

  otherPagesHeader: {
    isActive: boolean;
    logo: LogoSettings;
    jobName: { isActive: boolean; align: Align };
    jobDate: { isActive: boolean; align: Align };
  } = {
    isActive: true,
    logo: {
      isActive: false,
      align: Align.center,
      size: 180,
      url: "",
      file: "",
    },
    jobName: {
      isActive: true,
      align: Align.center,
    },
    jobDate: {
      isActive: false,
      align: Align.center,
    },
  };

  footer: {
    isActive: boolean;
    logo: LogoSettings;
    pageNumbering: {
      isActive: boolean;
      align: Align;
      type?: "page x" | "page x from y";
    };
    footerText: string;
  } = {
    isActive: true,
    logo: {
      isActive: false,
      align: Align.center,
      size: 70,
      url: "",
      file: "",
    },
    pageNumbering: {
      isActive: true,
      align: Align.center,
      type: "page x",
    },
    footerText: "",
  };

  constructor(args?: HeaderFooterPage) {
    if (args && typeof args !== "undefined") {
      Object.assign(this, _.defaultsDeep(args, this));
    }
  }
}

export class InformationPage {
  jobName: {
    isActive: boolean;
    fontSize: number;
    textDecoration: TextDecoration[];
    color: string;
    align: Align;
  } = {
    isActive: true,
    fontSize: 16,
    textDecoration: [],
    color: "",
    align: Align.center,
  };
  jobDate: {
    isActive: boolean;
    calendars: CalendarsTypes[];
    fontSize: number;
    textDecoration: TextDecoration[];
    color: string;
    align: Align;
  } = {
    isActive: true,
    calendars: [CalendarsTypes.hebrew],
    fontSize: 16,
    textDecoration: [],
    color: "",
    align: Align.center,
  };
  attendees: { isActive: true } = {
    isActive: true,
  };
  format = "row";
  showTitle = true;
  showRoleType = true;

  constructor(args?: InformationPage) {
    if (args && typeof args !== "undefined") {
      Object.assign(this, _.defaultsDeep(args, this));
    }
  }
}

export class TableOfSubjectsPage {
  tableOfSubjects: { isActive: boolean } = {
    isActive: true,
  };
  newPage = true;
  sign: ListTypeName = ListTypeName.NONE;
  font: Partial<FontSettings> = {
    fontSize: 16,
    textDecoration: [],
    align: Align.center,
    color: "",
  };

  constructor(args?: TableOfSubjectsPage) {
    if (args && typeof args !== "undefined") {
      Object.assign(this, _.defaultsDeep(args, this));
    }
  }
}

export class RemarksPage {
  startEnd: {
    isActive: boolean;
    text1: string;
    text2: string;
    font: Partial<FontSettings>;
  } = {
    isActive: true,
    text1: "",
    text2: "",
    font: {
      fontSize: 16,
      textDecoration: [],
      align: Align.center,
      color: "",
    },
  };
  greeting: { isActive: boolean; text1: string; font: Partial<FontSettings> } =
    {
      isActive: true,
      text1: "",
      font: {
        fontSize: 16,
        textDecoration: [],
        align: Align.center,
        color: "",
      },
    };
  signature: { font: Partial<FontSettings>; names: string[] } = {
    font: {
      fontSize: 16,
      textDecoration: [],
    },
    names: [],
  };

  constructor(args?: RemarksPage) {
    if (args && typeof args !== "undefined") {
      Object.assign(this, _.defaultsDeep(args, this));
    }
  }
}

export class SubjectsPage {
  subjects: { isActive: boolean } = {
    isActive: true,
  };
  sign: ListTypeName = ListTypeName.NONE;
  font: Partial<FontSettings> = {
    fontSize: 16,
    textDecoration: [],
    align: Align.center,
    color: "",
  };

  constructor(args?: SubjectsPage) {
    if (args && typeof args !== "undefined") {
      Object.assign(this, _.defaultsDeep(args, this));
    }
  }
}

export class SpeakersPage {
  showTitle = false;
  timing = true;
  newLine = false;
  indentation = false;
  interviewerInBold = false;
  markedSpeakers: string[] = markedSpeakers;
  font: Partial<FontSettings> = {
    fontSize: 16,
    textDecoration: [],
    color: "",
    align: Align.center,
  };

  constructor(args?: SpeakersPage) {
    if (args && typeof args !== "undefined") {
      Object.assign(this, _.defaultsDeep(args, this));
    }
  }
}

export class DecisionsPage {
  decisions: {
    isActive: boolean;
    viewType: "a" | "b" | "c";
    numbering: boolean;
    font: Partial<FontSettings>;
  } = {
    isActive: true,
    viewType: "a",
    numbering: true,
    font: {
      fontSize: 16,
      textDecoration: [],
      align: Align.center,
      color: "",
    },
  };
  // votes: {
  //   isActive: boolean;
  //   numbering: boolean;
  //   attendeesVotes: boolean;
  //   format: "table";
  //   font: Partial<FontSettings>;
  // } = {
  //   isActive: true,
  //   numbering: true,
  //   attendeesVotes: true,
  //   format: "table",
  //   font: {
  //     fontSize: 16,
  //     textDecoration: [],
  //     color: "",
  //   },
  // };
  // gatherDecisions: {
  //   isActive: boolean;
  //   font: Partial<FontSettings>;
  // } = {
  //   isActive: true,
  //   font: {
  //     fontSize: 16,
  //     textDecoration: [],
  //     align: Align.center,
  //     color: "",
  //   },
  // };

  constructor(args?: DecisionsPage) {
    if (args && typeof args !== "undefined") {
      Object.assign(this, _.defaultsDeep(args, this));
    }
  }
}

export class NotesPage {
  notes: { isActive: boolean; font: Partial<FontSettings> } = {
    isActive: true,
    font: {
      fontSize: 16,
      textDecoration: [],
      align: Align.center,
      color: "",
    },
  };

  constructor(args?: NotesPage) {
    if (args && typeof args !== "undefined") {
      Object.assign(this, _.defaultsDeep(args, this));
    }
  }
}

export class TasksPage {
  tasks: { isActive: boolean; font: Partial<FontSettings> } = {
    isActive: true,
    font: {
      fontSize: 16,
      textDecoration: [],
      align: Align.center,
      color: "",
    },
  };
  gatherTasks: { isActive: boolean; font: Partial<FontSettings> } = {
    isActive: true,
    font: {
      fontSize: 16,
      textDecoration: [],
      align: Align.center,
      color: "",
    },
  };

  constructor(args?: TasksPage) {
    if (args && typeof args !== "undefined") {
      Object.assign(this, _.defaultsDeep(args, this));
    }
  }
}

export class ImagesPage {
  images: { isActive: boolean; align: Align } = {
    isActive: true,
    align: Align.center,
  };

  constructor(args?: ImagesPage) {
    if (args && typeof args !== "undefined") {
      Object.assign(this, _.defaultsDeep(args, this));
    }
  }
}

export class FocusedDocumentPage {
  focusedDocument: {
    isActive: boolean;
    subjects: boolean;
    decisions: boolean;
    tasks: boolean;
  } = {
    isActive: true,
    subjects: true,
    decisions: true,
    tasks: true,
  };

  constructor(args?: FocusedDocumentPage) {
    if (args && typeof args !== "undefined") {
      Object.assign(this, _.defaultsDeep(args, this));
    }
  }
}

export class IntervalsGeneralPage {
  presetName?: string = "Default";
  font: Partial<FontSettings> = {
    fontFamily: "Arial",
    fontSize: 14,
    align: Align.start,
  };
  letterSpacing = 1.2;
  rowsSpacing = 1.5;
  paragraphSpacing = 1.0;

  constructor(args?: IntervalsGeneralPage) {
    if (args && typeof args !== "undefined") {
      Object.assign(this, _.defaultsDeep(args, this));
    }
  }
}

export class IntervalsSpeakersPage {
  showName = true;
  font: Partial<FontSettings> = {
    fontSize: 14,
    color: "",
    textDecoration: [],
  };
  markedSpeakers: string[] = markedSpeakers;
  interviewerInBold = false;

  constructor(args?: IntervalsSpeakersPage) {
    if (args && typeof args !== "undefined") {
      Object.assign(this, _.defaultsDeep(args, this));
    }
  }
}

export class IntervalsAndTimingPage {
  intervals = 30;
  timecode = "00:00:00";
  constructor(args?: IntervalsAndTimingPage) {
    if (args && typeof args !== "undefined") {
      Object.assign(this, _.defaultsDeep(args, this));
    }
  }
}

export interface DocxConfig {
  headerFooter: HeaderFooterPage;
}

export interface SpeakersDocxConfig extends DocxConfig {
  general: GeneralPage;
  information: InformationPage;
  tableOfSubjects: TableOfSubjectsPage;
  remarks: RemarksPage;
  subjects: SubjectsPage;
  speakers: SpeakersPage;
  decisions: DecisionsPage;
  notes: NotesPage;
}

export interface TableDocxConfig extends DocxConfig {
  general: IntervalsGeneralPage;
  speakers: IntervalsSpeakersPage;
  intervalsAndTiming: IntervalsAndTimingPage;
}

export interface TextRunContent<THeading> {
  phrases: {
    text: string;
    fontSettings: Partial<FontSettings>;
    break?: number;
  }[];
  fontSettings?: Partial<FontSettings>;
  heading?: THeading;
  frame?: boolean;
  disableRowCount?: boolean;
  indent?: boolean;
}

export interface ParagraphContent<THeading> {
  textRunContent: TextRunContent<THeading>[];
  fontSettings: Partial<FontSettings>;
  marginTop?: number;
}

export interface SignatureTableContent {
  columsContent: string;
  fontSettings: Partial<FontSettings>;
}

export interface TableOfContentsContent {
  title: string;
  fontSettings: Partial<FontSettings>;
}

export interface TableFormatTable<THeading> {
  time: ParagraphContent<THeading>;
  transcripts: ParagraphContent<THeading>[] | null;
}

export interface TableFormatPreviewTableContent<THeading> {
  keyContent: ParagraphContent<THeading> | null;
  valueContent: ParagraphContent<THeading> | null;
}

export interface DocxHeaderItemSettings {
  show: boolean;
  align: Align;
  style: Partial<FontSettings>;
}
export interface SpeakersShape<THeading> {
  sections: {
    initialSection: ParagraphContent<THeading>[];
    tableOfSubjectsSection: TableOfContentsContent[];
    contentSection: ParagraphContent<THeading>[];
    signatureSection: SignatureTableContent[];
  };
}

export interface TableShape<THeading> {
  sections: {
    intervalsInitialSection: TableFormatPreviewTableContent<THeading>[];
    intervalsContentSection: TableFormatTable<THeading>[];
  };
}

import _ from "lodash";
import { Box } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import {
  allowedImportSubtitles,
  Attachment,
  AttachmentType,
  fileInputAccept,
  jobSourceDataTypes,
  JobTranscriptionSource,
  JobTypeMeta,
  JobTypes,
  jobTypes,
  jobTypesOptions,
  MulterFileInfo,
  UploadTableRow,
} from "@sumit-platforms/types";

import { Button } from "../../core/Button/Button";
import { MultiSelect, Option } from "../../core/MultiSelect/MultiSelect";
import classNames from "classnames";
import { FileInput } from "../../core/FileInput/FileInput";
import { ToggleButtonGroup } from "../../core/ToggleButtonGroup/ToggleButtonGroup";
import { Switch } from "../../core/Switch/Switch";
import { primaryColor } from "../../bazar-theme";
import { UploadFilesTableFeatureFlags } from "../../core/UploadFilesTable/UploadFilesTable";

import "./JobTypeSelectPopover.scss";

export interface TypeDataProps {
  uploadAttachment: (attachment: File) => void;
  removeAttachment: (idUpload: number, idAttachment: number) => void;
  setAutoTranscript: (autoTranscript: boolean) => void;
  setTranscriptionType: (transcriptionType: JobTranscriptionSource) => void;
  setBurn: (burn: boolean) => void;
  setRepresentative: (representative: boolean) => void;
  rows: UploadTableRow[];
  allowAutoTranscript?: boolean;
  shouTranscriptionTypeRadio?: boolean;
  handleUploadSourceFile?: ({
    idUpload,
    file,
  }: {
    idUpload: number;
    file: File;
  }) => Promise<undefined | MulterFileInfo | null>;
  handleRemoveSourceFile?: (idUpload: number) => Promise<void | null>;
  isSourceFileLoading?: boolean;
  isLoading?: boolean;
  shouldShowSkipStt?: boolean;
  toast?: {
    success: (message: string) => void;
    error: (message: string, err: any) => void;
    info: (message: string) => void;
  };
}

export const TypeData = ({
  uploadAttachment,
  removeAttachment,
  setAutoTranscript,
  setTranscriptionType,
  setBurn,
  setRepresentative,
  rows,
  isLoading,
  allowAutoTranscript,
  shouTranscriptionTypeRadio,
  isSourceFileLoading,
  handleUploadSourceFile,
  handleRemoveSourceFile,
  shouldShowSkipStt,
  toast,
}: TypeDataProps) => {
  const { t } = useTranslation();
  const fileInput = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const types = useMemo(() => rows.map((r) => r.jobType), [rows]);

  const transcriptionSourceButtons = useMemo(() => {
    const buttons = [
      {
        label: t(JobTranscriptionSource.STT.toLowerCase() as any) as string,
        value: JobTranscriptionSource.STT,
        infoTooltip: t("stt_transcription_source_tooltip" as any) as string,
      },
      {
        label: t(
          JobTranscriptionSource.SKIP_STT.toLowerCase() as any
        ) as string,
        value: JobTranscriptionSource.SKIP_STT,
        infoTooltip: t(
          "skip_stt_transcription_source_tooltip" as any
        ) as string,
        hide: !shouldShowSkipStt,
      },
    ];
    if (rows.length < 2) {
      const isAllSubtitles = _.every(
        rows,
        (r) => r.jobType?.typeName === "subtitles"
      );
      if (!isAllSubtitles) {
        return buttons;
      }
      buttons.push({
        label: t(
          JobTranscriptionSource.SOURCE_FILE.toLowerCase() as any
        ) as string,
        value: JobTranscriptionSource.SOURCE_FILE,
        infoTooltip: t(
          "src_file_transcription_source_tooltip" as any
        ) as string,
      });
    }
    return buttons;
  }, [rows]);

  const transcriptionSourceValue = useMemo(() => {
    if (rows.length < 2) {
      return rows[0]?.jobType?.transcriptionSource;
    }
    const allRowsValues = _.uniq(
      rows.map((r) => r.jobType?.transcriptionSource)
    );

    if (allRowsValues.length === 1) {
      return allRowsValues[0];
    }

    return null;
  }, [rows]);

  const handleAutoTranscriptChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAutoTranscript(event.target.checked);
  };

  const handleBurnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBurn(event.target.checked);
  };

  const handleUploadFile = async () => {
    const files = Array.from(
      fileInput.current.files ? fileInput.current.files : []
    );
    await uploadAttachment(files[0]);
  };
  const onUploadSourceFile = async (file: File) => {
    if (!handleUploadSourceFile) return;
    await handleUploadSourceFile({ idUpload: +rows[0].id, file });
  };
  const onRemoveUploadSrt = async () => {
    if (!handleRemoveSourceFile) return;
    await handleRemoveSourceFile(+rows[0].id);
  };

  const addFile = () => {
    fileInput.current.click();
  };

  const handleOnTranscriptionSourceChange = (
    e: any,
    newValue: JobTranscriptionSource
  ) => {
    setTranscriptionType(newValue);
  };

  const isAutoDeliveryDisabled = useMemo(
    () =>
      isLoading ||
      _.some(rows, (r) =>
        r.jobType?.transcriptionSource
          ? r.jobType?.transcriptionSource === JobTranscriptionSource.SKIP_STT
          : false
      ),

    [rows]
  );

  return (
    <div className="TypeData">
      {!_.isEmpty(types) &&
        !_.some(types, (t) => _.isNil(t)) &&
        _.every(
          types.map(
            (t) => jobTypesOptions[t?.typeName || ""]?.allowRepresentative
          )
        ) && (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            pt={2}
          >
            <Switch
              checked={
                rows[0] ? rows[0].jobType?.representative || false : false
              }
              onChange={(e) => setRepresentative(e.target.checked)}
              label={t("representative") as string}
              disabled={isLoading}
              labelStyle={{ color: primaryColor }}
            />
          </Box>
        )}

      {shouTranscriptionTypeRadio &&
        !_.isEmpty(types) &&
        !_.some(types, (t) => _.isNil(t)) &&
        _.every(
          types.map(
            (t) =>
              jobTypesOptions[t?.typeName || ""]?.allowAutomaticTranscription
          )
        ) && (
          <div className={"import-src-file"}>
            <div className={"title"}>{`${t(
              "transcription_source_label"
            )}:`}</div>
            <ToggleButtonGroup
              buttons={transcriptionSourceButtons}
              exclusive={true}
              onChange={handleOnTranscriptionSourceChange}
              value={transcriptionSourceValue}
              disabled={isLoading}
              defaultValue={JobTranscriptionSource.STT}
            />
            {rows[0]?.jobType?.transcriptionSource ===
              JobTranscriptionSource.SOURCE_FILE && (
              <FileInput
                className={"srtFileInput"}
                label={t("attach_src_file")}
                onFileChange={onUploadSourceFile}
                onDeleteFile={onRemoveUploadSrt}
                allowedFormats={allowedImportSubtitles}
                loading={isSourceFileLoading}
                file={rows[0]?.srcFile as any}
                toast={toast}
              />
            )}
          </div>
        )}
      {allowAutoTranscript &&
        !_.isEmpty(types) &&
        !_.some(types, (t) => _.isNil(t)) &&
        _.every(
          types.map(
            (t) =>
              jobTypesOptions[t?.typeName || ""]?.allowAutomaticTranscription
          )
        ) && (
          <div className={"auto-transcription"}>
            <label
              className={classNames("title", {
                disabled: isAutoDeliveryDisabled,
                pointer: !isAutoDeliveryDisabled,
              })}
              htmlFor={"auto-transcription-toggle"}
            >
              {t("automatic_transcription")}:
            </label>
            <Switch
              onChange={handleAutoTranscriptChange}
              checked={
                rows[0].jobType?.autoTranscript
                  ? rows[0].jobType?.autoTranscript
                  : false
              }
              disabled={isAutoDeliveryDisabled}
              id={"auto-transcription-toggle"}
            />
          </div>
        )}

      {!_.isEmpty(types) &&
        !_.some(types, (t) => _.isNil(t)) &&
        rows.length < 2 &&
        jobTypesOptions[rows[0].jobType?.typeName || ""].hasSrcFile && (
          <div className={"file"}>
            <div className="action">{t("upload_file")}</div>
            <div>
              <input
                className={"typeDataFileInput"}
                type="file"
                ref={fileInput}
                onChange={handleUploadFile}
                accept={fileInputAccept(jobSourceDataTypes)}
              />
              <Button disabled={isLoading} onClick={addFile} color="primary">
                {t("upload_file")}
              </Button>
            </div>
            {!_.isEmpty(
              rows[0].attachments.filter(
                (_a: Attachment) => (_a.type = AttachmentType.JOB_SOURCE_DATA)
              )
            ) &&
              rows[0].attachments
                .filter(
                  (_a: Attachment) => (_a.type = AttachmentType.JOB_SOURCE_DATA)
                )
                .map((_a: Attachment) => (
                  <div className="fileWrapper" key={_a.idAttachment}>
                    <div className="name">{_a.name.substring(0, 30)}</div>
                    <div
                      className="delete"
                      onClick={() =>
                        removeAttachment(rows[0].id as number, _a.idAttachment)
                      }
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </div>
                  </div>
                ))}
          </div>
        )}
    </div>
  );
};

export interface JobTypeSelectPopoverProps {
  update: (
    ids: number[],
    jobType: JobTypeMeta,
    idValidationPreset?: number
  ) => Promise<void>;
  uploadAttachment: (
    type: AttachmentType,
    attachment: File,
    idUploads: number[]
  ) => Promise<Attachment>;
  removeAttachment: (idUpload: number, idAttachment: number) => Promise<void>;
  rows: any[];
  validations: Option[];
  allowAutoTranscript?: boolean;
  uploadSourceFile?: (
    idUpload: string | number,
    file: File
  ) => Promise<MulterFileInfo>;
  removeSourceFile?: (idUpload: string | number) => Promise<void>;
  shouTranscriptionTypeRadio?: boolean;
  isLoading?: boolean;
  featureFlags?: UploadFilesTableFeatureFlags;
  toast?: {
    success: (message: string) => void;
    error: (message: string, err?: any) => void;
    info: (message: string) => void;
  };
}
export const JobTypeSelectPopover = ({
  uploadAttachment,
  removeAttachment,
  update,
  rows,
  allowAutoTranscript,
  isLoading,
  validations,
  shouTranscriptionTypeRadio,
  removeSourceFile,
  uploadSourceFile,
  featureFlags,
  toast,
}: JobTypeSelectPopoverProps) => {
  //TODO: refactore this component, it is too big and messy
  const { t } = useTranslation();
  const [selectedJobTypeValue, setSelectedJobTypeValue] =
    useState<keyof JobTypes>();
  const [selectedValidationPresetValue, setSelectedValidationPresetValue] =
    useState<number>();
  const [sourceFileLoading, setSourceFileLoading] = useState<
    Record<number, boolean>
  >({});

  const [options] = useState<Option[]>(
    jobTypes.map(
      (jobType) =>
        ({
          label: t(jobType),
          value: jobType,
          toolTip: t(`${jobType}_tooltip`),
        } as Option)
    )
  );
  const uploadsIds = useMemo(
    () => rows.map((r) => r.idUpload as number),
    [rows]
  );

  const setSelectedJobType = async (selectedJobType: Array<keyof JobTypes>) => {
    await update(uploadsIds, {
      ...rows[0].jobType,
      typeName: selectedJobType[0],
    });
    setSelectedJobTypeValue(selectedJobType[0]);
  };

  const setSelectedValidationPreset = async (
    validationPreset: Array<number>
  ) => {
    if (!_.isEmpty(validationPreset) && validationPreset[0])
      await update(
        uploadsIds,
        {
          ...rows[0].jobType,
        },
        validationPreset[0]
      );
    setSelectedValidationPresetValue(validationPreset[0]);
  };

  const handleUploadAttachment = async (attachment: File) => {
    await uploadAttachment(
      AttachmentType.JOB_SOURCE_DATA,
      attachment,
      uploadsIds
    );
  };

  const handleRemoveAttachment = async (
    idUpload: number,
    idAttachment: number
  ) => {
    await removeAttachment(idUpload, idAttachment);
  };

  const setAutoTranscript = async (autoTranscript: boolean) => {
    await update(uploadsIds, { ...rows[0].jobType, autoTranscript });
  };
  const setRepresentative = async (value: boolean) => {
    await update(uploadsIds, { ...rows[0].jobType, representative: value });
  };
  const setTranscriptionSource = async (
    transcriptionSource: JobTranscriptionSource
  ) => {
    const changes = { ...rows[0].jobType, transcriptionSource };
    if (transcriptionSource !== JobTranscriptionSource.STT) {
      changes.autoTranscript = false;
    }
    await update(uploadsIds, changes);
  };

  const setBurn = async (burn: boolean) => {
    await update(uploadsIds, { ...rows[0].jobType, burn });
  };

  const selectedValidationPreset = useMemo(() => {
    const _v =
      rows.length === 1 && rows[0].validationPreset?.idValidationPreset
        ? [rows[0].validationPreset?.idValidationPreset]
        : selectedValidationPresetValue
        ? [selectedValidationPresetValue]
        : [];
    return _v;
  }, [selectedValidationPresetValue, rows]);

  const handleUploadSourceFile = async ({
    idUpload,
    file,
  }: {
    idUpload: number;
    file: File;
  }) => {
    if (!uploadSourceFile) return;
    try {
      setSourceFileLoading((prev) => ({ ...prev, [idUpload]: true }));

      const srcFile = await uploadSourceFile(idUpload, file);

      await update([idUpload], {
        ...rows[0].jobType,
      });
      return srcFile;
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      setSourceFileLoading((prev) => ({ ...prev, [idUpload]: false }));
    }
  };

  const handleRemoveSourceFile = async (idUpload: number) => {
    if (!removeSourceFile) return;
    try {
      setSourceFileLoading((prev) => ({ ...prev, [idUpload]: true }));

      const uploadedFile = await removeSourceFile(idUpload);
      await update([idUpload], {
        ...rows[0].jobType,
      });
      return uploadedFile;
    } catch (err) {
      return null;
    } finally {
      setSourceFileLoading((prev) => ({ ...prev, [idUpload]: false }));
    }
  };

  return (
    <div className="JobTypeSelectPopover">
      <div className="action">
        <Box pb={2}>
          <div className="title">{`${t("select_job_type")}:`}</div>
          <MultiSelect
            selected={
              rows.length === 1 && rows[0].jobType?.typeName
                ? [rows[0].jobType.typeName]
                : [selectedJobTypeValue]
            }
            setSelected={setSelectedJobType}
            placeholder="Not selected"
            isMulti={false}
            options={options}
          />
        </Box>
        <Box>
          <div
            className={classNames("title", {
              disabled: _.isEmpty(validations) || !rows[0].jobType,
            })}
          >{`${t("select_validation")}:`}</div>
          <MultiSelect
            selected={selectedValidationPreset}
            setSelected={setSelectedValidationPreset}
            placeholder={
              _.isEmpty(validations) ? t("no_options") : t("not_selected")
            }
            isMulti={false}
            options={validations}
            disabled={_.isEmpty(validations) || !rows[0].jobType}
          />
        </Box>
        <TypeData
          uploadAttachment={handleUploadAttachment}
          setAutoTranscript={setAutoTranscript}
          setRepresentative={setRepresentative}
          setTranscriptionType={setTranscriptionSource}
          setBurn={setBurn}
          rows={rows}
          removeAttachment={handleRemoveAttachment}
          allowAutoTranscript={allowAutoTranscript}
          shouTranscriptionTypeRadio={shouTranscriptionTypeRadio}
          handleUploadSourceFile={handleUploadSourceFile}
          handleRemoveSourceFile={handleRemoveSourceFile}
          isLoading={isLoading}
          isSourceFileLoading={sourceFileLoading[rows[0].idUpload]}
          shouldShowSkipStt={featureFlags?.skipStt}
          toast={toast}
        />
      </div>
    </div>
  );
};

import React, { useState } from "react";
import { Button, ButtonProps } from "../Button/Button";

import "./ConfirmModal.scss";
import _ from "lodash";

export interface CustomModalButton {
  text: string;
  onBeforeConfirm?: ButtonProps["onClick"];
  color?: ButtonProps["color"];
  shouldShowLoader?: boolean; // showing loader according to isLoading internal state
  variant?: ButtonProps["variant"];
}
export interface ConfirmModalProps {
  confirm: () => void;
  cancel: () => void;
  title: string | JSX.Element;
  message: string | JSX.Element;
  closeAfterConfirm?: boolean;
  confirmTitle?: string;
  cancelTitle?: string;
  customButtons?: CustomModalButton[];
}

export const ConfirmModal = ({
  confirm,
  cancel,
  title,
  message,
  closeAfterConfirm = true,
  confirmTitle = "Confirm",
  cancelTitle = "Cancel",
  customButtons = [],
}: ConfirmModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleConfirm = async (onBeforeConfirm?: Function) => {
    setIsLoading(true);
    if (_.isFunction(onBeforeConfirm)) {
      await onBeforeConfirm();
    }
    await confirm();
    setIsLoading(false);
    if (closeAfterConfirm) cancel();
  };
  return (
    <div className="ConfirmModal">
      <div className="formBody">
        <div className="upperBody">{title}</div>
        <div className="middelBody">
          <div className="message">{message}</div>
        </div>
        <div className="lowerBody">
          <Button onClick={cancel} variant="outlined">
            {cancelTitle}
          </Button>
          {customButtons?.length ? (
            customButtons.map((buttonBlueprint) => (
              <Button
                key={buttonBlueprint.text}
                disabled={isLoading}
                loading={buttonBlueprint.shouldShowLoader && isLoading}
                onClick={() => handleConfirm(buttonBlueprint.onBeforeConfirm)}
                color={buttonBlueprint.color || "primary"}
                variant={buttonBlueprint.variant}
              >
                {buttonBlueprint.text}
              </Button>
            ))
          ) : (
            <Button
              loading={isLoading}
              onClick={() => handleConfirm()}
              color="primary"
            >
              {confirmTitle}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
